import { Container } from "react-bootstrap";

const SupportTicket = () => {
    return (
        <>
            <Container fluid>
                <h1>Support Ticket</h1>
            </Container>
        </>
    );
}

export default SupportTicket;