import React, { useEffect, useRef, useLayoutEffect, useState } from 'react'
import { api } from '../../../../../../../Utils/API';
import { GOOGLE_WORKSPACE_PRODUCT_PLANS, GOOGLE_WORKSPACE_PRODUCT_ORDERING, GOOGLE_WORKSPACE_BILLING_TERMS_TYPE, GET_GST_RATES, ACCEPT_LOGIN_TERMS_AND_CONDITIONS } from '../../../../../../../Utils/GlobalConstants';
import { Col, Container, Form, InputGroup, OverlayTrigger, Row, Table, Tooltip, Button } from 'react-bootstrap';
import styles from './GoogleworkspaceProductDetail.module.scss';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import EmptyDataTableFilterSearch from '../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import SearchDropdown from '../../../../SearchDropdown';
import { alphanumericRegex } from '../../../../../../../GlobalComponents/RegExPatterns';
import loadingGif from "../../../../../../../Assets/Images/Animations/loading-management-console.gif";
import { useLocation, useNavigate } from 'react-router-dom';

const GoogleworkspaceProductDetail = ({ productId, serviceId, domainName, customerId, customerName, selectCustomerErrorState, setSelectCustomerErrorState, checkResellerPrereqisiteFlag, setPlanSelectedFlag, isreadOnly, setToastError, setToastMessage, gwIsTransferOrderInProgress, isTransferGwsInProgress, billingPaymentGatewayData, setBillingPaymentGatewayErrorMessage, setBillingPaymentGatewayData, billingPaymentCardExpiryFlag, billingPaymentDirecrDebitExpiryFlag, setBillingPaymentGatewayErrorPopup, Logout, proceedToProvisionFlag }) => {
  const navigate = useNavigate();
  const [isdisableManage, setIsdisableManage] = useState(localStorage.getItem("disabled_array").includes("Manage") ? true : false);
  let GoogleworkspaceProductDetailColumnName = ["Plan", "SKU ID", "Billing Term", "Category", "RRP (Inc GST)", "Price (Ex GST)", "Add Quantity"];                            // Columns of table for Suspend Legacy plan Action 
  let ReadOnlyGoogleworkspaceProductDetailColumnName = ["Plan", "SKU ID", "Billing Term", "Category", "RRP (Inc GST)", "Price (Ex GST)"];                            // Columns of table for Suspend Legacy plan Action
  const [productDetailsData, setProductDetailsData] = useState(null);
  const [initProductDetailsData, setInitProductDetailsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emptyData, setEmptyData] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [newQuantity, setNewQuantity] = useState(1);
  // const [customerId, setCustomerId] = useState("8CA367F6-DBBE-45CB-9B17-D58D655C9965");

  const [selectedAddOn, setSelectedAddOn] = useState();
  const [activeQtyCheckFlag, setActiveQtyCheckFlag] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [selectedAddOnRows, setSelectedAddOnRows] = useState([]);
  const [planId, setPlanId] = useState(null);
  const [minQuantity, setMinQuantity] = useState();
  const [maxQuantity, setMaxQuantity] = useState();
  const [isEditingQuantity, setIsEditingQuantity] = useState(false);
  const [addonsCount, setAddOnsCount] = useState(0);
  const [initialSubscriptionTableCall, setInitialSubscriptionTableCall] = useState(null);
  const [searchValueTable, setSearchValueTable] = useState(null);                     // storing searching value in the search input box
  const [searchIconFlag, setSearchIconFlag] = useState(false);                        // to open and close search box
  const searchInputRef = useRef();                                                    // Search Input Box

  // Search Value for Billing Term
  const [billingTermDefaultValue, setBillingTermDefaultValue] = useState("Monthly Payment (Flexible Plan)");
  const [billingTermSearchValue, setBillingTermSearchValue] = useState();
  const [billingTerm, setBillingTerm] = useState([]);
  const [billingTermFiltered, setBillingTermFiltered] = useState(billingTerm);
  const updatedBillingTerm = [];
  const [billingTermId, setBillingTermId] = useState("FlexiblePlan");

  // Check the category is selected or not
  const [selectedCategory, setSelectedCategory] = useState([]);

  // PopUp Details
  const [subscriptionDetailIsLoadingFlag, setSubscriptionDetailIsLoadingFlag] = useState(false) // Subscription is Loading Popup
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [orderConfirmationSuccess, setOrderConfirmationSuccess] = useState(false);
  const [gwsProductDetailsPopupModalOpen, setGWSProductDetailsPopupModalOpen] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") == "false" ? false : true);
  const [poNumber, setPONumber] = useState("");
  const [basePrice, setBasePrice] = useState(0);
  const [gstAmount, setGstAmount] = useState(0);
  const [totalWithGST, setTotalWithGST] = useState(0);
  const [gstPercentage, setGstPercentage] = useState(null);
  const [poConfirmation, setPOConfirmation] = useState({
    isSuccess: true,
    message: "",
    orderCode: "",
    orderId: 0,
    errorMessages: []
  });
  const [existingDataCheck, setExistingDataCheck] = useState(false);
  const [intialTableLoad, setIntialTableLoad] = useState(true);

  const [customerPrerequisiteModalUpen, setCustomerPrerequisiteModalUpen] = useState(false);
  const [resellerPrerequisiteModalUpen, setResellerPrerequisiteModalUpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);    // Is Processing for Order Confirmation Button

  const [isPlanSelectedSticky, setIsPlanSelectedSticky] = useState(false);
  const [tableWidth, setTableWidth] = useState();
  const tableRef = useRef(null);

  const [ipAddress, setIpAddress] = useState(null);
  const userAgent = navigator.userAgent;
  const browserName = userAgent.match(/(Chrome|Safari|Firefox|Edge|Opera|Internet Explorer)\//);
  const browserVersion = userAgent.match(/\((.*?)\)/);

  useEffect(() => {
    const handleScroll = () => {
      const tableElement = tableRef.current;
      if (tableElement) {
        const tableRect = tableElement.getBoundingClientRect();
        const isTablePartiallyVisible =
          tableRect.top < window.innerHeight && tableRect.bottom > window.innerHeight;
        setIsPlanSelectedSticky(isTablePartiallyVisible);
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [selectedPlan]);

  useEffect(() => {
    if (selectedRowId.length > 0) {
      setTimeout(() => {
        const element = document.getElementById('gws-plan-selected-component');
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 10);
    }
  }, [selectedRowId]);

  useLayoutEffect(() => {
    const calculateTableWidth = () => {
      if (tableRef.current) {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 380) {
          setTableWidth('83%');
        } else if (screenWidth <= 480) {
          setTableWidth('86%');
        } else if (screenWidth <= 580) {
          setTableWidth('88%');
        } else if (screenWidth <= 680) {
          setTableWidth('91%');
        } else if (screenWidth <= 992) {
          setTableWidth('68%');
        } else {
          setTableWidth(`${tableRef.current.offsetWidth}px`);
        }
      }
    };
    calculateTableWidth();
  }, [selectedPlan]);

  useEffect(() => {
    const handleResize = () => {
      if (tableRef.current) {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 380) {
          setTableWidth('83%');
        } else if (screenWidth <= 480) {
          setTableWidth('86%');
        } else if (screenWidth <= 580) {
          setTableWidth('88%');
        } else if (screenWidth <= 680) {
          setTableWidth('91%');
        } else if (screenWidth <= 992) {
          setTableWidth('68%');
        } else {
          setTableWidth(`${tableRef.current.offsetWidth}px`);
        }
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleIncrease = (qty) => {
    if (parseInt(newQuantity) <= parseInt(maxQuantity)) {
      // console.log(qty);
      setNewQuantity(parseInt(qty) + 1);
      setIsEditingQuantity(true);
    } else {
      setNewQuantity(qty);
    }
  }

  const handleDecrease = (qty) => {
    // console.log("Qty....123", minQuantity);
    if (parseInt(newQuantity) > parseInt(minQuantity)) {
      setNewQuantity(parseInt(qty) - 1);
      setIsEditingQuantity(true);
    } else {
      setNewQuantity(qty);
    }
  }

  useEffect(() => {
    if (gstPercentage) {// Calculate base price by multiplying quantity and price for each item in selectedPlan
      const gstPercent = parseInt(gstPercentage) / 100;
      // console.log("GST PERCENTAGE : ", gstPercent);
      const totalBasePrice = selectedPlan.reduce((accumulator, item) => {
        return accumulator + (item.quantity * item.price);
      }, 0);

      setBasePrice(totalBasePrice.toFixed(2));

      // Calculate GST (assume 18% GST rate)
      const gst = totalBasePrice * gstPercent;
      setGstAmount(gst.toFixed(2));

      // Calculate total with GST
      const total = totalBasePrice + gst;
      setTotalWithGST(total.toFixed(2));
    }
  }, [selectedPlan, productDetailsData, gwsProductDetailsPopupModalOpen, gstPercentage]);

  // Delete the selected Plans
  const handleDeleteRow = (index) => {
    const updatedPlan = [...selectedPlan];
    // console.log("Plan Id", selectedPlan[index].planId);
    updatedPlan.splice(index, 1);
    setSelectedPlan(updatedPlan);
    // console.log(selectedPlan);
    setSelectedRowId(selectedRowId.filter(id => id != selectedPlan[index].planId));
    setSelectedCategory(selectedCategory.filter(item => item != selectedPlan[index].category));

    // console.log("selectedCategory", selectedPlan[index].category);


    // Find the index of the object with planId = "68167"
    const indexToUpdate = productDetailsData.findIndex(item => item.planId === selectedPlan[index].planId);

    // Make sure the index is valid before updating
    if (indexToUpdate !== -1) {
      // Create a copy of the productDetailsData array
      const updatedProductDetails = [...productDetailsData];

      // Update the quantity of the object at the specified index
      updatedProductDetails[indexToUpdate] = {
        ...updatedProductDetails[indexToUpdate],
        quantity: 0 // Update the quantity to 0
      };

      // Set the updated array using setProductDetailsData
      setProductDetailsData(updatedProductDetails);
    } else {
      console.error("Object not found with planId: 68167");
    }
  };

  const [resetData, setResetData] = useState(false);

  // Delete the selected Plans
  const resetTableData = () => {
    const updatedAddOnTable = productDetailsData.map(obj => ({ ...obj, quantity: 0 }));
    setProductDetailsData(updatedAddOnTable);
    setSelectedCategory([]);
    setSelectedRowId([]);
    setSelectedPlan([]);
    setGWSProductDetailsPopupModalOpen(false);
    setAddOnsCount(0);

    setPONumber("");
    setNewQuantity(null);
    setIsEditingQuantity(false);
  }


  function generateRandomIp() {
    // Generate random IP address components
    const octets = [];
    for (let i = 0; i < 4; i++) {
      octets.push(Math.floor(Math.random() * 256).toString());
    }

    // Join the octets with dots to form the IP address
    const ipAddress = octets.join(".");

    return ipAddress;
  }

  // Terms and Condition accept Popup Data
  async function TermsAndConditionAcceptFunction(browser, ipAddress) {
    (ipAddress === undefined || ipAddress === null || ipAddress === "null") ? ipAddress = generateRandomIp() : ipAddress = ipAddress;
    let body = {
      "browser": browser,
      "ipAddress": ipAddress,
      "module": "Cloud",
    }
    await api.post(ACCEPT_LOGIN_TERMS_AND_CONDITIONS, body, config).then((resp) => {
      // handle success
      // console.log(resp.data);
      localStorage.setItem("cloud_terms_and_conditions", "true");
      setIsTermsChecked(true);
    })
      .catch((error) => {
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
      });
  }

  useEffect(() => {
    if (!isTermsChecked) {
      fetch("https://api.ipify.org?format=json")
        .then(response => response.json())
        .then(data => setIpAddress(data.ip));
    }
  }, [isTermsChecked])

  useEffect(() => {
    resetData === true && (
      <>
        {resetTableData()}
        {setResetData(false)}

      </>

    )
  }, [resetData]);

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Get Table Data with Pagination and Filter and Search Functionality
  async function GetTableData(productId, searchValueTable, billingTermId, customerId) {
    setLoading(true);
    (productId === undefined || productId === null) ? productId = "" : productId = productId;
    (billingTermId === undefined || billingTermId === null) ? billingTermId = "" : billingTermId = billingTermId;
    (searchValueTable === undefined || searchValueTable === null) ? searchValueTable = "" : searchValueTable = searchValueTable;
    (customerId === undefined || customerId === null) ? customerId = "" : customerId = customerId;
    await api.get(`${GOOGLE_WORKSPACE_PRODUCT_PLANS}?productId=${productId}&searchText=${searchValueTable}&billingTerm=${billingTermId}&customerCode=${customerId}`, config)
      .then(resp => {

        if (resp.status == 204) {
          // console.log("Empty", searchValueTable == "", billingTermId, billingTermId === "FlexiblePlan");
          if (searchValueTable == "" && billingTermId === "FlexiblePlan") {
            setExistingDataCheck(true);
          }
          else {
            setEmptyData(true);
            setExistingDataCheck(false);
          }

        }
        // handle success
        else {

          setEmptyData(false);

          // Replace the plan from API with the plan already selected for another billing term.  
          const updatedProductDetailsData = resp.data.map(product => {
            const matchingPlan = selectedPlan.find(plan => plan.planId === product.planId);
            if (matchingPlan) {
              return matchingPlan;
            } else {
              return product;
            }
          });

          setProductDetailsData(updatedProductDetailsData);

          setInitProductDetailsData(resp.data);
          setExistingDataCheck(false);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch(error => {
        // console.log(error);
        setLoading(false);
        setExistingDataCheck(false);
        setEmptyData(true);
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        else {
          setServerError(true);
        }
      });
  };

  // Function to call all dropdown values in Billing term Section in Filter Box
  async function GetAllBillingTerm() {
    await api.get(GOOGLE_WORKSPACE_BILLING_TERMS_TYPE, config)
      .then(resp => {
        // console.log("Data : ", resp.data);
        setBillingTerm(resp.data);
      })
      .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        else {
          // console.log(error);
        }
      });
  };

  // Function to get GST RATE
  async function GetGST() {
    await api.get(GET_GST_RATES, config)
      .then(resp => {
        // console.log("Data : ", resp.data.gst);

        setGstPercentage(resp.data.gst);

      })
      .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        else {
          // console.log(error);
        }
      });
  };

  const resetGWSPO = () => {
    setPOConfirmation({
      isSuccess: true,
      message: "",
      orderCode: "",
      orderId: 0,
      errorMessages: []
    });
    setSelectedCategory([]);
    setProductDetailsData(initProductDetailsData);
    setSelectedRowId([]);
  }

  const [poPlans, setPOPlans] = useState([]);

  useEffect(() => {
    let updatedPoPlans = selectedPlan.map(poPlan => {
      return {
        planId: poPlan.planId,
        quantity: poPlan.quantity
      }
    });
    setPOPlans(updatedPoPlans);
    // console.log("poPlans", poPlans);
  }, [selectedPlan])


  // Transition Domain
  async function postOrderConfirmation() {
    setProductDetailsData(initProductDetailsData);
    setIsProcessing(true);
    const body = {
      cloudIdentityId: "",
      purchaseOrderNumber: poNumber,
      customerCode: customerId,
      domainName: domainName,
      plans: [...poPlans]
    };

    await api.post(GOOGLE_WORKSPACE_PRODUCT_ORDERING, body, config)
      .then(resp => {
        // console.log(resp);
        setSelectedPlan([]);
        setPOConfirmation(resp.data);
        setOrderConfirmationSuccess(true);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        
        if (error.status == 500) {
          // console.log("Error : ", error);
          setToastMessage("Difficult in reaching servers, try again later.");
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 20000);
        }
        else {
          // console.log("Error : ", error);
          setToastMessage("GWS Product Provision Failed at the moment, try again later.");
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 20000);
        }
      });

    setIsProcessing(false);
  }

  useEffect(() => {
    // console.log("Datataaa", productId, billingTermId);
    if (productId && !(billingTermId === undefined || billingTermId === null)) {
      GetTableData(productId, searchValueTable, billingTermId, customerId);
      GetAllBillingTerm(productId);
    }
  }, [productId, billingTermId, customerId]);

  // On search field value we trigger this function having react debugging after every 800 ms
  useEffect(() => {
    if (searchValueTable !== undefined && searchValueTable !== null) {
      const getData = setTimeout(() => {
        GetTableData(productId, searchValueTable, billingTermId, customerId)
      }, 800);
      return () => clearTimeout(getData);
    }
  }, [searchValueTable])

  // Search Box Focus on Open
  useEffect(() => {
    searchInputRef.current && searchInputRef.current.focus();
  }, [searchIconFlag]);

  useEffect(() => {
    if (customerId) {
      setSelectCustomerErrorState(false);
      setSelectedPlan([]);
      setSelectedRowId([]);
      setSelectedAddOn();
      setSelectedCategory([]);
    }
  }, [customerId])

  useEffect(() => {
    if (selectedPlan.length > 0) {
      // console.log("selected PLan :", selectedPlan);
      setPlanSelectedFlag(true);
    }
    else {
      setPlanSelectedFlag(false);
    }
  }, [selectedPlan])

  useEffect(() => {
    if (selectedRowId.length > 0) {
      setTimeout(() => {
        const element = document.getElementById("gws-plan-selected-component");
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 10);
      // console.log("I am gENUIS", selectedRowId)
    }
  }, [selectedRowId])


  return (
    <>
      <div className={`mt-4 ${styles['productlisting-main-table']}`}>
        {/* content when server error comes */}
        {serverError && !existingDataCheck &&
          <div className={styles["no-data-cloud-invoice"]}>
            <img src={EmptyDataTableFilterSearch} className="empty-cloud-summary-table-svg" />
            <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
          </div>
        }
        {
          existingDataCheck && existingDataCheck !== null &&
          <Row className='ml-4 mt-4'>
            <Col lg={10} md={8}>
              <span className='notes-confirmation'>You already have an google workspace account, please click on Manage service to go to service management page.</span>
            </Col>
            {!isdisableManage && <Col lg={2} md={4}>
              <div className='btn-wrapper justify-content-end d-flex mr-2'>
                <Button
                  className="px-4 pt-0 pb-0 pl-3 pr-3 w-md-auto col-xs-12"
                  onClick={() => navigate("/management-console/gws", { state: { customerId: customerId } })}
                >   Manage service
                </Button></div>
            </Col>}
          </Row>
        }

        {productDetailsData && !existingDataCheck && !serverError && !loading &&
          <>
            <Row className='mb-4 px-3 align-items-end'>
              <Col md={5} xs={4}>
                <h2 className='mt-1'>Plans</h2>
              </Col>
              <Col md={4} xs={8}>
                {
                  <InputGroup className="search-input-box search-input-box-xl mt-4">
                    <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                      <span className="searchgrey cpointer"
                      >
                        &nbsp;
                      </span>
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Search for plan and SKU ID"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      className="search-input-box-input"
                      value={searchValueTable}
                      onChange={(e) => setSearchValueTable(e.target.value)}
                      ref={searchInputRef}
                    />
                    <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                      onClick={() => {
                        setSearchValueTable("");
                        setSearchIconFlag(!searchIconFlag);
                      }}
                    >
                      <span className="closebtn">
                        &nbsp;
                      </span>
                    </InputGroup.Text>
                  </InputGroup>
                }
              </Col>
              <Col md={3} className={`${styles['product-plan-top-right-card']}`}>
                <SearchDropdown
                  dropdownLabel="Billing Term"
                  formGroupId="billingTermId"
                  placeholder="Enter Billing Term"
                  selectDefaultValue={billingTermDefaultValue}
                  setSelectDefaultValue={setBillingTermDefaultValue}
                  setOptionId={setBillingTermId}
                  selectOptions={billingTerm}
                  selectOptionsFiltered={billingTermFiltered}
                  setSelectOptionsFiltered={setBillingTermFiltered}
                  selectSearchValue={billingTermSearchValue}
                  setSelectSearchValue={setBillingTermSearchValue}
                  updatedOptions={updatedBillingTerm}
                  hasStaticOptions={false}
                  setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                />
              </Col>
            </Row>
            {!emptyData && !serverError &&
              <Table className={`${styles['csp-change-status-core-table']}`}
                responsive ref={tableRef}>
                <thead className="sticky-top">
                  <tr>
                    {(isreadOnly ? ReadOnlyGoogleworkspaceProductDetailColumnName : GoogleworkspaceProductDetailColumnName).map(th => {
                      return (<th className={
                        th === "Add Quantity" ? "text-center" :
                          (th === "Price (Ex GST)" || th === "RRP (Inc GST)") ? "text-right" : "text-left"

                      }>{
                          th === "Billing Term" ? <>{th}
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}

                            >
                              <span className="infoIcon-support-table infoIcon-support-table-big ml-1"></span>
                            </OverlayTrigger></> : th}</th>)
                    })
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    productDetailsData &&
                    productDetailsData.map((td, index) => {
                      return (
                        <tr key={index} id={'row-' + index} className={selectedRowId.includes(td.planId) ? styles["selected-row"] : ""}>
                          <td className={styles['small-width']}>{td.planName}</td>
                          <td >{td.skuId}</td>
                          <td className={styles['big-width']}>{td.billingTerm}</td>
                          <td className={styles['big-width']}>{td.category}</td>
                          <td className='text-right'>${td.strRRP}</td>
                          <td className='text-right'>${td.strPrice}</td>
                          <td className={isreadOnly ? "d-none" : `text-right ${styles['mobile-display']} ${styles['big-width']}`}>

                            <div className={styles['qty-wrapper']}>
                              {selectedAddOn != td.planId &&
                                <>
                                  <div className={`${styles['show-qty-btn-wrapper']} ${selectedCategory.includes(td.category) && !selectedRowId.includes(td.planId) ? styles['disabled'] : ""}`}>
                                    <label
                                      id={td.planId}
                                      className={`${styles['show-qty-btn']}`}
                                      onClick={(e) => {
                                        // console.log(selectedCategory);
                                        if (!selectedCategory.includes(td.category) || selectedRowId.includes(td.planId)) {
                                          if (!activeQtyCheckFlag) {
                                            e.preventDefault();
                                            // console.log(td.planId);
                                            setMaxQuantity(parseInt(td.maxQuantity));
                                            setMinQuantity(parseInt(td.minQuantity));
                                            setPlanId(td.planId);
                                            setNewQuantity(td.quantity !== 0 ? td.quantity : td.minQuantity);
                                            setSelectedAddOn(e.target.id);
                                            setActiveQtyCheckFlag(true);
                                          }
                                        } else {

                                        }

                                      }
                                      }>{td.quantity}</label>


                                    <div className={`${styles['btn-wrapper']}`}>
                                      <button className={`${styles['plus']}  ${selectedCategory.includes(td.category) && !selectedRowId.includes(td.planId) ? styles['disabled'] : ""}`}
                                        id={td.planId}
                                        onClick={(e) => {
                                          if (!selectedCategory.includes(td.category) || selectedRowId.includes(td.planId)) {
                                            if (!activeQtyCheckFlag) {
                                              e.preventDefault();
                                              // console.log(e.target.id);
                                              // console.log(td.planId);
                                              setPlanId(td.planId);
                                              setMaxQuantity(parseInt(td.maxQuantity));
                                              setMinQuantity(parseInt(td.minQuantity));
                                              setNewQuantity(td.quantity !== 0 ? td.quantity : td.minQuantity);
                                              setSelectedAddOn(e.target.id);
                                              setActiveQtyCheckFlag(true);
                                            }
                                          }
                                          else {

                                          }
                                        }}
                                      >+</button>
                                      <button className={`${styles['minus']}  ${selectedCategory.includes(td.category) && !selectedRowId.includes(td.planId) ? styles['disabled'] : ""}`} id={td.planId}
                                        onClick={(e) => {
                                          if (!selectedCategory.includes(td.category) || selectedRowId.includes(td.planId)) {
                                            if (!activeQtyCheckFlag) {
                                              e.preventDefault();
                                              // console.log(e.target.id);
                                              // console.log(td.planId);
                                              setPlanId(td.planId);
                                              setMaxQuantity(parseInt(td.maxQuantity));
                                              setMinQuantity(parseInt(td.minQuantity));
                                              setNewQuantity(td.quantity !== 0 ? td.quantity : td.minQuantity);
                                              setSelectedAddOn(e.target.id);
                                              setActiveQtyCheckFlag(true);
                                            }
                                          }
                                          else {

                                          }
                                        }}>
                                        <span>&nbsp;</span>
                                      </button>
                                    </div>
                                  </div>

                                </>
                              }


                              {(selectedAddOn == td.planId) &&
                                <div className="d-flex flex-wrap  align-items-center justify-content-end">
                                  <>
                                    <div className="number-input">
                                      <input
                                        type="number"
                                        id={td.planId}
                                        className="no-arrows"
                                        defaultValue={td.quantity}
                                        value={parseInt(newQuantity)}
                                        min={td.minQuantity}
                                        max={td.maxQuantity}
                                        onChange={(e) => {
                                          setNewQuantity(e.target.value);
                                          // if ((e.target.value) == (td.quantity)) {
                                          //   // setNewQuantity(false);
                                          // }

                                          if ((e.target.value) > td.maxQuantity) {
                                            setNewQuantity(td.maxQuantity)
                                          }

                                          if ((e.target.value) <= td.minQuantity) {
                                            setNewQuantity(td.minQuantity)
                                          }

                                          if ((e.target.value) === td.quantity) {
                                            setNewQuantity(e.target.value)
                                          }

                                        }}
                                        onKeyDown={(e) => {
                                          if (e.code === 'Minus') {
                                            e.preventDefault();
                                          }
                                        }}
                                      />

                                      <div className="btn-wrapper">
                                        {parseInt(newQuantity) < parseInt(td.maxQuantity) ?
                                          <button className="plus" onClick={() => handleIncrease(parseInt(newQuantity))
                                          }>+</button> :
                                          <button className="plus disabled" disabled>+</button>
                                        }

                                        {parseInt(newQuantity) > td.minQuantity ?
                                          <button className="minus" onClick={
                                            () => handleDecrease(parseInt(newQuantity))
                                          }> <span>&nbsp;</span> </button>
                                          :
                                          <button className="minus disabled" disabled>
                                            <span>&nbsp;</span>
                                          </button>}

                                      </div>

                                    </div>
                                    {parseInt(newQuantity) !== parseInt(td.quantity) &&
                                      <div className='mx-1'
                                        onClick={(e) => {
                                          const updatedTd = { ...td, quantity: parseInt(newQuantity) };
                                          // console.log(td);
                                          td.quantity = parseInt(newQuantity);
                                          // console.log("Selected TD", selectedRowId, td.planId);
                                          // console.log("selectedRowId.includes(td.planId)", selectedRowId.includes(td.planId))
                                          if (!selectedRowId.includes(td.planId)) {
                                            // Update quantity since td.planId is already not in selectedRowId
                                            setSelectedRowId([...selectedRowId, td.planId]);
                                            setSelectedAddOnRows([...selectedAddOnRows, td]);
                                            // console.log("selectedAddOnRows", selectedAddOnRows);
                                            setSelectedPlan([
                                              {
                                                planId: td.planId,
                                                billingTerm: td.billingTerm,
                                                category: td.category,
                                                flavorDetailJson: td.flavorDetailJson,
                                                flavorId: td.flavorId,
                                                isPerpetualSoftware: td.isPerpetualSoftware,
                                                maxQuantity: td.maxQuantity,
                                                minQuantity: td.minQuantity,
                                                planName: td.planName,
                                                price: td.price,
                                                quantity: td.quantity,
                                                rrp: td.rrp,
                                                skuId: td.skuId,
                                                strPrice: td.strPrice,
                                                strRRP: td.strRRP,
                                                total: td.quantity * td.price
                                              },
                                              ...selectedPlan
                                            ]);
                                          } else {

                                            // Update quantity since td.planId is already in selectedRowId
                                            const updatedSelectedAddOnRows = selectedAddOnRows.map(item => {
                                              if (item.planId === td.planId) {
                                                return { ...item, quantity: parseInt(newQuantity) };
                                              }
                                              return item;
                                            });

                                            setSelectedAddOnRows(updatedSelectedAddOnRows);

                                            setSelectedPlan([
                                              {
                                                ...updatedTd,  // Include updatedTd properties
                                                total: parseInt(newQuantity) * td.price  // Recalculate total with new quantity
                                              },
                                              ...selectedPlan.filter(item => item.planId !== td.planId)  // Remove old item and add updated one
                                            ]);
                                          }

                                          // console.log("selectedPlan123", selectedPlan);
                                          setSelectedCategory([...selectedCategory, td.category]);
                                          //  setNewQuantity(td.quantity);
                                          setPlanId(null);
                                          setSelectedAddOn("");
                                          setActiveQtyCheckFlag(!activeQtyCheckFlag);
                                          // console.log("selectedRowId", selectedRowId);
                                        }
                                        }>
                                        <span className="quantity-tick" />
                                      </div>
                                    }

                                    {parseInt(newQuantity) == td.quantity &&
                                      <div className='mx-1'>
                                        <span className="tick-grey" />
                                      </div>
                                    }


                                    <div className='mx-1' onClick={(e) => {
                                      setNewQuantity(td.quantity);
                                      setPlanId(null);
                                      setSelectedAddOn("");
                                      setActiveQtyCheckFlag(!activeQtyCheckFlag);
                                      // console.log(activeQtyCheckFlag);
                                    }
                                    }>
                                      <span className="quantity-cross">&nbsp;</span>
                                    </div>
                                  </>
                                </div>

                              }


                            </div>

                          </td>
                        </tr>
                      )
                    })
                  }

                </tbody>
              </Table>}
          </>}

        <div>
          {
            selectedRowId.length > 0 &&
            <>
              <div className={`table ${styles['csp-purchase-addon-count-row']} ${isPlanSelectedSticky ? styles['sticky-bottom'] : ''}`} id="gws-plan-selected-component" style={isPlanSelectedSticky ? { maxWidth: tableWidth } : {}}>
                <div>
                  <Row>
                    <Col md={6} className="d-flex align-items-center">
                      <p className="m-0 p-0 text-small">{selectedPlan.length} {selectedPlan.length < 2 ? "Plan" : "Plans"} selected</p>
                    </Col>
                    <Col md={6}>
                      <div className="btn-wrapper justify-content-end d-flex m-0 p-0">
                        <Button
                          variant="light"
                          className="px-4 btn-border-light w-md-auto col-xs-12"
                          onClick={() => { setResetData(true) }}>
                          Clear
                        </Button>
                        <Button
                          className="px-4 ml-5"
                          onClick={() => {
                            if (billingPaymentGatewayData.isDefaultPaymentMethodValid) {
                              if (billingPaymentGatewayData.accountHoldStatus === "No Hold" || proceedToProvisionFlag) {
                                if (billingPaymentCardExpiryFlag || billingPaymentDirecrDebitExpiryFlag) {
                                  if (billingPaymentCardExpiryFlag) {
                                    setBillingPaymentGatewayErrorMessage("Your default payment method of credit card has an expired primary card, please proceed to add a new card")
                                    setBillingPaymentGatewayErrorPopup(true)
                                  }
                                  else {
                                    setBillingPaymentGatewayErrorMessage("Your default payment method of Direct Debit has all expired statuses, please proceed to add a new application")
                                    setBillingPaymentGatewayErrorPopup(true)
                                  }
                                }
                                else {
                                  if (checkResellerPrereqisiteFlag === false) {
                                    setResellerPrerequisiteModalUpen(true);
                                  }
                                  else {
                                    if (customerId) {
                                      if (domainName.length > 0) {
                                        if (isTransferGwsInProgress) {
                                          window.scrollTo(0, 0)
                                        }
                                        else {
                                          GetGST();
                                          setSelectCustomerErrorState(false);
                                          setGWSProductDetailsPopupModalOpen(true);
                                        }

                                      }
                                      else {
                                        setCustomerPrerequisiteModalUpen(true);
                                      }
                                    }
                                    else {
                                      setSelectCustomerErrorState(true);
                                      window.scrollTo(0, 0);
                                    }
                                  }
                                }
                              }
                              if (billingPaymentGatewayData.accountHoldStatus === "System Hold" &&  !proceedToProvisionFlag) {
                                setBillingPaymentGatewayErrorMessage("There is an issue with your account. If you choose to proceed to provision the order, please contact Synnex account team to complete the provisioning of this order.")
                                setBillingPaymentGatewayErrorPopup(true)
                              }
                              if (billingPaymentGatewayData.accountHoldStatus === "Manual Hold") {
                                setBillingPaymentGatewayErrorMessage("We are unable to process your request at this time. Please contact Synnex Accounts team at 'accounts@au.synnex-grp.com' or '0385408888' for further instructions")
                                setBillingPaymentGatewayErrorPopup(true)
                              }
                            }
                            else {
                              if (!(billingPaymentGatewayData.isDefaultPaymentMethodValid)) {
                                setBillingPaymentGatewayErrorMessage("No payment method is chosen by default, click on proceed to add payment method.")
                                setBillingPaymentGatewayErrorPopup(true)
                              }
                            }
                          }}
                        >
                          Proceed
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          }
        </div>

        {!loading && emptyData && !serverError && !existingDataCheck &&
          <div className={styles["no-data-cloud-invoice"]}>
            <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
            <h2 className="mt-4 mb-3">No Data Found</h2>
          </div>
        }
        {
          loading &&
          <div className={styles["no-data-cloud-invoice"]}>
            <img className="management-console-loading-icon" src={loadingGif} />
            <p className="text-medium text-strong management-console-loading-text">Loading...</p>
          </div>
        }
      </div>

      <Popup
        className={`custom-modal  ${!orderConfirmationSuccess ? "custom-modal-xl" : "custom-modal-md"}`}
        open={gwsProductDetailsPopupModalOpen}
        onClose={() => {
          setSelectedAddOn("");
          setNewQuantity(null);
          setActiveQtyCheckFlag(false);
          setGWSProductDetailsPopupModalOpen(false);
          setNewQuantity(null);
          setGstPercentage(null);
          setPONumber("");
          if (!orderConfirmationSuccess) {
            setIsEditingQuantity(false);
          }
          else {
            setIsEditingQuantity(false);
            resetGWSPO();
            setResetData(true);
            GetTableData(productId, searchValueTable, billingTermId, customerId);
            setOrderConfirmationSuccess(false);
          }

        }}
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            {!subscriptionDetailIsLoadingFlag &&
              <>
                <div className="header">
                  <h2>Order Confirmation - Cloud Provision</h2>
                  {!isProcessing &&
                    <XLg
                      size={18}
                      className="cpointer text-strong"
                      onClick={close}
                      color="#6A6A6A"
                    />
                  }

                  {isProcessing &&
                    <XLg
                      size={18}
                      className="text-strong"
                      color="#DDDDDD"
                      disabled
                    />
                  }
                </div>

                {!orderConfirmationSuccess &&
                  <Container className={`action-modal-height ${styles['edit-quantity-width']}`}>

                    <div className={`${styles["csp-purchase-addon-table-outer"]}`}>
                      <Row>
                        <Col>
                          <h3>
                            Customer Name: <span className="text-regular">{customerName}</span>
                          </h3>
                        </Col>
                        <Col>
                          <h3>
                            Cloud Service: <span className="text-regular">Google Workspace</span>
                          </h3>
                        </Col>
                      </Row>
                      <div className={`table-responsive col-12 my-3 ${styles['csp-popup-table']}`}>
                        <Table className={`table w-100 ${styles['csp-change-status-core-table']}`}>
                          <thead className="sticky-top">
                            <tr>
                              <th>Plan</th>
                              <th><span className={`d-flex align-items-center ${styles['billing-term']}`}>Billing Term  <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip className={`${styles['csp-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                              >
                                <span className="infoIcon-support-table ml-1"></span>
                              </OverlayTrigger></span>
                              </th>
                              <th>Category</th>
                              <th className="text-right">RRP (Inc GST)</th>
                              <th className="text-right">Price (Ex GST)</th>
                              <th className="text-center">Quantity</th>
                              <th className="text-right">Sub-Total Price</th>
                              <th className="text-center">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(selectedPlan && selectedPlan.length > 0) &&
                              selectedPlan.map((td, index) => {
                                return (
                                  <>
                                    <tr key={index}>
                                      <td><span>{td.planName}</span></td>
                                      <td><span>{td.billingTerm}</span></td>
                                      <td><span>{td.category}</span></td>
                                      <td className="text-right"><span>${td.strRRP}</span></td>
                                      <td className="text-right"><span>${td.strPrice}</span></td>
                                      <td className="text-right">
                                        <div className={`${styles['qty-wrapper']}`}>
                                          {selectedAddOn != td.planId &&
                                            <div className={`${styles['show-qty-btn-wrapper']} ${td.billingTerm == "Trial" ? styles['disabled'] : ""} `}>
                                              <label
                                                id={td.planId}
                                                className={`${styles['show-qty-btn']}`}
                                                onClick={(e) => {
                                                  if (td.billingTerm !== "Trial") {
                                                    e.preventDefault();
                                                    // console.log(td.planId);
                                                    setMaxQuantity(parseInt(td.maxQuantity));
                                                    setMinQuantity(parseInt(td.minQuantity));
                                                    setPlanId(td.planId);
                                                    setNewQuantity(td.quantity);
                                                    setSelectedAddOn(e.target.id);
                                                    setActiveQtyCheckFlag(true);
                                                  }
                                                }
                                                }>{td.quantity}</label>

                                              <div className={`${styles['btn-wrapper']}`}>
                                                <button className={`${styles['plus']}`}
                                                  id={td.planId}
                                                  onClick={(e) => {
                                                    if (td.billingTerm !== "Trial") {
                                                      e.preventDefault();
                                                      // console.log(e.target.id);
                                                      // console.log(td.planId);
                                                      setPlanId(td.planId);
                                                      setMaxQuantity(parseInt(td.maxQuantity));
                                                      setMinQuantity(parseInt(td.minQuantity));
                                                      setNewQuantity(td.quantity);
                                                      setSelectedAddOn(e.target.id);
                                                      setActiveQtyCheckFlag(true);
                                                    }
                                                  }
                                                  }
                                                >+</button>
                                                <button className={`${styles['minus']}`}
                                                  id={td.planId}
                                                  onClick={(e) => {
                                                    if (td.billingTerm !== "Trial") {
                                                      e.preventDefault();
                                                      // console.log(e.target.id);
                                                      // console.log(td.planId);
                                                      setPlanId(td.planId);
                                                      setMaxQuantity(parseInt(td.maxQuantity));
                                                      setMinQuantity(parseInt(td.minQuantity));
                                                      setNewQuantity(td.quantity);
                                                      setSelectedAddOn(e.target.id);
                                                      setActiveQtyCheckFlag(true);
                                                    }

                                                  }
                                                  }
                                                >
                                                  <span>&nbsp;</span>
                                                </button>
                                              </div>
                                            </div>
                                          }


                                          {(selectedAddOn == td.planId) &&
                                            <div className="d-flex flex-wrap  align-items-center justify-content-end">
                                              <>
                                                <div class="number-input">
                                                  <input
                                                    type="number"
                                                    id={td.planId}
                                                    className="no-arrows"
                                                    defaultValue={td.quantity}
                                                    value={parseInt(newQuantity)}
                                                    min={td.minQuantity}
                                                    max={td.maxQuantity}
                                                    onChange={(e) => {
                                                      setNewQuantity(e.target.value);
                                                      // if ((e.target.value) == (td.quantity)) {
                                                      //   // setNewQuantity(false);
                                                      // }

                                                      if ((e.target.value) > td.maxQuantity) {
                                                        setNewQuantity(td.maxQuantity)
                                                      }

                                                      if ((e.target.value) < td.minQuantity) {
                                                        setNewQuantity(td.minQuantity)
                                                      }

                                                      if ((e.target.value) === td.quantity) {
                                                        setNewQuantity(td.quantity)
                                                      }

                                                    }}
                                                    onKeyDown={(e) => {
                                                      if (e.code === 'Minus') {
                                                        e.preventDefault();
                                                      }
                                                    }}
                                                  />

                                                  <div className="btn-wrapper">
                                                    {parseInt(newQuantity) < parseInt(td.maxQuantity) ?
                                                      <button className="plus" onClick={() => handleIncrease(parseInt(newQuantity))
                                                      }>+</button> :
                                                      <button className="plus disabled" disabled>+</button>
                                                    }

                                                    {parseInt(newQuantity) > parseInt(td.minQuantity) ?
                                                      <button className="minus" onClick={
                                                        () => handleDecrease(parseInt(newQuantity))
                                                      }> <span>&nbsp;</span> </button>
                                                      :
                                                      <button className="minus disabled" disabled>
                                                        <span>&nbsp;</span>
                                                      </button>}

                                                  </div>

                                                </div>
                                                {parseInt(newQuantity) !== parseInt(td.quantity) &&
                                                  <div className='mx-1'
                                                    onClick={(e) => {
                                                      // console.log(td);
                                                      td.quantity = parseInt(newQuantity);
                                                      // console.log("Selected TD", td);
                                                      if (!selectedRowId.includes(td.planId)) {
                                                        setSelectedRowId([...selectedRowId, td.planId]);
                                                        setSelectedAddOnRows([...selectedAddOnRows, td]);
                                                        setSelectedPlan([
                                                          {
                                                            billingTerm: td.billingTerm,
                                                            category: td.category,
                                                            flavorDetailJson: td.flavorDetailJson,
                                                            flavorId: td.flavorId,
                                                            isNcePlan: td.isNcePlan,
                                                            isPerpetualSoftware: td.isPerpetualSoftware,
                                                            maxQuantity: td.maxQuantity,
                                                            minQuantity: td.minQuantity,
                                                            planId: td.planId,
                                                            planName: td.planName,
                                                            price: td.price,
                                                            quantity: newQuantity,
                                                            rrp: td.rrp,
                                                            skuId: td.skuId,
                                                            strPrice: td.strPrice,
                                                            strRRP: td.strRRP,
                                                            total: td.quantity * td.price
                                                          },
                                                          ...selectedPlan
                                                        ]);
                                                      }

                                                      // console.log("selectedPlan", selectedPlan);

                                                      setNewQuantity(td.quantity);
                                                      setPlanId(null);
                                                      setSelectedAddOn("");
                                                      setActiveQtyCheckFlag(!activeQtyCheckFlag);
                                                      // console.log("selectedRowId", selectedRowId);

                                                      // Update Quantity Corresponding in Main Table if we change here
                                                      const indexToUpdate = productDetailsData.findIndex(item => item.planId === td.planId);
                                                      const updatedProductDetails = [...productDetailsData];

                                                      // Update the quantity of the object at the specified index
                                                      updatedProductDetails[indexToUpdate] = {
                                                        ...updatedProductDetails[indexToUpdate],
                                                        billingTerm: td.billingTerm,
                                                        category: td.category,
                                                        flavorDetailJson: td.flavorDetailJson,
                                                        flavorId: td.flavorId,
                                                        isNcePlan: td.isNcePlan,
                                                        isPerpetualSoftware: td.isPerpetualSoftware,
                                                        maxQuantity: td.maxQuantity,
                                                        minQuantity: td.minQuantity,
                                                        planId: td.planId,
                                                        planName: td.planName,
                                                        price: td.price,
                                                        quantity: newQuantity,
                                                        rrp: td.rrp,
                                                        skuId: td.skuId,
                                                        total: td.quantity * td.price
                                                      };

                                                      setProductDetailsData(updatedProductDetails);
                                                    }
                                                    }>
                                                    <span className="quantity-tick" />
                                                  </div>
                                                }

                                                {parseInt(newQuantity) == parseInt(td.quantity) &&
                                                  <div className='mx-1'>
                                                    <span className="tick-grey" />
                                                  </div>
                                                }


                                                <div className='mx-1' onClick={(e) => {
                                                  setNewQuantity(td.quantity);
                                                  setPlanId(null);
                                                  setSelectedAddOn("");
                                                  setActiveQtyCheckFlag(!activeQtyCheckFlag);
                                                  // console.log(activeQtyCheckFlag);
                                                }
                                                }>
                                                  <span className="quantity-cross">&nbsp;</span>
                                                </div>
                                              </>
                                            </div>
                                          }
                                        </div>
                                      </td>
                                      <td className="text-right">
                                        <span>
                                          {
                                            (selectedAddOn == td.planId) ?
                                              "$" + (td.price == 0.0 ? "0.00" : (Math.round((((td.price) * parseInt(newQuantity)) + Number.EPSILON) * 100) / 100)) :
                                              "$" + (td.price == 0.0 ? "0.00" : (Math.round((((td.price) * parseInt(td.quantity)) + Number.EPSILON) * 100) / 100))
                                          }

                                        </span>
                                      </td>
                                      <td className={`text-center ${styles['delete-column']}`}>
                                        <span className="trash" onClick={() => handleDeleteRow(index)}>&nbsp;</span>
                                      </td>
                                    </tr>
                                  </>
                                )
                              })
                            }


                            {selectedPlan.length < 1 &&
                              <tr className="no-bdr-row no-hover-row">
                                <td colSpan={8} className="text-center">
                                  <p className="mb-0 py-3">No plans in the list. Please go back to provision module to buy subscriptions.</p>
                                </td>
                              </tr>
                            }
                          </tbody>
                        </Table>
                      </div>
                      {selectedPlan.length > 0 &&
                        <div className={`table-responsive col-12 my-3 mb-0 ${styles['csp-popup-table-total']}`}>
                          <Table className={`table w-100 ${styles['csp-change-status-core-table']}`}>
                            <tr>
                              <td colSpan={6} className={`text-right`}>
                                <div>
                                  <span>Base price (Ex. GST):</span>
                                  <span className={`text-right ${styles['total-block-right']}`}>${basePrice}</span><br />

                                  <span>GST ({gstPercentage && (parseFloat(gstPercentage).toFixed(2) + "%")}):</span>
                                  <span className={`text-right ${styles['total-block-right']}`}>${gstAmount}</span><br />
                                </div>
                                <span className={`${styles['total-block-row']}`}>
                                  <span className="text-strong">Total (Inc. GST):</span>
                                  <span className={`text-right text-strong ${styles['total-block-right']}`}>${totalWithGST}</span>
                                </span>

                              </td>
                              <td className={`${styles['delete-column']}`}></td>
                            </tr>
                          </Table>
                        </div>
                      }
                      <p className='my-3'><span className='text-strong'>Note :</span> Prices displayed are valid for the first 12 months after activation and subject to change according to Google's Partner Program</p>
                      <>
                        {selectedPlan.length > 0 &&
                          <Row>
                            <Col>
                              <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                              <Form.Control
                                type="text"
                                className="w-auto d-inline ml-3"
                                id="inputPONumber"
                                aria-describedby="inputPONumber"
                                placeholder='Add PO Number'
                                value={poNumber}
                                maxlength="25"
                                onChange={(e) => {
                                  alphanumericRegex.test(e.target.value)
                                    ? setPONumber(e.target.value)
                                    : console.log("")
                                }}
                              />
                              <Form.Text id="poNumber" />
                            </Col>
                          </Row>
                        }

                        <Row className="mt-2">
                          <Col md={6}>
                            {selectedPlan.length > 0 &&
                              <>
                                <Form.Check // prettier-ignore
                                  type="checkbox"
                                  id="termsCheckBox"
                                  label=""
                                  disabled
                                  checked={isTermsChecked}
                                  className="d-inline-block w-auto"
                                />
                                <p className="d-inline-block  w-auto mb-0">
                                  I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => { if (!isTermsChecked) { let browser = browserName[1] + " " + browserVersion[1]; TermsAndConditionAcceptFunction(browser, ipAddress); } }}>terms & conditions</a> of Synnex cloud platform
                                </p>
                              </>
                            }
                          </Col>
                          <Col md={6}>
                            <div className="btn-wrapper text-right mb-1">
                              {!isProcessing &&
                                <Button
                                  variant="light"
                                  className="px-4 btn-border-light w-md-auto col-xs-12"
                                  onClick={() => { setGWSProductDetailsPopupModalOpen(false); setPONumber(""); }}>
                                  Cancel
                                </Button>
                              }
                              {isProcessing &&
                                <Button
                                  variant="light"
                                  className="px-4 mx-2"
                                  disabled
                                >
                                  Cancel
                                </Button>
                              }
                              {(isTermsChecked && selectedPlan.length > 0) && !isProcessing && <>
                                <Button
                                  className="px-4 mx-2"
                                  onClick={postOrderConfirmation}>
                                  Confirm
                                </Button>
                              </>
                              }
                              {(!isTermsChecked || selectedPlan.length < 1) && !isProcessing &&
                                <>
                                  <Button
                                    variant="light"
                                    className="px-4 mx-2"
                                    disabled
                                  >
                                    Confirm
                                  </Button>
                                </>
                              }

                              {isProcessing &&
                                <>
                                  <Button
                                    variant="light"
                                    className="px-4 mx-2"
                                    disabled
                                  >
                                    Processing...
                                  </Button>
                                </>
                              }
                            </div>
                          </Col>
                        </Row>
                      </>




                    </div>

                  </Container>
                }

                {
                  orderConfirmationSuccess &&
                  <>
                    <Container className='text-center'>
                      <p>
                        <div className='success-page'></div>
                      </p>
                      <p className='text-regular'>{poConfirmation.message}</p>
                      <p className='text-regular'><strong>Provision Order ID:</strong> {poConfirmation.orderCode}</p>
                      <div>
                        <Button
                          className="px-4 mx-2"
                          onClick={() => {
                            close();
                            resetGWSPO();
                            setSelectedAddOnRows([]);
                            setSelectedPlan([]);
                            setGWSProductDetailsPopupModalOpen(false);
                            GetTableData(productId, searchValueTable, billingTermId, customerId)
                          }}>
                          Ok
                        </Button>
                      </div>
                    </Container>
                  </>
                }
              </>
            }
            {
              subscriptionDetailIsLoadingFlag &&
              <>
                <div className="header">
                  <h2 className="mb-0">Edit Quantity</h2>
                  <XLg
                    size={18}
                    className="cpointer text-strong"
                    onClick={close}
                    color="#6A6A6A"
                  />
                </div>
                <Container>
                  <div className="empty-subscription-detail">
                    <center><h2> Loading . . .</h2></center>
                  </div>
                </Container>
              </>
            }
          </div>
        )}
      </Popup>
      <Popup
        className="custom-modal custom-modal-sm"
        open={customerPrerequisiteModalUpen}
        onClose={() => setCustomerPrerequisiteModalUpen(false)}
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header p-4 py-3">
              <h2 className="mb-0 px-3">Customer Prerequisite</h2>
              <XLg
                size={18}
                className="cpointer text-strong close-icon-popup-sm"
                onClick={close}
                color="#6A6A6A"
              />
            </div>
            <Container className="p-5">
              <p className='pt-0 px-3 pb-5 mb-0'>
                <strong>Please add customer pre-requisites to provision subscriptions.</strong>
              </p>
              <div className="actions">
                <div className="d-flex justify-content-end px-3 pb-3 pt-2">
                  <Button
                    className="btn btn-lg mx-3 btn-border-light"
                    variant="light"
                    onClick={() => {
                      // console.log("modal closed ");
                      close();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-md"
                    onClick={() => {
                      if(customerName!=="Select Customer"){
                        navigate("/cloud-provision/customer/prerequisite/google", {
                        state: { serviceId: serviceId, customerId: customerId, customerName: customerName }
                        }
                        );
                        close();
                      }
                    }}
                  >
                    Proceed
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        )}
      </Popup>
      <Popup
        className="custom-modal custom-modal-sm"
        open={resellerPrerequisiteModalUpen}
        onClose={() => setResellerPrerequisiteModalUpen(false)}
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header p-4 py-3">
              <h2 className="mb-0 px-3">Vendor Requirement</h2>
              <XLg
                size={18}
                className="cpointer text-strong close-icon-popup-sm"
                onClick={close}
                color="#6A6A6A"
              />
            </div>
            <Container className="p-5">
              <p className='pt-0 px-3 pb-5 mb-0'>
                <strong>Please add vendor requirements to provision subscriptions.</strong>
              </p>
              <div className="actions">
                <div className="d-flex justify-content-end px-3 pb-3 pt-2">
                  <Button
                    className="btn btn-lg mx-3 btn-border-light"
                    variant="light"
                    onClick={() => {
                      // console.log("modal closed ");
                      close();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-md"
                    onClick={() => {
                      navigate("/cloud-provision/reseller/prerequisite/google", {
                        state: { serviceId: serviceId }
                      }
                      );
                      close();
                    }}
                  >
                    Proceed
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        )}
      </Popup>
    </>
  )
}

export default GoogleworkspaceProductDetail