import React from 'react'
import { Col, Row} from 'react-bootstrap';

const CSPOrderConfirmationModalHeader = (prop) => {
    return (
        <>
            <Row>
                <Col>
                    <h3>
                        Customer Name: <span className="text-regular">{prop.customerName}</span>
                    </h3>
                </Col>
                <Col>
                    <h3>
                        Cloud Service: <span className="text-regular">{prop.serviceName}</span>
                    </h3>
                </Col>
            </Row>
        </>
    );
}

export default CSPOrderConfirmationModalHeader;