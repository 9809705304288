import React , {useState,useEffect} from 'react'
import { LANDING_PAGE_CMS_DATA } from '../../../../../Utils/GlobalConstants';
import { cmsApi } from '../../../../../Utils/API';
import { Button, Container } from 'react-bootstrap';

const GoogleCloudToP = () => {
    const [termsAndConditonData, setTermsAndConditonData] = useState([]);           // privacy policy data storage

    // Function for calling Privacy Policy item data from Orchid Core CMS
    async function GetTermsAndConditonData() {
        const data = `{
        cloudTermsandConditionPageList(
            where: {alias: {alias_in: "synnex_google_tnc"}}
        ) {
            alias {
            alias
            }
            content {
            html
            }
            displayText
        }
        }`;
    
        // Defining Header Configuration
        const config = {
          headers: {
            "Content-Type": "application/graphql",
            Accept: "application/graphql",
          },
        };
    
        // Axios Post Request
        await cmsApi
          .post(LANDING_PAGE_CMS_DATA, data, config)
          .then((resp) => {
            // handle success
            // console.log(resp.data.data.privacyPolicyPage[0].content.html);
            setTermsAndConditonData(resp.data.data.cloudTermsandConditionPageList[0].content.html);
          })
          .catch((error) => {
            // handle error
            // console.log(error);
          });
      }

    // UseEffect Defination to call the get Privacy Policy items function from CMS
    useEffect(() => {
        GetTermsAndConditonData();
      }, []);
  return (
    <>
        <div className='px-4 mt-6' style={{minHeight:"90vh"}}  dangerouslySetInnerHTML={{__html:termsAndConditonData}}>
        </div>
        <Container className='text-right'>
            <Button variant="primary" className='col-1 container' onClick={()=>{window.close();}}>Close</Button>
        </Container>
    </>
  )
}

export default GoogleCloudToP
