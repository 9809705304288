import React , {useState,useEffect} from 'react'
import styles from './customersWidgets.module.scss'
import { Col, Row } from 'react-bootstrap'
import { GET_CUSTOMERS_WIDGETS } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';

const CustomersWidgets = (props) => {
  const [customerWidget, setCustomerWidget] = useState(null);
  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Function to call all Customer count data
  async function GetCustomerWidget() {
    await api.get(`${GET_CUSTOMERS_WIDGETS}`, config)
      .then(resp => {
       
        setCustomerWidget(resp.data); 
      })
      .catch(error => {
        if (error?.status == 401 || error?.response.status == 401) {
          props.Logout();
        }
        else {
        }
      });
  };

  useEffect(() => {
    GetCustomerWidget()
  }, [])

  return (
    <Row className='my-3'>
      {
        customerWidget && customerWidget.map((widget)=>{
          if(widget.widgetTitle==="Active Customers"){
            return(
              <Col lg={2} md={3} xs={5} className={`${styles["widget-body"]} px-3 mx-2 mr-4`}>
                <span>{widget.widgetTitle}</span>
                <h3 className={`${styles["widget-body-green"]} ${styles["active-customer-padding-top"]}`}>{`${Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format((parseInt(widget.count)).toFixed(2))}`}</h3>
              </Col>
            )
          }
          else{
            return(
              <Col lg={2} md={3} xs={5} className={`${styles["widget-body"]} px-3 mx-2 mr-4`}>
                <span>{widget.widgetTitle}</span>
                <Row>
                  <Col>
                    <h3 className={`${styles["widget-body-default"]} ${widget.isIncrease ? "text-success" : "text-danger"}`}>
                      <span className={widget.isIncrease ? "dashboard-green-arrow" : "dashboard-red-arrow"}></span> 
                      {widget.count}%
                    </h3>
                  </Col>
                  <Col className='text-right'>
                  <span className={`${styles["sub-description"]} text-small mb-0`}>{`${Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format((parseInt(widget.totalCount)).toFixed(2))}`} {widget.totalCount>1 ? "Customers" : "Customer"}</span>
                  </Col>
                </Row>  
              </Col>
            )
          }
        })
      }
    </Row>
  )
}

export default CustomersWidgets
