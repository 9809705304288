import React, { useState, useEffect } from 'react';
import logoTop from '../../../../../Assets/Images/Illustrations/Landing Page/Background-Image/svg/logo-top.svg';
import styles from './landingPageHeader.module.scss';
import { Container, Nav, Navbar } from 'react-bootstrap';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import OnboardResellerPopup from './LocalComponents/Popup';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { loginRequest } from '../../../../../authConfig';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import Toast from '../../../../../GlobalComponents/Toast';
import logoTopNew from '../../../../../Assets/Images/Illustrations/Landing Page/Background-Image/png/synnex-cloud.png';

function LandingPageHeader({landingPageData,globalData,cookieAccepted}) {

  const { instance } = useMsal();
  let activeAccount;

  // Defining UseNavigate for react router DOM v6
  const navigate = useNavigate();

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const [modalOpen, setModalOpen] = useState(false);                                // Usestate for handlink opening and closing of resller onboarding popup

  const [show, setShow] = useState(false);                                         // Usestate for handlink opening and closing of navbar hamburger

  const [resellerOnboardingForm, setResellerOnboardingForm] = useState(null);     // Reseller onboarding form data

  // Use State for Toasts
  const [toastError, setToastError] = useState(false);                                // toast message displaying error message 

  // Fxn for closing and opening navbar hamburger icon
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  
  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    // instance.loginPopup(loginRequest).catch((error) => console.log(error)); Popup
  };

  useEffect(() => {
    if(landingPageData){
      setResellerOnboardingForm(landingPageData.formEndpoint.contentItems[2].endpoint)
    }
  }, [landingPageData])


  return (
    <>
      <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={"Kindly accept cookies to proceed with login"} />
      <OnboardResellerPopup modalOpen={modalOpen} setModalOpen={setModalOpen} resellerOnboardingForm={resellerOnboardingForm} />
      <Container fluid>
        <Navbar collapseOnSelect  expand="md" className='pt-6'>
          <Navbar.Brand className={`${styles.title} cpointer`} onClick={()=>navigate("/")}>
            <img src={logoTopNew} alt="Logo Top" />
          </Navbar.Brand>
            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
            <Navbar.Collapse id="basic-navbar-nav"  className='d-none d-md-block'>
              <Nav className={` me-auto px-4`}>
                
                {
                  landingPageData && landingPageData.navLink.contentItems.map((navLink,id)=>{
                    return(
                      <Nav.Link eventKey={id} className={`${styles.navLink} pr-4`} target='_blank' href={navLink.linkUrl}>{navLink.displayText}</Nav.Link>
                    )
                  })
                }
              </Nav>
              <Nav className={` d-flex align-items-center`}>
                <button className={`${styles['reseller-btn']} px-3 text-strong`} onClick={() => setModalOpen(true)}><Person2OutlinedIcon className={`${styles.profileIconWhite} mr-1`} />Become a reseller</button>
                <button className={`${styles['login-btn']}  px-3 text-strong`}  onClick={() => {handleLoginRedirect()}} ><Person2OutlinedIcon className={`${styles.profileIconBlue} mr-1`}/>Log in</button>
              </Nav>
            </Navbar.Collapse>
          <div className='d-block d-lg-none'>

            <Navbar.Toggle aria-controls="basic-navbar-nav" className={`${styles.mobileNavBtn}`} onClick={handleShow} />

            <Offcanvas show={show} onHide={handleClose}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title className='d-flex justify-content-center align-items-center'>
                <img className={styles.mobileTitleImage} src={logoTop} alt="Logo Top" />{' '}<span className={styles.mobileTitle}>SYNNEX</span>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
              <Nav className={`${styles.offCanvasNavLinks} me-auto px-4`}>
                {
                  landingPageData && landingPageData.navLink.contentItems.map((navLink,id)=>{
                    return(
                      <Nav.Link eventKey={id} className={`${styles.navLink} pr-4`} target='_blank' href={navLink.linkUrl}>{navLink.displayText}</Nav.Link>
                    )
                  })
                }
              </Nav>
              <Nav className={` d-flex align-items-center`}>
                <button className={`${styles['login-btn']}  px-3 text-strong mb-5`}  onClick={() => {handleLoginRedirect()}} ><Person2OutlinedIcon className={`${styles.profileIconWhite} mr-1`}/>Log in</button>
                <button className={`${styles['reseller-btn']} px-3 text-strong mb-5`} onClick={() => setModalOpen(true)}><Person2OutlinedIcon className={`${styles.profileIconBlue} mr-1`} />Become a reseller</button>
              </Nav>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </Navbar>


      </Container >
    </>
  );
}

export default LandingPageHeader;
