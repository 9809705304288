import { Row, Col, Button, Form, NavDropdown } from "react-bootstrap";
import { useEffect, useState, } from "react";
import CSPCustomerPrerequisite from "./CSP";
import GWSCustomerPrerequisite from "./GWS";
import { useLocation, useNavigate } from 'react-router-dom';
import loadingGif from "../../../../../Assets/Images/Animations/loading-management-console.gif";
import styles from "./prerequisites.module.scss";

import {
    PROVISIONING_GET_SERVICES,
    PROVISIONING_GET_CUSTOMER_DOMAIN,
    GET_CSP_MPNID_DATA,
    GOOGLE_WORKSPACE_RESELLER_PREREQUISITES,
    CSP_MCA_AGREEMENT_DETAILS
} from "../../../../../Utils/GlobalConstants";
import { api } from "../../../../../Utils/API";
import BuyDomainFailed from "./BuyDomainFailed";

const Prerequisite = (props) => {
    const location = useLocation();                                             // get Location
    const navigate = useNavigate();                                             // to use navigate function

    const [isLoading, setIsLoading] = useState(false);
    const [iscomingFromProvision, setIscomingFromProvision] = useState(props.comingFromProvision ? props.comingFromProvision : false);
    const [selectedOption, setSelectedOption] = useState(props.selectedOption ? props.selectedOption : "Select");  // Select Service Name
    const [customerDetails, setCustomerDetails] = useState(props.customerDetails);
    const [domainName, setDomainName] = useState("");
    const [gwIsTransferOrderInProgress, setGwIsTransferOrderInProgress] = useState("");
    const [isMCACompleted, setIsMCACompleted] = useState(location.state?.isMCACompleted ? location.state?.isMCACompleted : false);
    const [mcaData, setMCAData] = useState("");
    const [showDomainExtension, setShowDomainExtension] = useState(false);

    // Services Option
    const services = [
        {
            eventKey: "Microsoft",
            integrationCode: "microsoftsaas",
            serviceId: props.cspServiceId
        },
        {
            eventKey: "Google",
            integrationCode: "googleworkspace",
            serviceId: props.gwsServiceId
        },
    ]

    const [serviceId, setServiceId] = useState(props.serviceId ? props.serviceId : null);
    const [integrationCode, setIntegrationCode] = useState(props.integrationCode ? props.integrationCode : null);


    // CSP Vendor Requirements
    const [isCspConfigured, setIsCspConfigured] = useState({
        isAlreadyConfigured: null,
        mpnId: null,
        isMPNIdExpired: null
    });

    const [isGWAlreadyConfigured, setIsGWAlreadyConfigured] = useState("");
    const [isCSPAlreadyConfigured, setIsCSPAlreadyConfigured] = useState("");

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to call all dropdown values in Service Section 
    async function GetAllServices() {
        await api.get(PROVISIONING_GET_SERVICES, config)
            .then(resp => {

            })
            .catch(error => {

                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                }
                else {

                }

            });
    };


    // Get MCA Status
    async function GetMCADetails() {
        await api.get(`${CSP_MCA_AGREEMENT_DETAILS}?customerCode=${props.customerId}`, config)
            .then(resp => {


                setMCAData(resp.data);
                if (resp.data.mcaStatus == true) {
                    setIsMCACompleted(true);
                } else {
                    setIsMCACompleted(false);
                }
            })
            .catch(error => {

                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                }
                else {

                }
            });
    }

    // Get Customer Domaian 
    async function GetDomain() {
        if (props.customerId && serviceId && integrationCode) {
            await api.get(`${PROVISIONING_GET_CUSTOMER_DOMAIN}?customerCode=${props.customerId}&serviceId=${serviceId}&integrationCode=${integrationCode}`, config)
                .then(resp => {
                    if (resp.status == 200) {
                        setDomainName(resp?.data.domainName ? resp.data.domainName : domainName);
                        setGwIsTransferOrderInProgress(resp?.data.gwIsTransferOrderInProgress);
                        setShowDomainExtension(resp?.data.domainName ? true : false);
                    }

                    if (resp.status == 204) {
                        setDomainName("");
                        setGwIsTransferOrderInProgress("")
                    }
                })
                .catch(error => {
                    if (error?.status == 401 || error?.response.status == 401) {
                        props.Logout();
                    }
                });
        }
    }


    // Select the service 
    const handleSelect = (eventKey) => {
        setIsLoading(true);
        const selectedService = services.find(service => service.eventKey === eventKey);
        if (selectedService) {
            setServiceId(selectedService.serviceId);
            setIntegrationCode(selectedService.integrationCode);
            setSelectedOption(selectedService.eventKey);
            setIsMCACompleted(mcaData?.mcaStatus == true ? true : false);
        }

        // Select Vendor Requirements
        if (eventKey == "Microsoft" || integrationCode == "microsoftsaas") {
            GetCSPVendorDetails();
        }

        if (eventKey == "Google" || integrationCode == "googleworkspace") {
            GetGWSVendorDetails();
        }
    };

    // Get CSP Vendor Details
    async function GetCSPVendorDetails() {
        await api.get(`${GET_CSP_MPNID_DATA}`, config)
            .then(resp => {
                if (resp.status == 200) {
                    setIsCspConfigured(resp.data);
                    setIsCSPAlreadyConfigured(resp.data.isAlreadyConfigured);
                }

                
            })
            .catch(error => {

                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                }
                

            });

        setIsLoading(false);
    }

    // Get GWS Vendor Details
    async function GetGWSVendorDetails() {
        await api.get(`${GOOGLE_WORKSPACE_RESELLER_PREREQUISITES}`, config)
            .then(resp => {
                if (resp.status == 200) {
                    setIsGWAlreadyConfigured(resp.data.isGWAlreadyConfigured);
                }
            })
            .catch(error => {

                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                }
                 

            });

        setIsLoading(false);
    }


    useEffect(() => {
        GetAllServices();

    }, []);

    useEffect(() => {
        if (selectedOption !== "Select") {
            handleSelect();
        }
    }, [selectedOption]);

    useEffect(() => {
        GetMCADetails();
    }, [props.customerId, selectedOption, serviceId, integrationCode]);

    useEffect(() => {
        GetDomain();
    }, [props.customerId, selectedOption, serviceId]);

    return (
        <>
            <Row>
                <Col md={3}>
                    <Form.Group>
                        <Form.Label>Vendor</Form.Label>
                        <NavDropdown
                            title={selectedOption}
                            id="cloud-service-dropdown"
                            onSelect={handleSelect}
                            className="servcies-dropdown"
                            value={selectedOption}
                        >
                            {services.map((service, index) => {
                                return (
                                    <NavDropdown.Item
                                        eventKey={service.eventKey}
                                        name={service.integrationCode}
                                        id={service.serviceId}
                                    >
                                        {service.eventKey}
                                    </NavDropdown.Item>
                                )
                            })
                            }

                        </NavDropdown>
                    </Form.Group>

                </Col>
            </Row>
            {selectedOption === 'Select' &&
                <BuyDomainFailed />
            }

            {!isLoading &&
                <>
                    {isCSPAlreadyConfigured == true &&
                        <>
                            {selectedOption === 'Microsoft' &&
                                <>
                                    <CSPCustomerPrerequisite
                                        customerId={props.customerId}
                                        mcaDomainName={domainName}
                                        serviceId={serviceId}
                                        comingFromProvision={props.comingFromProvision}
                                        setIscomingFromProvision={setIscomingFromProvision}
                                        cspServiceId={props.cspServiceId}
                                        isMCACompleted={isMCACompleted}
                                        selectedOption={selectedOption}
                                        mcaAgreementDetails={mcaData}
                                        domainName={domainName}
                                        GetMCADetails={GetMCADetails}
                                        setActiveLocalKey={props.setActiveLocalKey}
                                        toggleDrawer={props.toggleDrawer}
                                        setSelectService={props.setSelectService}
                                        setSelectCategory={props.setSelectCategory}
                                        Logout={props.Logout}
                                        showDomainExtension={showDomainExtension}
                                    />

                                </>
                            }
                        </>
                    }
                    {isGWAlreadyConfigured == true &&
                        <>
                            {selectedOption === 'Google' &&
                                <GWSCustomerPrerequisite
                                    customerId={props.customerId}
                                    domainName={domainName}
                                    serviceId={props.gwsServiceId}
                                    gwsServiceId={props.gwsServiceId}
                                    gwIsTransferOrderInProgress={gwIsTransferOrderInProgress}
                                    setActiveLocalKey={props.setActiveLocalKey}
                                    Logout={props.Logout}
                                />
                            }
                        </>
                    }


                    {
                        (selectedOption == 'Microsoft' && isCSPAlreadyConfigured !== true) &&
                        <>
                            <div className={styles["no-data-cloud-summary"]}>
                                <div className="col-12 text-center pt-5 mt-5">
                                    <p>To access cloud requirements, you need to complete vendor requirements first, please click on vendor requirements to proceed</p>

                                    <Button
                                        className="btn btn-lg ml-2 px-4"
                                        onClick={() => {
                                            navigate("/profile/")
                                        }}
                                    >
                                        Vendor Requirements
                                    </Button>
                                </div>
                            </div>
                        </>

                    }

                    {
                        (selectedOption == 'Google' && isGWAlreadyConfigured !== true) &&
                        <>
                            <div className={styles["no-data-cloud-summary"]}>
                                <div className="col-12 text-center pt-5 mt-5">
                                    <p>To access cloud requirements, you need to complete vendor requirements first, please click on vendor requirements to proceed</p>

                                    <Button
                                        className="btn btn-lg ml-2 px-4"
                                        onClick={() => {
                                            navigate("/profile/")
                                        }}
                                    >
                                        Vendor Requirements
                                    </Button>
                                </div>
                            </div>
                        </>
                    }
                </>
            }
            {isLoading &&
                <div className={styles["no-data-cloud-summary"]}>
                    <div className="text-center"><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading...</p></div>
                </div>
            }
        </>

    );
}

export default Prerequisite;