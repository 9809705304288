import React, { useState, useEffect, useContext } from 'react'
import { Link, NavLink } from 'react-router-dom';
import './styles.scss';
import { Bell, Telephone } from 'react-bootstrap-icons';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from "react-router-dom";
import profileIcon from "../../Assets/Images/Icons/profileicon.svg";
import Offcanvas from 'react-bootstrap/Offcanvas';
import CmsDetailsContext from '../../Context/CmsDetails/CmsDetailsContext';
import { GET_RECENT_NOTIFICATIONS, LOGOUT } from '../../Utils/GlobalConstants';
import { api } from '../../Utils/API';
import { navbarMainLinks } from './Utils';

import { loginRequest, b2cPolicies } from '../../authConfig';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import NotificationCentre from '../../GlobalComponents/NotificationCentre';
import Announcement from '../../GlobalComponents/Announcements';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Badge, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TrainingWidget from '../../GlobalComponents/TrainingWidget';

const CustomerNavbar = (props) => {

    // Instance for azure active directory initialization
    const { instance, inProgress } = useMsal();
    let activeAccount;
    if (instance) {
        activeAccount = instance.getActiveAccount();
    }


    const navigate = useNavigate();   // React Router v6
    const [showDropdown, setShowDropdown] = useState(false);
    const globalData = useContext(CmsDetailsContext);   // Global Variable
    const [active, setActive] = useState('default');   // Active profile dropdown
    const [tabActive, setTabActive] = useState('default');  // Active Blue color highlight for navigation
    const [profilePictureText, setProfilePictureText] = useState(localStorage.getItem("profile_initial_credential"));  // Profile Picture Text Initial
    // const profilePictureText = localStorage.getItem("profile_initial_credential");
    const [roleFeature, setRoleFeature] = useState(JSON.parse(localStorage.getItem("user_permissions")));
    const [roleSubaccount, setSubaccount] = useState(localStorage.getItem("role_subaccount"));
    const [notificationCount, setNotificationCount] = useState(null);
    const [notificationData, setNotificationData] = useState(null);
    const [notificationNewFlag, setNotificationNewFlag] = useState(false);
    // const [isDisabledBilling, setIsDisabledBilling] = useState(localStorage.getItem("disabled_array").includes("Billing") ? true : false);
    // const [roleFeature, setRoleFeature] = useState([
    //     {page:"My Synnex",isReadWriteAccess:true,isReadOnlyAccess:false,disabled:false},
    //     {page:"Provision",isReadWriteAccess:false,isReadOnlyAccess:true,disabled:false},
    //     {page:"Manage",isReadWriteAccess:true,isReadOnlyAccess:false,disabled:false},
    //     {page:"Billing",isReadWriteAccess:false,isReadOnlyAccess:false,disabled:true},
    //     {page:"Customers",isReadWriteAccess:false,isReadOnlyAccess:true,disabled:false},
    //     {page:"Integrations",isReadWriteAccess:false,isReadOnlyAccess:false,disabled:true},
    //     {page:"Order History",isReadWriteAccess:false,isReadOnlyAccess:false,disabled:true},
    //     {page:"Prerequisite",isReadWriteAccess:false,isReadOnlyAccess:true,disabled:false},
    //     {page:"Profile",isReadWriteAccess:true,isReadOnlyAccess:true,disabled:false},
    // ]);
    // const [roleSubaccount, setSubaccount] = useState(false);
    const [navbarMainLink, setNavbarMainLink] = useState(null);
    const [emptyNotification, setEmptyNotification] = useState(false);
    const [helpTrainingWidgetPopupOpen, setHelpTrainingWidgetPopupOpen] = useState(false);

    const [processingFlag, setProcessingFlag] = useState(false);
    const [processingFilesCount, setProcessingFilesCount] = useState(0);
    const [paymentGatewayProfileFlag, setPaymentGatewayProfileFlag] = useState(true);

    // Header Authorization for API
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to call account details (profile details)
    async function GetRecentNotifications() {
        let lastnotifiactionDate = localStorage.getItem("first_notification_date");
        (lastnotifiactionDate === undefined || lastnotifiactionDate === null || lastnotifiactionDate === "null") ? lastnotifiactionDate = "" : lastnotifiactionDate = lastnotifiactionDate;
        await api.get(`${GET_RECENT_NOTIFICATIONS}?lastnotifiactionDate=${lastnotifiactionDate}`, config)
            .then(resp => {
                if (resp.status == 200) {
                    setEmptyNotification(false);
                    // console.log("Data : ", resp.data.notifications);
                    setNotificationCount(resp.data.totalUnreadNotification);
                    setNotificationData(resp.data.notifications);
                    setNotificationNewFlag(resp.data.newNotificationAvailable);
                    localStorage.setItem("first_notification_date", resp.data.firstNotificationDate);
                }
                else {
                    setEmptyNotification(true);
                    setNotificationData(null);
                    setNotificationCount(null);
                }
            })
            .catch(error => {
                // console.log(error)
                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                }
                else {
                    // console.log(error);
                }

            });
    };

    useEffect(() => {
        setInterval(() => {
            // console.log("Notification Date :", localStorage.getItem("first_notification_date"))
            GetRecentNotifications();
        }, 90000);
    }, [])

    useEffect(() => {
        setTimeout(() => {
            GetRecentNotifications();
        }, 3000);
    }, [])

    useEffect(() => {
        if (props.hookRecentNotifications === true) {
            GetRecentNotifications();
            props.setHookRecentNotifications(false);
        }
    }, [props.hookRecentNotifications])

    const handleLogoutRedirect = () => {
        localStorage.clear();
        props.setCustomerIsAuthenticated(false);
        instance.logoutRedirect();
    };

    // Updating Initial Profile on Profile Updation
    useEffect(() => {
        if (props.profileInitialCredentialChange) {
            setProfilePictureText(localStorage.getItem("profile_initial_credential"));
            props.setProfileInitialCredentialChange(false);
        }
    }, [props.profileInitialCredentialChange])

    // UseEffect to set active tab for navigation 
    useEffect(() => {
        if (props.activeLocalKey !== "cloudSolutions") {
            setTabActive(props.activeLocalKey);
        }
    }, [props.activeLocalKey])

    async function LogoutRevoke() {
        instance.logoutRedirect();
        let data = "";
        await api
            .post(LOGOUT, data, config)
            .then((resp) => {
                props.setCustomerIsAuthenticated(false);
                if (props.setCustomerIsAuthenticated) { navigate("/") };
                localStorage.clear(); localStorage.clear();
                props.setActiveLocalKey('default');
                window.location.reload();
            })
            .catch((error) => {
                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                }
            });

    }

    const onHide = () => {
        setShowDropdown(false);
    };

    const handleButtonClick = () => {
        setShowDropdown(true); // Open the dropdown
    };

    useEffect(() => {
        if (roleSubaccount === "true") {
            let nav = navbarMainLinks;
            let disabledArray = [];
            let readOnlyAccessArray = [];
            let readAndWriteAccessArray = []
            // console.log("nav", nav);
            roleFeature.map((role) => {
                if (role.disabled) {
                    disabledArray.push(role.page);
                }
                else if (role.isReadOnlyAccess) {
                    readOnlyAccessArray.push(role.page);
                }
                else {
                    readAndWriteAccessArray.push(role.page);
                }
            })
            if (disabledArray.includes("Billing")) {
                setPaymentGatewayProfileFlag(false);
            }
            else {
                setPaymentGatewayProfileFlag(true);
            }
            if (localStorage.getItem("curentRole") && localStorage.getItem("curentRole").includes(["RA User"])) {
                localStorage.setItem("disabled_array", ["My Synnex", "Provision", "Manage", "Customers", "Integrations", "Order History", "Prerequisite", "Billing"]);
                localStorage.setItem("read_array", []);
                localStorage.setItem("read_write_array", []);
                disabledArray = ["My Synnex", "Provision", "Manage", "Customers", "Integrations", "Order History", "Prerequisite", "Billing", "Cloud Solutions"];
                readOnlyAccessArray = [];
                readAndWriteAccessArray = [];
            }
            else {
                // console.log('Disabled Array :', disabledArray);
                localStorage.setItem("disabled_array", disabledArray);
                localStorage.setItem("read_array", readOnlyAccessArray);
                localStorage.setItem("read_write_array", readAndWriteAccessArray);
            }

            nav.map((navItem) => {
                if (disabledArray.includes(navItem.label)) {
                    navItem.disabled = true;
                }
                else {
                    if (navItem.subMenus.length > 0) {
                        navItem.subMenus.map((item) => {
                            if (disabledArray.includes(item.label)) {
                                item.disabled = true
                            }
                            else if (disabledArray.includes("My Synnex") && item.label == "Dashboard") {
                                item.disabled = true
                            }
                            else {
                                item.disabled = false
                            }
                        })
                    }
                    else {
                        navItem.disabled = false;
                    }

                }

            })
            // console.log("nav", nav);
            setNavbarMainLink(nav);
            if (disabledArray.includes("My Synnex")) {
                navigate("/profile/");
                props.setActiveLocalKey("");
            }
        }
        else {
            localStorage.setItem("disabled_array", []);
            localStorage.setItem("read_array", []);
            localStorage.setItem("read_write_array", []);
            setNavbarMainLink(navbarMainLinks);
        }

    }, [roleFeature])

    const notificationAction = (
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setNotificationNewFlag(false)}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    );

    const processingTrainingAction = (
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setProcessingFlag(false)}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    );

    const handleNavigation = (url, external, openInNewTab) => {
        console.log("url:::",url)
        if (external || openInNewTab) {
            window.open(url, '_blank', 'noopener,noreferrer');
        } else {
            navigate(url);
        }
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                TransitionComponent="SlideTransition"
                autoHideDuration={6000}
                open={notificationNewFlag}
                onClose={() => setNotificationNewFlag(false)}
                message="New Notification Received"
                action={notificationAction}
            />
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className='anonymousPageNavbar main-nav'>
                <Container fluid>
                    {/* Mobile Menu */}
                    <Nav className="management-console-nav d-block d-lg-none">
                        {['md'].map((expand) => (
                            <Navbar collapseOnSelect key={expand} expand="lg" className="col">
                                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className="px-0" />
                                <Navbar.Offcanvas
                                    id={`offcanvasNavbar-expand-${expand}`}
                                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                    placement="end"
                                >
                                    <Offcanvas.Header closeButton>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body className="mobile-menu">
                                        {
                                            navbarMainLink && navbarMainLink.map((navLink, index) => {
                                                if (!navLink.disabled) {
                                                    return (
                                                        navLink["subMenus"].length == 0 ?
                                                            <>
                                                                {/* NavLink Structure 
                                                    <Nav.Link className="navbaricon mySynnex" onClick={() => navigate("/synnex-cloud/dashboard")} eventKey="default">
                                                        My Synnex
                                                    </Nav.Link>
                                                    */}
                                                                <Nav.Link
                                                                    key={navLink.id}
                                                                    className={`navbaricon ${navLink.customClass} ${window.location.href.slice('/').includes(navLink.url) ? "activeTab" : ""}`}
                                                                    onClick={() => {
                                                                        if (navLink.external) {
                                                                            window.open(navLink.url, '_blank');
                                                                        } else {
                                                                            navigate(`/${navLink.url}`)
                                                                        }
                                                                    }}
                                                                    eventKey={navLink.eventKey}>
                                                                    {navLink.label}
                                                                </Nav.Link>
                                                            </>
                                                            :
                                                            <>
                                                                {/* Sub Menu Structure
                                                        <NavDropdown.Item onClick={() => navigate(`/${navLink.url}/cloud-summary`)}>Summaries</NavDropdown.Item>
                                                    */}
                                                                <NavDropdown
                                                                    className={`navbaricon ${navLink.customClass}`}
                                                                    title={navLink.label}
                                                                >
                                                                    {
                                                                        navLink.subMenus.map((navSubLink) => {
                                                                            if (!navSubLink.disabled) {
                                                                                return (
                                                                                    <NavDropdown.Item
                                                                                        onClick={() => {
                                                                                            if (navSubLink.external) {
                                                                                                window.open(navSubLink.url, '_blank');
                                                                                            } else {
                                                                                                navigate(`/${navLink.url}/${navSubLink.url}`)
                                                                                            }
                                                                                        }}>
                                                                                        {navSubLink.label}
                                                                                    </NavDropdown.Item>
                                                                                )
                                                                            }

                                                                        })
                                                                    }
                                                                </NavDropdown>
                                                            </>
                                                    )
                                                }
                                            })
                                        }
                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>

                            </Navbar>
                        ))}
                    </Nav>
                    <Nav className="logo-block">
                        {/* {globalData &&
                            <Navbar.Brand className="col-lg-4 col brand-logo-navigation-bar">
                                <img src={globalData.baseUrl + globalData.headerLogo.urls[0]} alt="Synnex" />
                            </Navbar.Brand>
                        }
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <span className="d-none d-md-flex seperator">&nbsp;</span>
                            <Nav.Link className="header-link d-none d-md-flex pl-3">Cloud Marketplace</Nav.Link>
                        </Navbar.Collapse> */}

                        {/* Desktop and Tab Menu */}
                        <Container fluid id="navbarToggleExternalContent" className="d-none d-lg-block px-0">
                            <div className="my-0">
                                <Nav className="nav-tabs custom-navs" activeKey={tabActive} onSelect={(selectedKey) => { props.setActiveLocalKey(selectedKey) }}>
                                    {navbarMainLink && navbarMainLink.map((navLink) => {
                                        if (!navLink.disabled) {
                                            return (
                                                navLink["subMenus"].length === 0 ? (
                                                    <Nav.Link
                                                        key={navLink.id}
                                                        title={navLink.tooltip}
                                                        className={`navbaricon ${navLink.customClass} ${window.location.href.slice('/').includes(navLink.url) ? "activeTab active" : ""}`}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleNavigation(navLink.url, navLink.external, e.ctrlKey || e.metaKey);
                                                        }}
                                                        href={`/${navLink.url}`}
                                                        eventKey={navLink.eventKey}
                                                    >
                                                        {navLink.label}
                                                    </Nav.Link>
                                                ) : (
                                                    <NavDropdown
                                                        key={navLink.id}
                                                        className={`navbaricon ${navLink.customClass} ${window.location.href.slice('/').includes(navLink.url) ? "activeTab active" : ""}`}
                                                        title={navLink.label}
                                                    >
                                                        {navLink.subMenus.map((navSubLink) => {
                                                            if (!navSubLink.disabled) {
                                                                const fullUrl = navSubLink.external ? navSubLink.url : `/${navLink.url}/${navSubLink.url}`;
                                                                return (
                                                                    <NavDropdown.Item
                                                                        key={navSubLink.url}
                                                                        as="a"
                                                                        href={fullUrl}
                                                                        className={`${window.location.href.slice(window.location.href.lastIndexOf("/")) === "/" + navSubLink.url ? "activeTab" : ""}`}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            handleNavigation(fullUrl, navSubLink.external, e.ctrlKey || e.metaKey);
                                                                        }}
                                                                    >
                                                                        {navSubLink.label}
                                                                    </NavDropdown.Item>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </NavDropdown>
                                                )
                                            );
                                        }
                                        return null;
                                    })}
                                </Nav>
                            </div>
                        </Container>
                    </Nav>



                    <Navbar className="topnavbar">
                        <Nav className='ms-auto'>
                            {(localStorage.getItem("curentRole") && !localStorage.getItem("curentRole").includes(["RA User"])) && <div className="notifications">
                                <HelpOutlineIcon className='cpointer help-icon-training-widget' onClick={() => { setHelpTrainingWidgetPopupOpen(true) }} size={16} />
                                <NavDropdown autoClose={"outside"} eventKey={9} title={<span className='bell-icon'>
                                    {(notificationCount && notificationData) ?
                                        <Badge badgeContent={notificationCount} color="primary" overlap="circular" sx={{ "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15 } }}>
                                            <NotificationsNoneIcon sx={{ fontSize: 22 }} />
                                        </Badge> : <NotificationsNoneIcon sx={{ fontSize: 22 }} />}</span>} className="notification-nav" id="main-nav-dropdown">
                                    <NavDropdown.Item eventKey="link-1" className='notification-navbar-dropdown'>
                                        <NotificationCentre notificationData={notificationData} GetRecentNotifications={GetRecentNotifications} setHookNotifications={props.setHookNotifications} emptyNotification={emptyNotification} Logout={props.Logout} />
                                    </NavDropdown.Item>

                                </NavDropdown>
                                <NavDropdown autoClose={"outside"} eventKey={9} title={<span className='announcement-icon'>&nbsp;</span>} className="announcement-nav" id="main-nav-dropdown">
                                    <NavDropdown.Item eventKey="link-1" className='notification-navbar-dropdown' id="announcement-navbar-dropdown" onClick={(e) => {
                                        if (e.target.alt && e.target.alt.length > 0) {
                                            window.open(e.target.alt, '_blank');
                                        }
                                    }}>
                                        <Announcement Logout={props.Logout} />
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <span className='feedback-icon' onClick={props.toggleDrawerFeedback(true)}>&nbsp;</span>
                                <Telephone className='cpointer' onClick={() => { props.toggleDrawer(true)(); props.setComingFromIntegration(false) }} size={16} />
                            </div>}
                            <NavDropdown eventKey={1}
                                title=
                                {profilePictureText ?
                                    <span className='profile-text'>{profilePictureText}</span> :
                                    <img className="profile-image"
                                        src={profileIcon}
                                        alt="user pic"
                                    />
                                }
                                className="pofile-nav"
                                id="main-nav-dropdown">
                                {/* <NavDropdown.Item onClick={() => {navigate("/management-console/");props.setActiveLocalKey("default")}} eventKey="default">
                                        Management Console</NavDropdown.Item> */}
                                <NavDropdown.Item className="profile" onClick={() => { navigate("/profile/"); props.setActiveLocalKey("") }} eventKey="link-1">
                                    <label>Profile</label>
                                </NavDropdown.Item>
                                {roleSubaccount === "false" && <NavDropdown.Item className="User-management-icon" onClick={() => { navigate("/user-management"); props.setActiveLocalKey("") }} eventKey="link-2">
                                    <label>User Management</label>
                                </NavDropdown.Item>}
                                {(localStorage.getItem("curentRole") && !localStorage.getItem("curentRole").includes(["RA User"])) && <NavDropdown.Item className="subscriptions-icon" onClick={() => { navigate("/subscriptions"); props.setActiveLocalKey("") }} eventKey="link-3">
                                    <label>Subscriptions</label>
                                </NavDropdown.Item>}
                                {paymentGatewayProfileFlag && (localStorage.getItem("curentRole") && !localStorage.getItem("curentRole").includes(["RA User"])) && <NavDropdown.Item className="credit_card" onClick={() => { navigate("/billing/payment-methods"); props.setActiveLocalKey("") }} eventKey="link-4">
                                    <label>Payment Methods</label>
                                </NavDropdown.Item>}
                                {/* <NavDropdown.Item onClick={() => navigate("/change-password/")} eventKey="link-2">Change Password</NavDropdown.Item> */}
                                <NavDropdown.Item className="logout" onClick={() => { handleLogoutRedirect() }} eventKey="link-3">
                                    <label>Logout</label>
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>

                    </Navbar>

                </Container>
            </Navbar>
            <TrainingWidget setModalOpen={setHelpTrainingWidgetPopupOpen} modalOpen={helpTrainingWidgetPopupOpen} Logout={props.Logout} processingFlag={processingFlag} setProcessingFlag={setProcessingFlag} processingFilesCount={processingFilesCount} setProcessingFilesCount={setProcessingFilesCount} />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                className='processing-download-train-widget'
                TransitionComponent="SlideTransition"
                // autoHideDuration={6000}
                open={processingFlag}
                // onClose={() => setProcessingFlag(false)}
                message={`Processing ${processingFilesCount} Files . . .`}
                action={processingTrainingAction}
            />

        </div>
    )
}

export default CustomerNavbar