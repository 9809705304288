import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import styles from '../../CSPCustomerPrerequisite.module.scss';

const CSPDomainProvisioned = (props) => {

  const navigate = useNavigate();                                             // to use navigate function

  const [isDisablePrerequisite, setIsDisablePrerequisite] = useState(localStorage.getItem("disabled_array").includes("Prerequisite") ? true : false);

  return (
    <div className="details-box p-3 pb-5 product-box-outer">
      <h3 className={`mt-3 ${styles['accordion-header-wrapper']}`}>
        <label className="pl-2">
          <span className={`${styles['accordion-name']}`}>Domain</span>
        </label>
      </h3>

      <div className={`px-2 ${styles['accordion-content']}`}>
        <Row>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="formOrganaisation">
              <Form.Label>Customer Domain Name:</Form.Label>

              <Row>
                <Col lg={3} md={6} >
                  <Row className="align-items-center">
                    <Col md={12}>
                      <Form.Control
                        type="text"
                        value={props.domainName}
                        disabled
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>

      </div>
      {(props.mcaDetails.mcaStatus || props.mcaCompleted == true) &&
        <>
          <h3 className={`mt-5 ${styles['accordion-header-wrapper']}`}>
            <label className="pl-2">
              <span className={`${styles['accordion-name']}`}>MCA Agreement</span>
            </label>
          </h3>

          <div className={`px-2 ${styles['accordion-content']}`}>
            <Row>
              <Col md={8}>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="mcaFirstName">
                      <Form.Label>First name</Form.Label>
                      <Form.Control
                        type="text"
                        value={props.mcaDetails.firstName}
                        disabled
                      />
                    </Form.Group>

                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Last name</Form.Label>
                      <Form.Control
                        type="text"
                        value={props.mcaDetails.lastName}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email ID</Form.Label>
                      <Form.Control
                        type="text"
                        value={props.mcaDetails.email}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>



                    <Form.Group className="mb-3" controlId="phoneNumber">
                      <Form.Label >
                        Phone Number
                      </Form.Label>
                      <Row>
                        <Col>
                          <div className="phone-number-wrapper">
                            <label>+61</label>
                            <Form.Control
                              type="text"
                              className="phone-number form-control col"
                              name="phoneNumber"
                              value={props.mcaDetails.phoneNumber}
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form.Group>

                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>MCA Terms Accepted on (as per UTC Time)</Form.Label>
                      <Form.Control
                        type="text"
                        value={(props.isMCAFirstTime == true && props.comingFromProvision == false) ? props.mcaFirstTimeDate : props.mcaDetails.mcaAcceptanceDate}
                        disabled
                      />
                    </Form.Group>
                  </Col>

                </Row>
              </Col>
            </Row>
          </div>
        </>
      }
      <Row>
        <Col>
          <>
            {props.isMCAFirstTime == true && props.comingFromProvision == false &&
              <span className='d-flex items-align-center mt-1'>
                <span className="status-success  mr-1">&nbsp;</span>
                <span className="green text-small">
                  Your pre-requisites are successfully completed
                </span>
              </span>
            }
          </>
        </Col>

        <Col className="text-right">
          <div className="button-wrapper">
            {props.isMCAFirstTime == true && props.comingFromProvision == false &&

              // Working fine. Don't tocuh it
              <Button
                className="btn btn-lg ml-2 px-4"
                onClick={() => {
                  props.setActiveLocalKey("cloudProvision");
                  navigate("/cloud-provision", {
                    state: {
                      customerId: props.customerId,
                      serviceId: props.serviceId,
                      isManagementConsoleNavigation: true,
                    }
                  })
                }}
              >
                View Plans
              </Button>
            }

            {(props.isMCAFirstTime == false || props.comingFromProvision == true)
              &&
              // Working fine. Don't tocuh it
              <Button
                className={!isDisablePrerequisite ? "btn btn-lg ml-2 px-4" : "d-none"}
                onClick={() => {
                  props.setActiveLocalKey("cloudProvision");
                  navigate("/cloud-provision", {
                    state: {
                      customerId: props.customerId,
                      serviceId: props.serviceId,
                      isManagementConsoleNavigation: true,
                    }
                  })
                }}
              >
                Buy Subscriptions
              </Button>
            }
          </div>
        </Col>
      </Row>
    </div>


  );
}

export default CSPDomainProvisioned;