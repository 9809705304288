import { Col, Form, NavDropdown, Row } from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import styles from './seatSummary.module.scss'
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
// import Summary from "./LocalComponents/Summary";
import { api } from "../../../../../Utils/API";
import { DASHBOARD_CUSTOMERS, DASHBOARD_SEATS_SUMMARY } from "../../../../../Utils/GlobalConstants";
import Toast from "../../../../../GlobalComponents/Toast";
import loadingGif from "../../../../../Assets/Images/Animations/loading-management-console.gif"
import Summary from "./LocalComponents/Summary";
// import Summary from "./LocalComponents/Summary";

const SeatsSummary = ({ SaveDashboardWidget, id, widgetPositionData, Logout }) => {
  const date = new Date();                                                   // for js date time function

  const [data, setData] = useState(null)                   // for storing piechart data from api
  const [summaryPopup, setSummaryPopup] = useState(false);                   // for opening summary popup
  const [tabName, setTabName] = useState("Summary");                         // to store tab name

  // Use State for Toasts
  const [toastError, setToastError] = useState(false);                        // toast message displaying error message
  const [toastMessage, setToastMessage] = useState("");                       // toast message usestate


  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Function to call all Customer count data
  async function GetSubscriptions() {
    await api.get(`${DASHBOARD_SEATS_SUMMARY}`, config)
      .then(resp => {
        setData(resp.data);
      })
      .catch(error => {
        // console.log("Error : ", error);
        if (error?.status == 401 || error?.response.status == 401) {
          // console.log("Error 401");
          Logout();
      }
        else {
        }
      });
  };


  // Function to delete widget in dashboard
  async function DeleteWidget() {
    let body = [];
    widgetPositionData.map((wd) => {
      if (wd.widgetName !== "add" && wd.id !== id) {
        body.push(wd);
      }
    })
    SaveDashboardWidget(body, true);
  };



  //useEffect for calling get api on initial load
  useEffect(() => {
    GetSubscriptions();
  }, [])

  return (
    <>
      <Row className={`${styles["widget-header"]} align-items-center pt-0`}>
        <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

        <Col md={6}>
          <h3 className="mb-0">Seats Summary</h3>
        </Col>
        <Col md={6} className="text-right">
          <span className="dashboard-arrow mx-1 cpointer" onClick={() => { setSummaryPopup(true); setTabName("Summary"); }}></span>
          <span className="dashboard-cross mx-1 cpointer" onClick={() => DeleteWidget()}></span>
        </Col>
        {
          summaryPopup &&
          <Summary setSummaryPopup={setSummaryPopup} summaryPopup={summaryPopup} tabName={tabName} setTabName={setTabName} Logout={Logout}/>
        }
      </Row>
      {data && <div className={`mt-2 ${styles["seats-summary-div"]}`}>
        {
          data.map((data) => {
            return (
              <Row className={` ${styles["seats-summary-card"]} py-2 my-4 `}>
                <span className="text-strong my-2">{data.serviceName}</span>
                <Col>
                  <h1 className={`${data.isIncrease ? "text-success" : "text-danger"} notes-confirmation`}><span className={data.isIncrease ? "dashboard-green-arrow mb-1" : "dashboard-red-arrow mb-1"}></span> {data.percentage}%</h1>
                </Col>
                <Col className="text-right my-1">
                  <span>{data.seats} Seats</span>
                </Col>
              </Row>
            )

          })
        }
      </div>}
    </>
  )
}

export default SeatsSummary
