import React from 'react'
import { Row, Col, Button } from "react-bootstrap";
import styles from '../../CSPCustomerPrerequisite.module.scss';
import { useNavigate } from "react-router-dom";



const BuyDomainSuccess = (props) => {
    const navigate = useNavigate();                                             // to use navigate function
    return (

        <div className={`${styles['success-panel']} text-center`}>
            <Row>
                <Col md={12}>
                    <span className='success-page'>&nbsp;</span>
                </Col>

                {props.isDomainType.isDomainProceed == true &&
                    <>
                        <Col md={12} className="text-center">
                            <p>Your request has been submitted successfully.</p>
                        </Col>
                        <Col md={12} className="text-center">
                            <div className="button-wrapper">
                                {/* Working Fine. Don't touch it */}
                                <Button
                                    className="btn btn-lg btn-border-light px-4"
                                    variant="light"
                                    onClick={() => {

                                        props.setActiveLocalKey("myCustomers");

                                        navigate("/my-customers/edit-customer", {
                                            state: {
                                                customerCode: props.customerId,
                                                customerName: props.customerName,
                                                serviceId: props.serviceId,
                                                tabName: "Cloud Requirements",
                                                eventKey: "Microsoft",
                                                selectedOption: "Microsoft",
                                                integrationCode: "microsoftsaas",
                                                comingFromProvision: true,
                                                isMCACompleted: true,
                                                isDomainProvisioned: props.isDomainProvisioned,
                                                isCSPAlreadyConfigured: true,
                                                isMcaFirstTime: false,
                                                mcaCompleted: true
                                            }
                                        })
                                    }}
                                >
                                    View Details
                                </Button>

                                {/* Working fine. Don't tocuh it */}
                                <Button
                                    className="btn btn-lg ml-2 px-4"
                                    onClick={() => {

                                        navigate("/cloud-provision", {
                                            state: {
                                                customerId: props.customerId,
                                                serviceId: props.serviceId,
                                                isManagementConsoleNavigation: true
                                            }
                                        })
                                    }}
                                >
                                    Buy Subscriptions
                                </Button>
                            </div>
                        </Col>
                    </>
                }

                {props.isDomainType.isDomainTransition == true &&
                    <>
                        <Col md={12} className="text-center">
                            <p>Your domain is transitioned successfully.</p>
                        </Col>

                        <Col md={12} className="text-center">
                            <div className="button-wrapper">

                                {/* Working Fine. Don't touch it */}
                                <Button
                                    className="btn btn-lg btn-border-light px-4"
                                    variant="light"
                                    onClick={() => {
                                        props.setActiveLocalKey("myCustomers");
                                        navigate("/my-customers/edit-customer", {
                                            state: {
                                                customerCode: props.customerId,
                                                customerName: props.customerName,
                                                serviceId: props.serviceId,
                                                tabName: "Cloud Requirements",
                                                eventKey: "Microsoft",
                                                selectedOption: "Microsoft",
                                                integrationCode: "microsoftsaas",
                                                comingFromProvision: true,
                                                domainName: props.domainName,
                                                isMCACompleted: true,
                                                isDomainProvisioned: props.isDomainProvisioned,
                                                isCSPAlreadyConfigured: true,
                                                isMcaFirstTime: false
                                            }
                                        })
                                    }}
                                >
                                    View Details
                                </Button>

                                {/* Working Fine. Don't touch it */}
                                <Button
                                    className="btn btn-lg ml-2 px-4"
                                    onClick={() => {

                                        navigate("/cloud-provision", {
                                            state: {
                                                customerId: props.customerId,
                                                serviceId: props.serviceId,
                                                isManagementConsoleNavigation: true,
                                            }
                                        })
                                    }}
                                >
                                    Buy Subscriptions
                                </Button>

                            </div>
                        </Col>
                    </>
                }
            </Row>
        </div>

    );
}

export default BuyDomainSuccess;