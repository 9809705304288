import React from 'react'

const Expandable = ({ data }) => {

    let array = data.assignedRoles.split(',');

    return (
        <>
            <div className='mx-3'>
                <h4 className='my-1 ml-1'>Roles</h4>
                <span>
                    {data &&
                        array.map((item) => {
                            return (
                                <>
                                    <span className='d-inline-block'>
                                        <span className='notes-confirmation gdap-bullet'></span><span className='mr-5'>{item}</span>
                                    </span>
                                </>
                            )
                        })}
                </span>
                {data.status === "Approval Pending" && <div className='text-medium mt-1'>Please <a href={data.approvalLink} target='_blank'>click here</a> and sign in using customer tenants global admin credentials to approve GDAP</div>}
            </div>
        </>
    )
}

export default Expandable
