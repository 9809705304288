import React, { useEffect, useState } from 'react'
import { api } from '../../../../../../Utils/API';
import { AZURE_PRODUCT_PLANS , AZURE_GET_EMAIL_ID_USERS , AZURE_PRODUCT_ORDERING, ACCEPT_LOGIN_TERMS_AND_CONDITIONS } from '../../../../../../Utils/GlobalConstants';
import { Button, Col, Container, Form, InputGroup, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import styles from './AzureProductDetail.module.scss';
import EmptyDataTableFilterSearch from '../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import SearchDropdown from '../../../SearchDropdown';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { XLg } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';

const AzureProductDetail = ({ productId,serviceId,customerName,setSelectCustomerErrorState,customerId,setToastError,setToastMessage,domainName,checkResellerPrereqisiteFlag,checkResellerPrereqisiteExpiryFlag,setPlanSelectedFlag, isreadOnly,  billingPaymentGatewayData, setBillingPaymentGatewayErrorMessage, setBillingPaymentGatewayData, billingPaymentCardExpiryFlag, billingPaymentDirecrDebitExpiryFlag, setBillingPaymentGatewayErrorPopup, Logout, proceedToProvisionFlag  }) => {
    const navigate = useNavigate(); 
    let CSPProductDetailColumnName = ["", "Plan", "SKU ID", "Price", "Billing Term"];                            // Columns of table for Suspend Legacy plan Action 
    let ReadOnlyCSPProductDetailColumnName = ["Plan", "SKU ID", "Price", "Billing Term"];                            // Columns of table for Suspend Legacy plan Action 
    const [productDetailsData, setProductDetailsData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [emptyData, setEmptyData] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [checkProductId, setCheckProductId] = useState(null);
    const [orderConfirmationModalOpen, setOrderConfirmationModalOpen] = useState(false);
    const [poNumber, setPoNumber] = useState(null);
    const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") =="false" ? false : true);
    const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);

    const [userEmailId, setUserEmailId] = useState("");
    const [userEmailData, setUserEmailData] = useState(null);
    const [userEmailDataErrorFlag, setUserEmailDataErrorFlag] = useState(false);

    const [orderConfirmationSuccess, setOrderConfirmationSuccess] = useState(false);
    const [orderConfirmation, setOrderConfirmation] = useState(null);


    const alphanumericRegex = /^[a-zA-Z0-9]{1,25}$/; // RegEx for PO Number

    const [customerPrerequisiteModalUpen, setCustomerPrerequisiteModalUpen] = useState(false);
    const [resellerPrerequisiteModalUpen, setResellerPrerequisiteModalUpen] = useState(false);

    const [userEmailDataFlag, setUserEmailDataFlag] = useState(false);
     
    const [ipAddress, setIpAddress] = useState(null);
    const userAgent = navigator.userAgent;
    const browserName = userAgent.match(/(Chrome|Safari|Firefox|Edge|Opera|Internet Explorer)\//);
    const browserVersion = userAgent.match(/\((.*?)\)/);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

      // Function to call all dropdown values in Category Section in Filter Box
  async function GetAllUserId() {
    setUserEmailData(null);
    await api.get(`${AZURE_GET_EMAIL_ID_USERS}${domainName}`, config)
      .then(resp => {
        if(resp.status===200){
            // console.log("Data : ", resp.data.azureAdminUserEmails);
            setUserEmailData(resp.data.azureAdminUserEmails);
            setUserEmailDataFlag(false);
        }
        else{
            setUserEmailDataFlag(true);
            setUserEmailData(null);
        }
        
      })
      .catch(error => {
        // console.log(error)
        setUserEmailData(null);
        setUserEmailDataFlag(false);
        if (error?.status == 401 || error?.response.status == 401) {
            Logout();
        }
        else {
          // console.log(error);
        }
      });
  };

    // Get Table Data with Pagination and Filter and Search Functionality
    async function GetTableData(productId
        // , search, billingTerm, category, pageNo, pageSize, sortCol, sortDir
    ) {
        setLoading(true);
        (productId === undefined || productId === null) ? productId = "" : productId = productId;
        // (billingTerm === undefined || billingTerm === null) ? billingTerm = "" : billingTerm = billingTerm;
        // (search === undefined || search === null) ? search = "" : search = search;
        // (category === undefined || category === null) ? category = "" : category = category;
        await api.get(`${AZURE_PRODUCT_PLANS}?productId=${productId}
      `, config)
            .then(resp => {
                setLoading(false);
                if (resp.status == 204) {
                    setEmptyData(true);
                }
                // handle success
                else {
                    setEmptyData(false);
                    // console.log("listing:", resp.data);
                    setProductDetailsData(resp.data);
                }
            })
            .catch(error => {
                // console.log(error);
                setLoading(false);
                setEmptyData(true);
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                    // console.log(error);
                }
            });
    };

        // Product Ordering
        async function ProductOrdering() {
            setConfirmLoadingFlag(true);
            let body = {
                "purchaseOrderNumber": poNumber ? poNumber : "",
                "customerCode": customerId,
                "adminUserEmail": userEmailId,
                "planId": selectedData.planId,
                "quantity": 1
            }
            await api.post(AZURE_PRODUCT_ORDERING, body, config)
                .then(resp => {
                    // console.log("Response : ", resp.request.status);
                    if(resp.request.status==200){
                        // handle success
                        // console.log("Response : ", resp);
                        setConfirmLoadingFlag(false);
                        setOrderConfirmation(resp.data);
                        setOrderConfirmationSuccess(true);
                        
                        setCheckProductId(null); 
                        setSelectedData(null); 
                    }  
                })
                .catch(error => {
                    if (error?.status == 401 || error?.response.status == 401) {
                        Logout();
                    }
                    else {
                        // console.log("Error : ",error);
                        setConfirmLoadingFlag(false);
                        setToastMessage("Azure Product Provision Failed at the moment, try again later.");
                        setToastError(true);
                        setTimeout(() => {
                            setToastError(false);
                        }, 20000);
                    }
                });
        };

        function generateRandomIp() {
            // Generate random IP address components
            const octets = [];
            for (let i = 0; i < 4; i++) {
                octets.push(Math.floor(Math.random() * 256).toString());
            }
            
            // Join the octets with dots to form the IP address
            const ipAddress = octets.join(".");
            
            return ipAddress;
        }
        
        // Terms and Condition accept Popup Data
        async function TermsAndConditionAcceptFunction(browser,ipAddress) {
            (ipAddress === undefined || ipAddress === null || ipAddress === "null") ? ipAddress = generateRandomIp() : ipAddress = ipAddress;
            let body = {
                "browser": browser,
                "ipAddress": ipAddress,
                "module":"Cloud",
            }
            await api.post(ACCEPT_LOGIN_TERMS_AND_CONDITIONS,body, config).then((resp) => {
                // handle success
                // console.log(resp.data);
                localStorage.setItem("cloud_terms_and_conditions","true");
                setIsTermsChecked(true);
                })
                .catch((error) => {
                    if (error?.status == 401 || error?.response.status == 401) {
                        Logout();
                    }
                });
        }
        
        useEffect(() => {
            if(!isTermsChecked){
                fetch("https://api.ipify.org?format=json")
                .then(response => response.json())
                .then(data => setIpAddress(data.ip));
            }
        }, [isTermsChecked])

    useEffect(() => {
        if (productId)
            GetTableData(productId)
    }, [productId]);

    useEffect(() => {
        if (domainName){
            GetAllUserId()
        }
        else{
            setUserEmailData(null);
        }    
    }, [domainName]);

    useEffect(() => {
        if(customerId){
            setSelectCustomerErrorState(false);
        }
    }, [customerId])

    useEffect(() => {
        if(selectedData!==null){
            setPlanSelectedFlag(true);
        }
        else{
            setPlanSelectedFlag(false);
        }
    }, [selectedData])

    return (
        <>
            <Popup
                open={orderConfirmationModalOpen}
                onClose={() => { setOrderConfirmationModalOpen(false); setPoNumber("");
                if(orderConfirmationSuccess){
                    setUserEmailId("");
                    
                }
                setOrderConfirmationSuccess(false);
                }}
                className={`custom-modal custom-modal-ticket-details ${orderConfirmationSuccess ? "custom-modal-md" : "custom-modal-xl"}`}
                closeOnDocumentClick={!confirmLoadingFlag}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">

                        <div className="header">
                            <h2>Order Confirmation - Cloud Provision</h2>
                            <XLg
                                size={24}
                                className="cpointer close-icon-popup"
                                onClick={() => { if (!confirmLoadingFlag) { close(); } }}
                            />
                        </div>
                        {!orderConfirmationSuccess && <Container fluid>
                            <Row className="pb-3 pt-5">
                                <Col lg={8}>
                                    <h3 className="mb-3 text-regular text-bold-strong">Customer Name: <span className="text-regular">{customerName}</span></h3>
                                </Col>
                                <Col lg={4}>
                                    <h3 className="mb-3 text-regular text-bold-strong">Cloud Service: <span className="text-regular">Microsoft CSP Azure</span></h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {selectedData &&
                                        <Table className={`${styles['csp-change-status-core-table']}`}
                                            responsive="md">
                                            <thead className="sticky-top">
                                                <tr>
                                                    {CSPProductDetailColumnName.map(th => {
                                                        return (<th>
                                                            {th}</th>)
                                                    })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    productDetailsData &&
                                                    <tr>
                                                        <td></td>
                                                        <td>{productDetailsData.planName}</td>
                                                        <td >{productDetailsData.skuId}</td>
                                                        <td  >{productDetailsData.price}</td>
                                                        <td>{productDetailsData.billingTerm}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-3">
                                <Col>
                                    <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="w-auto d-inline ml-3"
                                        id="inputPONumber"
                                        placeholder='Add PO Number'
                                        aria-describedby="inputPONumber"
                                        value={poNumber}
                                        maxlength="25"
                                        onChange={(e) => {
                                            alphanumericRegex.test(e.target.value)
                                                ? setPoNumber(e.target.value)
                                                : console.log("")
                                        }}
                                    />
                                    <Form.Text id="poNumber" />

                                </Col>
                            </Row>
                            <Row className="mt-2 mb-4">
                                <Col md={6}>

                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id="termsCheckBox"
                                        label=""
                                        disabled
                                        checked={isTermsChecked}
                                        className="d-inline-block w-auto"
                                    />
                                    <Form.Label className="d-inline-block  w-auto mb-0">
                                        I agree to the <a href="/terms-and-conditions" target='_blank'  onClick={() => {if(!isTermsChecked){let browser = browserName[1] + " " + browserVersion[1];TermsAndConditionAcceptFunction(browser,ipAddress);}}}>terms & conditions</a> of Synnex cloud platform
                                    </Form.Label>

                                </Col>
                                <Col md={6}>
                                    <div className="btn-wrapper text-right d-md-block d-none mb-3">
                                        {!confirmLoadingFlag && isTermsChecked && <>
                                            <Button
                                                className="px-4 mx-2"
                                                onClick={() => { setOrderConfirmationModalOpen(false);  }}>Cancel</Button>
                                            <Button
                                                className="px-4 mx-2"
                                                onClick={() => {
                                                    ProductOrdering();
                                                }}
                                            >
                                                Confirm
                                            </Button>
                                        </>
                                        }
                                        {!confirmLoadingFlag && !isTermsChecked &&
                                            <>
                                                <Button
                                                    className="px-4 mx-2"
                                                    onClick={() => { setOrderConfirmationModalOpen(false); setPoNumber(""); }}>Cancel
                                                </Button>
                                                <Button
                                                    variant="light"
                                                    className="px-4 mx-2"
                                                    disabled
                                                >
                                                    Confirm
                                                </Button>
                                            </>
                                        }
                                        {confirmLoadingFlag &&
                                            <>
                                                <Button
                                                    variant="light"
                                                    disabled
                                                    className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                >Cancel
                                                </Button>
                                                <Button
                                                    disabled
                                                    className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                    variant="light"
                                                >
                                                    Processing . . .
                                                </Button>
                                            </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Container>}
                        {
                            orderConfirmationSuccess &&
                            <>
                              <Container className='text-center'>
                                <p>
                                  <div className='success-page'></div>
                                </p>
                                <p className='text-regular'>{orderConfirmation.message}</p>
                                <p className='text-regular'><strong>Provision Order ID:</strong> {orderConfirmation.orderCode}</p>
                                <div>
                                  <Button
                                    className="px-4 mx-2"
                                    onClick={() => {setOrderConfirmationModalOpen(false);setUserEmailId(""); setPoNumber("");close();}}>
                                    Ok
                                  </Button>
                                </div>
                              </Container>
                            </>
                        }
                    </div>

                )}
            </Popup>
            <Popup
        className="custom-modal custom-modal-sm"
        open={customerPrerequisiteModalUpen}
        onClose={() => setCustomerPrerequisiteModalUpen(false)}
        modal
        nested
    >
        {(close) => (
            <div className="modal">
                <div className="header p-4 py-3">
                    <h2 className="mb-0 px-3">Customer Prerequisite</h2>
                    <XLg
                        size={18}
                        className="cpointer text-strong close-icon-popup-sm"
                        onClick={close}
                        color="#6A6A6A"
                    />
                </div>
                <Container className="p-5">
                    <p className='pt-0 px-3 pb-5 mb-0'>
                        <strong>Please add customer pre-requisites to provision subscriptions.</strong>
                    </p>
                    <div className="actions">
                        <div className="d-flex justify-content-end px-3 pb-3 pt-2">
                            <Button
                                className="btn btn-lg mx-3 btn-border-light"
                                variant="light"
                                onClick={() => {
                                    // console.log("modal closed ");
                                    close();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="btn btn-md"
                                onClick={() => {
                                    if(customerName!=="Select Customer"){
                                        navigate("/cloud-provision/customer/prerequisite/microsoft", {
                                            state: { serviceId: serviceId, customerId: customerId, customerName: customerName }
                                            }
                                            );
                                            close();
                                    }
                                }}
                            >
                                Proceed
                            </Button>
                        </div>
                    </div>
                </Container>
            </div>
        )}
            </Popup>
            <Popup
                className="custom-modal custom-modal-sm"
                open={resellerPrerequisiteModalUpen}
                onClose={() => setResellerPrerequisiteModalUpen(false)}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">Vendor Requirement</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color="#6A6A6A"
                            />
                        </div>
                        <Container className="p-5">
                            <p className='pt-0 px-3 pb-5 mb-0'>
                                {checkResellerPrereqisiteExpiryFlag === true ? <strong>Please click on proceed to reset your MPNID.</strong> : <strong>Please add vendor requirements to provision subscriptions.</strong>}
                            </p>
                            <div className="actions">
                                <div className="d-flex justify-content-end px-3 pb-3 pt-2">
                                    <Button
                                        className="btn btn-lg mx-3 btn-border-light"
                                        variant="light"
                                        onClick={() => {
                                            // console.log("modal closed ");
                                            close();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="btn btn-md"
                                        onClick={() => {
                                        if(checkResellerPrereqisiteExpiryFlag===true){
                                            navigate("/profile", {
                                            state: { serviceId: serviceId }
                                            }
                                            );
                                        }
                                        else{
                                            navigate("/cloud-provision/reseller/prerequisite/microsoft", {
                                            state: { serviceId: serviceId }
                                            }
                                            );
                                            
                                        }
                                        close();  
                                        }}
                                    >
                                        Proceed
                                    </Button>
                                </div>
                            </div>
                        </Container>
                    </div>
                )}
            </Popup>
            <div className={`mt-4 ${styles['productlisting-main-table']}`}>

                {emptyData &&
                    <div className={styles["no-data-cloud-invoice"]}>
                        <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                        <h2 className="mt-4 mb-3">No Data Found</h2>
                    </div>
                }
                {productDetailsData &&
                    <>
                        <Row className='align-items-center py-2 px-3'>
                            <Col md={5}>
                                {userEmailData && <Row className='align-items-center'>
                                        <Col md={4}><p className="mb-1">Select User Email Id: </p></Col>
                                    <Col md={8}>
                                    {<>
                                        <Form.Select aria-label="Default select"
                                        className={userEmailDataErrorFlag ? "border-danger" : ""}
                                            value={userEmailId}
                                            id="email-id-select"
                                            onChange={(e) => {
                                                setUserEmailId(e.target.value); 
                                                if(!(e.target.value===null || e.target.value==="")){
                                                    setUserEmailDataErrorFlag(false);
                                                }
                                            }}
                                        >
                                            {userEmailId === "" && <option value="">Select Email Id</option>}
                                            {userEmailData &&
                                                userEmailData.map((dropdownItem) => {
                                                    return (
                                                        <option value={dropdownItem}>{dropdownItem}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>     
                                        {userEmailDataErrorFlag && <p className='text-danger'>Please Select Email Id</p>}
                                        </>
                                    }
                                </Col>
                                </Row>}
                                
                            </Col>
                            <Col md={4}>
                            </Col>
                            <Col md={3}>
                                <div className={`container my-3 text-right`}>
                                    <a href="https://azure.microsoft.com/en-au/pricing/calculator/" target='_blank' className={styles["price-calculator"]}>Price Calculator</a>
                                </div>
                            </Col>
                        </Row>
                        <Table className={`${styles['csp-change-status-core-table']}`}
                            responsive="md">
                            <thead className="sticky-top">
                                <tr>
                                    {(isreadOnly ? ReadOnlyCSPProductDetailColumnName : CSPProductDetailColumnName).map(th => {
                                        return (<th>
                                            {th}</th>)
                                    })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    productDetailsData &&
                                    <tr>
                                        <td className={isreadOnly ? 'd-none' : ""}><input type='radio' className='cpointer' checked={checkProductId === (productDetailsData.planId)} id={productDetailsData.planId} onChange={() => { setCheckProductId(productDetailsData.planId); setSelectedData(productDetailsData); }} /></td>
                                        <td>{productDetailsData.planName}</td>
                                        <td >{productDetailsData.skuId}</td>
                                        <td>{productDetailsData.price}</td>
                                        <td >{productDetailsData.billingTerm}</td>
                                    </tr>
                                }
                                {
                                    !selectedData &&
                                    <div className={styles['empty-selected-data']}>
                                    </div>
                                }
                                {
                                    selectedData &&
                                    <tr className='p-0 m-0x'>
                                        <td className={`${styles['azure-plan-selected']}`} colSpan="6">

                                            <>
                                                <div className={`table ${styles['csp-purchase-addon-count-row']} mb-0`}>
                                                    <div className={`${styles['csp-purchase-addon-count-row-inner']} mb-0`}>
                                                        <Row>
                                                            <Col md={6} className="d-flex pt-0 pb-0 align-items-center">
                                                                <p className="m-0 p-0 text-medium">1 plan selected</p>
                                                            </Col>
                                                            <Col md={6} className='pt-0 pb-0'>
                                                                <div className="btn-wrapper justify-content-end d-flex m-0 p-0">
                                                                    <Button
                                                                        variant="light"
                                                                        className="px-4 pt-0 pb-0 btn-border-light w-md-auto col-xs-12"
                                                                        onClick={() => { setCheckProductId(null);
                                                                             setSelectedData(null);
                                                                             setUserEmailId("");
                                                                            }}
                                                                    >
                                                                        Clear
                                                                    </Button>

                                                                    <Button
                                                                        className="px-4 pt-0 pb-0 ml-5"
                                                                        onClick={() => {
                                                                            if(billingPaymentGatewayData.isDefaultPaymentMethodValid){
                                                                                if(billingPaymentGatewayData.accountHoldStatus==="No Hold" || proceedToProvisionFlag){
                                                                                    if(billingPaymentCardExpiryFlag || billingPaymentDirecrDebitExpiryFlag){
                                                                                        if(billingPaymentCardExpiryFlag){
                                                                                            setBillingPaymentGatewayErrorMessage("Your default payment method of credit card has an expired primary card, please proceed to add a new card")
                                                                                            setBillingPaymentGatewayErrorPopup(true)
                                                                                        }
                                                                                        else{
                                                                                            setBillingPaymentGatewayErrorMessage("Your default payment method of Direct Debit has all expired statuses, please proceed to add a new application")
                                                                                            setBillingPaymentGatewayErrorPopup(true)
                                                                                        }
                                                                                    }
                                                                                    else{
                                                                                        if(checkResellerPrereqisiteFlag === false){
                                                                                            setResellerPrerequisiteModalUpen(true);
                                                                                        }
                                                                                        else{
                                                                                            if(checkResellerPrereqisiteExpiryFlag === true){
                                                                                            setResellerPrerequisiteModalUpen(true);
                                                                                            }
                                                                                            else{
                                                                                            if(customerId){
                                                                                                if(domainName.length>0){
                                                                                                    if(userEmailId ){
                                                                                                        if(userEmailData===null){
                                                                                                            setUserEmailDataErrorFlag(true)
                                                                                                        }else{
                                                                                                            setUserEmailDataErrorFlag(false);
                                                                                                            setOrderConfirmationModalOpen(true);
                                                                                                        }
                                                                                                    }
                                                                                                    else{
                                                                                                        setUserEmailDataErrorFlag(true);
                                                                                                        setSelectCustomerErrorState(false);
                                                                                                    }
                                                                                                
                                                                                                }
                                                                                            else{
                                                                                                setCustomerPrerequisiteModalUpen(true);
                                                                                            }
                                                                                            }
                                                                                            else{
                                                                                                setSelectCustomerErrorState(true);
                                                                                                window.scrollTo(0, 0);
                                                                                            }
                                                                                            } 
                                                                                        }
                                                                                    }
                                                                                }
                                                                                if(billingPaymentGatewayData.accountHoldStatus==="System Hold" && !proceedToProvisionFlag){
                                                                                    setBillingPaymentGatewayErrorMessage("There is an issue with your account. If you choose to proceed to provision the order, please contact Synnex account team to complete the provisioning of this order.")
                                                                                    setBillingPaymentGatewayErrorPopup(true)
                                                                                }
                                                                                if(billingPaymentGatewayData.accountHoldStatus==="Manual Hold"){
                                                                                    setBillingPaymentGatewayErrorMessage("We are unable to process your request at this time. Please contact Synnex Accounts team at 'accounts@au.synnex-grp.com' or '0385408888' for further instructions")
                                                                                    setBillingPaymentGatewayErrorPopup(true)
                                                                                }
                                                                                
                                                                            }
                                                                            else{
                                                                                if(!(billingPaymentGatewayData.isDefaultPaymentMethodValid)){
                                                                                    setBillingPaymentGatewayErrorMessage("No payment method is chosen by default, click on proceed to add payment method.")
                                                                                    setBillingPaymentGatewayErrorPopup(true)
                                                                                }
                                                                            }
                                                                            }
                                                                        }
                                                                    >
                                                                        Proceed
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>        
                                                    </div>
                                                </div>
                                            </>

                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        
                        {userEmailDataFlag && <p className='text-muted mb-0 pl-4'>Note : Acceptance of MCA details may take some time to reflect to proceed with provisioning of microsoft azure plan.</p>}
                                                
                    </>}
            </div>
        </>
    )
}

export default AzureProductDetail
