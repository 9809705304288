import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Button, Row, Col } from "react-bootstrap";
import "./styles.scss";
import BrandDetails from '../../../GlobalComponents/BrandDetails';
import InstallPWA from '../../../GlobalComponents/InstallPWA';
import AnonymousPageFormHeader from "../../../GlobalComponents/AnonymousPageFormHeader";
import Toast from "../../../GlobalComponents/Toast";

import { loginRequest } from '../../../authConfig';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';

const LoginCustomer = (props) => {

  const { instance } = useMsal();
  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  // UseState Defination
  const [mobileActive, setMobileActive] = useState("");                           // Mobile Screen Check
  const [bannerData, setBannerData] = useState();                                 // Banner Data from CMS
  const [backgroundImageUrl, setBackgroundImageUrl] = useState();                 // BG Image from CMS
  const [landingPageData, setLandingPageData] = useState();                       // Landing Page Data from CMS
  const [activationLink, setActivationLink] = useState(false);                    // Send activation link is open

  // Use State for Toasts
  const [myProfileSuccess, SetMyProfileSuccess] = useState(false);
  const [myProfileError, setMyProfileError] = useState(false);
  const [myProfileToastMessage, setMyProfileToastMessage] = useState("");

  // Defining UseNavigate for react router DOM v6
  const navigate = useNavigate();

  // To activate CSS Class when on mobile Screen
  const showMobileSceen = () => {
    setMobileActive("active");
  };

  // Adding CSS Style to Landing Page Main Component
  const loginComponent = {
    backgroundImage: backgroundImageUrl,
    backgroundSize: "cover",
  };

  // To navigate user To management Console when a user gets logged in
  if (props.customerIsAuthenticated) {
    navigate("/synnex-cloud/dashboard");
  }

  useEffect(() => {

    // console.log("Login Page ", props.customerIsAuthenticated)
    if (props.customerIsAuthenticated) {
      
      navigate('/dashboard')
    }
  }, [props.customerIsAuthenticated])

  

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  return (
    <>
      {/* Toasts */}
      <Toast toastCheckUseState={myProfileSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={myProfileToastMessage} />
      <Toast toastCheckUseState={myProfileError} toastType="danger" toastIconColor="#9C3B35" toastMessage={myProfileToastMessage} />
      <div className="d-flex flex-column login-page" style={loginComponent}>
        <Container>
          <Row>
            <Col md={6}>
              <BrandDetails
                handleClick={showMobileSceen}
                bannerData={bannerData}
              />
            </Col>
            <Col md={6}>
              {/* Default Login Page */}
              {!activationLink && (
                <>
                  <div className={`anonymous-login-main-div ${mobileActive}`}>
                    <Col xl={8} lg={10} md={12} className="login-block">
                      <div>


                        <AnonymousPageFormHeader page="login" pageData={landingPageData} setPageData={setLandingPageData} setBannerData={setBannerData} setBackgroundImageUrl={setBackgroundImageUrl} />
                        {/* {landingPageData && <LoginForm LogingIn={LogingIn} loginEmailAddress={loginEmailAddress} setLoginEmailAddress={setLoginEmailAddress} loginPassword={loginPassword} setLoginPassword={setLoginPassword} loginInitiatedFlag={loginInitiatedFlag} />} */}

                        <UnauthenticatedTemplate>
                          <Button
                            className="col-12"
                            type="submit"
                            onClick={() => handleLoginRedirect()}
                          >
                            Sign In
                          </Button>
                        </UnauthenticatedTemplate>

                        <AuthenticatedTemplate>
                          <Button
                            className="col-12"
                            type="submit"
                            onClick={() => handleLoginRedirect()}
                          >
                            Logout
                          </Button>
                        </AuthenticatedTemplate>
                      </div>
                      {/* {landingPageData && <LoginButton loginEmailAddress={loginEmailAddress} loginPassword={loginPassword} loginInitiatedFlag={loginInitiatedFlag} LogingIn={LogingIn} />} */}
                    </Col>

                  </div>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <InstallPWA />
    </>
  );
};

export default LoginCustomer;