import React, { useState, useEffect } from 'react'
import { Container, Button, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import "./styles.scss";
import { AVAST_STATUS_TYPE } from '../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../Utils/API';

const AvastFilterBox = (props) => {
    const [statusData, setStatusData] = useState(null);                             // data in status dropdown

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to call all dropdown values in Status Section in Filter Box
    async function GetAllStatuses() {
        await api.get(AVAST_STATUS_TYPE, config)
            .then(resp => {
                setStatusData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    };

    // Use Effect to get all the status in filter status dropdown
    useEffect(() => {
        GetAllStatuses();
    }, [])

    return (
        <div className="filter-box mt-1">
            <Container>
                <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
                    <h2>Filters</h2>
                    <XLg size={16} onClick={() => props.setShowFilterBox(false)} />
                </Col>
                <Row className="mb-3 mobile-filter-row">
                    <Col className="filter-column">
                        <h3 className="mb-3">Status</h3>
                        <div>
                            {
                                <Form.Select value={props.statusLastUsed} id='azure-status-id' aria-label="Default select example" onChange={(e) => { (e.target.value === "default") ? props.setStatus(null) : props.setStatus(e.target.value); props.setStatusLastUsed(e.target.value); }}>
                                    <option value="default">Select Status</option>
                                    {statusData &&
                                        (statusData).map((dropdownItem) => {
                                            return (
                                                <option value={dropdownItem.id}>{dropdownItem.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            }
                        </div>
                    </Col>
                </Row>
                <Row className="actions-row">
                    <Col className="d-flex justify-content-end">
                        {(!props.status) &&
                            <Button
                                className="btn btn-lg mx-3"
                                variant="light"
                                disabled
                            >
                                Clear
                            </Button>
                        }
                        {(props.status) &&
                            <Button
                                className="btn btn-lg mx-3 btn-border-light"
                                variant="light"
                                onClick={() => {
                                    props.setStatus(null);
                                    props.setStatusLastUsed("default");
                                    props.setPageNo(1);
                                    props.GetTableData(1, props.pageSize, props.searchValueTable, props.status);
                                    props.setFilterFlag(false);
                                }}
                            >
                                Clear
                            </Button>
                        }
                        {(props.status) &&
                            <Button
                                className="btn btn-lg"
                                variant="primary"
                                onClick={() => {
                                    props.setPageNo(1);
                                    props.GetTableData(1, props.pageSize, props.searchValueTable, props.status,true);
                                    props.setShowFilterBox(false);
                                    props.setFilterFlag(true);
                                    props.setFilterApplied(true);
                                }
                                }
                            >
                                Apply
                            </Button>
                        }
                        {(!props.status) &&
                            <Button
                                className="btn btn-lg"
                                variant="light"
                                disabled
                            >
                                Apply
                            </Button>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AvastFilterBox