import React, { useState, useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import './styles.scss';
import { api } from '../../Utils/API';
import { GET_TRAINING_WIDGET_DATA, GET_TRAINING_WIDGET_DATA_DOWNLOAD } from '../../Utils/GlobalConstants';
import loadingGif from "../../Assets/Images/Animations/loading-management-console.gif";
import Toast from '../Toast';

const TrainingWidget = ({modalOpen,setModalOpen,Logout, processingFlag, setProcessingFlag, processingFilesCount, setProcessingFilesCount}) => {
    const [contentDetails, setContentDetails] = useState();                             // popup for content having links and id of popup content
    const [infoLoadingPopup, setInfoLoadingPopup] = useState(false);                    // loading state while popup is not loaded

    // Use State for Toasts
    const [toastError, setToastError] = useState(false);                                // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                               // toast message usestate

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download PDF API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/pdf") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download Video using Js blobs
    const downloadVideo = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download PDF and CSV File from actions Dropdown
    async function DownloadAction(id, url, name, fileType) {
        setProcessingFilesCount((prevCount) => prevCount + 1);
        setProcessingFlag(true);
        let configuration;
        if (fileType === "pdf") {
            configuration = configBlob;
        }
        else if (fileType === "video") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(url + id, configuration)
            .then(resp => {
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    if (fileType === "video") {
                        downloadVideo({
                            data: (resp.data),
                            fileName: name,
                            fileType: `video/${fileType}`,
                        });
                    }
                    else{
                        downloadFile({
                            data: (resp.data),
                            fileName: name,
                            fileType: `application/${fileType}`,
                        });
                    }
                }
                setProcessingFilesCount((prevCount) => prevCount - 1);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                    setToastMessage("We encountered an issue while downloading files. Please try again later.");
                    setProcessingFilesCount((prevCount) => prevCount - 1);
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
            });
    };

    // function to get data of training widget
    async function GetContent() {
        setInfoLoadingPopup(true);
        await api.get(GET_TRAINING_WIDGET_DATA, config)
            .then(resp => {
                // console.log("Data : ", resp.data.content);
                setContentDetails(resp.data.content);
                setInfoLoadingPopup(false);
            })
            .catch(error => {
                // console.log(error)
                if (error?.status == 401 || error?.response.status == 401) {
                    // console.log("Error 401");
                    Logout();
                }
                else {
                    // console.log(error);
                    setInfoLoadingPopup(false);
                }

            });
    };

    useEffect(() => {
        if(modalOpen){
          GetContent();  
        }
    }, [modalOpen])

    useEffect(() => {
    // console.log("processingFilesCount",processingFilesCount)
    if(processingFilesCount<1){
        setProcessingFlag(false);
    }
        
    }, [processingFilesCount])

    return (
        <>
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
            <Popup
                open={modalOpen}
                onClose={() => { setModalOpen(false); }}
                className="custom-modal custom-modal-xl custom-modal-ticket-details"
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header">
                            <h2>Knowledge Base</h2>
                            <XLg
                                size={24}
                                className="cpointer close-icon-popup"
                                onClick={close}
                            />
                        </div>
                        <Container>
                            {
                                infoLoadingPopup &&
                                <div className={'help-table-additional-information-popup-loading'}>
                                    <img className="management-console-loading-icon" src={loadingGif} />
                                    <p className="text-medium text-strong management-console-loading-text">Loading ...</p>
                                </div>
                            }
                            {!infoLoadingPopup && contentDetails && (
                                <Row className='help-table-additional-information-popup-data'>
                                {contentDetails.map((detail) => {
                                    if (detail.contentTypeName === "Document Content") {
                                    return (
                                        <Col md={6} className="mb-2" key={detail.contentTypeName}>
                                        <h3 className="mb-3">PDF Guide</h3>
                                        {detail.contentItems.length==0 && <p className='text-muted py-2'>No PDF's Available</p>}
                                        {detail.contentItems.length>0 && detail.contentItems.map((data) => (
                                            <p className="cpointer py-2 mb-0 text-primary text-decoration-underline" key={data.id} onClick={(e) => { DownloadAction(data.id, GET_TRAINING_WIDGET_DATA_DOWNLOAD, `${data.mediaName+data.mediaExtension}`, "pdf") }}>{data.mediaName}</p>
                                        ))}
                                        </Col>
                                    );
                                    } else if (detail.contentTypeName === "Video Content") {
                                    return (
                                        <Col md={6} className="mb-2" key={detail.contentTypeName}>
                                        <h3 className="mb-3">Video Guide</h3>
                                        {detail.contentItems.length==0 && <p className='text-muted py-2'>No Video's Available</p>}
                                        <div className='training-widget-content-main-container'>
                                            {detail.contentItems.length>0 && detail.contentItems.map((data) => (
                                                // <p className="cpointer py-2 mb-0 text-primary text-decoration-underline" key={data.id} onClick={(e) => { DownloadAction(data.id, GET_TRAINING_WIDGET_DATA_DOWNLOAD, `${data.mediaName+data.mediaExtension}`, "video") }}>{data.mediaName}</p>
                                                <><a className="cpointer py-2 pb-3 mb-0 text-primary text-decoration-underline d-block" href={data.externalUrlLink} target='_blank'>{data.mediaName}</a></>
                                            ))}
                                        </div>
                                        </Col>
                                    );
                                    }
                                })}
                                </Row>
                            )}
                        </Container>

                    </div>
                )}
            </Popup>
        </>
    )
}

export default TrainingWidget
