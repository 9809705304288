import { NavDropdown, Form } from "react-bootstrap";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import './styles.scss';

const SearchDropdown = ({
    dropdownLabel,
    formGroupId,
    placeholder,
    selectOptions,
    selectDefaultValue,
    setSelectDefaultValue,
    setOptionId,
    selectOptionsFiltered,
    setSelectOptionsFiltered,
    selectSearchValue,
    setSelectSearchValue,
    updatedOptions,
    setInitialSubscriptionTableCall,
    hasStaticOptions
}) => {
    const navigate = useNavigate();       // to use navigate function
    const searchBoxValue = useRef();

    // Priority On Change Function
    const selectValueChange = (e) => {
        setSelectDefaultValue(e.target.getAttribute("value"));
        setOptionId(e.target.id);
        setInitialSubscriptionTableCall(null);
    }

    // OnBlur Event for Serch Input
    const clearSearchBox = () => {
        setSelectSearchValue('');
        setSelectOptionsFiltered(selectOptions);
    }

    const handleChange = (e) => {
        setSelectSearchValue(e.target.value);
        if (e.target.value.length > 2) {
            selectOptions.filter(option => {

                if (option.name) {
                    if ((option.name).toLowerCase().includes(e.target.value.toLowerCase())) {
                        updatedOptions.push(option);

                    } if (updatedOptions.length > 0) {
                        setSelectOptionsFiltered(updatedOptions);
                    } else {
                        setSelectOptionsFiltered([{ "name": "No data available" }]);
                    }
                }
            })
        } else {

            setSelectOptionsFiltered(selectOptions);
        }
    }

    return (
        <>
            <Form.Group
                className="form-group col-md-12 mb-0"
                controlId={formGroupId}
            >
                <Form.Label>{dropdownLabel}</Form.Label>

                <NavDropdown title={selectDefaultValue} id="cloud-service-dropdown"
                    className="servcies-dropdown" onToggle={clearSearchBox}>
                    <Form.Control
                        type="text"
                        value={selectSearchValue}
                        ref={searchBoxValue}
                        placeholder={placeholder}
                        maxlength="50"
                        onChange={handleChange}
                    />
                    <div className="dynamic-options-block">
                        {selectOptionsFiltered && selectOptionsFiltered.map((option) => {
                            return (
                                <>
                                    <NavDropdown.Item
                                        key={option.id}
                                        id={option.id}
                                        data-id={option.integrationCode}
                                        value={option.name}
                                        className={selectDefaultValue === option.name ? "selected" : ""}
                                        onClick={selectValueChange}>
                                        {option.name}
                                    </NavDropdown.Item>
                                </>
                            );
                        })}
                    </div>
                    {hasStaticOptions &&
                        <>
                            <NavDropdown.Item
                                key="createCustomer"
                                id="createCustomer"
                                data-id="createCustomer"
                                value="createCustomer"
                                className={`create-new-customer-dropdown-provision ${selectDefaultValue === "createCustomer" ? "selected" : ""}`}
                                onClick={() => navigate('/my-customers/add-customer')}>
                                Create New Customer <span className="plus-grey ml-4">&nbsp;</span>
                            </NavDropdown.Item>

                            <NavDropdown.Item
                                key="viewAll"
                                id="viewAll"
                                data-id="viewAll"
                                value="viewAll"
                                className={selectDefaultValue === "viewAll" ? "selected" : ""}
                                onClick={() => navigate('/my-customers')}>
                                View All Customers
                            </NavDropdown.Item>
                        </>
                    }
                </NavDropdown>
            </Form.Group>
        </>
    );
}

export default SearchDropdown;