import { Col, Form, NavDropdown, Row } from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import styles from './payoutstandinginvoices.module.scss'
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import { api } from "../../../../../Utils/API";
import { DASHBOARD_UNPAID_INVOICES, DASHBOARD_UNPAID_INVOICES_DOWNLOAD_REPORT } from "../../../../../Utils/GlobalConstants";
import Toast from "../../../../../GlobalComponents/Toast";
import Summary from "./LocalComponents/Summary";

const PayOutstandingInvoice = ({ SaveDashboardWidget, id, widgetPositionData, Logout }) => {
  const date = new Date();                                                   // for js date time function

  const [pieChartData, setPieChartData] = useState([null])                   // for storing piechart data from api
  const [summaryPopup, setSummaryPopup] = useState(false);                   // for opening summary popup
  const [tabName, setTabName] = useState("Summary");                         // to store tab name
  const [total, setTotal] = useState(null);                                  // total custumers to be displayed in center of piechart label
  const [invoiceDateFrom, setInvoiceDateFrom] = useState(null);                       // getTableData() invoice from date
  const [invoiceDateTo, setInvoiceDateTo] = useState(null);                           // getTableData() invoice to date

  // Use State for Toasts
  const [toastError, setToastError] = useState(false);                        // toast message displaying error message
  const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

  const [filterType, setFilterType] = useState('Last Month');                  // dropdown filter filter type

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 14,
  }));

  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();

    // Convert children to string
    const textContent = children.toString();
    const [labelWithCommas, numberWithCommas] = textContent.split(' ');

    // Remove commas from label
    const label = labelWithCommas.replace(/,/g, '');

    // Format number with commas
    const number = isNaN(parseInt(numberWithCommas.replace(/,/g, ''), 10)) ? '' : parseInt(numberWithCommas.replace(/,/g, ''), 10).toLocaleString();

    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        <tspan fill="grey" dy="-1.2em">{label}</tspan>
        <tspan className="text-strong text-large" fill="#585757" x={left + width / 2} dy="1.2em">{number}</tspan>
      </StyledText>
    );
  }

  // function for dropdown data
  const createHandleSelect = (setSelectedItem) => (eventKey) => {
    setSelectedItem(eventKey);
    if (eventKey === "This Month") {
      var endDateMonth = new Date(2008, (date.getMonth() + 1), 0);
      let fromDate = "01" + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
      let toDate = String(endDateMonth.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
      setInvoiceDateFrom(fromDate);
      setInvoiceDateTo(toDate);     // From and to Date will be same
    }
    else if (eventKey === "Last Month") {
      var today = new Date();
      var yesterday = new Date(today.setMonth(today.getMonth() - 1));
      var lastDayOfMonth = new Date(yesterday.getFullYear(), yesterday.getMonth() + 1, 0);
      let fromDate = "01" + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
      let toDate = String(lastDayOfMonth.getDate()).padStart(2, '0') + "/" + String(lastDayOfMonth.getMonth() + 1).padStart(2, "0") + "/" + lastDayOfMonth.getFullYear()
      setInvoiceDateFrom(fromDate);
      setInvoiceDateTo(toDate);     // From and to Date will be same
    }
    else {
      let lastQuarterEndDate = new Date(date.getFullYear(), date.getMonth(), 0);
      let lastQuarterStartDate = new Date(date);
      lastQuarterStartDate.setMonth(date.getMonth() - 3);
      lastQuarterStartDate.setDate(1);

      let fromDate = `${String(lastQuarterStartDate.getDate()).padStart(2, '0')}/${String(lastQuarterStartDate.getMonth() + 1).padStart(2, "0")}/${lastQuarterStartDate.getFullYear()}`;
      let toDate = `${String(lastQuarterEndDate.getDate()).padStart(2, '0')}/${String(lastQuarterEndDate.getMonth() + 1).padStart(2, "0")}/${lastQuarterEndDate.getFullYear()}`;
      setInvoiceDateFrom(fromDate);
      setInvoiceDateTo(toDate);
    }
  };

  // Header Authorization for Download CSV API having response type as blob
  const configBlob = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
    responseType: 'blob',
  };

  // Download File using Js blobs
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    if (fileType === "application/csv") {
      a.href = window.URL.createObjectURL(data)
    }
    else {
      a.href = window.URL.createObjectURL(blob)
    }
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  // Download CSV File 
  async function DownloadAction(url, name, fileType) {
    let configuration;
    if (fileType === "csv") {
      configuration = configBlob;
    }
    else {
      configuration = config;
    }
    await api.get(`${url}?duration=${filterType}`, configuration)
      .then(resp => {
        // handle success
        if (resp.status === 204) {
          setToastMessage("No Data available to Download !");
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 2000);
        }
        else {
          downloadFile({
            data: (resp.data),
            fileName: name,
            fileType: `application/${fileType}`,
          });
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

  // Function to call unpaid invoices data
  async function GetOutstandingPayInvoices() {
    await api.get(`${DASHBOARD_UNPAID_INVOICES}?duration=${filterType}`, config)
      .then(resp => {
        setPieChartData(resp.data);
        // Calculate the total sum
        let initialTotal = 0;
        resp.data.map((item) => {
          initialTotal += item.value;
        });
        setTotal(initialTotal);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

  // Function to delete widget in dashboard
  async function DeleteWidget() {
    let body = [];
    widgetPositionData.map((wd) => {
      if (wd.widgetName !== "add" && wd.id !== id) {
        body.push(wd);
      }
    })
    SaveDashboardWidget(body, true);
  };

  //   useEffect for calling get api on filter change
  useEffect(() => {
    if (filterType === "This Month") {
      var endDateMonth = new Date(2008, (date.getMonth() + 1), 0);
      let fromDate = "01" + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
      let toDate = String(endDateMonth.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
      setInvoiceDateFrom(fromDate);
      setInvoiceDateTo(toDate);     // From and to Date will be same
    }
    else if (filterType === "Last Month") {
      var today = new Date();
      var yesterday = new Date(today.setMonth(today.getMonth() - 1));
      var lastDayOfMonth = new Date(yesterday.getFullYear(), yesterday.getMonth() + 1, 0);
      let fromDate = "01" + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
      let toDate = String(lastDayOfMonth.getDate()).padStart(2, '0') + "/" + String(lastDayOfMonth.getMonth() + 1).padStart(2, "0") + "/" + lastDayOfMonth.getFullYear()
      setInvoiceDateFrom(fromDate);
      setInvoiceDateTo(toDate);     // From and to Date will be same
    }
    else {
      let lastQuarterEndDate = new Date(date.getFullYear(), date.getMonth(), 0);
      let lastQuarterStartDate = new Date(date);
      lastQuarterStartDate.setMonth(date.getMonth() - 3);
      lastQuarterStartDate.setDate(1);

      let fromDate = `${String(lastQuarterStartDate.getDate()).padStart(2, '0')}/${String(lastQuarterStartDate.getMonth() + 1).padStart(2, "0")}/${lastQuarterStartDate.getFullYear()}`;
      let toDate = `${String(lastQuarterEndDate.getDate()).padStart(2, '0')}/${String(lastQuarterEndDate.getMonth() + 1).padStart(2, "0")}/${lastQuarterEndDate.getFullYear()}`;
      setInvoiceDateFrom(fromDate);
      setInvoiceDateTo(toDate);
    }
    GetOutstandingPayInvoices();
  }, [filterType])

  return (
    <>
      <Row className={`${styles["widget-header"]} align-items-center pt-0`}>
        <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

        <Col md={6} xs={8}>
          <h3 className="mb-0">Unpaid Invoices Summary</h3>
        </Col>
        <Col md={6} xs={4} className="text-right">
          <span className="dashboard-arrow mx-1 cpointer d-none-mobile" onClick={() => { setSummaryPopup(true); setTabName("Summary"); }}></span>
          <span className="dashboard-circle-dot mx-1 cpointer d-none-mobile" onClick={() => { setSummaryPopup(true); setTabName("Details") }}></span>
          <span className="dashboard-download mx-1 cpointer d-none-mobile" onClick={() => { DownloadAction(DASHBOARD_UNPAID_INVOICES_DOWNLOAD_REPORT, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_unpaid-invoices-summary.csv`, "csv") }}
          ></span>
          <span className="dashboard-cross mx-1 cpointer" onClick={() => DeleteWidget()}></span>
        </Col>
        {
          summaryPopup &&
          <Summary setSummaryPopup={setSummaryPopup} summaryPopup={summaryPopup} tabName={tabName} setTabName={setTabName} filterType={filterType} setFilterType={setFilterType} invoiceDateFrom={invoiceDateFrom} setInvoiceDateFrom={setInvoiceDateFrom} invoiceDateTo={invoiceDateTo} setInvoiceDateTo={setInvoiceDateTo} Logout={Logout} />
        }
      </Row>
      <Row className={`pt-2 ${styles["widget-header"]} `}>
        <Col lg={8} md={8} xs={7}></Col>
        <Col lg={4} md={4} xs={5}>
          <Form.Group >
            <NavDropdown title={filterType} id="cloud-service-dropdown" className={`servcies-dropdown ${styles['dropdown-disabled']}`} onSelect={createHandleSelect(setFilterType)}>
              <NavDropdown.Item eventKey="This Month"
                className={filterType === "This Month" ? "selected" : ""}
              >This Month</NavDropdown.Item>
              <NavDropdown.Item eventKey="Last Month"
                className={filterType === "Last Month" ? "selected" : ""}
              >Last Month</NavDropdown.Item>
              <NavDropdown.Item eventKey="Last Quarter"
                className={filterType === "Last Quarter" ? "selected" : ""}
              >Last Quarter</NavDropdown.Item>
            </NavDropdown>
          </Form.Group>
        </Col>
      </Row>
      <PieChart
        series={[{ data: pieChartData, innerRadius: 70, outerRadius: 84, }]}
        colors={['#3599DA', '#F9A932']}
        slotProps={{
          legend: {
            direction: 'row',
            position: { vertical: 'bottom', horizontal: 'middle' },
            padding: 0,
            labelStyle: {
              fill: 'grey',
            },
          },
        }}
        height={210}
        width={400}
        margin={{ top: 0, bottom: 40, left: 80 }}
      >
        {pieChartData.length > 0 && <PieCenterLabel>Unpaid&nbsp;Invoices {pieChartData && total}</PieCenterLabel>}
      </PieChart>
    </>
  )
}

export default PayOutstandingInvoice