import React, { useEffect, useState } from 'react'
import styles from './subscription.module.scss'
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { XLg } from 'react-bootstrap-icons';
import { emailRegEx } from '../../../GlobalComponents/RegExPatterns';
import { SUBSCRIPTIONS, SUBSCRIPTIONS_TYPES } from '../../../Utils/GlobalConstants';
import { api } from '../../../Utils/API';
import Toast from '../../../GlobalComponents/Toast';
import loadingGif from "../../../Assets/Images/Animations/loading-management-console.gif"

const Subscriptions = (props) => {

  const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);                              // loading state for processing button
  const [subscriptionsTypes, setSubscriptionsTypes] = useState(null);                               // storing subscriptionTypes from get api

  const [errorStateMessage, setErrorStateMessage] = useState("");       // error state message                           

  //InvoiceNotificationEmail
  const [invoiceNotificationEmail, setInvoiceNotificationEmail] = useState([]);                    // storing email while user is adding emails  
  const [invoiceNotificationEmailList, setInvoiceNotificationEmailList] = useState([]);            // updating email list 
  const [invoiceNotificationEmailListDefault, setInvoiceNotificationEmailListDefault] = useState([]);// storing default email list from get api
  const [errorStateInvoiceNotification, setErrorStateInvoiceNotification] = useState(false);       // error state when email format is invalid                           

  //OrderConfirmationEmail
  const [orderConfirmationEmail, setOrderConfirmationEmail] = useState([]);                         // storing email while user is adding emails   
  const [orderConfirmationEmailList, setOrderConfirmationEmailList] = useState([]);                 // updating email list 
  const [orderConfirmationEmailListDefault, setOrderConfirmationEmailListDefault] = useState([]);   // storing default email list from get api
  const [errorStateOrderConfirmation, setErrorStateOrderConfirmation] = useState(false);            // error state when email format is invalid           

  //ItAdminEmail
  const [itAdminEmail, setItAdminEmail] = useState([]);                                             // storing email while user is adding emails 
  const [itAdminEmailList, setItAdminEmailList] = useState([]);                                     // updating email list 
  const [itAdminEmailListDefault, setItAdminEmailListDefault] = useState([]);                       // storing default email list from get api 
  const [errorStateItAdmin, setErrorStateItAdmin] = useState(false);                                // error state when email format is invalid

  //CloudNewsletterEmail
  const [cloudNewsletterEmail, setCloudNewsletterEmail] = useState([]);                             // storing email while user is adding emails 
  const [cloudNewsletterEmailList, setCloudNewsletterEmailList] = useState([]);                     // updating email list 
  const [cloudNewsletterEmailListDefault, setCloudNewsletterEmailListDefault] = useState([]);       // storing default email list from get api
  const [errorStateCloudNewsletter, setErrorStateCloudNewsletter] = useState(false);                // error state when email format is invalid

  // Use State for Toasts
  const [toastSuccess, setToastSuccess] = useState(false);                                         // toast message displaying success message
  const [toastError, setToastError] = useState(false);                                             // toast message displaying error message
  const [toastMessage, setToastMessage] = useState("");                                            // toast message  

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Function to call email account details 
  async function GetSubaccountDetails() {
    await api.get(`${SUBSCRIPTIONS}`, config)
      .then(resp => {
        // console.log("Array Data Vidhi:", resp.data);
        resp.data.map((item) => {
          let EmailArray = item.emails ? item.emails.split(';') : [];
          // console.log("split::", EmailArray);
          if (item.subscriptionTypeId === 1) {
            setInvoiceNotificationEmailList(EmailArray);
            setInvoiceNotificationEmailListDefault(EmailArray);
          }
          if (item.subscriptionTypeId === 2) {
            setOrderConfirmationEmailList(EmailArray);
            setOrderConfirmationEmailListDefault(EmailArray);
          }
          if (item.subscriptionTypeId === 3) {
            setItAdminEmailList(EmailArray);
            setItAdminEmailListDefault(EmailArray);
          }
          if (item.subscriptionTypeId === 4) {
            setCloudNewsletterEmailList(EmailArray);
            setCloudNewsletterEmailListDefault(EmailArray);
          }
        })
      })
      .catch(error => {
        if (error?.status == 401 || error?.response.status == 401) {
          // console.log("Error 401");
          props.Logout();
      }
        else {
        }

      });
  };

  // Function to call subscriptions types  
  async function GetSubaccountDetailsTypes() {
    await api.get(`${SUBSCRIPTIONS_TYPES}`, config)
      .then(resp => {
        // console.log(resp.data)
        setSubscriptionsTypes(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response.status == 401) {
          // console.log("Error 401");
          props.Logout();
      }
        else {
        }

      });
  };

  //post api for saving emails api
  async function SaveSubscriptionsEmail(changedEmailsData) {
    setConfirmLoadingFlag(true);
    const requestBody = changedEmailsData;

    await api.post(SUBSCRIPTIONS, requestBody, config)
      .then(resp => {
        setConfirmLoadingFlag(false);
        if (resp.status == 200) {
          setToastMessage("Changes successfully updated.");
          setSubscriptionsTypes(null);
          setToastSuccess(true);
          GetSubaccountDetailsTypes();
          GetSubaccountDetails();
          setTimeout(() => {
            setToastSuccess(false);
          }, 5000);
        }
      })
      .catch(error => {
        // console.log(error)
        setConfirmLoadingFlag(false);
        if (error?.status == 401 || error?.response.status == 401) {
          // console.log("Error 401");
          props.Logout();
      }
        else {
          setToastMessage("There seems an error while saving. Please try again later.");
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 5000);
        }

      });
  }

  // updating email for InvoiceNotification
  const EmailSubmitInvoiceNotification = (e) => {
    const emailList = invoiceNotificationEmail.split(';').filter(email => email.trim() !== '');
    e.preventDefault();

    let isValid = true;

    emailList.map(email => {
      if (!emailRegEx.test(email.trim())) {
        isValid = false;
        setErrorStateInvoiceNotification(true);
      }
    });

    if (isValid && invoiceNotificationEmailList.length < 5) {
      // if (invoiceNotificationEmailList.includes(invoiceNotificationEmail)) {
      if (invoiceNotificationEmailList.some(email => emailList.includes(email))) {
        // Display error for duplicate email
        setErrorStateInvoiceNotification(true);
        setErrorStateMessage("Duplicate email is not allowed");
      } else {
        // Add email to the list
        setInvoiceNotificationEmailList([...invoiceNotificationEmailList, ...invoiceNotificationEmail.split(';').filter(email => email.trim() !== '')]);
        setInvoiceNotificationEmail('');
        setErrorStateInvoiceNotification(false);
      }
    } else {
      setErrorStateInvoiceNotification(true);
      setErrorStateMessage("Please enter a valid email");
    }
  };

  // updating email for OrderConfirmation
  const EmailSubmitOrderConfirmation = (e) => {
    const emailList = orderConfirmationEmail.split(';').filter(email => email.trim() !== '');
    e.preventDefault();

    let isValid = true;

    emailList.map(email => {
      if (!emailRegEx.test(email.trim())) {
        isValid = false;
        setErrorStateOrderConfirmation(true);
      }
    });

    if (isValid && orderConfirmationEmailList.length < 5) {
      // if (invoiceNotificationEmailList.includes(invoiceNotificationEmail)) {
      if (orderConfirmationEmailList.some(email => emailList.includes(email))) {
        // Display error for duplicate email
        setErrorStateOrderConfirmation(true);
        setErrorStateMessage("Duplicate email is not allowed");
      } else {
        // Add email to the list
        setOrderConfirmationEmailList([...orderConfirmationEmailList, ...orderConfirmationEmail.split(';').filter(email => email.trim() !== '')]);
        setOrderConfirmationEmail('');
        setErrorStateOrderConfirmation(false);
      }
    } else {
      setErrorStateOrderConfirmation(true);
      setErrorStateMessage("Please enter a valid email");
    }
  };


  // updating email for ItAdmin
  const EmailSubmitItAdmin = (e) => {
    const emailList = itAdminEmail.split(';').filter(email => email.trim() !== '');
    e.preventDefault();

    let isValid = true;

    emailList.map(email => {
      if (!emailRegEx.test(email.trim())) {
        isValid = false;
        setErrorStateItAdmin(true);
      }
    });

    if (isValid && itAdminEmailList.length < 5) {
      // if (invoiceNotificationEmailList.includes(invoiceNotificationEmail)) {
      if (itAdminEmailList.some(email => emailList.includes(email))) {
        // Display error for duplicate email
        setErrorStateItAdmin(true);
        setErrorStateMessage("Duplicate email is not allowed");
      } else {
        // Add email to the list
        setItAdminEmailList([...itAdminEmailList, ...itAdminEmail.split(';').filter(email => email.trim() !== '')]);
        setItAdminEmail('');
        setErrorStateItAdmin(false);
      }
    } else {
      setErrorStateItAdmin(true);
      setErrorStateMessage("Please enter a valid email");
    }
  };

  // updating email for ItAdmin
  const EmailSubmitCloudNewsletter = (e) => {
    const emailList = cloudNewsletterEmail.split(';').filter(email => email.trim() !== '');
    e.preventDefault();

    let isValid = true;

    emailList.map(email => {
      if (!emailRegEx.test(email.trim())) {
        isValid = false;
        setErrorStateCloudNewsletter(true);
      }
    });

    if (isValid && cloudNewsletterEmailList.length < 5) {
      // if (invoiceNotificationEmailList.includes(invoiceNotificationEmail)) {
      if (cloudNewsletterEmailList.some(email => emailList.includes(email))) {
        // Display error for duplicate email
        setErrorStateCloudNewsletter(true);
        setErrorStateMessage("Duplicate email is not allowed");
      } else {
        // Add email to the list
        setCloudNewsletterEmailList([...cloudNewsletterEmailList, ...cloudNewsletterEmail.split(';').filter(email => email.trim() !== '')]);
        setCloudNewsletterEmail('');
        setErrorStateCloudNewsletter(false);
      }
    } else {
      setErrorStateCloudNewsletter(true);
      setErrorStateMessage("Please enter a valid email");
    }
  };


  // function for adding email list data into post api
  const CheckAndSaveEmail = () => {
    let updatedChangedEmailsDataArray = [];

    if (JSON.stringify(invoiceNotificationEmailList) !== JSON.stringify(invoiceNotificationEmailListDefault)) {
      let formatted_emails = invoiceNotificationEmailList.join(";");
      updatedChangedEmailsDataArray.push({
        subscriptionTypeId: 1,
        emails: formatted_emails
      });
    }

    if (JSON.stringify(orderConfirmationEmailList) !== JSON.stringify(orderConfirmationEmailListDefault)) {
      let formatted_emails = orderConfirmationEmailList.join(";");
      updatedChangedEmailsDataArray.push({
        subscriptionTypeId: 2,
        emails: formatted_emails
      });
    }

    if (JSON.stringify(itAdminEmailList) !== JSON.stringify(itAdminEmailListDefault)) {
      let formatted_emails = itAdminEmailList.join(";");
      updatedChangedEmailsDataArray.push({
        subscriptionTypeId: 3,
        emails: formatted_emails
      });
    }

    if (JSON.stringify(cloudNewsletterEmailList) !== JSON.stringify(cloudNewsletterEmailListDefault)) {
      let formatted_emails = cloudNewsletterEmailList.join(";");
      updatedChangedEmailsDataArray.push({
        subscriptionTypeId: 4,
        emails: formatted_emails
      });
    }

    if (updatedChangedEmailsDataArray.length > 0) {
      // Calling post api here
      SaveSubscriptionsEmail(updatedChangedEmailsDataArray);
    }
    // console.log(updatedChangedEmailsDataArray);
  };

  useEffect(() => {
    localStorage.getItem("navigation_link") ?  localStorage.removeItem("navigation_link") : localStorage.setItem("navigation_link","/subscriptions");
    GetSubaccountDetailsTypes();
    GetSubaccountDetails();
    // Scroll to Top on Initial loading
    window.scrollTo(0, 0);
  }, []);

  
  useEffect(() => {
    // console.log("orderConfirmationEmailList",orderConfirmationEmailList)
  }, [orderConfirmationEmailList])

  return (
    <div className="main-content">
      <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
      <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

      <div className={`${styles['profile-table']}`}>
        <h1>Subscriptions
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Manage your synnex cloud marketplace subscriptions.</Tooltip>}

          >
            <span className="infoIcon-support-table infoIcon-support-table-big ml-1"></span>
          </OverlayTrigger>
        </h1>
        <div className={`my-2 ${styles['my-profile-last-section']}`}>
          <div className='mt-3 py-4 details-box'>

            {!subscriptionsTypes &&
              <div className={styles["no-data-cloud-invoice"]}>
                <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading ...</p>
              </div>
            }

            {subscriptionsTypes &&
              <div className='px-2'>
                <Row className='px-3 align-items-center'>
                  <h3>Invoice Notification</h3>
                  <Col md={4}>
                    <Form.Group controlId="invoiceNotificationEmail">
                      <Form.Label className='justify-content-between d-flex col-12'>
                        <span>Add Email</span>
                        <span className={`float-right ${styles['maximum-add']} ${invoiceNotificationEmailList.length >= 5 ? "text-danger" : ""}`}>Max 5</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={invoiceNotificationEmail}
                        maxLength="250"
                        className={errorStateInvoiceNotification ? "border-danger" : ""}
                        name="invoiceNotificationEmail"
                        onChange={(e) => {
                          setErrorStateInvoiceNotification(false);
                          setErrorStateMessage("");
                          let count = e.target.value.split(';').length;
                          // console.log("Total :",count,invoiceNotificationEmail.length+count<=5);
                          if(invoiceNotificationEmailList.length+count<=5){
                          setInvoiceNotificationEmail(e.target.value);
                          }
                        }}
                        onKeyDown={(e) => {
                          let count = e.target.value.split(';').length;
                          if (e.key === 'Enter' && invoiceNotificationEmailList.length < 5 && invoiceNotificationEmailList.length+count<=5) {
                            EmailSubmitInvoiceNotification(e);
                          }
                        }}
                      />
                    </Form.Group>
                    {!errorStateInvoiceNotification && invoiceNotificationEmail.length>0 && <span className='text-medium text-muted mb-2'>Use Semicolon to add multiple mail ids. Hit Enter to add your email id(s).</span>}
                    {errorStateInvoiceNotification ? <span className='text-medium text-danger mb-2'>{errorStateMessage}</span> :
                      <p className='empty-paragragh mb-6'></p>}
                  </Col>
                  <Col md={8}>
                    <div className='mt-1 mb-0 d-flex flex-wrap'>
                      {invoiceNotificationEmailList.map((email, index) => (
                        <div key={index} className={`mr-2 mb-0 px-1 col-lg-3 col-md-4 col-xs-5 ${styles['member-options']}`}>
                          <label className='ml-0'>
                            <span>{email}</span>
                            <XLg
                              size={16}
                              className="cpointer text-strong close-icon-popup-sm"
                              onClick={() => {
                                const updatedEmailList = [...invoiceNotificationEmailList];
                                updatedEmailList.splice(index, 1);
                                setInvoiceNotificationEmailList(updatedEmailList);
                              }}
                              color="#6A6A6A"
                            />
                          </label>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
                <div className={`my-2 mx-3 ${styles['my-profile-last-section']}`}></div>
                <Row className="px-3 py-3 align-items-center">
                  <h3>Order Confirmation</h3>
                  <Col md={4}>
                    <Form.Group controlId="orderConfirmationEmail">
                      <Form.Label className='justify-content-between d-flex col-12'>
                        <span>Add Email</span>
                        <span className={`float-right ${styles['maximum-add']} ${orderConfirmationEmailList.length >= 5 ? "text-danger" : ""}`}>Max 5</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={orderConfirmationEmail}
                        className={errorStateOrderConfirmation ? "border-danger" : ""}
                        maxLength="250"
                        name="orderConfirmationEmail"
                        onChange={(e) => {
                          setErrorStateOrderConfirmation(false);
                          let count = e.target.value.split(';').length;
                          // console.log("Total :",count,orderConfirmationEmailList.length+count<=5);
                          if(orderConfirmationEmailList.length+count<=5){
                            setOrderConfirmationEmail(e.target.value);
                          }
                        }}
                        onKeyDown={(e) => {
                          let count = e.target.value.split(';').length;
                            if (e.key === 'Enter' && orderConfirmationEmailList.length < 5 && orderConfirmationEmailList.length+count<=5) {
                              EmailSubmitOrderConfirmation(e);
                            }
                        }}
                      />
                    </Form.Group>
                    {!errorStateOrderConfirmation && orderConfirmationEmail.length>0 && <span className='text-medium text-muted mb-2'>Use Semicolon to add multiple mail ids. Hit Enter to add your email id(s).</span>}
                    {errorStateOrderConfirmation ? <span className='text-medium text-danger mb-2'>{errorStateMessage}</span> :
                      <p className='empty-paragragh mb-6'></p>}
                  </Col>
                  <Col md={8}>
                    <div className='mt-1 mb-0 d-flex flex-wrap'>
                      {orderConfirmationEmailList.map((email, index) => (
                        <div key={index} className={`mr-2 mb-0 px-1 col-3 ${styles['member-options']}`}>
                          <label className='ml-0'>
                            <span>{email}</span>
                            <XLg
                              size={16}
                              className="cpointer text-strong close-icon-popup-sm"
                              onClick={() => {
                                const updatedEmailList = [...orderConfirmationEmailList];
                                updatedEmailList.splice(index, 1);
                                setOrderConfirmationEmailList(updatedEmailList);
                              }}
                              color="#6A6A6A"
                            />
                          </label>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
                <div className={`my-2 mx-3 ${styles['my-profile-last-section']}`}></div>
                <Row className='px-3 py-3 align-items-center'>
                  <h3>IT Admin Credentials</h3>
                  <Col md={4}>
                    <Form.Group controlId="itAdminEmail">
                      <Form.Label className='justify-content-between d-flex col-12'>
                        <span>Add Email</span>
                        <span className={`float-right ${styles['maximum-add']} ${itAdminEmailList.length >= 5 ? "text-danger" : ""}`}>Max 5</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={itAdminEmail}
                        className={errorStateItAdmin ? "border-danger" : ""}
                        maxLength="250"
                        name="itAdminEmail"
                        onChange={(e) => {
                          setErrorStateItAdmin(false);
                          let count = e.target.value.split(';').length;
                          // console.log("Total :",count,itAdminEmail.length+count<=5);
                          if(itAdminEmailList.length+count<=5){
                          setItAdminEmail(e.target.value);
                          }
                        }}
                        onKeyDown={(e) => {
                          let count = e.target.value.split(';').length;
                          if (e.key === 'Enter' && itAdminEmailList.length < 5 && itAdminEmailList.length+count<=5) {
                            EmailSubmitItAdmin(e);
                          }
                        }}
                      />
                    </Form.Group>
                    {!errorStateItAdmin && itAdminEmail.length>0 && <span className='text-medium text-muted mb-2'>Use Semicolon to add multiple mail ids. Hit Enter to add your email id(s).</span>}
                    {errorStateItAdmin ? <span className='text-medium text-danger mb-2'>{errorStateMessage}</span> :
                      <p className='empty-paragragh mb-6'></p>}
                  </Col>
                  <Col md={8}>
                    <div className='mt-1 mb-0 d-flex flex-wrap'>
                      {itAdminEmailList.map((email, index) => (
                        <div key={index} className={`mr-2 mb-0 px-1 col-3 ${styles['member-options']}`}>
                          <label className='ml-0'>
                            <span>{email}</span>
                            <XLg
                              size={16}
                              className="cpointer text-strong close-icon-popup-sm"
                              onClick={() => {
                                const updatedEmailList = [...itAdminEmailList];
                                updatedEmailList.splice(index, 1);
                                setItAdminEmailList(updatedEmailList);
                              }}
                              color="#6A6A6A"
                            />
                          </label>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
                <div className={`my-2 mx-3 ${styles['my-profile-last-section']}`}></div>
                <Row className='px-3 py-3 align-items-center'>
                  <h3>Cloud Newsletter</h3>
                  <Col md={4}>
                    <Form.Group controlId="cloudNewsletterEmail">
                      <Form.Label className='justify-content-between d-flex col-12'>
                        <span>Add Email</span>
                        <span className={`float-right ${styles['maximum-add']} ${cloudNewsletterEmailList.length >= 5 ? "text-danger" : ""}`}>Max 5</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={cloudNewsletterEmail}
                        className={errorStateCloudNewsletter ? "border-danger" : ""}
                        maxLength="250"
                        name="cloudNewsletterEmail"
                        onChange={(e) => {
                          setErrorStateCloudNewsletter(false);
                          let count = e.target.value.split(';').length;
                          // console.log("Total :",count,cloudNewsletterEmail.length+count<=5);
                          if(cloudNewsletterEmailList.length+count<=5){
                          setCloudNewsletterEmail(e.target.value);
                          }
                        }}
                        onKeyDown={(e) => {
                          let count = e.target.value.split(';').length;
                          if (e.key === 'Enter' && cloudNewsletterEmailList.length < 5 && cloudNewsletterEmailList.length+count<=5) {
                            EmailSubmitCloudNewsletter(e);
                          }
                        }}
                      />
                    </Form.Group>
                    {!errorStateCloudNewsletter && cloudNewsletterEmail.length>0 && <span className='text-medium text-muted mb-2'>Use Semicolon to add multiple mail ids. Hit Enter to add your email id(s).</span>}
                    {errorStateCloudNewsletter ? <span className='text-medium text-danger mb-2'>{errorStateMessage}</span> :
                      <p className='empty-paragragh mb-6'></p>}
                  </Col>
                  <Col md={8}>
                    <div className='mt-1 mb-0 d-flex flex-wrap'>
                      {cloudNewsletterEmailList.map((email, index) => (
                        <div key={index} className={`mr-2 mb-0 px-1 col-3 ${styles['member-options']}`}>
                          <label className='ml-0'>
                            <span>{email}</span>
                            <XLg
                              size={16}
                              className="cpointer text-strong close-icon-popup-sm"
                              onClick={() => {
                                const updatedEmailList = [...cloudNewsletterEmailList];
                                updatedEmailList.splice(index, 1);
                                setCloudNewsletterEmailList(updatedEmailList);
                              }}
                              color="#6A6A6A"
                            />
                          </label>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              </div>}
            {subscriptionsTypes && <div className="btn-wrapper text-right mx-4">
              {!confirmLoadingFlag && <>
                {
                  (
                    JSON.stringify(invoiceNotificationEmailList) === JSON.stringify(invoiceNotificationEmailListDefault) &&
                    JSON.stringify(orderConfirmationEmailList) === JSON.stringify(orderConfirmationEmailListDefault) &&
                    JSON.stringify(itAdminEmailList) === JSON.stringify(itAdminEmailListDefault) &&
                    JSON.stringify(cloudNewsletterEmailList) === JSON.stringify(cloudNewsletterEmailListDefault)) ?
                    <Button
                      className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                      variant="light"
                      disabled  >Clear</Button> :
                    <Button
                      className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                      variant="light"
                      onClick={() => {
                        setInvoiceNotificationEmailList(invoiceNotificationEmailListDefault);
                        setOrderConfirmationEmailList(orderConfirmationEmailListDefault);
                        setItAdminEmailList(itAdminEmailListDefault);
                        setCloudNewsletterEmailList(cloudNewsletterEmailListDefault);
                        setCloudNewsletterEmail('');
                        setItAdminEmail('');
                        setOrderConfirmationEmail('');
                        setInvoiceNotificationEmail('');
                      }}
                    >
                      Clear
                    </Button>
                }
                {(
                  JSON.stringify(invoiceNotificationEmailList) === JSON.stringify(invoiceNotificationEmailListDefault) &&
                  JSON.stringify(orderConfirmationEmailList) === JSON.stringify(orderConfirmationEmailListDefault) &&
                  JSON.stringify(itAdminEmailList) === JSON.stringify(itAdminEmailListDefault) &&
                  JSON.stringify(cloudNewsletterEmailList) === JSON.stringify(cloudNewsletterEmailListDefault)) ?
                  <Button
                    className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                    variant="light"
                    disabled  >Save</Button> :
                  <Button
                    className="px-4 mx-2"
                    onClick={() => {
                      CheckAndSaveEmail();
                    }}
                  >Save</Button>
                }
              </>}
              {
                confirmLoadingFlag &&
                <>
                  <Button
                    variant="light"
                    disabled
                    className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                  >Clear
                  </Button>
                  <Button
                    disabled
                    className="ml-3 mx-2 btn-border-light w-md-auto col-xs-12"
                    variant="light"
                  >
                    Processing . . .
                  </Button>
                </>
              }
            </div>}
          </div>

        </div>
      </div>
    </div>
  )
}

export default Subscriptions
