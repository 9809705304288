import React, { useState } from 'react'
import { Container, Button, Row, Col } from "react-bootstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import styles from "./SummaryFilterBox.module.scss";

const SummaryFilterBox = (props) => {
    const [dateCustomCheck, setDateCustomCheck] = useState(false);      // check state for custom chosen in summary date
    const date = new Date();                                                          // for js date time function

    // Function to Convert Date Selection Options to from and to dates in DD/MM/YYYYY format
    function DatePicker(e, from, to, dateCheck) {
        let todayDate = `${String(date.getDate()).padStart(2, '0')}`
        let todayMonth = `${String(date.getMonth() + 1).padStart(2, "0")}`
        let todayYear = `${date.getFullYear()}`
        dateCheck(false);
        if (e.target.value === "yesterday") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 1));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            from(fromDate);
            to(fromDate);     // From and to Date will be same
        }
        else if (e.target.value === "today") {
            let fromDate = String(todayDate) + "/" + String(todayMonth) + "/" + String(todayYear);
            from(fromDate);
            to(fromDate);     // From and to Date will be same
        }
        else if (e.target.value === "last7") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 7));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(date.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "last30") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 30));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(date.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "thismonth") {
            var endDateMonth = new Date(2008, (date.getMonth() + 1), 0);
            let fromDate = "01" + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            let toDate = String(endDateMonth.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "lastmonth") {
            var today = new Date();
            var yesterday = new Date(today.setMonth(today.getMonth() - 1));
            var lastDayOfMonth = new Date(yesterday.getFullYear(), yesterday.getMonth() + 1, 0);
            let fromDate = "01" + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(lastDayOfMonth.getDate()).padStart(2, '0') + "/" + String(lastDayOfMonth.getMonth() + 1).padStart(2, "0") + "/" + lastDayOfMonth.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "custom") {
            dateCheck(true);
        }
        else {
            from(null);
            to(null);
        }
    }

    const [searchServiceValue, setSearchServiceValue] = useState(""); // Searched Value from Service
    const clearFilterSearchService = () => { // Clear Filter Box Search Input
        setSearchServiceValue("");
    }

    const [searchPlanValue, setSearchPlanValue] = useState(""); // Searched Value from plan
    const clearFilterSearchPlan = () => { // Clear Filter Box Search Input
        setSearchPlanValue("");
    }

    // Date Array
    const dateArray = [
        {
            id: "0",
            value: "custom",
            name: "Custom Date"
        }
    ];

    const clearFilterBox = () => {
        if (props.planName && props.planName !== null) {
            const element = document.getElementById(props.planName);
            if (element) {
                element.checked = false;
            }
        };

        if (props.autoRenew && props.autoRenew !== null) {
            const element = document.getElementById(props.autoRenew);
            if (element) {
                element.checked = false;
            }
        };
        if (props.dateLastUsedDate !== "default") {
            props.setDateLastUsedDate("default");
            document.getElementById(props.dateLastUsedDate).checked = false;
        }
        // Calculate tomorrow's date
        const today = new Date();
        today.setDate(today.getDate() + 1);
        const tomorrow = today.toISOString().slice(0, 10);

        props.setAutoRenew(null);
        props.setScrollToDivTop(true);
        props.setTableFilterApplied(false);
        props.setPlanName(null);
        props.setFromDate(null);
        props.setToDate(null);
        props.setDateCustomFromLast(tomorrow);
        props.setDateCustomToLast(tomorrow);
        setDateCustomCheck(false);
        props.setPageNo(1);
        props.GetTableData(1, props.pageSize, props.searchValueTable, null, null, null, null, null, props.sortCol, props.sortDir, props.serviceId, props.customerId, props.customerCode);
        props.setFilterFlag(false);
    }

    // For custom filters in Date
    function getCurrentDateCustomDate() {
        const today = new Date();
        today.setDate(today.getDate() + 1); // Add one day to get tomorrow's date
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function getMaxDateCustomDate() {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1); // Get tomorrow's date
        tomorrow.setMonth(tomorrow.getMonth() + 3); // Add three months
        const year = tomorrow.getFullYear();
        const month = String(tomorrow.getMonth() + 1).padStart(2, '0');
        const day = String(tomorrow.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    return (
        <div className="filter-box filter-box-md dashboard-filters">
            <Container>
                <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
                    <h2>Filters</h2>
                    <XLg size={16} onClick={() => props.setShowFilterBox(false)} />
                </Col>
                <Row className={`mb-3 mobile-filter-row ${styles['ipad-pro-filterbox']}`}>

                    <Col className={`filter-column ${styles['filter-column-height']}`}>
                        <h3 className="mb-3">Plan</h3>

                        <div>
                            <InputGroup className="search-input-group mb-3">
                                <Form.Control
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="search"
                                    value={searchPlanValue}
                                    onChange={(e) => setSearchPlanValue(e.target.value)}
                                />
                                <InputGroup.Text class="clear-search" onClick={clearFilterSearchPlan}><span>&nbsp;</span></InputGroup.Text>
                            </InputGroup>
                            <div className="filter-options">

                                {
                                    <>
                                        {
                                            props.planData &&
                                            props.planData.filter(option => (option.name).toLowerCase().includes(searchPlanValue.toLowerCase())).map((filteredOption) => {
                                                {
                                                    return (
                                                        <>
                                                            <Form.Check // prettier-ignore
                                                                key={filteredOption.id}
                                                                type={'radio'}
                                                                id={filteredOption.id}
                                                                label={filteredOption.name}
                                                                name="planGroup"
                                                                value={filteredOption.id}
                                                                aria-label={filteredOption.name}
                                                                defaultChecked={filteredOption.id == props.planName ? true : false}
                                                                onChange={(e) => {
                                                                    (e.target.value === "default") ? props.setPlanName(null) : props.setPlanName(e.target.id);
                                                                }
                                                                }
                                                            />
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                    </>
                                }

                                {
                                    !props.planData && <p>Loading...</p>
                                }
                            </div>
                        </div>
                    </Col>

                    <Col className="filter-column" md={2}>
                        <h3 className="mb-3">Auto Renew</h3>
                        <div>
                            <div className="filter-options">
                                {
                                    <>
                                        {
                                            props.autoRenewData &&
                                            props.autoRenewData.filter(option => (option.name).toLowerCase().includes(searchServiceValue.toLowerCase())).map((filteredOption) => {
                                                {
                                                    return (
                                                        <>
                                                            <Form.Check // prettier-ignore
                                                                key={filteredOption.id}
                                                                type={'radio'}
                                                                id={filteredOption.id}
                                                                label={filteredOption.name}
                                                                name="autorenewGroup"
                                                                value={filteredOption.id}
                                                                aria-label={filteredOption.name}
                                                                defaultChecked={filteredOption.id == props.autoRenew ? true : false}
                                                                onChange={(e) => {
                                                                    (e.target.value === "default") ? props.setAutoRenew(null) : props.setAutoRenew(e.target.id);
                                                                }
                                                                }
                                                            />
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                    </>
                                }

                                {
                                    !props.autoRenewData && <p>Loading...</p>
                                }
                            </div>
                        </div>

                    </Col>
                    <Col className="filter-column" md={3}>
                        <h3 className="mb-3">Date</h3>
                        <div>
                            <Form>
                                <div className="filter-options date-filter-options">
                                    {
                                        dateArray.map(customerDate => {
                                            return (
                                                <>
                                                    < Form.Check // prettier-ignore
                                                        key={customerDate.value}
                                                        type={'radio'}
                                                        id={customerDate.value}
                                                        label={customerDate.name}
                                                        name="invoiceDateGroup"
                                                        value={customerDate.value}
                                                        aria-label={customerDate.name}
                                                        defaultChecked={customerDate.value == props.dateLastUsedDate ? true : false}
                                                        onChange={(e) => {
                                                            if (e.target.value === "custom") {
                                                                props.setFromDate(String(`${String(date.getDate()).padStart(2, '0')}`) + "/" + String(`${String(date.getMonth() + 1).padStart(2, "0")}`) + "/" + String(date.getFullYear()));
                                                                props.setToDate(String(`${String(date.getDate()).padStart(2, '0')}`) + "/" + String(`${String(date.getMonth() + 1).padStart(2, "0")}`) + "/" + String(date.getFullYear()))
                                                            }
                                                            DatePicker(e, props.setFromDate, props.setToDate, setDateCustomCheck); props.setDateLastUsedDate(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )
                                        })
                                    }
                                    {
                                        (dateCustomCheck || props.dateLastUsedDate === "custom") &&
                                        <>
                                            <p className='mt-2'>From :</p>
                                            <Form.Control value={props.dateCustomFromLast}
                                                min={getCurrentDateCustomDate()} // Set min date to current date
                                                max={getMaxDateCustomDate()} // Set max date to three months from current date
                                                type="date"
                                                onChange={(e) => {
                                                    const enteredDate = new Date(e.target.value);

                                                    // Get the current date and max date using the custom functions
                                                    const currentDateCustom = getCurrentDateCustomDate();
                                                    const maxDateCustom = getMaxDateCustomDate();

                                                    const currentDate = new Date(currentDateCustom);
                                                    const maxDate = new Date(maxDateCustom);

                                                    // Check if the entered date is within the allowed range
                                                    if (enteredDate < currentDate || enteredDate > maxDate) {
                                                        // Clear the input if the entered date is not within the allowed range
                                                        e.target.value = '';
                                                        props.setDateCustomFromLast(null);
                                                        props.setFromDate(null);
                                                    } else {
                                                        // Update state if the entered date is valid
                                                        props.setDateCustomFromLast(e.target.value);
                                                        let date = e.target.value.split("-");
                                                        props.setFromDate(`${date[2]}/${date[1]}/${date[0]}`);
                                                    }
                                                }}
                                            />

                                            <p className='mt-2'>To :</p>
                                            <Form.Control value={props.dateCustomToLast}
                                                //  max="2999-12-31" 
                                                min={getCurrentDateCustomDate()} // Set min date to current date
                                                max={getMaxDateCustomDate()} // Set max date to three months from current date
                                                type="date"
                                                onChange={(e) => {
                                                    const enteredDate = new Date(e.target.value);

                                                    // Get the current date and max date using the custom functions
                                                    const currentDateCustom = getCurrentDateCustomDate();
                                                    const maxDateCustom = getMaxDateCustomDate();

                                                    const currentDate = new Date(currentDateCustom);
                                                    const maxDate = new Date(maxDateCustom);

                                                    // Check if the entered date is within the allowed range
                                                    if (enteredDate < currentDate || enteredDate > maxDate) {
                                                        // Clear the input if the entered date is not within the allowed range
                                                        e.target.value = '';
                                                        props.setDateCustomToLast(null);
                                                        props.setToDate(null);
                                                    } else {
                                                        // Update state if the entered date is valid
                                                        props.setDateCustomToLast(e.target.value);
                                                        let date = e.target.value.split("-");
                                                        props.setToDate(`${date[2]}/${date[1]}/${date[0]}`);
                                                    }
                                                }}
                                            />
                                        </>
                                    }
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
                <Row className="actions-row">
                    <Col className="d-flex justify-content-end">
                        {(!props.fromDate && !props.toDate && !props.dueDateFrom && !props.dueDateTo && !props.autoRenew && !props.planName) &&
                            <Button
                                className="btn btn-lg mx-3"
                                variant="light"
                                disabled
                            >
                                Clear
                            </Button>
                        }
                        {(props.fromDate || props.toDate || props.dueDateFrom || props.dueDateTo || props.autoRenew || props.planName) &&
                            <Button
                                className="btn btn-lg mx-3 btn-border-light"
                                variant="light"
                                onClick={clearFilterBox}>
                                Clear
                            </Button>
                        }
                        {(props.fromDate || props.toDate || props.autoRenew || props.planName || props.dueDateFrom || props.dueDateTo) &&
                            <Button
                                className="btn btn-lg"
                                variant="primary"
                                onClick={() => {
                                    props.setPageNo(1);
                                    props.setScrollToDivTop(true);
                                    props.setTableFilterApplied(true);
                                    props.GetTableData(1, props.pageSize, props.searchValueTable, props.status, props.planName, props.autoRenew, props.fromDate, props.toDate, props.sortCol, props.sortDir, props.serviceId, props.customerId, props.customerCode, true);
                                    props.setShowFilterBox(false);
                                    props.setFilterFlag(true);
                                }
                                }
                            >
                                Apply
                            </Button>
                        }
                        {(!props.fromDate && !props.toDate && !props.dueDateFrom && !props.dueDateTo && !props.planName && !props.autoRenew) &&
                            <Button
                                className="btn btn-lg"
                                variant="light"
                                disabled
                            >
                                Apply
                            </Button>
                        }
                    </Col>
                </Row>
            </Container>
        </div >
    )
}
export default SummaryFilterBox