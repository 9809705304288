import React, { useContext, useEffect, useState } from 'react'
import CustomNumberInput from '../../../../../../../../GlobalComponents/CustomNumberInput';
import { Accordion, AccordionContext, Button, Card, Col, Container, Form, OverlayTrigger, Row, Tooltip, useAccordionButton } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import { XLg } from 'react-bootstrap-icons';
import styles from './CSPEditQuantity.module.scss';
import DataTable, { createTheme } from 'react-data-table-component';
import { api } from '../../../../../../../../Utils/API';
import { CSP_EDIT_QUANTITY_POST } from '../../../../../../../../Utils/GlobalConstants';
import failureImg from '../../../../../../../../Assets/Images/Illustrations/failure.svg';
import loadingGif from "../../../../../../../../Assets/Images/Animations/loading-management-console.gif"

const CSPEditQuantity = ({ subscriptionTableAPIData, setSubscriptionTableAPIData, subscriptionId, editQuantityActionPopupModalOpen, setEditQuantityActionPopupModalOpen, Logout,
  columnsSubscriptionTable, tableSubscriptionTable, minQuantity, maxQuantity, setEditQuantityActionsValue, editQuantityActionsValue, newQuantity, setNewQuantity, setToastSuccess, setToastMessage, setActionCompletedRefreshSubscriptionTable }) => {

  const alphanumericRegex = /^[a-zA-Z0-9]{0,25}$/; // RegEx for PO Number

  const [loading, setLoading] = useState(false);
  const [editQuantityStepTwo, setEditQuantityStepTwo] = useState(false);                    // to view second step in edit quantity
  let editQuantityStepTwoColumnName = ["Plan", "Billing Term", "Unit Price", "Quantity", "Sub - Total Price"];     // column name for second step table
  const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") == "false" ? false : true);    // to know whether terms and condition is already checked or not
  let EditedColumnName = ["Plan", "Current Quantity", "RRP (Inc GST)", "Reseller Price (Ex GST)", "New Quantity", "Sub - Total Price"]; // column name for first step edit quntity
  const [columnsEditQuantity, setColumnsEditQuantity] = useState([]);   // Setting Column Data-step 1
  const [editTable, setEditTable] = useState([]);               // Setting table Data-step 1
  const [editQuantityTableStepTwo, setEditQuantityTableStepTwo] = useState([]);  // Setting Column Data-step 2
  const [editQuantityColumnStepTwo, setEditQuantityColumnStepTwo] = useState([]); // Setting Column Data-step 2
  const [poNumber, setPoNumber] = useState(""); // to store po number
  const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false); // loading state to be dispalyed while calling api
  const [errorFlag, setErrorFlag] = useState(false); // error flag
  const [errorMessage, setErrorMessage] = useState(null); // display error message if any

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // accordion for expand and collapse button
  function CustomToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
      <span
        className='pt-1 align-content-center'
        onClick={decoratedOnClick}
      >{children}
        {isCurrentEventKey ? (
          <span className='up-accordion' />
        ) : (
          <span className='down-accordion' />
        )}
      </span>
    );
  }

  // Update Edit Quantity - put request
  async function UpdateEditQuantity() {
    setConfirmLoadingFlag(true);
    let requestBody = {
      "subscriptionResourceList": [{
        "subscriptionId": parseInt(subscriptionId),
        "quantity": parseInt(newQuantity),
        "poNumber": poNumber,
      }]
    };
    await api.post(CSP_EDIT_QUANTITY_POST, requestBody, config)
      .then(resp => {
        if (resp.status = 200) {
          setConfirmLoadingFlag(false);
          setErrorFlag(false);
          setToastMessage("Quantity has been Updated Successfully");
          setToastSuccess(true);
          setEditQuantityStepTwo(false);
          setTimeout(() => {
            setToastSuccess(false);
          }, 5000);
          setNewQuantity(null);
          setEditQuantityActionPopupModalOpen(false);
          setActionCompletedRefreshSubscriptionTable(true);
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        setConfirmLoadingFlag(false);
        setErrorFlag(true);
        setErrorMessage(error.message);
      })
  }

  //useEffect when quantity changes in edit quantity-step1
  useEffect(() => {
    if (subscriptionTableAPIData) {
      // Column Edited Quantity
      let f = [];
      f.push
        (
          {
            "RRP (Inc GST)": `$${subscriptionTableAPIData.rrpInclusiveGST}`,
            "Reseller Price (Ex GST)": `$${subscriptionTableAPIData.price}`,
            "Plan": subscriptionTableAPIData.planName,
            "Current Quantity": subscriptionTableAPIData.quantity,
            "New Quantity":
              <div className='d-flex flex-wrap my-2 align-items-center'>
                <CustomNumberInput
                  defaultValue={subscriptionTableAPIData.quantity}
                  min={minQuantity}
                  max={maxQuantity}
                  setUpdatedQuanity={setNewQuantity}
                  value={editQuantityActionsValue}
                  setValue={setEditQuantityActionsValue}
                  setAdditionalNextStepFlag={setEditQuantityStepTwo}
                />
              </div>,
            "Sub - Total Price": `$${editQuantityActionsValue ? Math.round(((subscriptionTableAPIData.price * editQuantityActionsValue) + Number.EPSILON) * 100) / 100 : Math.round(((subscriptionTableAPIData.price * subscriptionTableAPIData.quantity) + Number.EPSILON) * 100) / 100}`
          }
        );
      setTimeout(() => {
        setEditTable(f);         // Setting Table Data
      }, 50);
      let d = []
      EditedColumnName.map((td) => {
        if (td === "Sub - Total Price" || td === "Current Quantity" || td === "Reseller Price (Ex GST)") {
          d.push({
            id: `${td}`,
            name: <div>{td}</div>,
            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
            right: true,
          })
        }
        else if (td === "New Quantity") {
          d.push({
            id: `${td}`,
            name: <div>{td}</div>,
            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
            grow: 2,
            left: true,
          })
        }
        else if (td === "RRP (Inc GST)") {
          d.push({
            id: `${td}`,
            name: <div>{td}</div>,
            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
            grow: 1.4,
            right: true,
          })
        }
        else if (td === "Plan") {
          d.push({
            id: `${td}`,
            name: <div>{td}</div>,
            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
            grow: 1,
            left: true,
          })
        }
        else {
          d.push({
            id: `${td}`,
            name: <div>{td}</div>,
            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
            left: true,
          })
        }
      })
      setColumnsEditQuantity(d);     // Setting Column Data  
    }
  }, [subscriptionTableAPIData, editQuantityActionsValue])

  //useEffect when edit quantity changes edit quantity-step2
  useEffect(() => {
    if (subscriptionTableAPIData) {
      // Column Edited Quantity tab level step 2
      let f = [];
      f.push
        (
          {
            "Unit Price": `$${subscriptionTableAPIData.price}`,
            "Plan": subscriptionTableAPIData.planName,
            "Quantity": newQuantity,
            "Billing Term": subscriptionTableAPIData.billingTerm,
            "Sub - Total Price": `$${newQuantity ? Math.round(((subscriptionTableAPIData.price * newQuantity) + Number.EPSILON) * 100) / 100 : Math.round(((subscriptionTableAPIData.price * subscriptionTableAPIData.quantity) + Number.EPSILON) * 100) / 100}`
          }
        );
      setTimeout(() => {
        setEditQuantityTableStepTwo(f);         // Setting Table Data
      }, 50);
      let d = []
      editQuantityStepTwoColumnName.map((td) => {
        if (td === "Quantity" || td === "Unit Price") {
          d.push({
            id: `${td}`,
            name: <div>{td}</div>,
            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
            right: true,
          })
        }
        else if (td === "Sub - Total Price") {
          d.push({
            id: `${td}`,
            name: <div>{td}</div>,
            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
            right: true,
            grow: 5,
          })
        }
        else if (td === "Plan") {
          d.push({
            id: `${td}`,
            name: <div>{td}</div>,
            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
            left: true,
            grow: 5,
          })
        }
        else if (td === "Billing Term") {
          d.push({
            id: `${td}`,
            name: <div>{td}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
              >
                <span className="infoIcon-support-table ml-1"></span>
              </OverlayTrigger></div>,
            selector: row => <div className="text-wrap">
              {row[`${td}`]}</div>,
            left: true,
            grow: 2,
            allowOverflow: true,
          })
        }
        else {
          d.push({
            id: `${td}`,
            name: <div>{td}</div>,
            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
            left: true,
          })
        }
      })
      setEditQuantityColumnStepTwo(d);     // Setting Column Data  
    }
  }, [subscriptionTableAPIData, editQuantityStepTwo])

  useEffect(() => {
    if (newQuantity && (subscriptionTableAPIData && subscriptionTableAPIData.quantity != editQuantityActionsValue)) {
      setEditQuantityStepTwo(true);
    }
  }, [newQuantity])

  // React Data Table Custom Theme
  createTheme('solarized', {
    text: {
      primary: '#000000',
    },
    background: {
      default: '#FFFFFF',
    },
    divider: {
      default: '#d7dadf',
    },
    striped: {
      default: '#f5f5f5',
    },
  });

  // React Data Table Custom Styles
  const customStyles = {
    rows: {
      style: {
        minHeight: '60px', // override the row height
      },
    },
    cells: {
      style: {
        paddingLeft: '1rem', // override the cell padding for data cells
        paddingRight: '1rem',
        fontSize: '0.9rem',
        fontWeight: '400',
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '1rem', // override the cell padding for head cells
        paddingRight: '1rem',
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: '#fafafa',
        fontWeight: '500',
        fontSize: '0.875rem',
        color: '#6A6A6A !important',
        textTransform: 'capitalize',
      },
    },
    expanderButton: {
      style: {
        '&:hover:enabled': {
          cursor: 'pointer',
        },
        '&:disabled svg path': {
          color: '#999',
        },
        svg: {
          margin: 'auto',
        },
      },
    },
  };

  return (
    <div>
      {/* Edit Quantity Error Modal */}
      <Popup
        className={!errorFlag ? "custom-modal custom-modal-xl" : "custom-modal"}
        open={editQuantityActionPopupModalOpen}
        onClose={() => { setEditQuantityActionPopupModalOpen(false); setEditQuantityStepTwo(false); setNewQuantity(null); setSubscriptionTableAPIData(null); setErrorFlag(false); setErrorMessage(null); }}
        closeOnDocumentClick={!confirmLoadingFlag}
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            <>
              <div className="header">
                <h2>{(editQuantityStepTwo && !errorFlag) ? ("Order Confirmation - Edit Quantity") : (errorFlag ? ("Order Failed")
                  : ("Edit Quantity"))}</h2>

                <XLg
                  size={18}
                  className="cpointer text-strong"
                  onClick={() => { if (!confirmLoadingFlag) { close(); } }}
                  color="#6A6A6A"
                />
              </div>
              {errorFlag &&
                <>
                  <Row className="pb-3 pt-5">
                    <Col md={12} className="text-center my-4">
                      <img src={failureImg} alt="Edit Quantity Failure" />
                    </Col>
                  </Row>
                  <Container fluid >
                    <p className='text-center text-strong'>{errorMessage}</p>
                    <div className="btn-wrapper text-center mb-6">
                      <Button
                        className="px-4 mx-2"
                        onClick={() => { setEditQuantityActionPopupModalOpen(false); }}>
                        Cancel
                      </Button>

                      <Button
                        className="px-4 mx-2"
                        onClick={() => { setErrorFlag(false); }}>
                        Retry
                      </Button>

                    </div>
                  </Container>
                </>
              }
              {!errorFlag &&
                <Container className={`action-modal-height ${styles['edit-quantity-width']}`}>
                  <Accordion defaultActiveKey="1">
                    <Card className={`${styles['accordion-upgradeGWS']}`}>
                      <Row className={`ml-1 mr-1 ${styles['tab-header']}`}>
                        <Col md={5} className="mt-2 mb-2">
                          <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Plan :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.planName : "Loading"}</span>
                        </Col>
                        <Col md={6} className="mt-2 mb-2">
                          <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Subscription ID :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.vendorSubscriptionId : "Loading"}</span>
                        </Col>
                        <Col md={1} className='text-right'><CustomToggle className='text-left text-center' eventKey="0"></CustomToggle></Col>
                      </Row>

                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <div className={`table-responsive  ${styles['gws-subsription-details-table']}`}>
                            {tableSubscriptionTable &&
                              <DataTable
                                columns={columnsSubscriptionTable}
                                data={tableSubscriptionTable}
                                theme="solarized"
                                customStyles={customStyles}
                                persistTableHead={false}
                                noDataComponent={<div className={styles["loading-plans"]}><p><b>Loading...</b></p></div>}
                                width="100%"
                                fixedHeaderScrollHeight="31vh" progressPending={loading}
                                progressComponent={<div className={styles["loading-plans"]}><p><b>Loading...</b></p></div>}
                                fixedHeader
                              />
                            }
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  {editQuantityStepTwo ? (
                    <div className={`${styles['gws-edit-quantity-table-order-confirmation']} mt-4`}>
                      {subscriptionTableAPIData &&
                        <DataTable
                          columns={editQuantityColumnStepTwo}
                          data={editQuantityTableStepTwo}
                          theme="solarized"
                          customStyles={customStyles}
                          persistTableHead={false}
                          noDataComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans . . .</p></div>}
                          width="100%"
                          fixedHeaderScrollHeight="31vh"
                          progressPending={loading}
                          progressComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans . . .</p></div>}
                          fixedHeader
                        />
                      }
                      <p className='mt-4'><span className='text-strong'>Note :</span> Plan cost is provided under 'Sub - Total Price', final cost may vary based on vendor’s billing logic.</p>

                      <Row>
                        <Col>
                          <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                          <Form.Control
                            type="text"
                            className="w-auto d-inline ml-3"
                            id="inputPONumber"
                            aria-describedby="inputPONumber"
                            placeholder='Add PO Number'
                            maxlength="25"
                            value={poNumber}
                            onChange={(e) => {
                              alphanumericRegex.test(e.target.value)
                                ? setPoNumber(e.target.value)
                                : console.log("")
                            }}
                          />
                          <Form.Text id="poNumber" />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col md={6} className='notes-confirmation'>

                          <Form.Check // prettier-ignore
                            type="checkbox"
                            id="termsCheckBox"
                            label=""
                            disabled
                            checked={isTermsChecked}
                            className="d-inline-block w-auto"
                          />
                          <p className="d-inline-block  w-auto mb-0">
                            I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => setIsTermsChecked(true)}>terms & conditions</a> of Synnex cloud platform
                          </p>

                        </Col>
                        <Col md={6}>
                          <div className="btn-wrapper text-right d-md-block d-none mb-1">
                            {!confirmLoadingFlag && editQuantityStepTwo &&
                              <>
                                <Button
                                  variant="light"
                                  className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                  onClick={() => {
                                    setEditQuantityStepTwo(false);

                                    setPoNumber("");
                                  }}>Back
                                </Button>
                              </>
                            }
                            {!confirmLoadingFlag && isTermsChecked &&
                              <>
                                <Button
                                  className="px-4 mx-2"
                                  onClick={UpdateEditQuantity}>
                                  Confirm
                                </Button>
                              </>
                            }
                            {
                              !confirmLoadingFlag && !isTermsChecked &&
                              <Button
                                variant="light"
                                className="px-4 mx-2"
                                disabled
                              >
                                Confirm
                              </Button>
                            }
                            {confirmLoadingFlag &&
                              <>
                                <Button
                                  variant="light"
                                  disabled
                                  className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                >Back
                                </Button>
                                <Button
                                  disabled
                                  className="ml-3 btn-border-light w-md-auto col-xs-12"
                                  variant="light"
                                >
                                  Processing . . .
                                </Button>
                              </>
                            }
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    // Content when setEditQuantityStepTwo is false
                    <div className={`mt-5 ${styles['gws-edit-quantity-table']}`}>
                      {!subscriptionTableAPIData &&
                        <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading. . .</p></div>
                      }
                      {subscriptionTableAPIData && (
                        <DataTable
                          columns={columnsEditQuantity}
                          data={editTable}
                          theme="solarized"
                          customStyles={customStyles}
                          persistTableHead={false}
                          noDataComponent={<div className={styles["loading-plans"]}><p></p></div>}
                          width="100%"
                          fixedHeaderScrollHeight="31vh"
                          fixedHeader
                        />
                      )}
                      <p className='mt-4'><span className='text-strong'>Note :</span> Plan cost is provided under 'Sub - Total Price', final cost may vary based on vendor’s billing logic.</p>
                    </div>
                  )}
                </Container>}
            </>
          </div>
        )}
      </Popup>
    </div>
  )
}

export default CSPEditQuantity
