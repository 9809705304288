import React from 'react'
import SubscriptionsRenewal from "../SubscriptionsRenewal";
import ActiveVsInactiveCustomers from "../Customers";
import PayOutstandingInvoice from "../Pay Outstanding Invoices";
import SubscriptionWidget from "../Subscriptions";
import VendorRevenueContribution from "../VendorRevenueContribution";
import TrendingPlans from "../TrendingPlans";
import InvoiceAmmount from "../InvoiceAmount";
import SeatsSummary from "../SeatSummary";
import SubscriptionsOverview from "../SubscriptionsOverview";
import NewCustomers from "../NewCustomers";

const Card = ({ Logout,id, widget, index, moveItem,styles,SaveDashboardWidget, widgetPositionData, useDrop, ItemTypes, useDrag,setAddWidgetPopup }) => {
    const ref = React.useRef(null);
    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            moveItem(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: { id, index },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    if (widget === "Subscriptions Renewal") {
        return (
            <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }} key={id} className={styles["widget-body"]}><SubscriptionsRenewal id={id} SaveDashboardWidget={SaveDashboardWidget} widgetPositionData={widgetPositionData} Logout={Logout}/></div>
        );
    }
    else if (widget === "Subscriptions Overview") {
        return (
            <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }} key={id} className={styles["widget-body"]}><SubscriptionsOverview id={id} SaveDashboardWidget={SaveDashboardWidget} widgetPositionData={widgetPositionData} Logout={Logout} /></div>
        );
    }
    else if (widget === "Customers") {
        return (
            <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>
                <div key={id} className={styles["widget-body"]}> <ActiveVsInactiveCustomers id={id} SaveDashboardWidget={SaveDashboardWidget} widgetPositionData={widgetPositionData} Logout={Logout}  /></div>
            </div>
        );
    }
    else if (widget === "New Customers") {
        return (
            <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>
                <div key={id} className={styles["widget-body"]}> <NewCustomers id={id} SaveDashboardWidget={SaveDashboardWidget} widgetPositionData={widgetPositionData} Logout={Logout}/></div>
            </div>
        );
    }
    else if (widget === "Seats Summary") { 
        return (
            <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>
                <div key={id} className={styles["widget-body-no-flex-center"]}> <SeatsSummary id={id} SaveDashboardWidget={SaveDashboardWidget} widgetPositionData={widgetPositionData} Logout={Logout} /></div>
            </div>
        );
    }
    else if (widget === "Unpaid Invoice Summary") {
        return (
            <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>
                <div key={id} className={styles["widget-body"]}> <PayOutstandingInvoice id={id} SaveDashboardWidget={SaveDashboardWidget} widgetPositionData={widgetPositionData} Logout={Logout} /></div>
            </div>
        );
    }
    else if (widget === "Vendor Revenue Contribution") {
        return (
            <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>
                <div key={id} className={styles["widget-body"]}> <VendorRevenueContribution id={id} SaveDashboardWidget={SaveDashboardWidget} widgetPositionData={widgetPositionData} Logout={Logout}  /></div>
            </div>
        );
    }
    else if (widget === "Subscriptions") {
        return (
            <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>
                <div key={id} className={styles["widget-body"]}> <SubscriptionWidget id={id} SaveDashboardWidget={SaveDashboardWidget} widgetPositionData={widgetPositionData} Logout={Logout} /></div>
            </div>
        );
    }
    else if (widget === "Invoice Amount") {
        return (
            <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>
                <div key={id} className={styles["widget-body"]}> <InvoiceAmmount id={id} SaveDashboardWidget={SaveDashboardWidget} widgetPositionData={widgetPositionData} Logout={Logout} /></div>
            </div>
        );
    }
    else if (widget === "Trending Plans") {
        return (
            <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>
                <div key={id} className={styles["widget-body-no-flex-center"]}> <TrendingPlans id={id} SaveDashboardWidget={SaveDashboardWidget} widgetPositionData={widgetPositionData} Logout={Logout} /></div>
            </div>
        );
    }
    else if (widget === "add") {
        return (
            <div style={{ opacity: isDragging ? 0.5 : 1, cursor: 'pointer' }}>
                <div key={id} onClick={() => { setAddWidgetPopup(true); }} className={`${styles['widget-body']} ${styles['add-widget-border']} `}><span className="add-widget">&nbsp;</span></div>
            </div>
        );
    }
    else {
        return (
            <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>
                <div key={id} className={styles["widget-body"]}>Development in progress...</div>
            </div>
        );
    }

};

export default Card
