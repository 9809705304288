import React from 'react'
import { Row, Col } from "react-bootstrap";
import styles from ".././prerequisites.module.scss";

const BuyDomainFailed = () => {
    return (
        <div className="text-center">
            <Row>
                <Col md={12}>
                    <div className={styles["no-data-cloud-summary"]}>
                        <div className="col-12 text-center pt-5 mt-5">
                            <h2 className='mb-2 mt-5 pt-5'>Select Vendor</h2>
                            <p className='mb-2'>Please select vendor to view cloud requirements.</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default BuyDomainFailed;


