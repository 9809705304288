import React , {useState,useEffect} from 'react'
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import styles from './OnboardResellerPopup.module.scss';
import { Container } from 'react-bootstrap';
import loadingGif from "../../../../../../../Assets/Images/Animations/loading-management-console.gif"

const OnboardResellerPopup = ({modalOpen,setModalOpen,resellerOnboardingForm}) => {
    
//   const [scriptLoaded, setScriptLoaded] = useState(false);
const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://au177.activehosted.com/f/embed.php?id=303';
//     script.charset = 'utf-8';
//     document.body.appendChild(script);
//     setScriptLoaded(true);
//   }, []);

useEffect(() => {
  if(loading){
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }

}, [loading])


  return (
    <Popup
        className="custom-modal custom-modal-lg"
        open={modalOpen}
        onClose={() => { setModalOpen(false); }}
        modal
        nested
      >
        {(close) => (
          <div>
            <div  className={styles.closeCross}>
            <XLg
              size={24}
                className={`${styles.cross} cpointer`}
              onClick={close}
            />
            </div>
           
            <Container fluid className="_form_303" >
            { loading ? <div className={styles["loading-customers"]}>
                    <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading ...</p>
                </div> : <></> }
            <iframe
                src={resellerOnboardingForm && resellerOnboardingForm}
                frameBorder="0"
                width="100%"
                height="100%"
                allowTransparency="true"
                scrolling="yes"
                // onLoad={() => setLoading(false)}
            />
            </Container>
          </div>)}
      </Popup>
  )
}

export default OnboardResellerPopup
