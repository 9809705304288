import React, { useEffect , useContext } from "react";
import PageNotFoundImage from "../../../Assets/Images/Illustrations/pageNotFound.svg";
import {Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from './accessDenied.module.scss';

const AccessDenied = () => {
  const navigate = useNavigate(); // React Router DOM v6
  return (
    <div className={`${styles["main-page-not-found"]}`}>
        <img src={PageNotFoundImage} className={`${styles["image-page-not-found"]}`} alt="" />
        <h2 className="mt-4 mb-3">Access Denied</h2>
        <p className="mb-3">You do not have access to view access this URL.</p>
        <Button
            className="btn btn-lg btn-primary px-3"
            onClick={() => {
            navigate("/");
            }}
        >
            Go to Homepage
        </Button>
    </div>
  )
}

export default AccessDenied
