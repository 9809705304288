import React from 'react';
import { useContext, useEffect } from "react";
import CmsDetailsContext from '../../Context/CmsDetails/CmsDetailsContext';
import { cmsApi, api } from "../../Utils/API";
import { LANDING_PAGE_CMS_DATA} from '../../Utils/GlobalConstants';

const AnonymousPageFormHeader = (props) => {

    const globalData = useContext(CmsDetailsContext); 
      // Function for calling Landing Page data from Orchid Core CMS
  async function GetPageData() {
    // console.log("CMS Data Fetching Initiated");
    const data = `query {
      landingPage {
        bannerText
        ${props.page} {
          teaserText
          welcomeText
        }
        bannerImage {
          paths
          urls
        }
      }
    }`;
    const config = {
      headers: {
        "Content-Type": "application/graphql",
        Accept: "application/graphql",
      },
    };
    await cmsApi
      .post(LANDING_PAGE_CMS_DATA, data, config)
      .then((resp) => {
        // handle success
        // console.log("Login Image URL : ", globalData.baseUrl + resp.data.data.landingPage[0].bannerImage.urls[0]);
        props.setPageData(resp.data.data.landingPage[0]);
        props.setBannerData(resp.data.data.landingPage[0]);
        props.setBackgroundImageUrl(
          `url(${globalData.baseUrl + resp.data.data.landingPage[0].bannerImage.urls[0]})`
        );
      })
      .catch((error) => {
        // console.log(error);
      });
  }

    // Defining UseEffect to call CMS landing Page Data
    useEffect(() => {
        if (globalData)
        // console.log(props.page, props.pageData);
          GetPageData();
      }, [globalData, props.page]);

  return (
    <>
    {props.pageData && 
    <>
        <div>
        <h1>{props.pageData[props.page].welcomeText}</h1>
        <p>{props.pageData[props.page].teaserText}</p>
        </div>
    </>}
    </>
  )
}

export default AnonymousPageFormHeader
