import React, { useEffect, useRef, useState } from 'react';
import styles from './adduser.module.scss';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../../Utils/API';
import { GET_ROLES, SUB_ACCOUNTS, GET_ROLE_MODULES } from '../../../../Utils/GlobalConstants';
import { Button, Col, Form, Row, NavDropdown } from 'react-bootstrap';
import PhoneNumber from '../../../../GlobalComponents/PhoneNumber';
import { emailRegEx, numberRegEx, nameRegEx, phoneRegex, phoneOnChangeRegex } from '../../../../GlobalComponents/RegExPatterns';
import Toast from '../../../../GlobalComponents/Toast';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import noRole from '../../../../Assets/Images/Illustrations/noRole.svg';

const AddSubAccountUser = (props) => {
    const navigate = useNavigate();
    const { instance, inProgress } = useMsal();
    const [roleData, setRoleData] = useState(null);  // data in Role dropdown
    const [roleModuleFeatures, setRoleModuleFeatures] = useState(null); // Role Module Data on Get Request
    const [roleModuleFeaturesInitial, setRoleModuleFeaturesInitial] = useState(null); // Role Module Data on Get Request
    const [roleType, setRoleType] = useState("Select Role");
    const [roleUpdated, setRoleUpdated] = useState(false);
    const [isAllDisabled, setisAllDisabled] = useState([]);
    const [saveBtnEnabled, setSaveBtnEnabled] = useState(false);
    const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);            // loading state for processing button

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                        // toast message displaying success message
    const [toastError, setToastError] = useState(false);                            // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                           // toast message  



    const [formErrors, setFormErrors] = useState({
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        currentRole: null,
        userModuleFeatures: null
    });

    const [postErrors, setPostErrors] = useState(null);

    const phoneNumber = useRef();   // Phone number component

    // Store Inotial Values for reset
    const userDetailsInitial = {
        "firstName": "",
        "lastName": "",
        "email": "",
        "phoneNumber": "",
        "currentRole": "selectrole",
        "userModuleFeatures": null
    }

    // User Module Features for Post Request Body;
    const [userModuleFeatures, setUserModuleFeatures] = useState(null);


    // Post Request Body
    const [addUserPostBody, setAddUserPostBody] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        currentRole: roleType,
        userModuleFeatures: [userModuleFeatures]
    });



    // Logout Function on error 410
    // function Logout() {
    //     props.setCustomerIsAuthenticated(false);
    //     if (props.customerIsAuthenticated) { navigate("/") };
    //     localStorage.clear();
    //     instance.logoutRedirect();
    // }

    // Function to call all dropdown values in Role 
    async function GetAllRoles() {
        await api.get(GET_ROLES, config)
            .then(resp => {
                // console.log("Data : ", resp.data);
                setRoleData(resp.data);
            })
            .catch(error => {
                // console.log(error)
                if (error?.status == 401 || error?.response.status == 401) {
                    // console.log("Error 401");
                    props.Logout();
                }
                else {
                    // console.log(error);
                }

            });
    };

    // Function to call all dropdown values in Role 
    async function GetRoleModuleFeatures(role) {
        await api.get(GET_ROLE_MODULES + role, config)
            .then(resp => {
                // console.log("Data : ", resp.data);
                setRoleModuleFeatures(resp.data);
                setRoleModuleFeaturesInitial(resp.data);
            })
            .catch(error => {
                // console.log(error)
                if (error?.status == 401 || error?.response.status == 401) {
                    // console.log("Error 401");
                    props.Logout();
                }
                else {
                    // console.log(error);
                }

            });
    };

    async function AddUserDetails() {
        setConfirmLoadingFlag(true);

        await api.post(SUB_ACCOUNTS, addUserPostBody, config)
            .then(resp => {
                // console.log(resp);
                if (resp.status == 200) {
                    setToastMessage("User created successfully.")
                    setToastSuccess(true);
                    setSaveBtnEnabled(false);
                    setTimeout(() => {
                        setConfirmLoadingFlag(false);
                        setToastMessage(false);
                        navigate("/user-management");
                    }, 2000);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                }
                setToastMessage(error.response.data.errors);
                setToastError(true);
                setTimeout(() => {
                    setConfirmLoadingFlag(false);
                    setToastError(false);
                }, 5000);
            })
    }

    // function for form validation of email
    const validateForm = () => {
        let valid = true;

        // Validate email Address
        if (!emailRegEx.test(addUserPostBody.email)) {
            setFormErrors({
                ...formErrors,
                email: "Please enter a valid email"
            });
            window.scrollTo(0, 0);
            valid = false;
        } else {
            setFormErrors({
                ...formErrors,
                email: null
            });
            valid = true;
        }

        if (valid) {
            // Proceed with form submission
            AddUserDetails();
        }
    };

    // Handle Access Type Change
    const handleAccessChange = (moduleFeatureId, isSelected) => {
        const updatedModuleFeatures = userModuleFeatures.map(feature => {
            if (feature.moduleFeatureId === moduleFeatureId) {
                const updatedFeature = {
                    ...feature,
                    isReadAccess: false,
                    isReadWriteAccess: false,
                    isDisabled: false
                };

                if (isSelected === "isReadAccess") {
                    updatedFeature.isReadAccess = true;
                } else if (isSelected === "isReadWriteAccess") {
                    updatedFeature.isReadWriteAccess = true;
                } else if (isSelected === "isDisabled") {
                    updatedFeature.isDisabled = true;
                }
                return updatedFeature;

            }
            return feature;
        });
        setUserModuleFeatures(updatedModuleFeatures);
    }

    function resetUserDetails() {
        setRoleType("Select Role");
        setAddUserPostBody(userDetailsInitial);
        navigate("/user-management");
    }

    // Event handler to update form field values
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // console.log(e.target);

        setAddUserPostBody({
            ...addUserPostBody,
            [name]: value
        });
    };


    useEffect(() => {
        if (roleType) {
            GetRoleModuleFeatures(roleType);
        }
    }, [roleType]);

    useEffect(() => {
        const updatedRoleModules = roleModuleFeatures && roleModuleFeatures.map(roleModuleFeature => ({
            moduleFeatureId: roleModuleFeature.moduleFeatureId,
            isReadAccess: roleModuleFeature.isDefault == true ? false : roleModuleFeature.isReadAccess,
            isReadWriteAccess: roleModuleFeature.isDefault == true ? true : roleModuleFeature.isReadWriteAccess,
            isDisabled: roleModuleFeature.isDefault == true ? false : roleModuleFeature.isDisabled,
            // moduleName: roleModuleFeature.moduleName
        }

        ))
        setUserModuleFeatures(updatedRoleModules);
    }, [roleModuleFeatures]);

    useEffect(() => {

        // Filter out objects where "isDisabled" is false and extract moduleFeatureId
        if (userModuleFeatures) {
            const disabledModuleFeatureIds = userModuleFeatures
                .filter(feature => feature.isDisabled)
                .map(feature => feature.moduleFeatureId);

            setisAllDisabled(disabledModuleFeatureIds);

        }

        setAddUserPostBody(
            {
                ...addUserPostBody,
                currentRole: roleType,
                userModuleFeatures: userModuleFeatures
            }
        )
        setRoleUpdated(true);
        // console.log("roleUpdated-----", roleUpdated);
        // console.log("Is All Disabled", isAllDisabled, isAllDisabled.length);
    }, [userModuleFeatures]);

    // UseEffect to run on initial load to navigate unauthorized user to Login Page
    // useEffect(() => {
    //     if (props.customerIsAuthenticated === false) {
    //         navigate("/");
    //     }
    // }, [])

    // UseEffect to run on initial load to navigate unauthorized user to Login Page
    // useEffect(() => {
    //     if (props.customerIsAuthenticated === false) {
    //         navigate("/");
    //     }
    // }, []);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };


    // Use Effect to get all the Roles dropdown
    useEffect(() => {
        GetAllRoles();
    }, []);



    // Save Button Enabled
    useEffect(() => {
        if (
            (addUserPostBody.firstName !== "" && addUserPostBody.firstName.length <= 50 && nameRegEx.test(addUserPostBody.firstName))
            && (addUserPostBody.lastName !== "" && addUserPostBody.lastName.length <= 50 && nameRegEx.test(addUserPostBody.lastName))
            && (addUserPostBody.email !== "")
            && (addUserPostBody.phoneNumber.length >= 8 && addUserPostBody.phoneNumber.length <= 15)
            && (roleType !== 'Select Role')
            && isAllDisabled.length < 8
        ) {
            setSaveBtnEnabled(true);
        } else {
            setSaveBtnEnabled(false);
        }
    }, [addUserPostBody]);

    return (
        <>
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />


            <div className='main-content'>
                <div className="customer-management-console-main-div mb-5">
                    <h1 className="d-block d-md-none mobile-h1">Profile</h1>
                    <div className="mgmt-console-table-row">
                        <div className="my-3 d-flex">

                            <Col className={`py-0 ${styles['profile-table']}`}>
                                <label className="cpointer" onClick={() => navigate('/user-management')}>
                                    <span className="back">&nbsp;</span>&nbsp;<strong>Add User</strong>
                                </label>
                                <div className='cloud-invoice-table-inner table-details details-box'>
                                    <div className="my-0 table-heading align-items-center row">
                                        <Col lg={12} className='pl-1'>
                                            <Row className='px-3 py-2'>
                                                <Col lg={4} md={5}>
                                                    <Form.Group className="mb-3" controlId="formFirstName">
                                                        <Form.Label>First Name <span className="red">*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="firstName"
                                                            // placeholder='Enter first name'
                                                            value={addUserPostBody.firstName}
                                                            maxLength="50"
                                                            onChange={(e) => {
                                                                if (e.target.value === '' || /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/.test(e.target.value)) {
                                                                    {
                                                                        setAddUserPostBody({
                                                                            ...addUserPostBody,
                                                                            firstName: e.target.value
                                                                        });
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4} md={5}>
                                                    <Form.Group className="mb-3" controlId="formLastName">
                                                        <Form.Label>Last Name <span className="red">*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="lastName"
                                                            // placeholder='Enter last name'
                                                            value={addUserPostBody.lastName}
                                                            maxLength="50"
                                                            onChange={(e) => {
                                                                if (e.target.value === '' || /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/.test(e.target.value)) {
                                                                    {
                                                                        setAddUserPostBody({
                                                                            ...addUserPostBody,
                                                                            lastName: e.target.value
                                                                        });
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className='px-3 py-2'>
                                                <Col lg={4} md={5}>
                                                    <Form.Group className="mb-3" controlId="formEmail">
                                                        <Form.Label>Email ID<span className="red">*</span></Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            name="email"
                                                            // placeholder='Enter email id'
                                                            value={addUserPostBody.email}
                                                            maxLength="64"
                                                            onChange={(e) => handleInputChange(e)}
                                                        />

                                                        <span className="text-small red">
                                                            {formErrors.email}
                                                        </span>

                                                    </Form.Group>
                                                </Col>



                                                <Col lg={4} md={5}>
                                                    <PhoneNumber
                                                        phoneNumber={addUserPostBody.phoneNumber}
                                                        placeholder="xxxxxxxxxx"
                                                        ref={phoneNumber}
                                                        updatePhoneNumber={(e) => {
                                                            setAddUserPostBody({
                                                                ...addUserPostBody,
                                                                phoneNumber: phoneOnChangeRegex.test(e.target.value) ? e.target.value : ""
                                                            })
                                                        }}
                                                        errorMessage={formErrors.phoneNumber == null ? "" : formErrors.phoneNumber}
                                                        isBold={false}
                                                    ></PhoneNumber>
                                                </Col>
                                            </Row>
                                            <Row className='px-3 py-2'>
                                                <Col lg={4} md={5}>
                                                    <h2 className='pb-2'>Role Settings</h2>
                                                    <p className="mb-1 add-user-service-dropdown">Role <span className="red">*</span></p>
                                                    <div>

                                                        <>
                                                            <Form.Group
                                                                className="form-group col-lg-12 mb-0"
                                                                controlId="roleTypeId"
                                                            >
                                                                <NavDropdown
                                                                    title={roleType}
                                                                    id="select-role-dropdown"
                                                                    className={`servcies-dropdown ${styles['role-select-dropdown']}`}
                                                                >
                                                                    {roleData &&
                                                                        roleData.map((dropdownItem) => {
                                                                            return (
                                                                                <NavDropdown.Item
                                                                                    key={dropdownItem.roleId}
                                                                                    id={"roletype_" + dropdownItem.roleId}
                                                                                    value={dropdownItem.roleName}
                                                                                    className=""
                                                                                    onClick={() => {
                                                                                        setRoleType(dropdownItem.roleName);
                                                                                        GetRoleModuleFeatures(dropdownItem.roleName);
                                                                                    }}>
                                                                                    {dropdownItem.roleName}
                                                                                </NavDropdown.Item>
                                                                            )
                                                                        })}
                                                                </NavDropdown>
                                                            </Form.Group>
                                                        </>
                                                        {isAllDisabled.length > 7 &&
                                                            <p className="red text-small mt-1">Seelct either "Read only" or ""Read &amp; Write" access for at least one module.</p>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        {roleType == "Select Role" &&
                                            <>
                                                <div className={styles["no-role"]}>
                                                    <img src={noRole} className="empty-customers-table-svg" />
                                                    <h2 className="mt-4 mb-3">No Role Selected</h2>
                                                    <p>Please select role from the dropdown to view settings</p>
                                                </div>
                                            </>
                                        }
                                        {roleType !== "Select Role" &&
                                            <>
                                                <Row>
                                                    <Col>
                                                        <>
                                                            {roleModuleFeatures && roleModuleFeatures.map(roleModule => {
                                                                return (
                                                                    <>
                                                                        {
                                                                            roleModule.isDefault != true &&

                                                                            <Row className="my-2 pl-4" key={roleModule.roleModuleFeatureId}>
                                                                                <Form.Group >

                                                                                </Form.Group>
                                                                                <Col md={2}>
                                                                                    <span className='text-strong'>{roleModule.moduleName} <span className="red">*</span> :</span>
                                                                                </Col>
                                                                                <Col md={2}>
                                                                                    <Form.Check
                                                                                        type="radio"
                                                                                        id={"isReadAccess_" + roleModule.roleModuleFeatureId}
                                                                                        label={`Read only`}
                                                                                        name={roleModule.moduleName}
                                                                                        defaultChecked={roleModule.isReadAccess}
                                                                                        // checked={roleModule.isReadAccess}
                                                                                        onClick={() =>
                                                                                            handleAccessChange(roleModule.moduleFeatureId, "isReadAccess")
                                                                                        }
                                                                                    />
                                                                                </Col>
                                                                                <Col md={2}>
                                                                                    <Form.Check
                                                                                        type="radio"
                                                                                        id={"isReadWriteAccess_" + roleModule.roleModuleFeatureId}
                                                                                        label={`Read & write`}
                                                                                        name={roleModule.moduleName}
                                                                                        defaultChecked={roleModule.isReadWriteAccess}
                                                                                        // checked={roleModule.isReadWriteAccess}
                                                                                        onClick={() => handleAccessChange(roleModule.moduleFeatureId, "isReadWriteAccess")}
                                                                                    />
                                                                                </Col>
                                                                                <Col md={2}>
                                                                                    <Form.Check
                                                                                        type="radio"
                                                                                        id={"isDisabled_" + roleModule.roleModuleFeatureId}
                                                                                        label={`Disable`}
                                                                                        name={roleModule.moduleName}
                                                                                        defaultChecked={roleModule.isDisabled}
                                                                                        // checked={roleModule.isDisabled}
                                                                                        onClick={() => handleAccessChange(roleModule.moduleFeatureId, "isDisabled")}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        }

                                                                        {
                                                                            roleModule.isDefault == true &&
                                                                            <Row className="my-2 pl-4">
                                                                                <Form.Group >

                                                                                </Form.Group>
                                                                                <Col md={2}>
                                                                                    <span className='text-strong'>{roleModule.moduleName} <span className="red">*</span> :</span>
                                                                                </Col>

                                                                                <Col md={2}>
                                                                                    <label title="" className="form-check-label">Read &amp; write</label>
                                                                                </Col>
                                                                            </Row>
                                                                        }
                                                                    </>
                                                                )
                                                            })}


                                                        </>

                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        <Row>
                                            <Col className="my-3 text-right btn-wrapper-outer">
                                                {!confirmLoadingFlag &&
                                                    <Button
                                                        className="btn-border-light w-md-auto col-xs-12 mb-3"
                                                        variant="light"
                                                        onClick={resetUserDetails}
                                                    >
                                                        Cancel
                                                    </Button>
                                                }
                                                {
                                                    !saveBtnEnabled && !confirmLoadingFlag &&
                                                    <>


                                                        <Button
                                                            className="ml-3 w-md-auto btn-light col-xs-12 mb-3"
                                                            variant='light'
                                                            disabled
                                                        >
                                                            Save
                                                        </Button>
                                                    </>
                                                }

                                                {
                                                    saveBtnEnabled && !confirmLoadingFlag &&
                                                    <Button
                                                        className="ml-3 w-md-auto col-xs-12 mb-3"
                                                        onClick={validateForm}
                                                    >
                                                        Save
                                                    </Button>
                                                }


                                                {
                                                    confirmLoadingFlag &&
                                                    <>
                                                        <Button
                                                            variant="light"
                                                            disabled
                                                            className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                        >Cancel
                                                        </Button>
                                                        <Button
                                                            disabled
                                                            className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                        >
                                                            Processing . . .
                                                        </Button>
                                                    </>
                                                }





                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddSubAccountUser