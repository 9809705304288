import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { GET_CONNECTWISE_AGREEMENT_ADDITION } from '../../../../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../../../../Utils/API';
import styles from './AgreementSelection.module.scss';
import loadingGif from "../../../../../../../../../../Assets/Images/Animations/loading-management-console.gif"

const AgreementSelection = ({ agreementData, setAgreementData, defaultAgreementSelected, setDefaultAgreementSelected, setActiveStep, agreementName, setAgreementName, companyId, setButtonCheckstep2, buttonCheckstep2, setAgreementId, Logout }) => {

    const [emptySearch, setEmptySearch] = useState(false);                              // empty search response check state in table during api call to load data
    const [searchValueTable, setSearchValueTable] = useState(null);                     // storing searching value in the search input box
    const [searchIconFlag, setSearchIconFlag] = useState(false);                        // to open and close search box
    const searchInputRef = useRef();                                                    // Search Input Box
    let AgreementColumnName = ["", "Agreement Names", "Agreement End Date", "Status"];  // Columns of Agreement table 
    const [loadingState, setLoadingState] = useState(false);                            // loading State

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // get api for agreement mapping data
    async function GetConnectwiseAgreementData(agreementSelected) {
        setLoadingState(true);
        (agreementSelected === undefined || agreementSelected === null) ? agreementSelected = "" : agreementSelected = agreementSelected;

        await api.get(`${GET_CONNECTWISE_AGREEMENT_ADDITION}${companyId}/connectwise-agreements?agreementName=${agreementSelected}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setLoadingState(false);
                    setEmptySearch(true);
                }
                else {
                    setLoadingState(false);
                    setEmptySearch(false);
                    setAgreementData(resp.data);
                }

            })
            .catch(error => {
                setLoadingState(false);
                setEmptySearch(false);
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
        if (searchValueTable !== undefined && searchValueTable !== null) {
            const getData = setTimeout(() => {
                GetConnectwiseAgreementData(searchValueTable)
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable])

    useEffect(() => {
        if (companyId)
            GetConnectwiseAgreementData();
    }, [companyId]);

    return (
        <div sx={{ mt: 2 }}>
            <Row className='mb-3'>
                <Col md={8}> <h3 className="mb-5 px-3 mt-2  notes-confirmation">Agreements</h3></Col>
                <Col md={4}>
                    <div className="text-right">
                        <InputGroup className="search-input-box search-input-box-xl ">
                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                <span className="searchgrey cpointer"
                                >
                                    &nbsp;
                                </span>
                            </InputGroup.Text>
                            <Form.Control
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                                className="search-input-box-input"
                                value={searchValueTable}
                                onChange={(e) => setSearchValueTable(e.target.value)}
                                ref={searchInputRef}
                            />
                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                onClick={() => {
                                    setSearchValueTable("");
                                    setSearchIconFlag(!searchIconFlag);
                                }}
                            >
                                <span className="closebtn">
                                    &nbsp;
                                </span>
                            </InputGroup.Text>
                        </InputGroup>
                    </div>
                </Col>
            </Row>
            {/* loading state */}
            {loadingState &&
                <div className={styles["loading-customers"]}>
                    <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading ...</p>
                </div>}
            {/* data from api with table */}

            {!emptySearch && !loadingState &&
                <div className={styles["table-height-adjustment"]}>
                    <Table responsive="md">
                        <thead className="sticky-top">
                            <tr>
                                {AgreementColumnName.map(th => {
                                    return (<th className={(th === "Status") ? "text-center" : ""}>{th}</th>)
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                agreementData && agreementData.map((item) => {
                                    return (
                                        <tr key={item.agreementId}>
                                            <td><input type='radio'
                                                style={{ verticalAlign: 'bottom' }}
                                                id={item.agreementId}
                                                name="agreementData"
                                                checked={defaultAgreementSelected === (item.agreementId)}
                                                onChange={() => {
                                                    setDefaultAgreementSelected(item.agreementId);
                                                    setButtonCheckstep2(true);
                                                    setAgreementName(item.agreementName);
                                                    setAgreementId(item.agreementId);
                                                }}
                                            />
                                            </td>
                                            <td>{item.agreementName}</td>
                                            <td>{item.agreementEndDate}</td>
                                            <td className='text-center'>{item.status}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>}
            {/* empty search result */}
            {emptySearch && !loadingState &&
                <div className={styles["no-data-cloud-invoice"]}>
                    <h3 className="mt-4 mb-3">No Data Found</h3>
                </div>
            }
            <Row className='px-3 mt-3'>
                <Col md={6}>
                    <div className='text-small mb-5 mt-3'>
                        <span className='text-strong'>Agreement name : </span>
                        {agreementName}
                    </div>
                </Col>
                <Col md={6}>
                    <div className="btn-wrapper text-right">
                        <Button
                            className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                            variant="light"
                            onClick={() => {
                                setDefaultAgreementSelected(null);
                                setActiveStep(0);
                                setButtonCheckstep2(false);
                                setAgreementName("");
                            }}
                        >
                            Back
                        </Button>
                        {(buttonCheckstep2 && !emptySearch) ? <Button
                            className="px-4 mx-2"
                            onClick={() => {
                                setActiveStep(2);
                            }}
                        >Proceed</Button> :
                            <Button
                                className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                variant="light"
                                disabled  >Proceed</Button>}
                    </div></Col>
            </Row>
        </div>
    )
}

export default AgreementSelection
