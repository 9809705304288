const navbarMainLinks = [
    {
        "id": 1,
        "label": "Synnex Cloud",
        "url": "synnex-cloud",
        "eventKey": "default",
        "customClass": "mySynnex",
        "disabled": false,
        "external": false,
        "subMenus": [
            {
                "id": 1,
                "label": "Dashboard",
                "url": "dashboard",
                "eventKey": "default",
                "customClass": "",
                "subMenus": null,
                "external": false,
                "disabled": false,
            },  
            {
                "id": 2,
                "label": "Order History",
                "url": "order-history",
                "eventKey": "orderHistory",
                "customClass": "orderHistoryIcon",
                "subMenus": null,
                "external": false,
                "disabled": false,
            },
            {
                "id": 3,
                "label": "Integrations",
                "url": "integrations",
                "eventKey": "integrations",
                "customClass": "integrationIcon",
                "subMenus": null,
                "external": false,
                "disabled": false,
            },
            {
                "id": 3,
                "label": "Synnex B2B",
                "url": `${localStorage.getItem("b2b_navigation")}`,
                "eventKey": "synnexb2b",
                "customClass": "integrationIcon",
                "subMenus": null,
                "external": true,
            }

        ]
    },
    {
        "id": 2,
        "label": "Customers",
        "url": "my-customers",
        "eventKey": "myCustomers",
        "customClass": "customers",
        "disabled": false,
        "external": false,
        "subMenus": []
    },
    {
        "id": 3,
        "label": "Provision",
        "url": "cloud-provision",
        "eventKey": "cloudProvision",
        "customClass": "cloudProvision",
        "disabled": false,
        "external": false,
        "subMenus": []
    },
    {
        "id": 4,
        "label": "Manage",
        "url": "management-console",
        "eventKey": "managementConsole",
        "tooltip" : "Manage Customers",
        "customClass": "managementConsole",
        "disabled": false,
        "external": false,
        "subMenus": []
    },
    {
        "id": 5,
        "label": "Billing",
        "url": "billing",
        "eventKey": "billing",
        "customClass": "billing",
        "disabled": false,
        "external": false,
        "subMenus": [
            {
                "id": 1,
                "label": "Summaries",
                "url": "cloud-summary",
                "eventKey": "cloudSummary",
                "customClass": "",
                "subMenus": null,
                "external": false,
                "disabled": false,
            },
            {
                "id": 2,
                "label": "Invoices",
                "url": "invoice",
                "eventKey": "invoice",
                "customClass": "",
                "subMenus": null,
                "external": false,
                "disabled": false,
            },
            {
                "id": 3,
                "label": "Credit Notes",
                "url": "credit-notes",
                "eventKey": "creditNotes",
                "customClass": "",
                "subMenus": null,
                "external": false,
                "disabled": false,
            },
            {
                "id": 4,
                "label": "Payment Methods",
                "url": "payment-methods",
                "eventKey": "paymentMethods",
                "customClass": "",
                "external": false,
                "disabled": false,
                "subMenus": []
            },
        // {
        //     "id": 4,
        //     "label": "Unbilled Iaas Usage",
        //     "url": "unbilled-iaas-usage-report",
        //     "eventKey": "unbilledIaasUsageReport",
        //     "customClass": "",
        //     "subMenus": null
        // },
        ]
    },
    // {
    //     "id": 6,
    //     "label": "Cloud Solutions",
    //     "url":  `${localStorage.getItem("cloud_solutions_navigation")}`,
    //     "eventKey": "cloudSolutions",
    //     "customClass": "cloudSolutions",
    //     "disabled": false,
    //     "external": true,
    //     "subMenus": []
    // },
    // {
    //     "id": 7,
    //     "label": "Order History",
    //     "url": "order-history",
    //     "eventKey": "orderHistory",
    //     "customClass": "orderHistoryIcon",
    //     "disabled": false,
    //     "subMenus": []
    // },
    // {
    //     "id": 8,
    //     "label": "Terms & Conditions",
    //     "url": "terms-and-Conditions",
    //     "eventKey": "termsAndConditions",
    //     "customClass": "mySynnex",
    //     "subMenus": []
    // }
]

export { navbarMainLinks };