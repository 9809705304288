import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col } from "react-bootstrap";
import "./styles.scss";
import failureIcon from '../../../../Assets/Images/Illustrations/failure.gif';

const ResetFailure = () => {

  // Defining UseNavigate for react router DOM v6
  const navigate = useNavigate();

  // Navigating to login page after 2.5 seconds
  setTimeout(() => {
    navigate(0);
  }, 2500);

  // Proceed to sign in Button Function
  function redirectToLogin() {
    navigate(0);
  }

  return (
    <>
      <div className="anonymous-login-main-div active">
        <Col xl={8} lg={10} md={12} className="login-block">
          <div className="failureIcon">
            <img src={failureIcon} alt="Reset Failure" />
          </div>
          <p className="text-center">
            <label className="text-danger text-medium">Your password reset has failed</label>
          </p>
          <p className="text-center mt-3 login-back-link">
            <Button
              className="col-12 mb-4"
              variant="primary"
              type="button"
              onClick={redirectToLogin}
            >
              Back to Reset Password
            </Button>
          </p>
        </Col>
      </div>
    </>
  );
};

export default ResetFailure;
