import React from 'react';
import { Row, Col, Button, Accordion, Form } from "react-bootstrap";
import styles from '../../GWSCustomerPrerequisite.module.scss';

const GWSCustomerTransferDomain = (props) => {

    return (
        <>
            {/* TransferAccorion */}
            <Accordion.Item className={`mt-5 ${props.stepsCompletedCheck.isDomainCheckCompleted ? "" : "disabled"} ${props.errorBlock ? "accordionErrors" : ""}`} eventKey="tarnsferDomain">
                <Accordion.Header>
                    <h3 className={`${styles['accordion-header-wrapper']}`}>
                        <label>
                            {props.stepsCompletedCheck.isDomainTransitionCompleted ?
                                <span className="step-completed">&nbsp;</span>
                                : <span className={`${styles['accordion-number']}`}>2</span>
                            }
                        </label>
                        <label className="pl-2">
                            <span className={`${styles['accordion-name']}`}>Transfer Domain</span>
                        </label>
                    </h3>
                </Accordion.Header>
                {props.stepsCompletedCheck.isDomainCheckCompleted &&
                    <Accordion.Body className='pt-0'>
                        <div className={`${styles['accordion-content']}`}>
                            <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-3" controlId="formOrganaisation">
                                        <Form>
                                            <Row>
                                                <Col lg={3} md={6} >
                                                    <Row className="align-items-center">
                                                        <Col md={12}>
                                                            <Form.Control
                                                                type="text"
                                                                value={props.domainName}
                                                                placeholder={props.domainName}
                                                                readOnly
                                                                disabled
                                                            />
                                                        </Col>

                                                    </Row>
                                                </Col>

                                                <Col lg={10} md={12} className="mt-5">
                                                    <Row>
                                                        <Col lg={2} md={4}>
                                                            <span className="text-strong">
                                                                Select Transfer type:
                                                            </span>
                                                        </Col>

                                                        <Col lg={9} md={8}>
                                                            <Row>
                                                                <Col>
                                                                    <Form.Check
                                                                        type={'radio'}
                                                                        id="googleDirect"
                                                                        label="Google Direct"
                                                                        name="domainTransferGroup"
                                                                        value="googleDirect"
                                                                        aria-label="googleDirect"
                                                                        defaultChecked={false}
                                                                        onChange={(e) => {
                                                                            
                                                                            props.setIsTransferInitiated(true);
                                                                            props.setTransferType("googleDirect");
                                                                            props.setFinalPostRequest("gwsDirecTransferPOST");
                                                                            props.setErrorBlock(null);
                                                                        }
                                                                        }
                                                                    />
                                                                </Col>

                                                                <Col>
                                                                    <Form.Check
                                                                        type={'radio'}
                                                                        id="differentReseller"
                                                                        label="Different Reseller"
                                                                        name="domainTransferGroup"
                                                                        value="differentReseller"
                                                                        aria-label="differentReseller"
                                                                        defaultChecked={false}
                                                                        onChange={(e) => {
                                                                           
                                                                            props.setIsTransferInitiated(true);
                                                                            props.setTransferType("differentReseller");
                                                                            props.setFinalPostRequest("gwsDifferentResellerTransferPOST");
                                                                            props.setErrorBlock(null);
                                                                        }
                                                                        }
                                                                    />
                                                                </Col>

                                                                <Col>
                                                                    <Form.Check
                                                                        type={'radio'}
                                                                        id="ownPartnerConsole"
                                                                        label="My Own Partner Sales Console"
                                                                        name="domainTransferGroup"
                                                                        value="ownPartnerConsole"
                                                                        aria-label="ownPartnerConsole"
                                                                        defaultChecked={false}
                                                                        onChange={(e) => {
                                                                          
                                                                            props.setIsTransferInitiated(true);
                                                                            props.setTransferType("ownPartnerConsole");
                                                                            props.setFinalPostRequest("gwsOwnPartnerConsoleTransferPOST");
                                                                            props.setErrorBlock(null);
                                                                        }
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={12} className="mt-3">
                                                    <p>
                                                        Please generate a transfer token first to initiate transfer. <a href="https://support.google.com/channelservices/answer/9547629?hl=en&ref_topic=9289684#zippy=%2Cgenerate-the-transfer-token" target="_blank">Learn here</a>
                                                    </p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="text-right">
                                                    <div className="button-wrapper">
                                                        <Button
                                                            className="btn btn-lg btn-border-light px-4"
                                                            variant="light"
                                                            onClick={() => props.setActiveAccordion(["domainCheck"])}
                                                        >
                                                            Back
                                                        </Button>

                                                        {(!props.isTransferInitiated || props.errorBlock) &&
                                                            <Button
                                                                className="btn btn-lg ml-2 px-4"
                                                                variant="light"
                                                                disabled
                                                            >
                                                                Initiate
                                                            </Button>
                                                        }
                                                        {props.isTransferInitiated &&

                                                            <>
                                                                {props.transferType == "googleDirect" && !props.errorBlock &&
                                                                    <>
                                                                        {!props.gwsDirectInitiating &&
                                                                            <Button
                                                                                className="btn btn-lg ml-2 px-4"
                                                                                onClick={props.gwsDirecTransfer}
                                                                            >
                                                                                Initiate
                                                                            </Button>
                                                                        }
                                                                        {props.gwsDirectInitiating &&
                                                                            <Button
                                                                                className="btn btn-lg ml-2 px-4"
                                                                                variant="light"
                                                                                disabled
                                                                            >
                                                                                Initiating...
                                                                            </Button>
                                                                        }
                                                                    </>
                                                                }

                                                                {props.transferType == "differentReseller" && !props.errorBlock &&
                                                                    <>
                                                                        {props.gwsDirectInitiating &&
                                                                            <Button
                                                                                className="btn btn-lg ml-2 px-4"
                                                                                variant="light"
                                                                                disabled
                                                                            >
                                                                                Initiating...
                                                                            </Button>
                                                                        }

                                                                        {!props.gwsDirectInitiating &&
                                                                            <>
                                                                                {props.isDomainAvailable.owned == false &&
                                                                                    <Button
                                                                                        className="btn btn-lg ml-2 px-4"
                                                                                        onClick={props.gwsDifferentResellerTransfer}
                                                                                    >
                                                                                        Initiate
                                                                                    </Button>
                                                                                }

                                                                                {props.isDomainAvailable.owned == true && !props.errorBlock &&
                                                                                    <Button
                                                                                        className="btn btn-lg ml-2 px-4"
                                                                                        onClick={props.gwsDifferentResellerR1R2POST}
                                                                                    >
                                                                                        Initiate
                                                                                    </Button>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </>
                                                                }
                                                                {props.transferType == "ownPartnerConsole" && !props.errorBlock &&
                                                                    <>
                                                                        {props.gwsDirectInitiating &&
                                                                            <Button
                                                                                className="btn btn-lg ml-2 px-4"
                                                                                variant="light"
                                                                                disabled
                                                                            >
                                                                                Initiating...
                                                                            </Button>
                                                                        }

                                                                        {!props.gwsDirectInitiating &&
                                                                            <Button
                                                                                className="btn btn-lg ml-2 px-4"
                                                                                onClick={props.gwsOwnPartnerConsoleTransfer}
                                                                            >
                                                                                Initiate
                                                                            </Button>
                                                                        }
                                                                    </>
                                                                }
                                                            </>

                                                        }
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Form>
                                    </Form.Group>
                                </Col>
                            </Row>

                        </div>
                    </Accordion.Body>
                }
            </Accordion.Item >
            {/* Error Message updated from API Response */}

            {props.errorBlock &&
                // <p className='red text-small mt-3'>Note: Customer not eligible for transfer. Check that the customer's information is correct and that a transfer token has been generated.</p>

                <p className='red text-small mt-3'>Note: {props.transferError}</p>
            }
        </>
    )
}

export default GWSCustomerTransferDomain;