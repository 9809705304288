import React, { useState } from 'react';
import './styles.scss';

const FileUploadPaymentMethods = ({ fileFormat, setUploadedFile, selectedFile, setSelectedFile, setEmptyFileError }) => {
    const [,] = useState(null);
    const [fileSize, setFileSize] = useState(0);

    const handleClearFile = () => {
        setSelectedFile(null);
        setEmptyFileError(null);
        document.getElementById('file-upload').value = '';
    };



    function fileUploaded(e) {
        setSelectedFile(e.target.files[0]);
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        if (file) {
            setFileSize(e.target.files[0].size);
        } else {
            setFileSize('');
        }

        // console.log(e.target.files[0].size)
        setUploadedFile(formData);
    }





    return (
        <>
            <div className="custom-upload-btn-block">
                <label for="file-upload" className="custom-upload-btn">Choose file</label>
                <input type={"file"} id="file-upload" accept={fileFormat} onChange={(e) => { fileUploaded(e) }} />

                {!selectedFile &&
                    <span className="selected-file-name-disabled">No file selected</span>
                }

                {selectedFile &&
                    <span className="selected-file-name">{selectedFile.name}</span>
                }
                <span className="clear-file"><span className="closebtn" onClick={handleClearFile}>&nbsp;</span></span>
            </div>
            <span className="text-small text-muted">Support file type (png, txt, pdf) up to 10 mb</span><br />
            {fileSize > 10000000 && <span className="text-small red">Filesize should be lessthan 10 MB</span>}
        </>
    );
};

export default FileUploadPaymentMethods;

