import React from 'react'
import { Card } from 'react-bootstrap';
import styles from './landingCard.module.scss'
function LandingCard(props) {
  return (
      <Card className={`${styles['card-container']}`}>
        <Card.Img className={`${styles['card-icon']} py-1 my-2 mb-3`} variant="top" src={props.src} />
        <Card.Title className={`${styles['card-title']}`}>{props.title}</Card.Title>
        <Card.Body className={`${styles['card-container-body']} pt-3`}>
          <Card.Text className={`${styles['card-text']}`}>
        <span>{props.space}{props.space}</span>{props.description}
          </Card.Text>
        </Card.Body>
      </Card>
    
  )
}

export default LandingCard;