import { Container, Row, Col, Button, Accordion, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../Utils/API";
import { CUSTOMER_LISTING_TABLE } from '../../../../Utils/GlobalConstants';
import { emailRegEx, numberRegEx, phoneOnChangeRegex, phoneRegex, websiteURLRegEx } from "../../../../GlobalComponents/RegExPatterns";
import { useEffect, useRef, useState } from "react";
import Toast from "../../../../GlobalComponents/Toast";
import PhoneNumber from "../../../../GlobalComponents/PhoneNumber";
import { customerDetailsErros } from "../Utils";
import { useMsal } from '@azure/msal-react';

const AddCustomer = (props) => {
    // get Location
    const navigate = useNavigate();                                             // to use navigate function

    const phoneNumberValidationMsg = "Phone number should between 8 to 15 digits";

    const [updatedToastMessage, setUpdatedToastMessage] = useState(false);      // To check Toast Message is active
    const [toastMessage, setToastMessage] = useState("");                       // Set Toast Message
    const [processing, setProcessing] = useState(false);                      // Creating Customer processing

    const [expandedAll, setExpandedAll] = useState(false);                      // Expand All Accordions
    const [activeAccordion, setActiveAccordion] = useState(['0']);              // Default Active Accordion Id is "0"

    // Expands all accordions
    const handleExpandAll = () => {
        setExpandedAll(!expandedAll);
        setActiveAccordion(expandedAll ? ['0', '1', '2', '3', '4'] : []);
    };

    // Errors display after validation block level
    const [organisationBlockErrors, setOrganisationBlockErrors] = useState(false);          // Organization Block Errors
    const [addressBlockErrors, setAddressBlockErrors] = useState(false);                    // Address Block Errors
    const [itContactBlockErrors, setItContactBlockErrors] = useState(false);                // Contact Block Errors
    const [businessContactBlockErrors, setBusinessContactBlockErrors] = useState(false);    // BUsiness Contact Block Errors
    const [billToBlockErrors, setBillToBlockErrors] = useState(false);                      // Bill To Block Errors


    // To access input elements with useRef Starts. 

    const addressLine1 = useRef();
    const addressLine2 = useRef();
    const city = useRef();
    const state = useRef();
    const zipcode = useRef();
    const organization = useRef();
    const abnNumber = useRef();
    const acnNumber = useRef();

    const websiteUrl = useRef();

    const itContactDetailsEmail = useRef();
    const itContactDetailsFirstName = useRef();
    const itContactDetailsLastName = useRef();
    const itContactDetailsPhoneNumber = useRef();
    const businessContactDetailsEmail = useRef();
    const businessContactDetailsFirstName = useRef();
    const businessContactDetailsLastName = useRef();
    const businessContactDetailsPhoneNumber = useRef();
    const billToDetailsEmail = useRef();
    const billToDetailsFirstName = useRef();
    const billToDetailsLastName = useRef();
    const billToDetailsPhoneNumber = useRef();

    // To access input elements with useRef Ends.

    // States 
    let ausStates = ["WA", "SA", "ACT", "QLD", "TAS", "NSW", "NT", "VIC"];

    // ****************** Front End Validations Starts ****************** //
    const [organizationErrorFe, setOrganizationErrorFe] = useState(null);
    const [websiteURLErrorFe, setWebsiteURLErrorFe] = useState(null);
    const [abnNumberErrorFe, setAbnNumberErrorFe] = useState(null);
    const [acnNumberErrorFe, setAcnNumberErrorFe] = useState(null);
    const [addressLine1ErrorFe, setAddressLine1ErrorFe] = useState("");
    const [addressLine2ErrorFe, setAddressLine2ErrorFe] = useState("");
    const [cityErrorFe, setCityErrorFe] = useState("");
    const [zipCodeErrorFe, setZipCodeErrorFe] = useState("");
    const [stateErrorFe, setStateErrorFe] = useState("");

    // IT Contact Details Error FE
    const [itContactDetailsFirstNameErrorFe, setITContactDetailsFirstNameErrorFe] = useState("");
    const [itContactDetailsLastNameErrorFe, setITContactDetailsLastNameErrorFe] = useState("");
    const [itContactDetailsEmailErrorFe, setITContactDetailsEmailErrorFe] = useState("");
    const [itContactDetailsPhoneNumberErrorFe, setITContactDetailsPhoneNumberErrorFe] = useState("");

    // Business Contact Details Error FE
    const [businessContactDetailsFirstNameErrorFe, setBusinessContactDetailsFirstNameErrorFe] = useState("");
    const [businessContactDetailsLastNameErrorFe, setBusinessContactDetailsLastNameErrorFe] = useState("");
    const [businessContactDetailsEmailErrorFe, setBusinessContactDetailsEmailErrorFe] = useState("");
    const [businessContactDetailsPhoneNumberErrorFe, setBusinessContactDetailsPhoneNumberErrorFe] = useState("");

    // Bill To Details Error FE
    const [billToDetailsFirstNameErrorFe, setBillToDetailsFirstNameErrorFe] = useState("");
    const [billToDetailsLastNameErrorFe, setBillToDetailsLastNameErrorFe] = useState("");
    const [billToDetailsEmailErrorFe, setBillToDetailsEmailErrorFe] = useState("");
    const [billToDetailsPhoneNumberErrorFe, setBillToDetailsPhoneNumberErrorFe] = useState("");
    // ****************** Front End Validations Ends ****************** //

    // Customer Details All Fields 
    const [customerDetails, setCustomerDetails] = useState({
        "addressLine1": "",
        "addressLine2": "",
        "city": "",
        "state": "",
        "zipcode": "",
        "organization": "",
        "abnNumber": "",
        "acnNumber": "",
        "customerCompanyName": "",
        "customerCode": "",
        "websiteUrl": "",
        "isAcademicApproved": true,
        "isCloudCareEDUApproved": true,
        "isCloudCareGOVTApproved": true,
        "resellerCompanyCode": "",
        "isActive": true,
        "itContactDetails": {
            "email": "",
            "firstName": "",
            "lastName": "",
            "phoneNumber": ""
        },
        "businessContactDetails": {
            "email": "",
            "firstName": "",
            "lastName": "",
            "phoneNumber": ""
        },
        "billToDetails": {
            "email": "",
            "firstName": "",
            "lastName": "",
            "phoneNumber": ""
        },
        "mcaDetails": {
            "mcaEmail": "",
            "mcaFirstName": "",
            "mcaIPAddress": "",
            "mcaLastName": "",
            "mcaPhone": "",
            "mcaAgreementDate": "",
            "mcaDomainFlag": true
        }
    });

    const [initialCustomerDetails, setInitialCustomerDetails] = useState(customerDetails); // Initial Customer Details

    // Reset Customer Details When Click on Reset to initialCustomerDetails
    const resetAddCustomerDetails = () => {

        setActiveAccordion(["0"]);
        setCustomerDetails(initialCustomerDetails);
        navigate('/my-customers');

        // Set Address and Organziation Details to null
        setOrganizationErrorFe(null);
        setWebsiteURLErrorFe(null);
        setAbnNumberErrorFe(null);
        setAcnNumberErrorFe(null);
        setAddressLine1ErrorFe("");
        setAddressLine2ErrorFe("");
        setCityErrorFe("");
        setZipCodeErrorFe("");
        setStateErrorFe("");

        // IT Contact Details Error FE
        setITContactDetailsFirstNameErrorFe("");
        setITContactDetailsLastNameErrorFe("");
        setITContactDetailsEmailErrorFe("");
        setITContactDetailsPhoneNumberErrorFe("");

        // Business Contact Details Error FE
        setBusinessContactDetailsFirstNameErrorFe("");
        setBusinessContactDetailsLastNameErrorFe("");
        setBusinessContactDetailsEmailErrorFe("");
        setBusinessContactDetailsPhoneNumberErrorFe("");

        // Bill To Details Error FE
        setBillToDetailsFirstNameErrorFe("");
        setBillToDetailsLastNameErrorFe("");
        setBillToDetailsEmailErrorFe("");
        setBillToDetailsPhoneNumberErrorFe("");
    }


    // Error Response. Default values are empty
    const [errorObject, setErrorObject] = useState({
        "City": "",
        "Zipcode": "",
        "AbnNumber": "",
        "AcnNumber": "",
        "AddressLine1": "",
        "AddressLine2": "",
        "CustomerCompanyName": "",
        "BillToDetails.Email": "",
        "BillToDetails.LastName": "",
        "BillToDetails.FirstName": "",
        "BillToDetails.PhoneNumber": "",
        "ITContactDetails.Email": "",
        "ITContactDetails.LastName": "",
        "ITContactDetails.FirstName": "",
        "ITContactDetails.PhoneNumber": "",
        "BusinessContactDetails.Email": "",
        "BusinessContactDetails.LastName": "",
        "BusinessContactDetails.FirstName": "",
        "BusinessContactDetails.PhoneNumber": "",
    });

    const { instance, inProgress } = useMsal(); // for logging purpose

    // Logout Function on error 410
    function Logout() {
        props.setCustomerIsAuthenticated(false);
        if (props.customerIsAuthenticated) { navigate("/") };
        localStorage.clear();
        instance.logoutRedirect();
    }


    // Header Authorization for General API's
    const config = {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Check duplicate values in Accordion Array
    const removeAccordion = (arr, myItem) => {
        if (!arr.includes(myItem)) {
            arr.push(myItem);
        }


        return arr;
    }


    // ********************* Front End Validations *********************//
    // Organisation Validation
    function organisationValidation() {
        if (organization.current.value === '') {
            setOrganizationErrorFe(customerDetailsErros.organisationEmpty);
        } else {
            if (organization.current.value.length > 100) {
                setOrganizationErrorFe(customerDetailsErros.organisationMaxChars);
            } else {
                setOrganizationErrorFe("");
                setErrorObject({
                    ...errorObject, "CustomerCompanyName": ""
                })
            }
        }
    }

    // Website URL Validation
    function websiteURLValidation() {
        if (websiteUrl.current.value === '' || websiteURLRegEx.test(websiteUrl.current.value)) {
            setWebsiteURLErrorFe("");
        } else {
            setWebsiteURLErrorFe(customerDetailsErros.websiteURLFormat);
        }
    }

    // ABN Validation   
    function abnValidation(abnNumberLocal) {
        if (abnNumberLocal && abnNumberLocal === '') {
            setAbnNumberErrorFe("The ABN number field is required.");
        } else {
            if (abnNumberLocal && abnNumberLocal.length !== 11) {
                setAbnNumberErrorFe("The ABN number should be 11 digits");
            } else {
                setAbnNumberErrorFe("");
                setErrorObject({
                    ...errorObject, "AbnNumber": ""
                })
            }
        }
    }

    // ACN Validation
    function acnValidation() {
        if (acnNumber.current.value.length !== 0 && acnNumber.current.value.length !== 9) {
            setAcnNumberErrorFe("The ACN number should be 9 digits");
        } else {
            setAcnNumberErrorFe("");
            setErrorObject({
                ...errorObject, "AcnNumber": ""
            })
        }
    }

    // Address Line 1 Validation
    function addressLine1Validation() {
        if (addressLine1.current.value === '') {
            setAddressLine1ErrorFe("Level/unit/street no field is required");
        } else {
            if (addressLine1.current.value.length > 50) {
                setAddressLine1ErrorFe("Level/unit/street no field should not be greater than 50 characters");
            } else {
                setAddressLine1ErrorFe("");
                setErrorObject({
                    ...errorObject, "AddressLine1": ""
                })
            }
        }
    }

    // Address Line 2 Validation
    function addressLine2Validation() {
        if (addressLine2.current.value === '') {
            setAddressLine2ErrorFe("Street name No field is required");
        } else {
            if (addressLine2.current.value.length > 50) {
                setAddressLine2ErrorFe("Street name No field should not be greater than 50 characters");
            } else {
                setAddressLine2ErrorFe("");
                setErrorObject({
                    ...errorObject, "AddressLine2": ""
                })
            }
        }
    }

    // Suburb Validation
    function suburbValidation() {
        if (city.current.value === '') {
            setCityErrorFe("City field is required");
        } else {
            if (city.current.value.length > 50) {
                setCityErrorFe("City field should not be greater than 50 characters");
            } else {
                setCityErrorFe("");
                setErrorObject({
                    ...errorObject, "City": ""
                })
            }
        }
    }

    // Zip Code Validation
    function zipCodeValidation() {
        if (zipcode.current.value === '') {
            setZipCodeErrorFe("The Zipcode field is required.");
        } else {
            if (zipcode.current.value.length !== 4) {
                setZipCodeErrorFe("The Zipcode should be 4 digits");
            } else {
                setZipCodeErrorFe("");
                setErrorObject({
                    ...errorObject, "Zipcode": ""
                })
            }
        }
    }

    // State Validation
    function stateValidation() {
        if (state.current.value === "selectstate") {
            setStateErrorFe("Please select state");
        } else {
            setStateErrorFe("");
        }
    }
    // IT Contact Details First Name
    function itContactDetailsFirstNameValidation() {
        const forbiddenNames = ['test', 'admin', 'nobody', 'administrator', 'testing', 'placeholder'];
        const firstName = itContactDetailsFirstName.current.value.trim().toLowerCase();
        // Check if the forbidden names appear in the first name
        const hasForbiddenName = forbiddenNames.some(name => {
            // Check if the forbidden name is an exact match or appears after a space
            return firstName === name || firstName.includes(name) || firstName.includes(" " + name) || firstName.includes(name + " ");
        });

        if (firstName === '') {
            setITContactDetailsFirstNameErrorFe("First name field is required");
        } else if (firstName.length > 40) {
            setITContactDetailsFirstNameErrorFe("First name field should not be greater than 40 characters");
        } else if (firstName.length < 2) {
            setITContactDetailsFirstNameErrorFe("First name field should be greater than 1 character");
        } else if (hasForbiddenName) {
            setITContactDetailsFirstNameErrorFe("Please enter a valid first name");
        }
        else {
            setITContactDetailsFirstNameErrorFe("");
            setErrorObject({
                ...errorObject,
                "ITContactDetails.FirstName": ""
            });
        }
    }

    // IT Contact Details Last Name
    function itContactDetailsLastNameValidation() {
        const forbiddenNames = ['test', 'admin', 'nobody', 'administrator', 'testing', 'placeholder'];
        const lastName = itContactDetailsLastName.current.value.trim().toLowerCase();
        // Check if the forbidden names appear in the last name
        const hasForbiddenName = forbiddenNames.some(name => {
            // Check if the forbidden name is an exact match or appears after a space
            return lastName === name || lastName.includes(name) || lastName.includes(" " + name) || lastName.includes(name + " ");
        });

        if (lastName === '') {
            setITContactDetailsLastNameErrorFe("Last name field is required");
        } else if (lastName.length > 40) {
            setITContactDetailsLastNameErrorFe("Last name field should not be greater than 40 characters");
        } else if (lastName.length < 2) {
            setITContactDetailsLastNameErrorFe("Last name field should be greater than 1 character");
        } else if (hasForbiddenName) {
            setITContactDetailsLastNameErrorFe("Please enter a valid last name");
        }
        else {
            setITContactDetailsLastNameErrorFe("");
            setErrorObject({
                ...errorObject, "ITContactDetails.LastName": ""
            })
        }
    }


    // IT Contact Details EMail
    function itContactDetailsEmailValidation() {
        if (itContactDetailsEmail.current.value === '') {
            setITContactDetailsEmailErrorFe("Email is required");
        } else {
            if (emailRegEx.test(itContactDetailsEmail.current.value)) {
                setITContactDetailsEmailErrorFe("");
                setErrorObject({
                    ...errorObject, "ITContactDetails.Email": ""
                })
            } else {
                setITContactDetailsEmailErrorFe("Email should be in required format");
            }
        }
    }

    // IT Contact Details Phone Number
    function itContactDetailsPhoneNumberValidation() {
        if (itContactDetailsPhoneNumber.current.value === '') {
            setITContactDetailsPhoneNumberErrorFe("Phone number is required.");
        } else {
            if (itContactDetailsPhoneNumber.current.value.length < 8 || itContactDetailsPhoneNumber.current.value.length > 15) {
                setITContactDetailsPhoneNumberErrorFe(phoneNumberValidationMsg);
            } else {
                setITContactDetailsPhoneNumberErrorFe("");
                setErrorObject({
                    ...errorObject, "ITContactDetails.PhoneNumber": ""
                })
            }
        }
    }

    // Business Contact Details First Name
    function businessContactDetailsFirstNameValidation() {
        const forbiddenNames = ['test', 'admin', 'nobody', 'administrator', 'testing', 'placeholder'];
        const firstName = businessContactDetailsFirstName.current.value.trim().toLowerCase();
        // Check if the forbidden names appear in the first name
        const hasForbiddenName = forbiddenNames.some(name => {
            // Check if the forbidden name is an exact match or appears after a space
            return firstName === name || firstName.includes(name) || firstName.includes(" " + name) || firstName.includes(name + " ");
        });

        if (firstName === '') {
            setBusinessContactDetailsFirstNameErrorFe("First name field is required");
        } else if (firstName.length > 40) {
            setBusinessContactDetailsFirstNameErrorFe("First name field should not be greater than 40 characters");
        } else if (firstName.length < 2) {
            setBusinessContactDetailsFirstNameErrorFe("First name field should be greater than 1 character");
        } else if (hasForbiddenName) {
            setBusinessContactDetailsFirstNameErrorFe("Please enter a valid first name");
        }
        else {
            setBusinessContactDetailsFirstNameErrorFe("");
            setErrorObject({
                ...errorObject, "BusinessContactDetails.FirstName": ""
            })
        }
    }

    // Business Contact Details Last Name
    function businessContactDetailsLastNameValidation() {
        const forbiddenNames = ['test', 'admin', 'nobody', 'administrator', 'testing', 'placeholder'];
        const lastName = businessContactDetailsLastName.current.value.trim().toLowerCase();
        // Check if the forbidden names appear in the last name
        const hasForbiddenName = forbiddenNames.some(name => {
            // Check if the forbidden name is an exact match or appears after a space
            return lastName === name || lastName.includes(name) || lastName.includes(" " + name) || lastName.includes(name + " ");
        });

        if (lastName === '') {
            setBusinessContactDetailsLastNameErrorFe("Last name field is required");
        } else if (lastName.length > 40) {
            setBusinessContactDetailsLastNameErrorFe("Last name field should not be greater than 40 characters");
        } else if (lastName.length < 2) {
            setBusinessContactDetailsLastNameErrorFe("Last name field should be greater than 1 character");
        } else if (hasForbiddenName) {
            setBusinessContactDetailsLastNameErrorFe("Please enter a valid last name");
        }
        else {
            setBusinessContactDetailsLastNameErrorFe("");
            setErrorObject({
                ...errorObject, "BusinessContactDetails.LastName": ""
            })
        }
    }

    // Business Contact Details Email
    function businessContactDetailsEmailValidation() {
        if (businessContactDetailsEmail.current.value === '') {
            setBusinessContactDetailsEmailErrorFe("Email is required");
        } else {
            if (emailRegEx.test(businessContactDetailsEmail.current.value)) {
                setBusinessContactDetailsEmailErrorFe("");
                setErrorObject({
                    ...errorObject, "BusinessContactDetails.Email": ""
                })
            } else {
                setBusinessContactDetailsEmailErrorFe("Email should be in required format");
            }
        }
    }

    // Business Contact Details Phone Number
    function businessContactDetailsPhoneNumberValidation() {
        if (businessContactDetailsPhoneNumber.current.value === '') {
            setBusinessContactDetailsPhoneNumberErrorFe("Phone number is required.");
        } else {
            if (businessContactDetailsPhoneNumber.current.value.length < 8 || businessContactDetailsPhoneNumber.current.value.length > 15) {
                setBusinessContactDetailsPhoneNumberErrorFe(phoneNumberValidationMsg);
            } else {
                setBusinessContactDetailsPhoneNumberErrorFe("");
                setErrorObject({
                    ...errorObject, "BusinessContactDetails.PhoneNumber": ""
                })
            }
        }
    }

    // Bill To Details First Name
    function billToDetailsFirstNameValidation() {
        const forbiddenNames = ['test', 'admin', 'nobody', 'administrator', 'testing', 'placeholder'];
        const firstName = billToDetailsFirstName.current.value.trim().toLowerCase();
        // Check if the forbidden names appear in the first name
        const hasForbiddenName = forbiddenNames.some(name => {
            // Check if the forbidden name is an exact match or appears after a space
            return firstName === name || firstName.includes(name) || firstName.includes(" " + name) || firstName.includes(name + " ");
        });

        if (firstName === '') {
            setBillToDetailsFirstNameErrorFe("First name field is required");
        } else if (firstName.length > 40) {
            setBillToDetailsFirstNameErrorFe("First name field should not be greater than 40 characters");
        } else if (firstName.length < 2) {
            setBillToDetailsFirstNameErrorFe("First name field should be greater than 1 character");
        } else if (hasForbiddenName) {
            setBillToDetailsFirstNameErrorFe("Please enter a valid first name");
        }
        else {
            setBillToDetailsFirstNameErrorFe("");
            setErrorObject({
                ...errorObject, "BillToDetails.FirstName": ""
            })
        }
    }

    // Bill To Details Last Name
    function billToDetailsLastNameValidation() {
        const forbiddenNames = ['test', 'admin', 'nobody', 'administrator', 'testing', 'placeholder'];
        const lastName = billToDetailsLastName.current.value.trim().toLowerCase();
        // Check if the forbidden names appear in the last name
        const hasForbiddenName = forbiddenNames.some(name => {
            // Check if the forbidden name is an exact match or appears after a space
            return lastName === name || lastName.includes(name) || lastName.includes(" " + name) || lastName.includes(name + " ");
        });

        if (lastName === '') {
            setBillToDetailsLastNameErrorFe("Last name field is required");
        } else if (lastName.length > 40) {
            setBillToDetailsLastNameErrorFe("Last name field should not be greater than 40 characters");
        } else if (lastName.length < 2) {
            setBillToDetailsLastNameErrorFe("Last name field should be greater than 1 character");
        } else if (hasForbiddenName) {
            setBillToDetailsLastNameErrorFe("Please enter a valid last name");
        } else {
            setBillToDetailsLastNameErrorFe("");
            setErrorObject({
                ...errorObject, "BillToDetails.LastName": ""
            })
        }
    }

    // Bill To Details Email
    function billToDetailsEmailValidation() {
        if (billToDetailsEmail.current.value === '') {
            setBillToDetailsEmailErrorFe("Email is required");
        } else {
            if (emailRegEx.test(billToDetailsEmail.current.value)) {
                setBillToDetailsEmailErrorFe("");
                setErrorObject({
                    ...errorObject, "BillToDetails.Email": ""
                })
            } else {
                setBillToDetailsEmailErrorFe("Email should be in required format");
            }
        }
    }

    // Bill To Details Phone Number
    function billToDetailsPhoneNumberValidation() {
        if (billToDetailsPhoneNumber.current.value === '') {
            setBillToDetailsPhoneNumberErrorFe("Phone number is required.");
        } else {
            if (billToDetailsPhoneNumber.current.value.length < 8 || billToDetailsPhoneNumber.current.value.length > 15) {
                setBillToDetailsPhoneNumberErrorFe(phoneNumberValidationMsg);
            } else {
                setBillToDetailsPhoneNumberErrorFe("");
                setErrorObject({
                    ...errorObject, "BillToDetails.PhoneNumber": ""
                })
            }
        }
    }
    // ********************* Front End Validations *********************//


    // Validation Errors. Call from "Front End Validations"
    const validateFrontEndErrors = () => {
        organisationValidation();
        websiteURLValidation();
        abnValidation();
        acnValidation();
        addressLine1Validation();
        addressLine2Validation();
        suburbValidation();
        zipCodeValidation();
        stateValidation();
        itContactDetailsFirstNameValidation();
        itContactDetailsLastNameValidation();
        itContactDetailsEmailValidation();
        itContactDetailsPhoneNumberValidation();
        businessContactDetailsFirstNameValidation();
        businessContactDetailsLastNameValidation();
        businessContactDetailsEmailValidation();
        businessContactDetailsPhoneNumberValidation();
        billToDetailsFirstNameValidation();
        billToDetailsLastNameValidation();
        billToDetailsEmailValidation();
        billToDetailsPhoneNumberValidation();
    }

    // Get Updated Organization Value
    function getOrganisationValue() {
        setCustomerDetails({
            ...customerDetails,
            organization: organization.current.value.replace(/\s+/g, ' '),
            customerCompanyName: organization.current.value.replace(/\s+/g, ' ')
        });
        organisationValidation();
    }

    // Get Updated Website URL
    function getWebsiteURLValue() {
        setCustomerDetails({ ...customerDetails, websiteUrl: websiteUrl.current.value });
        websiteURLValidation();
    }

    // Get Updated ABN Value
    function getABNValue(e) {
        let abnNumberLocal = ((e.target.value).replaceAll(" ","")).trim();
        if(numberRegEx.test(abnNumberLocal) && abnNumberLocal.length<=11 ){
            setCustomerDetails({ ...customerDetails, abnNumber: abnNumberLocal })
            abnValidation(abnNumberLocal)
        }
        else{

        }
        
    }

    // Get Updated ACN Value
    function getACNValue() {
        setCustomerDetails({ ...customerDetails, acnNumber: (numberRegEx.test(acnNumber.current.value.trim()) ? acnNumber.current.value.trimStart() : "") })
        acnValidation()
    }

    // useEffect to check Organisation Block value changes
    useEffect(() => {
        if (
            (organizationErrorFe === null || organizationErrorFe === "") &&
            (websiteURLErrorFe == null || websiteURLErrorFe == "") &&
            (abnNumberErrorFe == null || abnNumberErrorFe == "") &&
            (acnNumberErrorFe == null || acnNumberErrorFe == "")
        ) {
            setOrganisationBlockErrors(false)
        } else {
            setOrganisationBlockErrors(true)
        }
    }, [organizationErrorFe, websiteURLErrorFe, abnNumberErrorFe, acnNumberErrorFe])


    // Get Updated Address Details Block

    // Get Updated Address Details Line1 
    function getAddressLine1Value() {
        setCustomerDetails({ ...customerDetails, addressLine1: addressLine1.current.value.replace(/\s+/g, ' ') })
        addressLine1Validation();
    }

    // Get Updated Address Details Line2 
    function getAddressLine2Value() {
        setCustomerDetails({ ...customerDetails, addressLine2: addressLine2.current.value.replace(/\s+/g, ' ') })
        addressLine2Validation();
    }

    // Get Updated SubUrb
    function getSuburbValue() {
        setCustomerDetails({ ...customerDetails, city: city.current.value })
        suburbValidation();
    }

    // Get Updated Zip Code
    function getZipCodeValue() {
        setCustomerDetails({ ...customerDetails, zipcode: (numberRegEx.test(zipcode.current.value) ? zipcode.current.value : customerDetails.zipcode) })
        zipCodeValidation();
    }

    // Get Updated State
    function getStateValue() {
        setCustomerDetails({ ...customerDetails, state: state.current.value })
        stateValidation();
    }

    // useEffect to check Address Block value changes
    useEffect(() => {
        if (addressLine1ErrorFe !== "" || addressLine2ErrorFe !== "" || cityErrorFe !== "" || zipCodeErrorFe !== "" || stateErrorFe !== "") {
            setAddressBlockErrors(true)
        } else {
            setAddressBlockErrors(false)
        }
    }, [addressLine1ErrorFe, addressLine2ErrorFe, cityErrorFe, zipCodeErrorFe, stateErrorFe])


    // IT Contact Details Block

    // Get Updated IT Contact Details First Name
    function getITContactDetailsFirstNameValue(e) {
        setCustomerDetails((prevState) => ({
            ...customerDetails, itContactDetails: {
                ...prevState.itContactDetails,
                firstName: itContactDetailsFirstName.current.value
            }
        }))
        itContactDetailsFirstNameValidation();
    }

    // Get Updated IT Contact Details Last Name
    function getITContactDetailsLastNameValue() {
        setCustomerDetails((prevState) => ({
            ...customerDetails, itContactDetails: {
                ...prevState.itContactDetails,
                lastName: itContactDetailsLastName.current.value
            }
        }))
        itContactDetailsLastNameValidation();
    }

    // Get Updated IT Contact Details Email
    function getITContactDetailsEmailValue() {
        setCustomerDetails((prevState) => ({
            ...customerDetails, itContactDetails: {
                ...prevState.itContactDetails,
                email: itContactDetailsEmail.current.value
            }
        }))
        itContactDetailsEmailValidation();
    }

    // Get Updated IT Contact Details Phone Number
    function getITContactDetailsPhoneNumberValue() {
        setCustomerDetails((prevState) => ({
            ...customerDetails, itContactDetails: {
                ...prevState.itContactDetails,
                phoneNumber: (phoneOnChangeRegex.test(itContactDetailsPhoneNumber.current.value) ? itContactDetailsPhoneNumber.current.value : "")
            }
        }))
        itContactDetailsPhoneNumberValidation()
    }

    // useEffect to check IT Contact Details Block value changes
    useEffect(() => {
        if (itContactDetailsFirstNameErrorFe !== "" || itContactDetailsLastNameErrorFe !== "" || itContactDetailsEmailErrorFe !== "" || itContactDetailsPhoneNumberErrorFe !== "") {
            setItContactBlockErrors(true)
        } else {
            setItContactBlockErrors(false)
        }
    }, [itContactDetailsFirstNameErrorFe, itContactDetailsLastNameErrorFe, itContactDetailsEmailErrorFe, itContactDetailsPhoneNumberErrorFe])

    // Business Contact Details

    // Get Updated Business Contact Details First Name
    function getBusinessContactDetailsFirstNameValue() {
        setCustomerDetails((prevState) => ({
            ...customerDetails, businessContactDetails: {
                ...prevState.businessContactDetails,
                firstName: businessContactDetailsFirstName.current.value
            }
        }))
        businessContactDetailsFirstNameValidation();
    }

    // Get Updated Business Contact Details Last Name
    function getBusinessContactDetailsLastNameValue() {
        setCustomerDetails((prevState) => ({
            ...customerDetails, businessContactDetails: {
                ...prevState.businessContactDetails,
                lastName: businessContactDetailsLastName.current.value
            }
        }))
        businessContactDetailsLastNameValidation();
    }

    // Get Updated Business Contact Details Email
    function getBusinessContactDetailsEmailValue() {
        setCustomerDetails((prevState) => ({
            ...customerDetails, businessContactDetails: {
                ...prevState.businessContactDetails,
                email: businessContactDetailsEmail.current.value,
            }
        }))
        businessContactDetailsEmailValidation();
    }

    // Get Updated Business Contact Details Phone Number
    function getBusinessContactDetailsPhoneNumberValue() {
        setCustomerDetails((prevState) => ({
            ...customerDetails, businessContactDetails: {
                ...prevState.businessContactDetails,
                phoneNumber: (phoneOnChangeRegex.test(businessContactDetailsPhoneNumber.current.value) ? businessContactDetailsPhoneNumber.current.value : "")
            }
        }))

        businessContactDetailsPhoneNumberValidation()
    }

    // useEffect to check  Business Contact Details Block value changes
    useEffect(() => {
        if (businessContactDetailsFirstNameErrorFe !== "" || businessContactDetailsLastNameErrorFe !== "" || businessContactDetailsEmailErrorFe !== "" || businessContactDetailsPhoneNumberErrorFe !== "") {
            setBusinessContactBlockErrors(true)
        } else {
            setBusinessContactBlockErrors(false)
        }
    }, [businessContactDetailsFirstNameErrorFe, businessContactDetailsLastNameErrorFe, businessContactDetailsEmailErrorFe, businessContactDetailsPhoneNumberErrorFe])

    // Bill To Details

    // Get Updated Business Contact Details First Name
    function getBillToDetailsFirstNameValue() {
        setCustomerDetails((prevState) => ({
            ...customerDetails, billToDetails: {
                ...prevState.billToDetails,
                firstName: billToDetailsFirstName.current.value
            }
        }))
        billToDetailsFirstNameValidation();
    }

    // Get Updated Business Contact Details Last Name
    function getBillToDetailsLastNameValue() {
        setCustomerDetails((prevState) => ({
            ...customerDetails, billToDetails: {
                ...prevState.billToDetails,
                lastName: billToDetailsLastName.current.value
            }
        }))
        billToDetailsLastNameValidation();
    }

    // Get Updated Business Contact Details Email
    function getBillToDetailsEmailValue() {
        setCustomerDetails((prevState) => ({
            ...customerDetails, billToDetails: {
                ...prevState.billToDetails,
                email: billToDetailsEmail.current.value,
            }
        }))
        billToDetailsEmailValidation();
    }

    // Get Updated Business Contact Details Phone Number
    function getBillToDetailsPhoneNumberValue() {
        setCustomerDetails((prevState) => ({
            ...customerDetails, billToDetails: {
                ...prevState.billToDetails,
                phoneNumber: (phoneOnChangeRegex.test(billToDetailsPhoneNumber.current.value) ? billToDetailsPhoneNumber.current.value : "")
            }
        }))
        billToDetailsPhoneNumberValidation()
    }

    // useEffect to check Bill To Details Block value changes 
    useEffect(() => {
        if (billToDetailsFirstNameErrorFe !== "" || billToDetailsLastNameErrorFe !== "" || billToDetailsEmailErrorFe !== "" || billToDetailsPhoneNumberErrorFe !== "") {
            setBillToBlockErrors(true)
        } else {
            setBillToBlockErrors(false)
        }
    }, [billToDetailsFirstNameErrorFe, billToDetailsLastNameErrorFe, billToDetailsEmailErrorFe, billToDetailsPhoneNumberErrorFe])


    // Block Level Validations

    const blockLevelErrorValidations = () => {
        // Block Level Errors

        // ****************** Organization Block ****************** //
        if (
            (organization.current.value === "" || organization.current.value.length > 100) ||
            (websiteUrl.current.value !== "" && !websiteURLRegEx.test(websiteUrl.current.value)) ||
            (abnNumber.current.value === "" || abnNumber.current.value.length !== 11) ||
            (errorObject.AcnNumber || (acnNumber.current.value !== "" && acnNumber.current.value.length !== 9))
        ) {
            setActiveAccordion(removeAccordion(activeAccordion, '0'));
            setOrganisationBlockErrors(true);
            setExpandedAll(false);
        } else {

            setOrganisationBlockErrors(false);
        }

        // ****************** Address Block ****************** //
        if
            (
            (addressLine1.current.value === "" || addressLine1.current.value.length > 50) ||
            (addressLine2.current.value === "" || addressLine2.current.value.length > 50) ||
            (city.current.value === "" || city.current.value.length > 50) ||
            (zipcode.current.value === "" || zipcode.current.value.length !== 4 || !numberRegEx.test(zipcode.current.value)) ||
            (state.current.value === "selectstate")
        ) {
            setActiveAccordion(removeAccordion(activeAccordion, '1'));
            setAddressBlockErrors(true);
            setExpandedAll(false);
        } else {

            setAddressBlockErrors(false);
        }

        // ****************** IT Contact Details Block ****************** //
        if (
            (itContactDetailsFirstName.current.value === "" || itContactDetailsFirstName.current.value.length > 50) ||
            (itContactDetailsLastName.current.value === "" || itContactDetailsLastName.current.value.length > 50) ||
            (itContactDetailsEmail.current.value === "" || !emailRegEx.test(itContactDetailsEmail.current.value)) ||
            (itContactDetailsPhoneNumber.current.value === "" || !phoneRegex.test(itContactDetailsPhoneNumber.current.value) || (itContactDetailsPhoneNumber.current.value.length < 8 || itContactDetailsPhoneNumber.current.value.length > 15)
            )
        ) {
            setActiveAccordion(removeAccordion(activeAccordion, '2'));
            setItContactBlockErrors(true);
            setExpandedAll(false);
        } else {

            setItContactBlockErrors(false);
        }


        // ****************** Business Contact Details Block ****************** //
        if (
            (businessContactDetailsFirstName.current.value === "" || businessContactDetailsFirstName.current.value.length > 50) ||
            (businessContactDetailsLastName.current.value === "" || businessContactDetailsLastName.current.value.length > 50) ||
            (businessContactDetailsEmail.current.value === "" || !emailRegEx.test(businessContactDetailsEmail.current.value)) ||
            (businessContactDetailsPhoneNumber.current.value === "" || !phoneRegex.test(businessContactDetailsPhoneNumber.current.value) || (businessContactDetailsPhoneNumber.current.value.length < 8 || businessContactDetailsPhoneNumber.current.value.length > 15)
            )
        ) {
            setActiveAccordion(removeAccordion(activeAccordion, '3'));
            setBusinessContactBlockErrors(true);
            setExpandedAll(false);
        } else {

            setBusinessContactBlockErrors(false);
        }

        // ****************** Bill To Details Block ****************** //
        if (
            (billToDetailsFirstName.current.value === "" || billToDetailsFirstName.current.value.length > 50) ||
            (billToDetailsLastName.current.value === "" || billToDetailsLastName.current.value.length > 50) ||
            (billToDetailsEmail.current.value === "" || !emailRegEx.test(billToDetailsEmail.current.value)) ||
            (billToDetailsPhoneNumber.current.value === "" || !phoneRegex.test(billToDetailsPhoneNumber.current.value) || (billToDetailsPhoneNumber.current.value.length < 8 || billToDetailsPhoneNumber.current.value.length > 15)
            )
        ) {
            setActiveAccordion(removeAccordion(activeAccordion, '4'));
            setBillToBlockErrors(true);
            setExpandedAll(false);
        } else {

            setBillToBlockErrors(false);
        }

        return activeAccordion;
    }

    // Function to Add Customer
    async function createCustomer() {
        validateFrontEndErrors();
        blockLevelErrorValidations();

        setProcessing(true);

        if (
            organisationBlockErrors == false &&
            addressBlockErrors == false &&
            itContactBlockErrors == false &&
            businessContactBlockErrors == false &&
            billToBlockErrors == false
        ) {

            await api.post(CUSTOMER_LISTING_TABLE, customerDetails, config)
                .then(resp => {
                    if (resp.status == 200) {
                        setToastMessage("Customer created successfully.");
                        setUpdatedToastMessage(true);
                        setTimeout(() => {
                            setUpdatedToastMessage(false);

                            navigate("/my-customers");
                        }, 2000);
                    }
                })
                .catch(error => {

                    if (error?.status == 401 || error?.response.status == 401) {
                        Logout();
                    }
                    if (error.response.data.errors) {
                        setErrorObject(error.response.data.errors);

                    }

                    if (error.response.data.errors) {
                        if (error.response.data.errors.AbnNumber ||
                            error.response.data.errors.CustomerCompanyName ||
                            error.response.data.errors.AcnNumber
                        ) {
                            setOrganisationBlockErrors(true);
                            setActiveAccordion(removeAccordion(activeAccordion, '0'));
                            return activeAccordion;
                        } else {
                            setActiveAccordion(activeAccordion.splice(activeAccordion.indexOf("0")));
                            setOrganisationBlockErrors(false);
                        }

                        if (error.response.data.errors.AddressLine1 ||
                            error.response.data.errors.AddressLine2 ||
                            error.response.data.errors.city ||
                            error.response.data.errors.Zipcode ||
                            error.response.data.errors.state
                        ) {
                            setAddressBlockErrors(true);
                            setActiveAccordion(removeAccordion(activeAccordion, '1'));

                        } else {
                            setActiveAccordion(activeAccordion.splice(activeAccordion.indexOf('1')));
                            setAddressBlockErrors(false);
                        }

                        if (error.response.data.errors["ITContactDetails.FirstName"] ||
                            error.response.data.errors["ITContactDetails.LastName"] ||
                            error.response.data.errors["ITContactDetails.Email"] ||
                            error.response.data.errors["ITContactDetails.PhoneNumber"]
                        ) {
                            setActiveAccordion(removeAccordion(activeAccordion, '2'));
                            setItContactBlockErrors(true);
                        } else {
                            setActiveAccordion(activeAccordion.splice(activeAccordion.indexOf("2")));
                            setItContactBlockErrors(false);
                        }

                        if (error.response.data.errors["BusinessContactDetails.FirstName"] ||
                            error.response.data.errors["BusinessContactDetails.LastName"] ||
                            error.response.data.errors["BusinessContactDetails.Email"] ||
                            error.response.data.errors["BusinessContactDetails.PhoneNumber"]
                        ) {
                            setActiveAccordion(removeAccordion(activeAccordion, '3'));
                            setBusinessContactBlockErrors(true);
                        } else {
                            setActiveAccordion(activeAccordion.splice(activeAccordion.indexOf("3")));
                            setBusinessContactBlockErrors(false);
                        }

                        if (error.response.data.errors["BillToDetails.FirstName"] ||
                            error.response.data.errors["BillToDetails.LastName"] ||
                            error.response.data.errors["BillToDetails.Email"] ||
                            error.response.data.errors["BillToDetails.PhoneNumber"]
                        ) {
                            setActiveAccordion(removeAccordion(activeAccordion, '4'));
                            setBillToBlockErrors(true);
                        } else {
                            setActiveAccordion(activeAccordion.splice(activeAccordion.indexOf("4")));
                            setBillToBlockErrors(false);
                        }
                    }


                });
        }

        setProcessing(false);
    }

    useEffect(() => {
        if (activeAccordion.length == 5) {
            setExpandedAll(false)
        } else if (activeAccordion.length != 5) {
            setExpandedAll(true)
        }
    }, [activeAccordion, expandedAll])


    return (
        <>

            <Toast toastCheckUseState={updatedToastMessage} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <div className="main-content">
                <Container fluid>
                    <Row>
                        <Col className="justify-content-between d-flex align-items-center">
                            <label className="cpointer" onClick={() => navigate('/my-customers')}>
                                <span className="back">&nbsp;</span>&nbsp;<strong>Create customer</strong>
                            </label>
                            <div>
                                <Button
                                    className="px-4 mr-3 btn-xl btn-border-light expand-collapse-button"
                                    variant="light"
                                    onClick={handleExpandAll}>
                                    {expandedAll ? "Expand All" : "Collapse All"}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div className="accordion-panel">
                        <Form>
                            <Accordion
                                activeKey={activeAccordion}
                                onSelect={(e) => { setActiveAccordion(e); setExpandedAll(false) }}
                                alwaysOpen>
                                <Accordion.Item className={`mt-5 ${organisationBlockErrors ? "accordionErrors" : ""}`} eventKey="0">
                                    <Accordion.Header>Organization Details</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col lg={8} md={12}>
                                                <Form.Group className="mb-3" controlId="formOrganaisation">
                                                    <Form.Label>Organization <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetails.organization}
                                                        // placeholder="Enter Organisation"
                                                        maxlength="110"
                                                        ref={organization}
                                                        onChange={getOrganisationValue}
                                                    />
                                                    {!organizationErrorFe && <>{errorObject.CustomerCompanyName && <span className="red text-small">
                                                        {errorObject.CustomerCompanyName}</span>}</>}
                                                    <span className="red text-small">{organizationErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}></Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formWebsite">
                                                    <Form.Label>Website url</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetails.websiteUrl}
                                                        // placeholder="Enter Website URL"
                                                        ref={websiteUrl}
                                                        onChange={getWebsiteURLValue}
                                                    />
                                                    <span className="red text-small">{websiteURLErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={2} md={3}>
                                                <Form.Group className="mb-3" controlId="formABNNumber">
                                                    <Form.Label>ABN number<span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetails.abnNumber}
                                                        // placeholder="Enter ABN Number"
                                                        ref={abnNumber}
                                                        // maxlength="11"
                                                        onChange={(e)=>getABNValue(e)}
                                                    />
                                                    {!abnNumberErrorFe && <>
                                                        {errorObject.AbnNumber && <span className="red text-small">{errorObject.AbnNumber}</span>}</>}
                                                    <span className="red text-small">{abnNumberErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={2} md={3}>
                                                <Form.Group className="mb-3" controlId="formACNNumber">
                                                    <Form.Label>ACN number</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetails.acnNumber}
                                                        // placeholder="Enter ACN Number"
                                                        ref={acnNumber}
                                                        maxlength="9"
                                                        onChange={getACNValue}
                                                    />
                                                    {errorObject.AcnNumber && <span className="red text-small">{errorObject.AcnNumber}</span>}
                                                    <span className="red text-small">{acnNumberErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {
                                    organisationBlockErrors && <p className="red text-small mt-2">
                                        Organization details section is not complete. Please fill all the details to proceed.
                                    </p>
                                }

                                <Accordion.Item className={`mt-5 ${addressBlockErrors ? "accordionErrors" : ""}`} eventKey="1">
                                    <Accordion.Header>Address Details</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formLevelNumber">
                                                    <Form.Label>Level/unit/street no <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetails.addressLine1}
                                                        // placeholder="Enter Level/Unit/Street No"
                                                        ref={addressLine1}
                                                        maxlength="50"
                                                        onChange={getAddressLine1Value}
                                                    />
                                                    {!addressLine1ErrorFe && <>{!addressLine1ErrorFe && <>{errorObject.AddressLine1 && <span className="red text-small">{errorObject.AddressLine1}</span>}</>}</>}
                                                    <span className="red text-small">{addressLine1ErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formStreetNumber">
                                                    <Form.Label>Street name <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetails.addressLine2}
                                                        // placeholder="Enter Street Name"
                                                        ref={addressLine2}
                                                        maxlength="50"
                                                        onChange={getAddressLine2Value}
                                                    />
                                                    {!addressLine2ErrorFe && <>{errorObject.AddressLine2 && <span className="red text-small">{errorObject.AddressLine2}</span>}</>}
                                                    <span className="red text-small">{addressLine2ErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}></Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formSuburb">
                                                    <Form.Label>Suburb <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetails.city}
                                                        // placeholder="Enter Suburb"
                                                        ref={city}
                                                        maxlength="50"
                                                        onChange={getSuburbValue}
                                                    />
                                                    {!cityErrorFe && <>{errorObject.City && <span className="red text-small">{errorObject.City}</span>}</>}
                                                    <span className="red text-small">{cityErrorFe}</span>
                                                </Form.Group>
                                            </Col>

                                            <Col lg={2} md={3}>
                                                <Form.Group className="mb-3" controlId="formPostalCode">
                                                    <Form.Label>Postal code <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetails.zipcode}
                                                        // placeholder="Enter Postal Code"
                                                        ref={zipcode}
                                                        maxlength="4"
                                                        onChange={getZipCodeValue}
                                                    />
                                                    {!zipCodeErrorFe && <>{errorObject.Zipcode && <span className="red text-small">{errorObject.Zipcode}</span>}</>}
                                                    <span className="red text-small">{zipCodeErrorFe}</span>
                                                </Form.Group>
                                            </Col>

                                            <Col lg={2} md={3}>
                                                <Form.Group className="mb-3" controlId="formState">
                                                    <Form.Label>State <span className="red">*</span></Form.Label>
                                                    <Form.Select
                                                        ref={state}
                                                        onChange={getStateValue}
                                                    >
                                                        <option value="selectstate">Select state</option>
                                                        {
                                                            ausStates.map(state => {
                                                                return (
                                                                    <option
                                                                        key={state}
                                                                        id={state}
                                                                        value={state}
                                                                        selected={state === customerDetails.state ? true : false}
                                                                    >
                                                                        {state}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                    {!stateErrorFe && <>{errorObject.state && <span className="red text-small">{errorObject.state}</span>}</>}
                                                    <span className="red text-small">{stateErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {
                                    addressBlockErrors && <p className="red text-small mt-2">
                                        Address details section is not complete. Please fill all the details to proceed.
                                    </p>
                                }

                                <Accordion.Item eventKey="2" className={`mt-5 ${itContactBlockErrors ? "accordionErrors" : ""}`}>
                                    <Accordion.Header>IT Contact Details</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formITFirstName">
                                                    <Form.Label>First name <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetails.itContactDetails.firstName}
                                                        // placeholder="Enter First Name"
                                                        ref={itContactDetailsFirstName}
                                                        maxlength="40"
                                                        onChange={getITContactDetailsFirstNameValue}
                                                        autoComplete="off"
                                                    />
                                                    {!itContactDetailsFirstNameErrorFe && <>{errorObject["ITContactDetails.FirstName"] && <span className="red text-small">{errorObject["ITContactDetails.FirstName"]}</span>}</>}
                                                    <span className="red text-small">{itContactDetailsFirstNameErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formITLastName">
                                                    <Form.Label>Last name <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetails.itContactDetails.lastName}
                                                        // placeholder="Enter Last Name"
                                                        ref={itContactDetailsLastName}
                                                        maxlength="40"
                                                        onChange={getITContactDetailsLastNameValue}
                                                    />
                                                    {!itContactDetailsLastNameErrorFe && <>{errorObject["ITContactDetails.LastName"] && <span className="red text-small">{errorObject["ITContactDetails.LastName"]}</span>}</>}
                                                    <span className="red text-small">{itContactDetailsLastNameErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}></Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formITEmail">
                                                    <Form.Label>Email <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetails.itContactDetails.email}
                                                        // placeholder="Enter Email"
                                                        ref={itContactDetailsEmail}
                                                        maxlength="50"
                                                        onChange={getITContactDetailsEmailValue}
                                                    />
                                                    {!itContactDetailsLastNameErrorFe && <>{errorObject["ITContactDetails.Email"] && <span className="red text-small">{errorObject["ITContactDetails.Email"]}</span>}</>}
                                                    <span className="red text-small">{itContactDetailsEmailErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <PhoneNumber
                                                    phoneNumber={customerDetails.itContactDetails.phoneNumber}
                                                    // placeholder="Enter Phone Number"
                                                    ref={itContactDetailsPhoneNumber}
                                                    updatePhoneNumber={getITContactDetailsPhoneNumberValue}
                                                    errorMessage={!itContactDetailsPhoneNumberErrorFe && errorObject["ITContactDetails.PhoneNumber"]}
                                                    errorMessageFE={itContactDetailsPhoneNumberErrorFe}
                                                ></PhoneNumber>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {
                                    itContactBlockErrors && <p className="red text-small mt-2">
                                        IT contact details section is not complete. Please fill all the details to proceed.
                                    </p>
                                }

                                <Accordion.Item eventKey="3" className={`mt-5 ${businessContactBlockErrors ? "accordionErrors" : ""}`}>
                                    <Accordion.Header>Business Contact Details</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formBusinessFirstName">
                                                    <Form.Label>First name <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetails.businessContactDetails.firstName}
                                                        // placeholder="Enter First Name"
                                                        ref={businessContactDetailsFirstName}
                                                        maxlength="40"
                                                        onChange={getBusinessContactDetailsFirstNameValue}
                                                    />
                                                    {!businessContactDetailsFirstNameErrorFe && <> {errorObject["BusinessContactDetails.FirstName"] && <span className="red text-small">{errorObject["BusinessContactDetails.FirstName"]}</span>}</>}
                                                    <span className="red text-small">{businessContactDetailsFirstNameErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formBusinessLastName">
                                                    <Form.Label>Last name <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetails.businessContactDetails.lastName}
                                                        // placeholder="Enter Last Name"
                                                        ref={businessContactDetailsLastName}
                                                        maxlength="40"
                                                        onChange={getBusinessContactDetailsLastNameValue}
                                                    />
                                                    {!businessContactDetailsLastNameErrorFe && <>{errorObject["BusinessContactDetails.LastName"] && <span className="red text-small">{errorObject["BusinessContactDetails.LastName"]}</span>}</>}
                                                    <span className="red text-small">{businessContactDetailsLastNameErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}></Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formBusinessEmail">
                                                    <Form.Label>Email <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetails.businessContactDetails.email}
                                                        // placeholder="Enter Email"
                                                        ref={businessContactDetailsEmail}
                                                        maxlength="50"
                                                        onChange={getBusinessContactDetailsEmailValue}
                                                    />
                                                    {!businessContactDetailsEmailErrorFe && <>{errorObject["BusinessContactDetails.Email"] && <span className="red text-small">{errorObject["BusinessContactDetails.Email"]}</span>}</>}
                                                    <span className="red text-small">{businessContactDetailsEmailErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <PhoneNumber
                                                    phoneNumber={customerDetails.businessContactDetails.phoneNumber}
                                                    // placeholder="Enter Phone Number"
                                                    ref={businessContactDetailsPhoneNumber}
                                                    updatePhoneNumber={getBusinessContactDetailsPhoneNumberValue}
                                                    errorMessage={!businessContactDetailsPhoneNumberErrorFe && errorObject["BusinessContactDetails.PhoneNumber"]}
                                                    errorMessageFE={businessContactDetailsPhoneNumberErrorFe}
                                                ></PhoneNumber>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {
                                    businessContactBlockErrors && <p className="red text-small mt-2">
                                        Business contact details section is not complete. Please fill all the details to proceed.
                                    </p>
                                }

                                <Accordion.Item eventKey="4" className={`mt-5 ${billToBlockErrors ? "accordionErrors" : ""}`}>
                                    <Accordion.Header>Bill To Information (End Customer)</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formBillToFirstName">
                                                    <Form.Label>First name <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetails.billToDetails.firstName}
                                                        // placeholder="Enter First Name"
                                                        ref={billToDetailsFirstName}
                                                        maxlength="40"
                                                        onChange={getBillToDetailsFirstNameValue}
                                                    />
                                                    {!billToDetailsFirstNameErrorFe && <>{errorObject["BillToDetails.FirstName"] && <span className="red text-small">{errorObject["BillToDetails.FirstName"]}</span>}</>}
                                                    <span className="red text-small">{billToDetailsFirstNameErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formBillToLastName">
                                                    <Form.Label>Last name <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetails.billToDetails.lastName}
                                                        // placeholder="Enter Last Name"
                                                        ref={billToDetailsLastName}
                                                        maxlength="40"
                                                        onChange={getBillToDetailsLastNameValue}
                                                    />
                                                    {!billToDetailsLastNameErrorFe && <>{errorObject["BillToDetails.LastName"] && <span className="red text-small">{errorObject["BillToDetails.LastName"]}</span>}</>}
                                                    <span className="red text-small">{billToDetailsLastNameErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}></Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formBillToEmail">
                                                    <Form.Label>Email <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetails.billToDetails.email}
                                                        // placeholder="Enter Email"
                                                        ref={billToDetailsEmail}
                                                        maxlength="50"
                                                        onChange={getBillToDetailsEmailValue}
                                                    />
                                                    {!billToDetailsEmailErrorFe && <>{errorObject["BillToDetails.Email"] && <span className="red text-small">{errorObject["BillToDetails.Email"]}</span>}</>}
                                                    <span className="red text-small">{billToDetailsEmailErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <PhoneNumber
                                                    phoneNumber={customerDetails.billToDetails.phoneNumber}
                                                    // placeholder="Enter Phone Number"
                                                    ref={billToDetailsPhoneNumber}
                                                    updatePhoneNumber={getBillToDetailsPhoneNumberValue}
                                                    errorMessage={!billToDetailsPhoneNumberErrorFe && errorObject["BillToDetails.PhoneNumber"]}
                                                    errorMessageFE={billToDetailsPhoneNumberErrorFe}
                                                ></PhoneNumber>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {
                                    billToBlockErrors && <p className="red text-small mt-2">
                                        Bill to information (end customer) section is not complete. Please fill all the details to proceed.
                                    </p>
                                }
                            </Accordion>


                            <Row>
                                <Col className="my-3 text-right btn-wrapper-outer">

                                    {!processing &&
                                        <>
                                            <Button
                                                className="btn-border-light w-md-auto col-xs-12 mb-3"
                                                variant="light"
                                                onClick={() => resetAddCustomerDetails()}
                                            >
                                                Cancel
                                            </Button>

                                            <Button
                                                className="ml-3 w-md-auto col-xs-12 mb-3"
                                                onClick={createCustomer}
                                            >
                                                Save
                                            </Button>
                                        </>
                                    }
                                    {processing &&
                                        <>
                                            <Button
                                                className="btn-border-light w-md-auto col-xs-12 mb-3"
                                                variant="disabled"
                                                disabled
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className="ml-3 w-md-auto col-xs-12 mb-3"
                                                variant="disabled"
                                                disabled
                                            >
                                                Creating...
                                            </Button>
                                        </>
                                    }
                                </Col>
                            </Row>

                        </Form>
                    </div>
                </Container >
            </div >
        </>
    );
}

export default AddCustomer;