import React, { useEffect, useState } from 'react'
import { Accordion, Button, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';
import styles from './creditCard.module.scss';
import EmptyDataTable from '../../../../../Assets/Images/Illustrations/no-credit-card-data.svg'
import PasswordToggle from '../../../../../GlobalComponents/PasswordToggle';
import Popup from 'reactjs-popup';
import { XLg } from 'react-bootstrap-icons';
import SuccessImagePreRequisite from '../../../../../Assets/Images/Illustrations/SuccessImagePreRequisite.svg';
import failure from '../../../../../Assets/Images/Illustrations/failure.png';
import DeleteCardImage from '../../../../../Assets/Images/Illustrations/delete-card-img.svg';
import { api } from '../../../../../Utils/API';
import { DELETE_CREDIT_CARD, GET_CARD_DETAILS, GET_CREDIT_CARD_DETAILS, SAVE_CREDIT_CARD_DETAILS, UPDATE_PRIMARY_CREDIT_CARD } from '../../../../../Utils/GlobalConstants';
import { numberRegEx } from '../../../../../GlobalComponents/RegExPatterns';
import Toast from '../../../../../GlobalComponents/Toast';
import EditCreditCard from './LocalComponents';

const CreditCards = (props) => {
    const [addCardClicked, setAddCardClicked] = useState(false);                                // add card is clicked when no card is there
    const [modalFlag, setModalFlag] = useState(false);                                          // flag for modal open
    const [deleteCard, setDeleteCard] = useState(false);                                        // flag for modal open for first time card deletion popup
    const [addCardFirstTimePopup, setAddCardFirstTimePopup] = useState(false);                  // flag for modal open for first time card addition popup
    const [addCardFirstTime, setAddCardFirstTime] = useState(false);                            // flag for first time card 
    const [alreadyHavingCard, setAlreadyHavingCard] = useState(false);                          // already having credit card
    let ColumnName = ["Cards", "Name on Card", "Expiry Date", ""];                              // Columns for table
    const [cardData, setCardData] = useState([]);                                               // data for table regarding card details
 
    // Credit card details
    const [expirationDate, setExpirationDate] = useState("");                             
    const [creditCardNumber, setCreditCardNumber] = useState("");                         
    const [isTypeCard, setIsTypeCard] = useState("");                         
    const [isErrorCardExpiry, setIsErrorCardExpiry] = useState(false);                         
    const [isErrorCardNumber, setIsErrorCardNumber] = useState(false);                        
    const [isTermsChecked, setIsTermsChecked] = useState(false);                              
    const [isDefaultChecked, setIsDefaultChecked] = useState(false);                          
    const [creditCardName, setCreditCardName] = useState("");
    const [creditCardCVV, setCreditCardCVV] = useState("");

    // Credit card usesate to store active card ids, buttons clicked checked,primary card change
    const [creditCardAddStatus, setCreditCardAddStatus] = useState(null);
    const [creditCardActionPrimaryChoosen, setCreditCardActionPrimaryChoosen] = useState(null);
    const [creditCardActionChoosenId, setCreditCardActionChoosenId] = useState(null);
    const [editCardClicked, setEditCardClicked] = useState(false);           
    const [editCardDetails, setEditCardDetails] = useState(null);

    const [loadingFirstTime, setLoadingFirstTime] = useState(true);

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);
    const [toastError, setToastError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    
     // Header Authorization for General API's
    const config = {
        headers: {
        "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Get Credit Cards Data
    async function GetCreditCardDetails() {
        await api.get(GET_CREDIT_CARD_DETAILS, config)
          .then(resp => {
            if(resp.status===200){
                setCardData(resp.data);
                setAlreadyHavingCard(true);
                setLoadingFirstTime(false);
                props.setIsCreditCardAllowed(true); 
            }
            else{
                // console.log(resp.status);
                setAlreadyHavingCard(false);
                setLoadingFirstTime(false);
                props.setIsCreditCardAllowed(false); 
            }
          })
          .catch(error => {
            // console.log(error)
            if (error?.status == 401 || error?.response.status == 401) {
                // console.log("Error 401");
                props.Logout();
            }
            else {
              // console.log(error);
              setLoadingFirstTime(false);
            }
    
          });
      };

          // Get Credit Cards Data
    async function GetCardDetails(cardId) {
        await api.get(GET_CARD_DETAILS+cardId, config)
          .then(resp => {
            if(resp.status===200){
                setEditCardDetails(resp.data);
                // setAlreadyHavingCard(false);
            }
          })
          .catch(error => {
            // console.log(error)
            if (error?.status == 401 || error?.response.status == 401) {
                // console.log("Error 401");
                props.Logout();
            }
            else {
              // console.log(error);
            }
    
          });
      };

    // Post Credit Card Data
    async function SaveCreditCard() {
        let body ={
            "cardNumber": creditCardNumber,
            "cardType": isTypeCard,
            "cardName": creditCardName,
            "expiryMonth": expirationDate.split("/")[0],
            "expiryYear": expirationDate.split("/")[1],
            "isPrimary": isDefaultChecked,
            "cvv": creditCardCVV,
        }
        await api.post(SAVE_CREDIT_CARD_DETAILS,body, config)
          .then(resp => {
            if(resp.status===200){
                if(resp.data.isSuccess){
                    setCreditCardAddStatus(true);
                    setModalFlag(true);
                    setIsDefaultChecked(false); 
                    setIsTermsChecked(false);
                    setCreditCardName("");
                    setCreditCardCVV("");
                    setExpirationDate("");
                    setCreditCardNumber("");
                    setIsTypeCard("");
                    GetCreditCardDetails();
                    if(addCardFirstTime){
                        let paymentMethod = "Credit Card"
                        props.setIsDirectDebitAllowed(false);
                        props.UpdatePaymentMethod(paymentMethod);
                        setAddCardFirstTime(false);
                    }
                }
                else{
                    setCreditCardAddStatus(false);
                    setModalFlag(true);
                }  
            }
          })
          .catch(error => {
            // console.log(error)
            if (error?.status == 401 || error?.response.status == 401) {
                // console.log("Error 401");
                props.Logout();
            }
            else {
                setCreditCardAddStatus(false);
                setModalFlag(true);
              // console.log(error);
            }
    
          });
      };

    // Post Credit Card Data
    async function MakePrimaryCard(cardId) {
        await api.put(`${UPDATE_PRIMARY_CREDIT_CARD}?cardId=${cardId}`,null, config)
          .then(resp => {
            if(resp.status===200){
                if(resp.data.isSuccess){
                    setToastMessage("Primary Card Updated.");
                    setToastSuccess(true);
                    GetCreditCardDetails();
                    
                    setTimeout(() => {
                        setDeleteCard(false);
                        setToastSuccess(false);
                        setCreditCardActionPrimaryChoosen(null);
                        setCreditCardActionChoosenId(null);
                    }, 2000);
                    
                }
                else{
                    setToastMessage("We encountered an issue while trying to update your primary card. Please try again later.");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 3000);
                }  
            }
          })
          .catch(error => {
            // console.log(error)
            if(error?.status == 401 || error?.response.status == 401) {
                // console.log("Error 401");
                props.Logout();
            }
            else {
                setToastMessage("We encountered an issue while trying to update your primary card. Please try again later.");
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 5000);
              // console.log(error);
            }
    
          });
      };

         // Post Credit Card Data
    async function DeleteCreditCard(cardId) {
        await api.delete(`${DELETE_CREDIT_CARD}${cardId}`, config)
          .then(resp => {
            if(resp.status===200){
                if(resp.data.isSuccess){
                    setToastMessage("Card deleted successfully.");
                    setToastSuccess(true);
                    GetCreditCardDetails();
                    setTimeout(() => {
                        setToastSuccess(false);
                        setDeleteCard(false);
                        setCreditCardActionPrimaryChoosen(null);
                        setCreditCardActionChoosenId(null);
                    }, 2000);
                }
                else{
                    setToastMessage("We encountered an issue while deleting the card. Please try again later.");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 3000);
                }  
            }
          })
          .catch(error => {
            // console.log(error)
            if (error?.status == 401 || error?.response.status == 401) {
                // console.log("Error 401");
                props.Logout();
            }
            else {
                setToastMessage("We encountered an issue while deleting the card. Please try again later.");
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 5000);
              // console.log(error);
            }
    
          });
      };

      useEffect(() => {
        if(props.activeAccordion===1){
          GetCreditCardDetails();  
        //   console.log("props.isCreditCardActive",props.isCreditCardActive);
        }
      }, [props])

      useEffect(() => {
        if(addCardClicked){
            const currentDate = new Date();
            const day = currentDate.getDate();
            const month = currentDate.getMonth() + 1; // getMonth() returns 0-11, so we add 1
            const year = currentDate.getFullYear();
            
            const formattedDate = `${day}/${month}/${year}`;
            // console.log(formattedDate);
        }
      }, [addCardClicked])

    return (
        <div >
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

            {/* className='d-none' */}
            {!loadingFirstTime && !addCardClicked && !alreadyHavingCard &&
                <div className={styles["no-data-cloud-invoice"]}>
                    <img src={EmptyDataTable} className="empty-cloud-invoice-table-svg" />
                    <h2 className="mt-4 mb-3">No Credit Card found</h2>
                    <div className={`text-medium my-2 ${styles['mobile-display']}`}>Please add at least one valid card to set Credit Card as the default payment method</div>
                    <div className={`text-center my-3 ${styles['mobile-display']}`}>
                        <Button onClick={() => {setAddCardFirstTimePopup(true); }}>Add Card</Button>
                    </div>
                </div>}
            {!loadingFirstTime && addCardClicked && <div className='mx-1 my-3 mt-0'>
                <span className='visa-mastercard'></span>
                <Row className='px-3 py-1'>
                    <Col lg={4} md={5}>
                        <Form.Group className="mb-3" controlId="firstName">
                            <Form.Label >Credit Card Number <span className="red">*</span></Form.Label>
                            <InputGroup className="password-toggle">
                                <Form.Control
                                    type="text"
                                    // value={creditCardNumber.replace(/[^0-9]/g, "").replace(/\W/gi, '').replace(/(.{4})/g, '$1 ')}
                                    value={creditCardNumber}
                                    // placeholder=" Enter first name"
                                    maxlength="16"
                                    name="cardNumber"
                                    className={isErrorCardNumber ? "border-danger" : ""}
                                    onChange={(e) => {
                                        setIsErrorCardNumber(false);
                                        if((e.target.value.length)>=4){
                                            const prefix = (e.target.value).substring(0, 1);
                                            const prefix2 = (e.target.value).substring(0, 2);
                                            if (prefix === '4') {
                                                setIsTypeCard("VISA")
                                            } else if (prefix2 === '51' || prefix2 === '52' || prefix2 === '53' || prefix2 === '54' || prefix2 === '55') {
                                                setIsTypeCard("MASTER")
                                            } else {
                                                setIsTypeCard("OTHERS")
                                            }
                                        }
                                        if(numberRegEx.test(e.target.value)){
                                            setCreditCardNumber(e.target.value);
                                        }
                                        
                                    }}
                                />
                                <InputGroup.Text>
                                    <span className={creditCardNumber<4 ? 'credit-card-defualt-icon' : (isTypeCard=="VISA"? 'credit-card-visa-icon' : (isTypeCard=="MASTER"? 'credit-card-master-icon' : 'credit-card-defualt-icon'))}></span>
                                </InputGroup.Text>
                            </InputGroup>
                            {isErrorCardNumber && <p className='mb-0 text-danger'>Only Visa and Master cards are accepted</p>}
                            {!isErrorCardNumber && <p className='mb-0'>&nbsp;</p>}
                        </Form.Group>
                    </Col>
                    <Col lg={2} md={4}>
                        <Form.Group className="mb-3" controlId="lastName">
                            <Form.Label >Card Expiry Date <span className="red">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="expiration"
                                className={isErrorCardExpiry ? "border-danger" : ""}
                                value={expirationDate.replace(/\//g, "").substring(0, 2) + 
                                    (expirationDate.length > 2 ? '/' : '') + 
                                    expirationDate.replace(/\//g, "").substring(2, 6)}
                                // placeholder="Enter last name"
                                maxlength="7"
                                placeholder='MM/YYYY'
                                onChange={(e) => {
                                    if((/^[0-9\/]*$/).test(e.target.value)){
                                        setExpirationDate(e.target.value)
                                        setIsErrorCardExpiry(false);
                                    }
                                }}
                            />
                            {isErrorCardExpiry && <p className='mb-0 text-danger'>Credit card is expired</p>}
                            {!isErrorCardExpiry && <p className='mb-0'>&nbsp;</p>}
                        </Form.Group>
                        
                    </Col>
                    <Col lg={2} md={5}>
                        <Form.Group className="mb-3" controlId="Email">
                            <Form.Label >CVV <span className="red">*</span></Form.Label>
                            <PasswordToggle 
                                passwordValue={creditCardCVV}
                                passwordToggleEvent={(e) => (e.target.value.length <= 3 && numberRegEx.test(e.target.value) ? setCreditCardCVV(e.target.value) : "")}
                                maxLength={3} />
                        </Form.Group>
                    </Col>
                    <Col lg={12}></Col>

                    <Col lg={4} md={5}>
                        <Form.Group className="mb-3" controlId="cardholder">
                            <Form.Label >Name of Cardholder <span className="red">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                value={creditCardName}
                                // placeholder=" Enter first name"
                                name="Cardholder"
                            onChange={(e) => {
                                if (e.target.value === '' || /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/.test(e.target.value)) {
                                    setCreditCardName(e.target.value);
                                }
                            }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <div className='pb-0'>
                    <Form.Check // prettier-ignore
                        type="checkbox"
                        id="termsCheckBox"
                        label=""
                        // disabled
                        checked={isDefaultChecked}
                        onChange={()=>{setIsDefaultChecked(!isDefaultChecked)}}
                        className="d-inline-block w-auto"
                    />
                    <div className="d-inline-block text-small w-auto">
                        Make Default
                    </div>
                </div>
                <div>
                    <Form.Check // prettier-ignore
                        type="checkbox"
                        id="termsCheckBox"
                        label=""
                        disabled
                        checked={isTermsChecked}
                        className="d-inline-block w-auto"
                    />
                    <div className="d-inline-block text-small w-auto">
                        I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => { setIsTermsChecked(true); }}>Terms & Conditions</a> of synnex cloud platform (Synnex Australia Pty Ltd).
                    </div>
                </div>
                <Row className='mt-4'>
                    <Col lg={8} md={6}>
                        {<p className=' text-small notes-confirmation'> <b>Note :</b> A temporary charge of <b>AUD 1 </b>will be applied to your credit card for validation purposes. This charge will be refunded within 10 business days to the same card. Please note that your primary credit card will be automatically charged for all future cloud invoice payments. If you choose the Trial plan, you won't be billed unless you decide to upgrade later.</p>}
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="btn-wrapper mt-5 text-right">
                            {/* when length of selected item is graeter than 0 */}
                            {<>
                                {
                                    <>
                                        <Button
                                            className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                            variant="light"
                                            onClick={() => { setIsDefaultChecked(false);  setCreditCardName("");setCreditCardCVV("");setExpirationDate("");setCreditCardNumber("");setIsTypeCard("");setAddCardClicked(false);setAddCardFirstTime(false);setIsTermsChecked(false);setIsErrorCardNumber(false); }}
                                        >
                                            Back
                                        </Button>
                                        {creditCardNumber.length==16 && creditCardName.length>0 && creditCardCVV.length==3 && expirationDate.length==7 && isTermsChecked && parseInt(expirationDate.split("/")[0])<13 && parseInt(expirationDate.split("/")[0])>0 && !(expirationDate.split("/")[0].includes("-"))  ? <Button
                                            className="px-4 mx-2"
                                            onClick={() => {
                                                // setModalFlag(true);
                                                const today = new Date();
                                                const currentMonth = today.getMonth() + 1;
                                                const currentYear = today.getFullYear();
                                                const [month, year] = expirationDate.split('/');
                                                const expiryMonth = parseInt(month);
                                                const expiryYear = parseInt(year);
                                                if(isTypeCard!=="OTHERS" && !(expiryYear < currentYear || (expiryYear === currentYear && expiryMonth <= currentMonth)) ){
                                                    SaveCreditCard()
                                                }
                                                else{
                                                    if(isTypeCard==="OTHERS"){
                                                    setIsErrorCardNumber(true);
                                                  }
                                                  else{
                                                    setIsErrorCardNumber(false);
                                                  }
                                                  if (expiryYear < currentYear || (expiryYear === currentYear && expiryMonth <= currentMonth)) {
                                                    // console.log('Expiry date must be more than the current date!');
                                                    setIsErrorCardExpiry(true);
                                                  } else {
                                                    // console.log('Valid expiry date!');
                                                    setIsErrorCardExpiry(false);
                                                  }
                                                }
                                                  
                                            }}
                                        >Save</Button> :
                                        <Button
                                            className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                            variant="light"
                                            disabled
                                        >Save</Button>
                                        }
                                    </>
                                    }
                                {/* {!confirmLoadingFlag && !((JSON.stringify(defaultChecked) !== JSON.stringify(checkedItems)) || (value != gdapData?.duration)) &&
                    <>
                      <Button
                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                        variant="light"
                        disabled
                      >
                        Back
                      </Button>
                      <Button
                        className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                        variant="light"
                        disabled
                      >Save</Button></>} */}
                            </>}
                            {/* when length of selected item is equal to 0 */}
                            {/* {!confirmLoadingFlag && checkedIds?.length == 0 && <>
                  <Button
                    className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                    variant="light"
                    disabled
                  >
                    Back
                  </Button>
                  <Button
                    className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                    variant="light"
                    disabled
                  >Save</Button>
                </>} */}
                            {/* buttons used when post api is called */}
                            {/* {<>
                  <Button
                    className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                    variant="light"
                    disabled
                  >
                    Back
                  </Button>
                  <Button
                    disabled
                    className="ml-2 px-4 btn-border-light w-md-auto col-xs-12"
                    variant="light"
                  >
                    Processing . . .
                  </Button>
                </>} */}
                        </div>
                    </Col>
                </Row>

                {/* popup for success / error message on card details submission */}
                <Popup
                    className="custom-modal custom-modal-sm"
                    open={modalFlag}
                    onClose={() => {setModalFlag(false);setCreditCardAddStatus(null);setAlreadyHavingCard(true);setAddCardClicked(false);}}
                    modal
                    nested
                >
                    {(close) => (
                        <div className="modal">
                            <div className="header p-4 py-3">
                                <h2 className="mb-0 px-3">{creditCardAddStatus ? "Credit Card Successfully Verified" : "Credit Card  Verification failed"} </h2>
                                <XLg
                                    size={18}
                                    className="cpointer text-strong close-icon-popup-sm"
                                    onClick={close}
                                    color="#6A6A6A"
                                />
                            </div>
                            <Container>
                                <div className={styles["no-data-cloud-invoice"]}>
                                    {creditCardAddStatus ? <img src={SuccessImagePreRequisite} className="empty-cloud-invoice-table-svg" />
                                        : <img src={failure} className="empty-cloud-invoice-table-svg" />}
                                    {creditCardAddStatus ? <div className='my-3 text-center text-medium'>Your credit card has been verified and saved
                                        successfully
                                    </div>
                                        : <div className='my-3 text-center text-medium'>Looks like there might be an issue with your credit card. Would you like to try a different card or payment method</div>}
                                    <Button onClick={close}>Ok</Button>
                                </div>
                            </Container>
                        </div>
                    )}
                </Popup>
            </div>}
            {!loadingFirstTime && editCardClicked && editCardDetails && 
                <EditCreditCard setEditCardDetails={setEditCardDetails} editCardClicked={editCardClicked} setAlreadyHavingCard={setAlreadyHavingCard} GetCreditCardDetails={GetCreditCardDetails} editCardDetails={editCardDetails} setEditCardClicked={setEditCardClicked} />
            }
            {!loadingFirstTime && alreadyHavingCard && !addCardClicked && !editCardClicked && <>
                <div className={styles["table-height-adjustment"]}>
                    <Table responsive="md">
                        <thead className="sticky-top">
                            <tr>
                                {ColumnName.map(th => {
                                    return (<th className={(th === "Status") ? "text-center" : ""}>{th}</th>)
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                cardData && cardData.map((item) => {
                                    return (
                                        <tr key={item.cardNumber}>
                                            <td className={`${styles['mobile-width']}`}>
                                                <input type='radio'
                                                className={`mr-2 ${styles['mobile-display']}`}
                                                style={{ verticalAlign: 'middle' }}
                                                id={item.cardNumber}
                                                disabled={item.isExpired}
                                                name="creditCardData"
                                                checked={item.isPrimary}
                                                onChange={() => {
                                                    setCreditCardActionChoosenId(item.cardId);
                                                    setCreditCardActionPrimaryChoosen(true);
                                                    setDeleteCard(true);
                                                    // setDefaultAgreementSelected(item.agreementId);
                                                    // setAgreementName(item.agreementName);
                                                    // setAgreementId(item.agreementId);
                                                }}
                                            /> {item.cardNumber}
                                                {item.isPrimary && <Button className={`ml-3 ${styles['tags-product-listing-trial']}`}>Primary</Button>}
                                                {item.isExpired && <Button className={`ml-3 ${styles['tags-product-listing-expired']}`}>Expired</Button>}
                                            </td>
                                            <td>{item.cardName}</td>
                                            <td>{item.expiryMonth}/{item.expiryYear}</td>
                                            <td className={`${styles['mobile-display']}`}><span className='edit cpointer mr-6' onClick={() => { GetCardDetails(item.cardId);setEditCardClicked(true); }}></span>
                                                {((!item.isPrimary && props.isCreditCardActive) || (!props.isCreditCardActive)) && <span className='delete cpointer ml-6' onClick={() => { setCreditCardActionChoosenId(item.cardId);
                                                    setCreditCardActionPrimaryChoosen(false);
                                                    setDeleteCard(true); }}></span>}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
                <div className= {`btn-wrapper my-4 text-right ${styles['mobile-display']}`}>
                    {/* when length of selected item is graeter than 0 */}
                    {cardData && cardData.length<2 && <Button
                        className="px-4 mx-2"
                        onClick={() => { setAddCardClicked(true); }}
                    >
                        Add New Card
                    </Button>}
                     {/* when length of selected item is graeter than 0 */}
                     {cardData && cardData.length>1 && <Button
                        className="btn btn-border-light btn-lg mx-2 px-4 "
                        variant="light"
                        disabled
                    >
                        Add New Card
                    </Button>}
                    {/* {<Button
                                            className="px-4 mx-2"
                                            onClick={() => {
                                            }}
                                        >Add New Card</Button>} */}

                </div>
                {/* popup for success / error message on card details submission */}
                <Popup
                    className="custom-modal custom-modal-sm"
                    open={deleteCard}
                    onClose={() =>{ setDeleteCard(false);setCreditCardActionPrimaryChoosen(null);setCreditCardActionChoosenId(null);}}
                    modal
                    nested
                >
                    {(close) => (
                        <div className="modal">
                            <div className="header p-4 py-3">
                                <h2 className="mb-0 px-3">{!creditCardActionPrimaryChoosen ? "Delete Credit Card" : "Reset Credit Card"}</h2>
                                <XLg
                                    size={18}
                                    className="cpointer text-strong close-icon-popup-sm"
                                    onClick={close}
                                    color="#6A6A6A"
                                />
                            </div>
                            <Container>

                                <div className={styles["no-data-cloud-delete"]}>
                                    <img src={DeleteCardImage} className="empty-cloud-invoice-table-svg" />
                                    {!creditCardActionPrimaryChoosen ? <div className='my-3 text-center text-medium'>Are you sure you want to delete the credit card ?
                                    </div>:
                                    <div className='my-3 text-center text-medium'>Are you sure you want to set this card as your primary credit card?
                                    </div>
                                    }

                                    <div> <Button
                                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                        variant="light"
                                        onClick={close}>No</Button>
                                        <Button onClick={()=>{
                                            if(creditCardActionPrimaryChoosen){
                                                MakePrimaryCard(creditCardActionChoosenId);
                                        }
                                        else{
                                            DeleteCreditCard(creditCardActionChoosenId);
                                        } }}>Yes</Button></div>

                                </div>
                            </Container>
                        </div>
                    )}
                </Popup>

            </>}
            {
                loadingFirstTime && <div className={styles["no-data-cloud-invoice"]}></div>
            }
            {/* popup for add credit card for first time */}
                <Popup
                    className="custom-modal custom-modal-sm"
                    open={addCardFirstTimePopup}
                    onClose={() =>{ setAddCardFirstTimePopup(false)}}
                    modal
                    nested
                >
                    {(close) => (
                        <div className="modal">
                            <div className="header p-4 py-3">
                                <h2 className="mb-0 px-3">Switching Payment Method</h2>
                                <XLg
                                    size={18}
                                    className="cpointer text-strong close-icon-popup-sm"
                                    onClick={close}
                                    color="#6A6A6A"
                                />
                            </div>
                            <Container>
                                <div className={styles["no-data-cloud-delete"]}>
                                    <img src={DeleteCardImage} className="empty-cloud-invoice-table-svg" />
                                    <div className='my-3 text-center text-medium'>
                                        Adding new card details will switch your default payment method to Credit Card. Would you like to proceed ?
                                    </div>

                                    <div> <Button
                                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                        variant="light"
                                        onClick={close}>No</Button>
                                        <Button onClick={()=>{
                                            setAddCardClicked(true);
                                            setAddCardFirstTime(true);
                                            close();
                                        } }>Yes</Button></div>

                                </div>
                            </Container>
                        </div>
                    )}
                </Popup>

        </div>
    )
}

export default CreditCards
