import React from 'react'

const OfflineServices = () => {
  return (
    <div>
      <h1>Offline Services</h1>
    </div>
  )
}

export default OfflineServices
