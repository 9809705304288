import React from 'react'
import { Button, Container } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';

const LegacyToNCE = ({popupOpen,setPopupOpen}) => {
  return (
    <div>
        <Popup
            className="custom-modal custom-modal-md"
            open={popupOpen}
            onClose={() => setPopupOpen(false)}
            modal
            nested
        >
            {(close) => (
            <div className="modal">
                <>
                    <div className="header">
                    <h2>Connectwise Integration</h2>
                    <XLg
                        size={18}
                        className="cpointer text-strong"
                        onClick={close}
                        color="#6A6A6A"
                    />
                    </div>
                    <Container>
                    <p className='mb-0'>
                        ConnectWise integration now has NCE SKU’s mapping and sync available. This is in Beta testing mode only and we highly recommend you to first check your Legacy and NCE synchronization settings before you map any NCE subscriptions.
                        <br/><br/>
                        We also strongly recommend you to not perform any bulk mapping of SKU’s rather check the sync first with a test agreement in your ConnectWise instance to make sure sync settings are propagating desired results
                        <br/><br/>
                        If you have any further enquiries then please feel free to contact <span className='text-strong'>online-support@au.synnex-grp.com</span>
                    </p>
                    </Container>
                    <div className="mt-1 mb-5 btn-wrapper text-center">
                        <Button
                            className="px-4 mx-2"
                            onClick={() => {
                                close();
                            }}
                        >Ok</Button>
                    </div>
                </>
            </div>
            )}
        </Popup>
    </div>
  )
}

export default LegacyToNCE
