import React, { useState, useEffect } from 'react'
import { Container, Button, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";

const BulkUploadFilterBoxMCA = (props) => {
    const [selectedStatus, setSelectedStatus] = useState([]);                // all the selected value in status
    const [dateCustomCheck, setDateCustomCheck] = useState(false);                  // check state for custom chosen in date
    const date = new Date();                                                        // for js date time function

    // Function to handle "All Status" checkbox
    const handleAllStatusChange = (isChecked) => {
        setSelectedStatus(isChecked ? props.statusData.map((status) => status.id) : []);
    };

    // Function to update selected billing term
    const handleStatusChange = (id) => {
        setSelectedStatus((prevSelectedStatus) => {
            if (prevSelectedStatus.includes(id)) {
                return prevSelectedStatus.filter((typeId) => typeId !== id);
            } else {
                return [...prevSelectedStatus, id];
            }
        });
    };

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to Convert Date Selection Options to from and to dates in DD/MM/YYYYY format
    function DatePicker(e, from, to, dateCheck) {
        let todayDate = `${String(date.getDate()).padStart(2, '0')}`
        let todayMonth = `${String(date.getMonth() + 1).padStart(2, "0")}`
        let todayYear = `${date.getFullYear()}`
        dateCheck(false);
        if (e.target.value === "yesterday") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 1));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()

            from(fromDate);
            to(fromDate);     // From and to Date will be same
        }
        else if (e.target.value === "today") {
            let fromDate = String(todayDate) + "/" + String(todayMonth) + "/" + String(todayYear);

            from(fromDate);
            to(fromDate);     // From and to Date will be same
        }
        else if (e.target.value === "last7") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 7));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(date.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "last30") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 30));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(date.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "thismonth") {
            var endDateMonth = new Date(2008, (date.getMonth() + 1), 0);
            let fromDate = "01" + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            let toDate = String(endDateMonth.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "lastmonth") {
            var today = new Date();
            var yesterday = new Date(today.setMonth(today.getMonth() - 1));
            var lastDayOfMonth = new Date(yesterday.getFullYear(), yesterday.getMonth() + 1, 0);
            let fromDate = "01" + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(lastDayOfMonth.getDate()).padStart(2, '0') + "/" + String(lastDayOfMonth.getMonth() + 1).padStart(2, "0") + "/" + lastDayOfMonth.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same

        }
        else if (e.target.value === "custom") {
            dateCheck(true);
        }
        else {
            from(null);
            to(null);
        }
    }

    const [searchStatusValue, setSearchStatusValue] = useState(""); // Searched Value from Customer
    const clearFilterSearchStatus = () => { // Clear Filter Box Search Input
        setSearchStatusValue("");
    }

    // Date Array
    const dateArray = [
        // {
        //     id: "0",
        //     value: "today",
        //     name: "Today"
        // },
        // {
        //     id: "1",
        //     value: "yesterday",
        //     name: "Yesterday"
        // },
        // {
        //     id: "2",
        //     value: "last7",
        //     name: "Last 7 Days"
        // },
        // {
        //     id: "3",
        //     value: "last30",
        //     name: "Last 30 Days"
        // },
        {
            id: "4",
            value: "thismonth",
            name: "This Month"
        },
        {
            id: "5",
            value: "lastmonth",
            name: "Last Month"
        },
        {
            id: "6",
            value: "custom",
            name: "Custom Date"
        }
    ];

    const clearFilterBox = () => {
        if (props.status && props.status !== null) {
            const element = document.getElementById(props.status);
            if (element) {
                element.checked = false;
            }
        };

        if (props.dateLastUsedDate !== "default") {
            props.setDateLastUsedDate("default");
            document.getElementById(props.dateLastUsedDate).checked = false;
        }
        props.setScrollToDivTopMCA(true);
        props.setPageNoMCA(1);
        props.setDateFrom(null);
        props.setDateTo(null);
        props.setStatus(null);
        setSelectedStatus([]);
        setDateCustomCheck(false);
        props.setDateCustomFromLast(date.toISOString().slice(0, 10));
        props.setDateCustomToLast(date.toISOString().slice(0, 10));
        props.GetTableData(props.searchValueTable, [], "", "", false, 1, props.pageSize);
        props.setFilterFlag(false);
    }

    useEffect(() => {
        (props.status != null || props.status != undefined) ? setSelectedStatus(props.status) : setSelectedStatus([]);
    }, []);

    return (
        <div className="filter-box filter-box-sm">
            <Container>
                <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
                    <h2>Filters</h2>
                    <XLg size={16} onClick={() => props.setShowFilterBox(false)} />
                </Col>
                <Row className="mb-3 mobile-filter-row">
                    <Col className="filter-column">
                        <h3 className="mb-3">Status</h3>
                        <div>
                            <div className="filter-options">

                                {props.statusData && props.statusData.length > 0 && <Form.Check
                                    className="mb-2"
                                    type="checkbox"
                                    id={`statuses-all`}
                                    label="All Statuses"
                                    checked={(selectedStatus.length === props.statusData.length)}
                                    onChange={(e) => handleAllStatusChange(e.target.checked)}
                                />}
                                {props.statusData && props.statusData.length > 0 &&
                                    props.statusData.map((statusItem) => {
                                        return (
                                            <Form.Check
                                                className="mb-2"
                                                key={statusItem.id}
                                                type="checkbox"
                                                id={`status-${statusItem.id}`}
                                                label={statusItem.name}
                                                checked={selectedStatus.includes(statusItem.id)}
                                                onChange={() => { handleStatusChange(statusItem.id) }}
                                            />
                                        );
                                    })}

                            </div>
                        </div>

                    </Col>
                    <Col className="filter-column">
                        <h3 className="mb-3">Date</h3>
                        <div>

                            <Form>
                                <div className="filter-options date-filter-options">

                                    {
                                        dateArray.map(customerDate => {
                                            return (

                                                < Form.Check // prettier-ignore
                                                    key={customerDate.value}
                                                    type={'radio'}
                                                    id={customerDate.value}
                                                    label={customerDate.name}
                                                    name="invoiceDateGroup"
                                                    value={customerDate.value}
                                                    aria-label={customerDate.name}
                                                    defaultChecked={customerDate.value == props.dateLastUsedDate ? true : false}
                                                    onChange={(e) => {
                                                        if (e.target.value === "custom") {
                                                            props.setDateFrom(String(`${String(date.getDate()).padStart(2, '0')}`) + "/" + String(`${String(date.getMonth() + 1).padStart(2, "0")}`) + "/" + String(date.getFullYear()));
                                                            props.setDateTo(String(`${String(date.getDate()).padStart(2, '0')}`) + "/" + String(`${String(date.getMonth() + 1).padStart(2, "0")}`) + "/" + String(date.getFullYear()))
                                                        }
                                                        DatePicker(e, props.setDateFrom, props.setDateTo, setDateCustomCheck); props.setDateLastUsedDate(e.target.value);
                                                    }}
                                                />

                                            )

                                        })
                                    }
                                    {
                                        (dateCustomCheck || props.dateLastUsedDate === "custom") &&
                                        <>
                                            <p className='mt-2'>From :</p>
                                            <Form.Control value={props.dateCustomFromLast} max="2999-12-31" type="date" onChange={(e) => {
                                                if (!(e.target.value)) { props.setDateCustomFromLast(null); props.setDateFrom(null); } else {
                                                    props.setDateCustomFromLast(e.target.value);
                                                    let date = (e.target.value.split("-")).reverse(); props.setDateFrom(String(date[0] + "/" + date[1] + "/" + date[2]));
                                                }
                                            }} />

                                            <p className='mt-2'>To :</p>
                                            <Form.Control value={props.dateCustomToLast} max="2999-12-31" type="date" onChange={(e) => {
                                                if (!(e.target.value)) { props.setDateCustomToLast(null); props.setDateTo(null); } else {
                                                    props.setDateCustomToLast(e.target.value);
                                                    let date = (e.target.value.split("-")).reverse(); props.setDateTo(String(date[0] + "/" + date[1] + "/" + date[2]))
                                                }
                                            }} />
                                        </>
                                    }
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
                <Row className="actions-row">
                    <Col className="d-flex justify-content-end">
                        {(!props.dateFrom && !props.dateTo && selectedStatus.length == 0) &&
                            <Button
                                className="btn btn-lg mx-3"
                                variant="light"
                                disabled
                            >
                                Clear
                            </Button>
                        }
                        {(props.dateFrom || props.dateTo || selectedStatus.length > 0) &&
                            <Button
                                className="btn btn-lg mx-3 btn-border-light"
                                variant="light"
                                onClick={clearFilterBox}
                            >
                                Clear
                            </Button>
                        }
                        {(props.dateFrom || props.dateTo || selectedStatus.length > 0) &&
                            <Button
                                className="btn btn-lg"
                                variant="primary"
                                onClick={() => {
                                    props.setScrollToDivTopMCA(true);
                                    props.setPageNoMCA(1);
                                    props.setStatus(selectedStatus);
                                    props.GetTableData(props.searchValueTable, selectedStatus, props.dateFrom, props.dateTo, false, 1, props.pageSize, false, true);
                                    props.setShowFilterBox(false);
                                    props.setFilterFlag(true);
                                }
                                }
                            // disabled
                            >
                                Apply
                            </Button>
                        }
                        {(!props.dateFrom && !props.dateTo && selectedStatus.length == 0) &&
                            <Button
                                className="btn btn-lg"
                                variant="light"
                                disabled
                            >
                                Apply
                            </Button>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BulkUploadFilterBoxMCA;