import React, { useState } from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import IntegrationSettingsManage from './LocalComponents/IntegrationSettings'
import SynchronizationSettingsManage from './LocalComponents/SynchronizationSettings'
import AgreementMappingManage from './LocalComponents/AgreementMapping'
import AuditReportManage from './LocalComponents/AuditReport'
import LegacyToNCE from './LocalComponents/Popup/LegacyToNCE'
import styles from './manageIntegration.module.scss'

const ManageIntegration = ({ toggleDrawer, setSelectService, setSelectCategory, Logout }) => {
    const [tabName, setTabName] = useState("Agreement Mapping");
    const [legacyNceSynchronizationPopupFlag, setLegacyNceSynchronizationPopupFlag] = useState(false);
    const [bulkMappingSuccessfull, setBulkMappingSuccessfull] = useState(false);
  return (
    <div className='main-content'>
      <LegacyToNCE popupOpen={legacyNceSynchronizationPopupFlag} setPopupOpen={setLegacyNceSynchronizationPopupFlag} />
      <Tab.Container id="main-tab-bar" defaultActiveKey="Agreement Mapping">
          <div className={`mgmt-console-table-row mobile-padding ${styles['integrations-mobile-padding']}`}>
            <div className="mb-3 d-flex">
              <Col className={`py-0 overflow-auto ${styles['profile-table']}`}>
                <Tab.Container activeKey={tabName}>
                  <Row>
                    <Col sm={12}>
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="Integration Settings" className='addons-core-gws-manage  pl-6 pr-6 pt-3 pb-3'
                            onClick={() => { setTabName("Integration Settings") }}
                          >
                            Integration Settings</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="Synchronization Settings" className='addons-core-gws-manage pl-6 pr-6 pt-3 pb-3'
                            onClick={() => { setTabName("Synchronization Settings") }}
                          >
                            Synchronization Settings</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="Agreement Mapping" className='addons-core-gws-manage pl-6 pr-6 pt-3 pb-3'
                            onClick={() => { setTabName("Agreement Mapping") }}
                          >
                            Agreement Mapping</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="Audit Report" className='addons-core-gws-manage pl-6 pr-6 pt-3 pb-3'
                            onClick={() => { setTabName("Audit Report") }}
                          >
                            Audit Report</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col lg={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="Integration Settings">
                            <IntegrationSettingsManage toggleDrawer={toggleDrawer} setSelectService={setSelectService} setSelectCategory={setSelectCategory} Logout={Logout}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Synchronization Settings">
                            <SynchronizationSettingsManage setPopupOpen={setLegacyNceSynchronizationPopupFlag} Logout={Logout} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Agreement Mapping">
                            <AgreementMappingManage setBulkMappingSuccessfull={setBulkMappingSuccessfull} Logout={Logout} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Audit Report">                 
                            <AuditReportManage bulkMappingSuccessfull={bulkMappingSuccessfull} setBulkMappingSuccessfull={setBulkMappingSuccessfull} Logout={Logout}/>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
            </div >

          </div >
        </Tab.Container>
    </div>
  )
}

export default ManageIntegration
