import React, { useState, useEffect } from 'react';
import { Carousel, Row, Col, Card, Container } from 'react-bootstrap';
import styles from './carousal.module.scss';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';

function Carousal({landingPageData,globalData}) {

  // Carousal Usestates
  const [cards, setCards] = useState(null);             // Carousal Card data usestate
  const [index, setIndex] = useState(0);
  const [chunkSize, setChunkSize] = useState(4);
  const chunks = [];

  useEffect(() => {
    const updateChunkSize = () => {
      setChunkSize(window.innerWidth < 768 ? 2 : 4);
    };

    updateChunkSize();
    window.addEventListener('resize', updateChunkSize);
    return () => window.removeEventListener('resize', updateChunkSize);
  }, []);

  
    useEffect(() => {
      if(landingPageData && globalData){
        let localData=[];
        landingPageData.newsLatter.contentItems[0].latestNewsCards.contentItems.map((blogData)=>{
          localData.push({
            "title": blogData.heading,
            "description": blogData.description,
            "imgSrc": globalData.baseUrl + blogData.image.urls,
            "url": blogData.url
          })
        })
        setCards(localData);
      }
    }, [landingPageData,globalData]);

    if(cards){
      for (let i = 0; i < cards.length; i += chunkSize) {
    chunks.push(cards.slice(i, i + chunkSize));
  }

    }

  
    const next = () => {
      setIndex((prevIndex) => (prevIndex + 1) % chunks.length);
    };

    const prev = () => {
      setIndex((prevIndex) => (prevIndex - 1 + chunks.length) % chunks.length);
    };

    // Touch event handlers
  let touchStartX = 0;
  let touchEndX = 0;

  const handleTouchStart = (e) => {
    touchStartX = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    touchEndX = e.changedTouches[0].clientX;
    handleGesture();
  };

  const handleGesture = () => {
    const gestureDistance = touchStartX - touchEndX;

    if (Math.abs(gestureDistance) > 50) { // Adjust this threshold as needed
      if (gestureDistance > 0) {
        next(); // Swipe left
      } else {
        prev(); // Swipe right
      }
    }
  };
  
  return (
    <Container fluid className={styles['carousel-container']} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
      <div className={styles['carousel-header']}>
        <h2 className={styles['h3']}>{landingPageData && landingPageData.newsLatter.contentItems[0].title}</h2>
        <h1 className={styles['h1']}>
          {landingPageData && landingPageData.newsLatter.contentItems[0].description}
        </h1>
        <div className={styles['carousel-buttons']}>
          <button className={styles['carousel-button']} onClick={prev} disabled={index === 0}><ChevronLeft className={styles['chevron-left']}/></button>
          <button className={styles['carousel-button']} onClick={next} disabled={index === chunks.length - 1}><ChevronRight className={styles['chevron-right']}/></button>
        </div>
      </div>
      <div className={`${styles['carousel-wrapper']} py-1`}>
        <Carousel activeIndex={index} indicators={false} controls={false}>
          {chunks.map((chunk, chunkIndex) => (
            <Carousel.Item key={chunkIndex}>
              <Row className={styles['carousel-row']}>
                {chunk.map((card, idx) => (
                  <Col key={idx} xs={6} md={3} className={styles['carousel-row-inner']}>
                    <Card className={styles['carousel-card']}>
                      <div className={styles['card-img-container']}>
                        <Card.Img variant="top" src={card.imgSrc} alt={card.title} />
                        {/* <div className={styles['card-title']}>{card.title}</div> */}
                      </div>
                      <Card.Body className={styles['card-body']}>
                        <h1 className={`${styles['title']} mb-0`}>{card.title}</h1>
                        <Card.Text className={styles['carousel-desc']}>{card.description}</Card.Text>
                        <a href={card.url} target='_blank'><button className={styles['card-arrow']}><ChevronRight className={styles['chevron-right']}/></button></a>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </Container>
  );
}

export default Carousal;