import React, { useState , useEffect } from "react";
import CmsDetailsContext from "./CmsDetailsContext";
import { LANDING_PAGE_CMS_DATA } from "../../Utils/GlobalConstants";
import { cmsApi } from "../../Utils/API";
import ReactGA from "react-ga4";

const CmsDetailsState = (props) => {
    const [globalData, setGlobalData] = useState();
      // Function for calling Site Setting item data from Orchid Core CMS
  async function GetLandingPageData() {
    // console.log("Site Setting API Initiated..");
    const data = `{
      siteSettings {
        baseUrl
        favIcon {
          paths
          urls
        }
        headerLogo {
          paths
          urls
        }
        customerPortalName
        googleAnalyticsFlag
        googleAnalyticsCode
      }
    }`;

    // Defining Header Configuration
    const config = {
      headers: {
        "Content-Type": "application/graphql",
        Accept: "application/graphql",
      },
    };

    // Axios Post Request
    await cmsApi
      .post(LANDING_PAGE_CMS_DATA, data, config)
      .then((resp) => {
        // handle success
        // console.log(resp.data.data.siteSettings[0].googleTagManagerCode);
        setGlobalData(resp.data.data.siteSettings[0]);
        const favicon = document.getElementById("favicon");
        favicon.href = resp.data.data.siteSettings[0].baseUrl+resp.data.data.siteSettings[0].favIcon.urls[0];
        document.title = resp.data.data.siteSettings[0].customerPortalName;
        // console.log("googleAnalyticsCode :",resp.data.data.siteSettings[0].googleAnalyticsCode)
        // ReactGA.initialize("GTM-WGPZW5G");
        if(resp.data.data.siteSettings[0].googleAnalyticsFlag){
          // console.log("googleAnalyticsCode :",resp.data.data.siteSettings[0].googleAnalyticsCode)
          ReactGA.initialize(resp.data.data.siteSettings[0].googleAnalyticsCode);
          // To Debug
          // ReactGA.initialize(resp.data.data.siteSettings[0].googleAnalyticsCode, { debug: true });
          // ReactGA.pageview(window.location.pathname + window.location.search);
          ReactGA.send({ hitType: "pageview", page: `${window.location.pathname + window.location.search}`, title: resp.data.data.siteSettings[0].customerPortalName });
        }  
        let myDynamicManifest = {
          "short_name": `${resp.data.data.siteSettings[0].customerPortalName}`,
          "name": `${resp.data.data.siteSettings[0].customerPortalName}`,
          "icons": [
            {
              "src": `favicon.ico`,
              "sizes": "64x64 32x32 24x24 16x16",
              "type": "image/x-icon"
            },
            {
              "src": `${resp.data.data.siteSettings[0].baseUrl+"/media/icon-192x192.png"}`,
              "type": "image/png",
              "sizes": "192x192"
            },
            {
              "src": `${resp.data.data.siteSettings[0].baseUrl+"/media/icon-256x256.png"}`,
              "type": "image/png",
              "sizes": "256x256"
            },
            {
              "src": `${resp.data.data.siteSettings[0].baseUrl+"/media/icon-384x384.png"}`,
              "type": "image/png",
              "sizes": "384x384"
            },
            {
              "src": `${resp.data.data.siteSettings[0].baseUrl+"/media/icon-512x512.png"}`,
              "type": "image/png",
              "sizes": "512x512"
            }
          ],
          "start_url": `${window.location.href}`,
          "display": "standalone",
          "theme_color": "#000000",
          "background_color": "#ffffff"
        }        
        const link = document.createElement("link");
        link.rel = "manifest";    
        const stringManifest = JSON.stringify(myDynamicManifest);
        link.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(stringManifest))
        document.head.appendChild(link);
      })
      .catch((error) => {
        // handle error
        // console.log(error);
      });
  }

  // UseEffect Defination to call the get Site Settings items function from CMS
  useEffect(() => {
    GetLandingPageData();
  }, []);

    return(
        <CmsDetailsContext.Provider value={globalData}>
            {props.children}
        </CmsDetailsContext.Provider>
    )
}

export default CmsDetailsState;
