import React from 'react'
import styles from '../../../../CurrentSubscription/CSP/LocalComponents/Expandable/CSPExpandable.module.scss'
import { useEffect, useState, useRef } from "react";
import DataTable, { createTheme } from 'react-data-table-component';
import { Container, Row, Col, Dropdown, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CircleFill, XLg } from "react-bootstrap-icons";
import Popup from 'reactjs-popup';
import { CSP_ADDONS, GOOGLE_WORKSPACE_ADDONS } from '../../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../../Utils/API';

const ExpandableTable = ({ data }) => {
    const [columns, setColumns] = useState([]);                                     // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                         // data state used to display data in react data table component
    let ColumnName = ["", "Plan", "Quantity", "Billing Term",
        "Start Date", "Renewal Date", "Subscription Type", "Status"];     // table headers used to make object of react data table component 

    const [initialLoading, setInitialLoading] = useState(true);                     // initial loading of data check 

    const [serverErrorUser, setServerErrorUser] = useState(false);                  // server error check state in table during api call to load data
    const [status, setStatus] = useState(null);                                     // getTableData() status
    const [billingTerm, setBillingTerm] = useState(null);                           // getTableData() billing term
    const [planType, setPlanType] = useState(null);                                 // getTableData() plan type
    const [showTableLoading, setShowTableLoading] = useState("Loading . . . ");     //loading status of table
    const [loading, setLoading] = useState(false);                                   // loading state while table loads
    const [errorMessage, setErrorMessage] = useState(null);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    let d = [];
    ColumnName.map((td) => {
        if (td === "Status") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                center: true,
                allowOverflow: true,
            })
        }
        else if (td === "Quantity") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                right: true,
                allowOverflow: true,
            })
        }
        else if (td === "Plan") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                allowOverflow: true,
                grow: 7,
            })
        }
        else if (td === "Subscription Type") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 2.6,
                allowOverflow: true,
            })
        }
        else if (td === "Billing Term") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">
                    {row[`${td}`]}</div>,
                left: true,
                grow: 3.5,
                allowOverflow: true,
            })
        }
        else if (td === "Start Date") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 2.4
            })
        }
        else if (td === "Renewal Date") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 2.5
            })
        }
        else {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 1
            })
        }

    })

    // Get Table Data
    async function GetTableData(billingTerm, planType) {
        setInitialLoading(false);
        (billingTerm === undefined || billingTerm === null) ? billingTerm = "" : billingTerm = billingTerm;
        (planType === undefined || planType === null) ? planType = "" : planType = planType;
        await api.get(`${GOOGLE_WORKSPACE_ADDONS}?parentSubscriptionId=${data.subscriptionId}`, config)
            .then(resp => {
                setInitialLoading(true);
                setErrorMessage(false);
                if (resp.status == 204) {
                    {
                        setShowTableLoading("No AddOns Found !");
                    }
                }
                // handle success
                else {
                    setShowTableLoading("Loading  . . . ");
                    setServerErrorUser(false);
                    let f = [];
                    // console.log("API RESPONSE : ", resp.data);
                    (resp.data).map((td) => {
                        f.push
                            (
                                {
                                    "Plan": <>
                                        {/* <CircleFill size={6} color="#6A6A6A" className="mr-2" /> */}
                                        {td.planName}
                                    </>,
                                    "has Add On": td.hasAddOn,
                                    "Assigned Seats": td.assignedSeat,
                                    "subscriptionId": td.subscriptionId,
                                    "Quantity": td.quantity,
                                    "Billing Term": td.billingTerm,
                                    "Start Date": td.startDate,
                                    "Renewal Date": td.renewalDate,
                                    "Subscription Type": td.planType,
                                    "Status": td.status === 'ACTIVE' ? (
                                        <div className='text-center'>
                                            <span className="status-active"></span>
                                            <div className="py-1 text-small text-muted">Active</div>
                                        </div>
                                    ) : td.status === 'CANCELLED' ? (
                                        <div className='text-center'>
                                            <span className="status-suspended"></span>
                                            <div className="py-1 text-small text-muted">Cancelled</div>
                                        </div>
                                    ) : td.status === 'PENDING' ? (
                                        <div className='text-center'>
                                            <span className="status-pending"></span>
                                            <div className="py-1 text-small text-muted">Pending</div>
                                        </div>
                                    ) : td.status === 'SUSPENDED' ? (
                                        <div className='text-center'>
                                            <span className="status-suspended"></span>
                                            <div className="py-1 text-muted text-small">Suspended</div>
                                        </div>
                                    ) : (
                                        td.status
                                    ),
                                    "Minimum Quantity": td.minQuantity,
                                    "Maximum Quantity": td.maxQuantity
                                }
                            );
                    })
                    // console.log("Test F", f);
                    setTimeout(() => {
                        setTable(f);         // Setting Table Data
                    }, 50);
                }
            })
            .catch(error => {
                // console.log(error)
                if (error?.status == 401 || error?.response.status == 401) {

                    
                    // Logout();
                }
                else {
                    setServerErrorUser(true);
                    setShowTableLoading("Facing Issue in reaching Server.Please Try Again Later !");
                    setErrorMessage(true);
                    // console.log("GWS addons:",error);
                }

            });

        // React Data Table Custom Theme
        createTheme('solarized', {
            text: {
                primary: '#000000',
            },
            background: {
                default: '#FFFFFF',
            },
            divider: {
                default: '#d7dadf',
            },
            striped: {
                default: '#f5f5f5',
            },
        });
    };


    // React Data Table Small Custom Styles
    const customStylesTableSmall = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        //for adding background in row-first child
        headRow: {
            style: {
                backgroundColor: '#fafafa'
            },
        },
        cells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for data cells
                paddingRight: '1rem',
                // textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
                borderBottom: '0.5px solid #DDDDDD',
            },
        },
        headCells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for head cells
                paddingRight: '1rem',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                // textAlign: 'center',
            },
        },
        expanderButton: {
            style: {
                '&:hover:enabled': {
                    cursor: 'pointer',
                },
                '&:disabled svg path': {
                    // color: '#999',
                    visibility: 'hidden'
                },
                svg: {
                    margin: 'auto',
                },
            },
        },
    };

    // Use Effect to get table data during initial start of page
    useEffect(() => {
        GetTableData(billingTerm, planType);
    }, []);

    return (
        <>
            {/* Add-on : {data && data.subscriptionId} */}
            <Container fluid className="p-0">
                <div>
                    <Col>
                        <div className={`table-responsive ${styles['csp-service-table']}`}>
                            {errorMessage && <p className='text-center py-3'>{showTableLoading}</p>}
                            {!serverErrorUser && !errorMessage &&
                                <DataTable
                                    columns={d}
                                    data={table}
                                    theme="solarized"
                                    customStyles={customStylesTableSmall}
                                    noTableHead
                                    noDataComponent={<div className="loading-Add-Ons"><p>{showTableLoading}</p></div>}
                                    width="90%"

                                />}
                        </div>
                    </Col>
                </div>
            </Container>
        </>
    )
}

export default ExpandableTable
