import { NavDropdown, Form } from "react-bootstrap";
import { useRef } from "react";

const SearchDropdown = ({
    dropdownLabel,
    formGroupId,
    placeholder,
    selectOptions,
    selectDefaultValue,
    setSelectDefaultValue,
    setOptionId,
    selectOptionsFiltered,
    setSelectOptionsFiltered,
    selectSearchValue,
    setSelectSearchValue,
    updatedOptions,
    setInitialSubscriptionTableCall,
}) => {

    const searchBoxValue = useRef();

    // Priority On Change Function
    const selectValueChange = (e) => {
        setSelectDefaultValue(e.target.getAttribute("value"));
        setOptionId(e.target.id);
        setInitialSubscriptionTableCall(null);
    }

    // OnBlur Event for Serch Input
    const clearSearchBox = () => {
        setSelectSearchValue('');
        setSelectOptionsFiltered(selectOptions);
    }

    const handleChange = (e) => {
        setSelectSearchValue(e.target.value);
        if (e.target.value.length > 2) {
            selectOptions.filter(option => {

                if (option.name){
                if ((option.name).toLowerCase().includes(e.target.value.toLowerCase())) {
                    updatedOptions.push(option);

                } if (updatedOptions.length > 0) {
                    setSelectOptionsFiltered(updatedOptions);
                } else {
                    setSelectOptionsFiltered([{ "name": "No data available" }]);
                }
         } })
        } else {

            setSelectOptionsFiltered(selectOptions);
        }
    }

    return (
        <>
            <Form.Group
                className="form-group col-md-12 mb-0"
                controlId={formGroupId}
            >
                <Form.Label>{dropdownLabel}</Form.Label>

                <NavDropdown title={selectDefaultValue} id="cloud-service-dropdown"
                    className="servcies-dropdown" onToggle={clearSearchBox}>
                    <Form.Control
                        type="text"
                        value={selectSearchValue}
                        ref={searchBoxValue}
                        placeholder={placeholder}
                        maxlength="50"
                        onChange={handleChange}
                    />
                    {selectOptionsFiltered && selectOptionsFiltered.map((option) => {
                        return (
                            <>
                                <NavDropdown.Item
                                    key={option.id}
                                    id={option.id}
                                    data-id={option.integrationCode}
                                    value={option.name}
                                    className={selectDefaultValue === option.name ? "selected" : ""}
                                    onClick={selectValueChange}>
                                    {option.name}
                                </NavDropdown.Item>
                            </>
                        );
                    })}
                </NavDropdown>
            </Form.Group>
        </>
    );
}

export default SearchDropdown;