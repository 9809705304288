import { Accordion, Button, Col, Container, Form, Row } from 'react-bootstrap';
import styles from './legacysync.module.scss';
import { useEffect, useState } from 'react';

const LegacySync = (
    { legacyData,
        setLegacyData,
        setLegacyPostBody,
        isLegacyDataChanged,
        setIsLegacyDataChanged,
        legacyInitailPostValues,
        errorStateLegacy,
        setIsLegacyDataFilled,
        isReset,
        setIsReset }) => {

    // Get Additional Properties On Initial Loading 
    const [additionalProperties, setAdditionalProperties] = useState(legacyData.find(setting => setting.id === "Legacy_AdditionProperties").data
        .filter(data => data.selected)
        .map(data => data.id).sort((a, b) => a - b).join(",") == "" ? '0' : legacyData.find(setting => setting.id === "Legacy_AdditionProperties").data
            .filter(data => data.selected)
            .map(data => data.id).sort((a, b) => a - b).join(","));

    // console.log("additionalProperties", additionalProperties);

    // Default Legacy Post Values. These will be updated on change
    const [legacyPostValues, setLegacyPostValues] = useState({
        legacy_Renewal: 0,
        legacy_AdditionProperties: additionalProperties == "" ? '0' : additionalProperties,
        legacy_SuspendSubscription: 0,
        legacy_AdditionOfExistingSubscription: 0,
    });

    legacyInitailPostValues = legacyPostValues;     // Set Initial Post Values for Comparison
    const [legacyResetValues, setLegacyResetValues] = useState({
        legacy_Renewal: 0,
        legacy_AdditionProperties: additionalProperties,
        legacy_SuspendSubscription: 0,
        legacy_AdditionOfExistingSubscription: 0,
    });

    // Function to Get Initial Values while loading
    const onRenewal = legacyData.length > 0 ? legacyData.find(item => item.id === "Legacy_Renewal") : null;
    const suspendSubscription = legacyData.length > 0 ? legacyData.find(item => item.id === "Legacy_SuspendSubscription") : null;
    const subscriptionChangeQuantity = legacyData.length > 0 ? legacyData.find(item => item.id === "Legacy_AdditionOfExistingSubscription") : null;
    const additionPropertiesData = legacyData.length > 0 ? legacyData.find(item => item.id === "Legacy_AdditionProperties") : null;


    //Function to update Post Values while loading
    const updateLegacyRenewalState = (dataId, postBodyId) => {
        const legacyUpdatedData = legacyData.find(item => item.id === dataId);
        if (legacyUpdatedData) {
            const selectedOption = legacyUpdatedData.data.find(option => option.selected);

            if (selectedOption) {
                setLegacyPostValues(prevState => ({
                    ...prevState,
                    [postBodyId]: selectedOption.id
                }));

                setLegacyResetValues(prevState => ({
                    ...prevState,
                    [postBodyId]: selectedOption.id
                }));

            }
        }

    };

    // Call this on mount component
    useEffect(() => {
        updateLegacyRenewalState("Legacy_Renewal", "legacy_Renewal");
        updateLegacyRenewalState("Legacy_SuspendSubscription", "legacy_SuspendSubscription");
        updateLegacyRenewalState("Legacy_AdditionOfExistingSubscription", "legacy_AdditionOfExistingSubscription");
    }, []);

    // Get the values of Radio elements
    const handleRadioClick = (e, legacyOption) => {

        setLegacyPostValues(prevState => ({
            ...prevState,
            [legacyOption]: parseInt(e.target.value)
        }));
    };

    // Get the values of Check Box Elements Additional Properties
    const handleCheckBox = (e, legacyOption) => {
        const { checked } = e.target;

        if (checked) {
            // If checkbox is checked, add the value to the array
            setAdditionalProperties(prevState => prevState + (prevState ? ',' : '') + e.target.value);
        } else {
            // If checkbox is unchecked, remove the value from the array
            setAdditionalProperties(prevState => prevState.split(',').filter(item => item != e.target.value).join(','));
        }

        setLegacyPostValues(prevState => ({
            ...prevState,
            [legacyOption]: additionalProperties
        }));
    };

    // Updates Legacy Values whenver we change Addition Properties
    useEffect(() => {

        let additionalPropertiesArr = additionalProperties.split(',').map(Number).sort((a, b) => a - b).join(",").toString();

        setLegacyPostValues(prevState => ({
            ...prevState,
            legacy_AdditionProperties: additionalPropertiesArr
        }));
    }, [additionalProperties]);

    useEffect(() => {
        setLegacyPostBody(legacyPostValues);
        setIsLegacyDataChanged(!isLegacyDataChanged);
        if (additionalProperties == "0") {
            setAdditionalProperties("");
        }
    }, [legacyPostValues, additionalProperties]);

    useEffect(() => {
        if (legacyPostValues.legacy_Renewal != "0" && legacyPostValues.legacy_SuspendSubscription != "0" && legacyPostValues.legacy_AdditionOfExistingSubscription != "0") {
            setIsLegacyDataFilled(true);
        } else {
            setIsLegacyDataFilled(false);
        }

    }, [legacyPostValues]);

    useEffect(() => {
        if (isReset == true) {
            setLegacyPostValues(legacyResetValues)  // Set Initial Post Values for Comparison
            legacyInitailPostValues = legacyPostValues;
            setIsReset(false);

        }
    }, [isReset]);

    return (
        <>
            <Accordion.Item className={`mt-5 ${errorStateLegacy ? "accordionErrors" : ""}`} eventKey="1">
                <Accordion.Header>
                    <h3 className={`${styles['accordion-header-wrapper']}`}>
                        Manage Legacy Sync
                    </h3>
                </Accordion.Header>
                <Accordion.Body className='pt-0'>
                    <div className={`${styles['accordion-content']}`}>
                        <Row className="mb-3">
                            <Col md={3}>
                                <span>On Renewal <span className='red'>*</span>:</span>
                            </Col>

                            <Col md={9}>
                                <Row>
                                    {   // Renewal Settings
                                        onRenewal.data.map(item => (
                                            <Col md={4} key={item.id}>
                                                <Form.Check
                                                    type="radio"
                                                    id={`${onRenewal.id}_${item.id}`}
                                                    label={item.name}
                                                    name="legacyRenewalGroup"
                                                    value={item.id}
                                                    defaultChecked={item.selected}
                                                    onClick={(e) => handleRadioClick(e, "legacy_Renewal")}
                                                />
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={3}>
                                <span>Suspend Subscription <span className='red'>*</span>:</span>
                            </Col>
                            <Col md={9}>
                                <Row>
                                    {   // Suspend Subscription
                                        suspendSubscription.data.map(item => (
                                            <Col md={4} key={item.id}>
                                                <Form.Check
                                                    type="radio"
                                                    id={`${suspendSubscription.id}_${item.id}`}
                                                    label={item.name}
                                                    name="legacySuspendGroup"
                                                    value={item.id}
                                                    defaultChecked={item.selected}
                                                    onClick={(e) => handleRadioClick(e, "legacy_SuspendSubscription")}
                                                />
                                            </Col>
                                        ))
                                    }

                                </Row>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={3}>
                                <span>Subscription Quantity Change <span className='red'>*</span>:</span>
                            </Col>
                            <Col md={9}>
                                <Row>
                                    {   // Suspend Subscription
                                        subscriptionChangeQuantity.data.map(item => (
                                            <Col md={4} key={item.id}>
                                                <Form.Check
                                                    type="radio"
                                                    id={`${subscriptionChangeQuantity.id}_${item.id}`}
                                                    label={item.name}
                                                    name="legacySubscriptionQuantityChange"
                                                    value={item.id}
                                                    defaultChecked={item.selected}
                                                    onClick={(e) => handleRadioClick(e, "legacy_AdditionOfExistingSubscription")}
                                                />
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={3}>
                                <span>Addition Properties:</span>
                            </Col>
                            <Col md={9}>
                                <Row>
                                    {   // Renewal Settings
                                        additionPropertiesData.data.map(item => (
                                            <Col md={4} key={item.id}>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={`${additionPropertiesData.id}_${item.id}`}
                                                    label={item.name}
                                                    value={item.id}
                                                    defaultChecked={item.selected}
                                                    onClick={(e) => handleCheckBox(e, "legacy_AdditionProperties")}
                                                />
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </>
    );
}

export default LegacySync;