import { useEffect, useState, useRef } from "react";
import "./styles.scss";

const QuantityEditor = ({
    editedQuantity,
    editQuantityRef,
    setEditedQuantity,
    handleIncrease,
    handleDecrease,
    setIsEditingQuantity,
    maxQuantity,
    minQuantity,
    updatedQuanity
}) => {

    const [initQuantity, setInitQuantity] = useState(editedQuantity);

    const qunatityRefBox = useRef();
    useEffect(() => {
        // console.log("initQuantity", initQuantity);
        if (updatedQuanity == editedQuantity) {
            qunatityRefBox.current.className = "number-input";
            setIsEditingQuantity(false)
        } else {
            qunatityRefBox.current.className = "number-input active";
        }
    }, [editedQuantity]);

    return (
        <>
            <div class="number-input" ref={qunatityRefBox}>
                <input
                    type="number"
                    className="no-arrows"
                    value={editedQuantity}
                    ref={editQuantityRef}
                    min={minQuantity}
                    max={maxQuantity}
                    onChange={(e) => {
                        setEditedQuantity(e.target.value);
                        if ((e.target.value) == (updatedQuanity)) {
                            setIsEditingQuantity(false);
                        }
                        else {
                            setIsEditingQuantity(true);
                        }

                        if ((e.target.value) > maxQuantity) {
                            setEditedQuantity(maxQuantity)
                        }

                        if ((e.target.value) < minQuantity) {
                            setEditedQuantity(minQuantity)
                        }

                        if ((e.target.value) === editedQuantity) {
                            setIsEditingQuantity(false)
                        }

                    }}
                    onKeyDown={(e) => {
                        if (e.code === 'Minus') {
                            e.preventDefault();
                        }
                    }}
                />

                <div className="btn-wrapper">
                    {maxQuantity && editedQuantity < maxQuantity ?
                        <button className="plus" onClick={() => handleIncrease(editedQuantity)
                        }>+</button> :
                        <button className="plus disabled" disabled>+</button>
                    }

                    {minQuantity && editedQuantity > minQuantity ?
                        <button className="minus" onClick={
                            () => handleDecrease(editedQuantity)
                        }> <span>&nbsp;</span> </button>
                        :
                        <button className="minus disabled" disabled><span>&nbsp;</span></button>}

                </div>

            </div>
        </>
    );
}

export default QuantityEditor;