import React, { useState } from 'react'
import { Col, Nav, Row, Tab, Button } from 'react-bootstrap';
import styles from './bulkmapping.module.scss';
import { api } from '../../../../../../../../../Utils/API';
import { DONWLOAD_AGREEMENT_MAPPING_TEMPLATE, UPLOAD_AGREEMENT_MAPPING, DOWNLOAD_AGREEMENT_MAPPING, DOWNLOAD_BULK_MAPPING_MAPPING } from '../../../../../../../../../Utils/GlobalConstants';
import FileUpload from '../../../../../../../../../GlobalComponents/FileUpload';
import Toast from '../../../../../../../../../GlobalComponents/Toast';
import DataTable from 'react-data-table-component';
import loadingGif from "../../../../../../../../../Assets/Images/Animations/loading-management-console.gif";
import NoDataFound from "../../../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg";

const BulkMapping = (props) => {

    const date = new Date();     // js date time function
    const [tabName, setTabName] = useState("all");
    const [uploadedFile, setUploadedFile] = useState();                         // for uploading file 
    const [columns, setColumns] = useState([]);                                         // columns used to display column headers in the react data table component
    const mappingTableColumns = ["Customer", "Agreement Name", "Addition Name", "Addition Description", "Status", "Reason"];

    const [processing, setProcessing] = useState(false);                        // Uplaoding process
    const [isDownloadProcessing, setIsDownloadProcessing] = useState(false);    // Downloading process
    const [emptyFileError, setEmptyFileError] = useState(null);                 // Error for empty state

    const [allTabCount, setAllTabCount] = useState(0);
    const [failedCount, setFailedCount] = useState(0);
    const [successCount, setSuccessCount] = useState(0);
    const [bulkMappingTraceId, setBulkMappingTraceId] = useState("");

    const [bulkmapingTable, setBulkmapingTable] = useState([]); // All Table
    const [bulkmapingTableFail, setBulkmapingTableFail] = useState([]); // Failed Table
    const [bulkmapingTableSuccess, setBulkmapingTableSuccess] = useState([]); // Success Table
    const [selectedFile, setSelectedFile] = useState(null);

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);
    const [toastError, setToastError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    
    // Header Authorization for API
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for uploading file API
    const configFile = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download PDF API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download CSV File template
    async function DownloadTemplate(url, name, fileType) {
        setIsDownloadProcessing(true);
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url}`, configuration)
            .then(resp => {
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download!");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                    setToastMessage("Report downloaded successfully!");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 2000);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
        setIsDownloadProcessing(false);
    };

    // api for uploading file 
    async function BulkUpload() {
        setProcessing(true);
        await api.post(UPLOAD_AGREEMENT_MAPPING, uploadedFile, configFile)
            .then(resp => {
                if (resp.status == 200) {
                    setBulkMappingTraceId(resp.data.bulkMappingTraceId);
                    // setBulkmapingTable(resp.data.response);
                    setToastMessage("File uploaded successfully!");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 2000);
                    props.setUploadMappingDone(true);
                    let f = [];      // All Tab
                    let ff = [];    // Failed Tab
                    let fs = [];    // Success Tab

                    props.setBulkMappingSuccessfull(true);

                    (resp.data.response).map((obj) => {
                        f.push
                            (
                                {
                                    "Customer": obj.customerCompanyName,
                                    "Agreement Name": obj.connectwiseCustomerAgreementName != null ? obj.connectwiseCustomerAgreementName : "",
                                    "Addition Name": obj.additionName != null ? obj.additionName : "",
                                    "Addition Description": obj.additionDescription != null ? obj.additionDescription : "",
                                    "Status": obj.status !== "FAILED" ?
                                        <>
                                            <span className='d-block text-center mt-1'>
                                                <span className="status-success">&nbsp;</span><br />
                                                <span className="green text-small">Success</span>
                                            </span>
                                        </>
                                        :
                                        <>
                                            <span className='d-block text-center mt-1'>
                                                <span className="status-error">&nbsp;</span><br />
                                                <span className="red text-small">Failed</span>
                                            </span>
                                        </>,
                                    "Reason": obj.errorMessage,
                                    "UpdatedStatus": obj.status
                                }
                            );
                    });

                    // Setting All Table Data
                    setAllTabCount(f.length);
                    setTimeout(() => {
                        setBulkmapingTable(f);
                    }, 50);

                    (resp.data.response).map((obj) => {
                        if (obj.status == "FAILED") {
                            ff.push
                                (
                                    {
                                        "Customer": obj.customerCompanyName,
                                        "Agreement Name": obj.connectwiseCustomerAgreementName != null ? obj.connectwiseCustomerAgreementName : "",
                                        "Addition Name": obj.additionName != null ? obj.additionName : "",
                                        "Addition Description": obj.additionDescription != null ? obj.additionDescription : "",
                                        "Status": obj.status == "FAILED" ?
                                            <>
                                                <span className='d-block text-center mt-1'>
                                                    <span className="status-error">&nbsp;</span><br />
                                                    <span className="red text-small">Failed</span>
                                                </span>
                                            </>
                                            :
                                            <>
                                                <span className='d-block text-center mt-1'>
                                                    <span className="status-success">&nbsp;</span><br />
                                                    <span className="green text-small">Success</span>
                                                </span>
                                            </>
                                        ,
                                        "Reason": obj.errorMessage,
                                        "UpdatedStatus": obj.status
                                    }
                                );
                        }
                    });

                    // Setting Fail Table Data
                    setFailedCount(ff.length);
                    setTimeout(() => {
                        setBulkmapingTableFail(ff);
                    }, 50);

                    (resp.data.response).map((obj) => {
                        if (obj.status !== "FAILED") {
                            fs.push
                                (
                                    {
                                        "Customer": obj.customerCompanyName,
                                        "Agreement Name": obj.connectwiseCustomerAgreementName != null ? obj.connectwiseCustomerAgreementName : "",
                                        "Addition Name": obj.additionName != null ? obj.additionName : "",
                                        "Addition Description": obj.additionDescription != null ? obj.additionDescription : "",
                                        "Status": obj.status == "FAILED" ?
                                            <>
                                                <span className='d-block text-center mt-1'>
                                                    <span className="status-error">&nbsp;</span><br />
                                                    <span className="red text-small">Failed</span>
                                                </span>
                                            </>
                                            :
                                            <>
                                                <span className='d-block text-center mt-1'>
                                                    <span className="status-success">&nbsp;</span><br />
                                                    <span className="green text-small">Success</span>
                                                </span>
                                            </>
                                        ,
                                        "Reason": obj.errorMessage,
                                        "UpdatedStatus": obj.status
                                    }
                                );
                        }
                    });

                    // Setting Success Table Data
                    setSuccessCount(fs.length);
                    setTimeout(() => {
                        setBulkmapingTableSuccess(fs);
                    }, 50);


                    let d = [];
                    mappingTableColumns.map((td) => {
                        if (td === "Status") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                grow: 1.2,
                            })
                        } else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 1.2,
                            })
                        }

                    })
                    setColumns(d);      
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                } else if (error.response.status == 400) {
                    setEmptyFileError(error.response.data.errors[0])
                    // console.log("Resp", error.response.data.errors[0]);
                }
            });
        setProcessing(false);
    };

    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '300px'
            },
        },
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                textAlign: 'left',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                textAlign: 'left',
            },
        },
    };

    const conditionalRowStyles = [
        {
            when: row => row.UpdatedStatus === "FAILED",
            classNames: ["failed_row"],
        },

    ];

    return (
        <>
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

            {!props.uploadMappingDone &&
                <>
                    <div className={`${styles["upload-block"]}`}>
                        <Row>
                            <Col className={`${styles["number-block"]}`}>
                                <span>1</span>
                            </Col>
                            <Col>
                                <p>Download the bulk mapping template, pre-populated with all the unmapped Synnex subscriptions.</p>
                                <p>
                                    {!isDownloadProcessing &&
                                        <Button
                                            className="btn btn-lg px-3"
                                            variant="primary"
                                            onClick={(e) => { DownloadTemplate(DONWLOAD_AGREEMENT_MAPPING_TEMPLATE, `ConnectwiseAgreementMappinTemplate_${String(date.getMonth() + 1).padStart(2, "0")}${String(date.getDate()).padStart(2, '0')}${date.getFullYear()}.csv`, "csv") }}>Download Template </Button>
                                    }

                                    {isDownloadProcessing &&
                                        <Button
                                            variant="light"
                                            className="btn btn-lg px-3"
                                            disabled
                                        >Downloading Template...</Button>
                                    }
                                </p>


                            </Col>
                        </Row>
                        <Row>
                            <Col className={`${styles["number-block"]}`}>
                                <span>2</span>
                            </Col>
                            <Col>
                                <p>Fill ConnectWise details from the additions export file.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={`${styles["number-block"]}`}>
                                <span>3</span>
                            </Col>
                            <Col>
                                <p>Steps to export Additions on ConnectWise Portal</p>
                                <ul>
                                    <li>Go to Finance &gt; Agreements &gt; Additions Tab</li>
                                    <li>Click on Export</li>
                                    <li>Provide Company Name, Agreement Name, Sequence from the ConnectWise Additions export as ConnectWiseCompany, ConnectWiseAgreementName, ConnectWiseAdditionSequence respectively in the Bulk mapping template.</li>
                                </ul>


                            </Col>
                        </Row>

                        <Row>
                            <Col className={`${styles["number-block"]}`}>
                                <span>4</span>
                            </Col>
                            <Col>
                                <p>Upload the mapping template</p>
                                <form>
                                    <Row>
                                        <Col>
                                            <FileUpload fileFormat=".csv"
                                                setUploadedFile={setUploadedFile}
                                                selectedFile={selectedFile}
                                                setSelectedFile={setSelectedFile}
                                                setEmptyFileError={setEmptyFileError}
                                            />
                                        </Col>

                                        <Col md={12}>
                                            <p className='text-small red'> {emptyFileError && <>{emptyFileError}</>}</p>
                                        </Col>


                                        <Col md={12} className="mt-4 text-center">
                                            {!processing &&
                                                <Button
                                                    variant="light"
                                                    className="px-4 btn-border-light w-md-auto col-xs-12 mx-2"
                                                    onClick={() => {
                                                        setSelectedFile(null);
                                                        document.getElementById('file-upload').value = '';
                                                        setUploadedFile(null);
                                                        setEmptyFileError();
                                                    }}>
                                                    Cancel
                                                </Button>
                                            }
                                            {!processing && <>
                                                {!selectedFile &&
                                                    <Button
                                                        variant="light"
                                                        className="px-4 mx-2"
                                                        disabled
                                                    >
                                                        Upload
                                                    </Button>
                                                }
                                                {selectedFile && !processing &&
                                                    <Button
                                                        className="btn btn-lg px-3 mx-2"
                                                        variant="primary"
                                                        onClick={(e) => { BulkUpload() }}>
                                                        Upload
                                                    </Button>
                                                }
                                            </>}

                                            {processing &&
                                                <Button
                                                    variant="light"
                                                    className="px-4 mx-2"
                                                    disabled
                                                >
                                                    Cancel
                                                </Button>
                                            }

                                            {processing &&
                                                <Button
                                                    variant="light"
                                                    className="px-4 mx-2"
                                                    disabled
                                                >
                                                    Uploading...
                                                </Button>
                                            }
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                        </Row>
                    </div>
                </>
            }

            {props.uploadMappingDone &&
                <Tab.Container id="main-tab-bar" defaultActiveKey="all">
                    <div className="mgmt-console-table-row">
                        <div className="my-3 d-flex">

                            <Col className={`py-0 px-0 ${styles['profile-table']}`}>
                                <Tab.Container activeKey={tabName}>
                                    <Row>
                                        <Col md={8}>
                                            <Nav variant="pills" className='mb-4'>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="all" className={`bulk-mapping-tabs pl-6 pr-6 pt-3 pb-3`}
                                                        onClick={() => { setTabName("all") }}
                                                    >
                                                        All ({allTabCount})</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="failed" className={`bulk-mapping-tabs pl-6 pr-6 pt-3 pb-3`}
                                                        onClick={() => { setTabName("failed") }}
                                                    >
                                                        Failed ({failedCount})</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="success" className={`bulk-mapping-tabs pl-6 pr-6 pt-3 pb-3`}
                                                        onClick={() => { setTabName("success") }}
                                                    >
                                                        Successful ({successCount})</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col md={4} className="d-flex justify-content-md-end justify-content-start">
                                            {isDownloadProcessing &&
                                                <Button
                                                    variant="light"
                                                    className="px-4 mx-2 mb-3"
                                                    disabled>
                                                    Downloading Report...
                                                </Button>
                                            }
                                            {!isDownloadProcessing &&
                                                <Button
                                                    className="btn btn-lg px-3 mb-3"
                                                    variant="primary"
                                                    onClick={(e) => { DownloadTemplate(DOWNLOAD_BULK_MAPPING_MAPPING + "?billingTrackId=" + bulkMappingTraceId, `ConnectwiseAgreementMappingResults_${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}.csv`, "csv") }}>
                                                    Download Report
                                                </Button>
                                            }
                                        </Col>
                                        <Col lg={12}>
                                            <Tab.Content className={styles['bulk-mapping-tab-pane']}>
                                                <Tab.Pane eventKey="all">
                                                    <div className='p-0'>
                                                        <div className={`table-responsive ${styles['bulk-mapping-table']}`}>
                                                            <DataTable
                                                                columns={columns}
                                                                data={bulkmapingTable}
                                                                theme="solarized"
                                                                customStyles={customStyles}
                                                                conditionalRowStyles={conditionalRowStyles}
                                                                persistTableHead={false}
                                                                noDataComponent={<div className={styles["no-data-found"]}><img className="" src={NoDataFound} /><p className="text-medium text-strong management-console-loading-text">No Data Found</p></div>}
                                                                width="100%"
                                                                progressComponent={<div className={styles["no-data-cloud-invoice"]}>
                                                                    <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading ...</p>
                                                                </div>}
                                                                fixedHeaderScrollHeight="50vh"
                                                                fixedHeader
                                                                pagination={false}
                                                                paginationServer
                                                                paginationRowsPerPageOptions={[25, 50, 75, 100, 125, 150]}
                                                            />
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="failed">
                                                    {bulkmapingTableFail.length > 0 &&
                                                        <div className={`table-responsive ${styles['bulk-mapping-table']}`}>
                                                            <DataTable
                                                                columns={columns}
                                                                data={bulkmapingTableFail}
                                                                theme="solarized"
                                                                customStyles={customStyles}
                                                                conditionalRowStyles={conditionalRowStyles}
                                                                persistTableHead={false}
                                                                width="100%"
                                                                // noDataComponent={<div className={styles["no-data-found"]}><img className="" src={NoDataFound} /><p className="text-medium text-strong management-console-loading-text">No Data Found</p></div>}
                                                                noDataComponent={<p>No records</p>}
                                                                progressComponent={<div className={styles["no-data-cloud-invoice"]}>
                                                                    <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading ...</p>
                                                                </div>}
                                                                fixedHeaderScrollHeight="50vh"
                                                                fixedHeader
                                                                pagination={false}
                                                                paginationServer
                                                                paginationRowsPerPageOptions={[25, 50, 75, 100, 125, 150]}
                                                            />
                                                        </div>
                                                    }
                                                    {bulkmapingTableFail.length < 1 &&
                                                        <div className={styles["no-data-cloud-invoice"]}>
                                                            <img src={NoDataFound} className="no-data-found" />
                                                            <h2 className="mt-4 mb-3">No Data Found</h2>
                                                        </div>
                                                    }
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="success">
                                                    {bulkmapingTableSuccess.length > 0 &&
                                                        <div className={`table-responsive ${styles['bulk-mapping-table']}`}>
                                                            <DataTable
                                                                columns={columns}
                                                                data={bulkmapingTableSuccess}
                                                                theme="solarized"
                                                                customStyles={customStyles}
                                                                conditionalRowStyles={conditionalRowStyles}
                                                                persistTableHead={false}
                                                                noDataComponent={<div className={styles["no-data-found"]}><img className="" src={NoDataFound} /><p className="text-medium text-strong management-console-loading-text">No Data Found</p></div>}
                                                                width="100%"
                                                                progressComponent={<div className={styles["no-data-cloud-invoice"]}>
                                                                    <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading ...</p>
                                                                </div>}
                                                                fixedHeaderScrollHeight="50vh"
                                                                fixedHeader
                                                                pagination={false}
                                                                paginationServer
                                                                paginationRowsPerPageOptions={[25, 50, 75, 100, 125, 150]}
                                                            />
                                                        </div>
                                                    }
                                                    {bulkmapingTableSuccess.length < 1 &&
                                                        <div className={styles["no-data-cloud-invoice"]}>
                                                            <img src={NoDataFound} className="no-data-found" />
                                                            <h2 className="mt-4 mb-3">No Data Found</h2>
                                                        </div>
                                                    }
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>

                                        <Col md={12} className="text-right mt-3">
                                            <Button
                                                className="btn btn-lg"
                                                variant="primary"
                                                onClick={() => { props.setUploadModalOpen(false); props.setUploadMappingDone(false); }}>
                                                Ok
                                            </Button>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </Col>
                        </div >
                    </div >
                </Tab.Container>
            }
        </>

    );
}

export default BulkMapping;