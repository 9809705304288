import { Button, Col, Container, Row } from "react-bootstrap";
import styles from './integration.module.scss'
import { api } from "../../../Utils/API";
import { INTEGRATION_LISTING, DOWNLOAD_CONNECTWISE_USER_GUIDE } from '../../../Utils/GlobalConstants';
import React, { useState, useEffect } from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { XLg } from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import AccessDenied from "../../../GlobalComponents/EmptyStates/AccessDenied";
import Toast from "../../../GlobalComponents/Toast";

const Integrations = ({ toggleDrawer, setSelectService, setSelectCategory, setIntegrationContactUsFormFlag, integrationContactUsFormFlag, setComingFromIntegration, Logout }) => {

    const navigate = useNavigate();
    const [isreadOnly, setIsreadOnly] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Integration") ? true : false);       // for read only mode
    const [isDisabled, setIsDisabled] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Integration") ? true : false); // for disabled mode
    const [integrationData, setIntegrationData] = useState(null);      // storing integration data
    const [modalOpen, setModalOpen] = useState(false);                 // for opening popup which is having Connectwise Details
    const defaultServiceName = "Connectwise";
    const defaultCategoryName = "Cloud Technical Support";

    // Use State for Toasts
    const [toastError, setToastError] = useState(false);                                // toast message displaying error message 
    const [toastMessage, setToastMessage] = useState("");                               // toast message usestate

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to call integrations api
    async function GetIntegrationListing() {
        await api.get(INTEGRATION_LISTING, config)
            .then(resp => {
                // console.log("Integration Listing :", resp.data.content);
                setIntegrationData(resp.data.content);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Header Authorization for Download PDF API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/pdf") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download PDF and CSV File 
    async function DownloadAction(url, name, fileType, integrationCode) {
        let configuration;
        if (fileType === "pdf") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url}?integrationCode=${integrationCode}`, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
            });
    };

    useEffect(() => {
        localStorage.getItem("navigation_link") ? localStorage.removeItem("navigation_link") : localStorage.setItem("navigation_link", "/synnex-cloud/integrations");
        GetIntegrationListing();
    }, [])

    useEffect(() => {
        if (integrationContactUsFormFlag) {
            GetIntegrationListing();
            setIntegrationContactUsFormFlag(false);
        }
    }, [integrationContactUsFormFlag])

    return (
        <>
            <Popup
                open={modalOpen}
                onClose={() => { setModalOpen(false); }}
                className="custom-modal custom-modal-md custom-modal-ticket-details"
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header">
                            <h2>Connectwise Details</h2>
                            <XLg
                                size={24}
                                className="cpointer close-icon-popup"
                                onClick={close}
                            />
                        </div>
                        <Container>
                            <Row>
                                {integrationData && <div dangerouslySetInnerHTML={{ __html: integrationData[0].additionalDetails }} />}
                            </Row>
                        </Container>
                    </div>
                )}
            </Popup>
            <div className="main-content">
                <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

                {!isDisabled && <Container fluid>
                    <h1>Integrations</h1>
                    {
                        integrationData && integrationData.map((item) => {
                            return (
                                <Row className={`mt-4 p-2 ${styles["card"]}`}>
                                    <Col md={2} lg={2} className={`${styles["image-mobile"]}`}>
                                        <img className={`${styles['logo']} p-2`} src={item.integrationLogo} alt="" />
                                    </Col>
                                    <Col md={7} lg={8} className={`py-2 ${styles['connectwise-content-ipad-pro']}`}>
                                        <span className="d-flex align-items-center">
                                            <span className={`text-regular text-strong ${styles["heading-mobile"]}`}>{item.integrationName}</span>
                                            {item.integrationCode && !item.isIntegrated && (item.ticketStatus === null || item.ticketStatus === "INACTIVE") && <Button className={`${styles['tags-not-integrated-featured']}`}>Not Integrated</Button>}
                                            {item.integrationCode && item.isIntegrated && <Button className={`${styles['tags-integrated-featured']}`}>Integrated</Button>}
                                            {item.integrationCode && (item.ticketStatus === "COMPLETED" || item.ticketStatus === "INPROGRESS") && !item.isIntegrated && <Button className={`${styles['tags-request-in-progress']}`}>Integration Request In Progress</Button>}
                                        </span>
                                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                        {item.integrationCode === "connectwise" && <a className={`${styles['view-more']} text-small cpointer mt-1`} onClick={() => { setModalOpen(true); }}>View more</a>}
                                        {item.integrationCode != "connectwise" && <a className={`${styles['view-more']} text-small cpointer mt-1`}></a>}
                                        {item.ticketStatus === "INPROGRESS" && <span className="text-small">Your request to enable {item.integrationName} has been shared on {item.createdDate} . Please wait until synnex team approves the request.</span>}
                                    </Col>
                                    {item.integrationCode === "connectwise" &&
                                        <Col lg={2} md={3} xs={12} className={`${styles['action-button']}`}>
                                            <Button
                                                type="button"
                                                className='px-4 btn-border-light w-md-auto col-xs-12 my-2 col-md-8'
                                                variant="light"
                                                onClick={(e) => { if (item.integrationCode === "connectwise") { DownloadAction(DOWNLOAD_CONNECTWISE_USER_GUIDE, `${item.integrationName}_User_Guide.pdf`, "pdf", item.integrationCode) } }}
                                            >User Guide
                                            </Button>
                                            {!item.isIntegrated && (item.ticketStatus === null || item.ticketStatus === "INACTIVE") && <Button
                                                type="button"
                                                className={isreadOnly ? "d-none" : 'px-4 mx-2 my-2 col-md-8'}
                                                onClick={() => {
                                                    toggleDrawer(true)();
                                                    setSelectService(defaultServiceName);
                                                    setSelectCategory(defaultCategoryName);
                                                    setComingFromIntegration(true);
                                                }}
                                            >Enable
                                            </Button>}
                                            {!item.isIntegrated && item.ticketStatus === "INPROGRESS" && <Button
                                                type="button"
                                                variant="light"
                                                className={isreadOnly ? "d-none" : "px-4 mx-2 my-2 col-md-8"}
                                                disabled
                                            >Enable
                                            </Button>}
                                            {!item.isSyncronizationSetting && item.ticketStatus === "COMPLETED" && <Button
                                                type="button"
                                                onClick={() => {
                                                    navigate("/integrations/configure", {
                                                        state: { integrationId: item.integrationId }
                                                    })
                                                }}
                                                className={isreadOnly ? "d-none" : 'px-4 mx-2 my-2 col-md-8'}
                                            >Configure
                                            </Button>}
                                            {item.isIntegrated && item.isSyncronizationSetting && item.isConfigured && <Button
                                                type="button"
                                                className='px-4 mx-2 my-2 col-md-8'
                                                onClick={() => {
                                                    navigate("/integrations/manage", {
                                                        state: { integrationId: item.integrationId }
                                                    })
                                                }}
                                            >Manage
                                            </Button>}
                                            {item.isIntegrated && item.isSyncronizationSetting && !item.isConfigured && <Button
                                                variant="light"
                                                className='px-4 mx-2 my-2 col-md-8'
                                                disabled
                                            >Manage
                                            </Button>}
                                        </Col>}
                                    {item.integrationCode === "autotask" &&
                                        <Col md={3} lg={2} className={`${styles['action-button']}`}>
                                            <Button
                                                type="button"
                                                variant="light"
                                                className={isreadOnly ? "d-none" : "px-4 mx-2 my-2 col-md-8"}
                                                disabled
                                            >Coming Soon
                                            </Button>
                                        </Col>}
                                </Row>
                            );
                        })
                    }
                </Container>}
                {
                    isDisabled &&
                    <AccessDenied />
                }
            </div>
        </>
    );
}
export default Integrations;