import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { userApi } from "../../../Utils/API";
import "./styles.scss";
import PasswordToggle from '../../../GlobalComponents/PasswordToggle';
import BrandDetails from "../../../GlobalComponents/BrandDetails";
import ResetSuccess from "./ResetSuccess";
import ResetFailure from "./ResetFailure";
import CmsDetailsContext from '../../../Context/CmsDetails/CmsDetailsContext';
import { RESET_PASSWORD } from "../../../Utils/GlobalConstants";
import Footer from '../../../Layout/Footer';
import AnonymousPageFormHeader from "../../../GlobalComponents/AnonymousPageFormHeader";

const ResetPassword = () => {

  // UseState Defination
  const [userName, setUserName] = useState("SYNNEXCLOUD");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPwdError, setNewPwdError] = useState("");
  const [confirmPwdError, setConfirmPwdError] = useState("");
  const [regExError, setRegExError] = useState(false);
  const regexPassword = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,15})"
  );
  const [passwordLength, setPasswordLength] = useState("");
  const [passwordFormat, setPasswordFormat] = useState("");
  const [passwordMatch, setPasswordMatch] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(

  );
  const [enableBtn, setEnableBtn] = useState(false);
  const [bannerData, setBannerData] = useState();
  const [backgroundImageUrl, setBackgroundImageUrl] = useState();
  const [landingPageData, setLandingPageData] = useState();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [token1UrlParameter, setToken1UrlParameter] = useState("");
  const [token2UrlParameter, setToken2UrlParameter] = useState("");

  // Defining UseNavigate for react router DOM v6
  const navigate = useNavigate();
  const globalData = useContext(CmsDetailsContext);

  // Adding CSS Style to Landing Page Main Component
  const loginComponent = {
    backgroundImage: backgroundImageUrl,
    backgroundSize: "cover",
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setToken1UrlParameter(urlParams.getAll('auth')[0]);
    setToken2UrlParameter(urlParams.getAll('token')[0]);
    // console.log("URL : ",window.location.href.split("?"));
  }, []);



  // Checking Password Validation of length and regex constaint
  const getPassword = (e) => {
    setNewPassword(e.target.value);

    if (e.target.value.length !== 0) {
      if (e.target.value.length > 7 && e.target.value.length < 16) {
        setPasswordLength("green");
      } else {
        setPasswordLength("red");
      }
    }
    else {
      setPasswordLength("text-disabled");
    }

    if (e.target.value.length !== 0) {
      if (regexPassword.test(e.target.value)) {
        setPasswordFormat("green");
      } else {
        setPasswordFormat("red");
      }
    } else {
      setPasswordFormat("text-disabled");
    }

    if (e.target.value.length !== 0) {
      if (e.target.value === confirmPassword) {
        setPasswordMatch("green");
        setPasswordMatchError("Passwords are matched");
      } else {
        setPasswordMatch("red");
        setPasswordMatchError("Passwords are not matched");
      }
    } else {
      setPasswordMatchError("");
      setPasswordMatch("text-disabled");
    }

  };

  // Password is matched or not Function
  const getPasswordMatch = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value.length !== 0) {
      if (e.target.value === newPassword) {
        setPasswordMatch("green");
        setPasswordMatchError("Passwords are matched");
      } else {
        setPasswordMatch("red");
        setPasswordMatchError("Passwords are not matched");
      }
    } else {
      setPasswordMatchError("");
      setPasswordMatch("text-disabled");
    }

  };

  useEffect(() => {
    if (regexPassword.test(newPassword) && (newPassword === confirmPassword)) {
      setEnableBtn(true);
    } else {
      setEnableBtn(false);
    }

  }, [newPassword, confirmPassword]);

  // To check Password Constraints
  const checkPwd = async () => {
    if (
      newPassword !== "" &&
      confirmPassword !== "" &&
      !regexPassword.test(newPassword)
    ) {
      setRegExError(true);
    } else {
      setRegExError(false);
    }

    // userName === '' ? setUserNameError("Please enter user name") : setUserNameError('');
    newPassword === ""
      ? setNewPwdError("Please enter valid password")
      : setNewPwdError("");
    confirmPassword === ""
      ? setConfirmPwdError("Please enter confirm password")
      : setConfirmPwdError("");

    if (
      regexPassword.test(newPassword) &&
      newPassword === confirmPassword &&
      userName !== ""
    ) {
      // console.log("New Password : ", token1UrlParameter, token2UrlParameter);
      const data = {
        "token": token2UrlParameter,
        "auth": token1UrlParameter,
        "password": newPassword
      };
      const config = { headers: { "Content-Type": "application/json" } };

      await userApi
        .post(RESET_PASSWORD, data, config)
        .then((response) => {
          setShowSuccess(true);
          setTimeout(() => {
            navigate("/");
          }, 3000);
        })
        .catch((error) => {
          // console.log(error);
          setShowFailure(true);
          setTimeout(() => {
            navigate("/", {
              state: {
                bannerData: bannerData,
                bgImageUrl: backgroundImageUrl,
              },
            });
          }, 3000);
        });
    }
  };

  return (
    <>
      {globalData && <div className="d-flex flex-column login-page" style={loginComponent}>
        <Container>
          <Row>
            <Col md={6}>
              <BrandDetails bannerData={bannerData} />
            </Col>
            <Col md={6}>
              {!showSuccess && !showFailure && (
                <div>
                  <div className="anonymous-login-main-div active">
                    <Col xl={8} lg={10} md={12} className="login-block">
                      <div>
                        <AnonymousPageFormHeader page="resetPassword" pageData={landingPageData} setPageData={setLandingPageData} setBannerData={setBannerData} setBackgroundImageUrl={setBackgroundImageUrl} />
                        <div className="reset-password-form">
                          <Form>
                            <Form.Group className="mb-3" controlId="password">
                              <Form.Label>New Password</Form.Label>
                              <PasswordToggle
                                value={newPassword}
                                placeholder="Enter New Password"
                                passwordToggleEvent={getPassword}
                                maxLength="15"
                              />
                              <Form.Text className="text-danger password-validation">
                                <p>Password must contain</p>
                                <ul>
                                  <li className={passwordLength}>
                                    <span>
                                      Minimum 8 and maximum 15 characters
                                    </span>
                                  </li>
                                  <li className={passwordFormat}>
                                    <span>
                                      A mix of uppercase and lowercase characters,
                                      numbers and special characters
                                    </span>
                                  </li>
                                </ul>
                              </Form.Text>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="confirmPassword"
                            >
                              <Form.Label>Confirm New Password</Form.Label>
                              <PasswordToggle
                                value={confirmPassword}
                                placeholder="Confirm New Password"
                                passwordToggleEvent={getPasswordMatch}
                                maxLength="15"
                              />
                              <Form.Text className="text-danger password-validation">
                                {passwordMatchError && <ul>
                                  <li className={passwordMatch}>
                                    <span>
                                      {passwordMatchError}
                                    </span>
                                  </li>
                                </ul>}
                                {!passwordMatchError && <ul className="opacity-0">
                                  <li className={passwordMatch}>
                                    <span>
                                      &nbsp;
                                    </span>
                                  </li>
                                </ul>}
                              </Form.Text>
                            </Form.Group>
                            {enableBtn &&
                              <div className="hidden-btn">

                                <Button
                                  className="col-12"
                                  variant="primary"
                                  type="button"
                                  onClick={checkPwd}
                                >
                                  Confirm3
                                </Button>
                              </div>
                            }
                          </Form>

                        </div>
                      </div>
                      <div className="mobile-login-btns">
                        <div className="w-100">
                          {enableBtn ? (
                            <div className="d-block">
                              <Button
                                className="col-12"
                                variant="primary"
                                type="button"
                                onClick={checkPwd}
                              >
                                Confirm
                              </Button>
                            </div>
                          ) : (
                            <div className="d-block">
                              <Button
                                className="col-12"
                                variant="light"
                                type="button"
                                disabled
                              >
                                Confirm
                              </Button>
                            </div>
                          )}
                          <p className="text-center mt-3 login-back-link">
                            <label
                              onClick={() => {
                                navigate("/", {
                                  state: {
                                    bannerData: bannerData,
                                    bgImageUrl: backgroundImageUrl,
                                  },
                                });
                              }}
                            >
                              Back to Sign In?
                            </label>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </div>
                </div>
              )}

              {/* To display Success Reset Password Component */}
              {showSuccess && <ResetSuccess />}

              {/* To display Failure Reset Password Component */}
              {showFailure && <ResetFailure />}

            </Col>
          </Row>
        </Container>
      </div>}
    </>
  );
};

export default ResetPassword;