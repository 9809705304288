import React, { useState, useEffect } from 'react'
import { Container, Button, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import styles from './gdapRelationshipFilterBox.module.scss'

const FilterBox = (props) => {
    const [selectedStatus, setSelectedStatus] = useState([]);        // all the selected value in status

    // Function to handle "All Status" checkbox
    const handleAllStatusChange = (isChecked) => {
        setSelectedStatus(isChecked ? props.statusData.map((status) => status.id) : []);
    };

    const [searchStatusValue, setSearchStatusValue] = useState(""); // Searched Value from Customer
    const clearFilterSearchStatus = () => { // Clear Filter Box Search Input
        setSearchStatusValue("");
    }

    const clearFilterBox = () => {

        if (props.status && props.status !== null) {
            const element = document.getElementById(props.status);
            if (element) {
                element.checked = false;
            }
        };

        setSelectedStatus([]);
        props.setStatus(null);
        props.GetTableData(null, props.searchValueTable,props.sortCol,props.sortDir);
        props.setFilterFlag(false);

    }

    useEffect(() => {
        (props.status != null || props.status != undefined) ? setSelectedStatus(props.status) : setSelectedStatus([]);
    }, []);

    return (
       
        <div className= {`filter-box filter-box-xsm ${styles['filter-height']}`}>
            <Container>
                <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
                    <h2>Filters</h2>
                    <XLg size={16} onClick={() => props.setShowFilterBox(false)} />
                </Col>
                <Row className="mb-3 mobile-filter-row">
                    <Col className="filter-column">
                        <h3 className="mb-3">Status</h3>
                        <div>
                            <div className="filter-options">
                                <>
                                    {props.statusData && props.statusData.length > 0 && <Form.Check
                                        className="mb-2"
                                        type="radio"
                                        id={`statuses-all`}
                                        label="All Statuses"
                                        checked={(selectedStatus.length === props.statusData.length)}
                                        onChange={(e) => handleAllStatusChange(e.target.checked)}
                                    />}
                                    {props.statusData && props.statusData.length > 0 &&
                                        props.statusData.map((statusItem) => {
                                            return (
                                                <Form.Check
                                                    className="mb-2"
                                                    key={statusItem.id}
                                                    type="radio"
                                                    id={`status-${statusItem.id}`}
                                                    label={statusItem.name}
                                                    checked={selectedStatus.includes(statusItem.id) && !((selectedStatus.length === props.statusData.length))}
                                                    onChange={() => setSelectedStatus(statusItem.id)}
                                                />
                                            );
                                        })}
                                    {
                                        !props.statusData && <>Loading...</>
                                    }
                                </>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="actions-row">
                    <Col className="d-flex justify-content-end">
                        {(selectedStatus.length == 0) &&
                            <Button
                                className="btn btn-lg mx-3"
                                variant="light"
                                disabled
                            >
                                Clear
                            </Button>
                        }
                        {(selectedStatus.length > 0) &&
                            <Button
                                className="btn btn-lg mx-3 btn-border-light"
                                variant="light"
                                onClick={clearFilterBox}
                            >
                                Clear
                            </Button>
                        }
                        {(selectedStatus.length > 0) &&
                            <Button
                                className="btn btn-lg"
                                variant="primary"
                                onClick={() => {
                                    props.setStatus(selectedStatus);
                                    props.GetTableData(selectedStatus,props.searchValueTable,props.sortCol,props.sortDir);
                                    props.setShowFilterBox(false);
                                    props.setFilterFlag(true);
                                }
                                }
                            // disabled
                            >
                                Apply
                            </Button>
                        }
                        {(selectedStatus.length == 0) &&
                            <Button
                                className="btn btn-lg"
                                variant="light"
                                disabled
                            >
                                Apply
                            </Button>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FilterBox