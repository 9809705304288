import { React, useState, useEffect, useRef } from 'react'
import { Accordion, Button, Col, Container, Form, Row } from 'react-bootstrap';
import NCESync from './LocalComponents/NCESync';
import LegacySync from './LocalComponents/LegacySync';
import { GET_SYNCHRONIZATION_DATA } from '../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../Utils/API';
import loadingGif from "../../../../../../../Assets/Images/Animations/loading-management-console.gif";
import styles from './synchronizationSettings.module.scss';
import Toast from '../../../../../../../GlobalComponents/Toast';

const SynchronizationSettingsManage = ({ setActiveStep, setPopupOpen, Logout }) => {
  const [isreadOnly, setIsreadOnly] = useState(localStorage.getItem("read_array").includes("Integration") ? true : false);
  const integrationId = 1;                                                  // Getting Static for now
  const [activeAccordion, setActiveAccordion] = useState(['0']);              // Default Active Accordion Id is "0"
  const [isLoading, setIsLoading] = useState(true);                           // Loading State
  const [isDataChanged, setIsDataChanged] = useState(false);                  // Enable and Disable Clear and Save Buttons
  const [isProceed, setIsProceed] = useState(false);                          // Enable Save Button
  const [isProcessing, setIsProcessing] = useState(false);                    // Processing Button on Post Request
  const formRef = useRef(null);                                               // Form Ref
  const [synchronisationSuccess, setSynchronisationSuccess] = useState({
    isSuccess: "",
    message: ""
  });

  const [postErrorMsg, setPostErrorMsg] = useState(null);                         // postErrors 
  const [postErrorMsgNce, setPostErrorMsgNce] = useState("");                   // postErrors 
  const [postErrorMsgLegacy, setPostErrorMsgLegacy] = useState(null);                   // postErrors 

  // Use State for Toasts
  const [toastSuccess, setToastSuccess] = useState(false);                       // toast message displaying success message
  const [toastError, setToastError] = useState(false);                           // toast message displaying error message
  const [toastMessage, setToastMessage] = useState("");                          // toast message usestate
  const [isReset, setIsReset] = useState(false);	                               // Reset Form

  const [syncData, setSyncData] = useState({});

  //NCE Details
  const [nceData, setNCEData] = useState([]);                       // NCE Data
  const [ncePostBody, setNCEPostBody] = useState(null);             // Post API Body from NCE
  let nceInitailPostValues;

  const [isNCEDataChanged, setIsNCEDataChanged] = useState(false);  // Updated Post API Boy on Every Change
  const [isNCEDataFilled, setIsNCEDataFilled] = useState(false);
  const [nceResetData, setNCEResetData] = useState({});

  // Legacy Details
  const [legacyData, setLegacyData] = useState([]);                       // Legacy Data
  const [legacyPostBody, setLegacyPostBody] = useState(null);            // Post API Body from Legacy
  let legacyInitailPostValues;
  const [isLegacyDataChanged, setIsLegacyDataChanged] = useState(false);  // Updated Post API Boy on Every Change
  const [isLegacyDataFilled, setIsLegacyDataFilled] = useState(false);
  const [legacyResetData, setLegacyResetData] = useState({});

  // Synchronisation Details
  const [postBody, setPostBody] = useState();
  const [postBodyInit, setPostBodyInit] = useState(null);                       // If it's not null Update to postBody


  const postBodyDataReset = {
    integrationId: integrationId,
    ...nceInitailPostValues,
    ...legacyInitailPostValues
  }


  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // get api for Synchronisation Data
  async function GetSyncronizationData() {

    await api.get(`${GET_SYNCHRONIZATION_DATA}${integrationId}`, config)
      .then(resp => {
        if (resp.status == 200) {
          //  console.log("Resp", resp);
          setSyncData(resp.data);
          setNCEData(resp.data.nceSyncronizationSettings);
          setLegacyData(resp.data.legacySyncronizationSettings);
          setIsLoading(false);
        } else {
          setIsLoading(true);
        }

      })
      .catch(error => {
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        else {
          // console.log(error);
        }
      });
  };

  //post api for saving integration settings
  async function PostSynchronizationSettings() {
    setIsProcessing(true);

    const data = { ...postBody };
    const configFile = {
      headers: {
        "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };



    await api.post(GET_SYNCHRONIZATION_DATA, data, configFile)
      .then(resp => {
        if (resp.status = 200) {

          setToastMessage(resp.data.message);
          setToastSuccess(true);
          setIsProceed(false);
          setIsProcessing(false);
          setIsDataChanged(false);
          setPostErrorMsg(null);
          setPostErrorMsgLegacy(null);
          setPostErrorMsgNce(null);
          setActiveAccordion(['0']);
          setIsNCEDataFilled(false);
          GetSyncronizationData();
          setPostBodyInit(postBody);

          setIsReset(false);


          setTimeout(() => {
            setToastSuccess(false);
          }, 5000);



        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        setPostErrorMsg(error.response.data.errors);
        setToastMessage("We encountered an issue while submitting the request. Please try again later.");
        setPostErrorMsgLegacy()
        setToastError(true);
        setIsProceed(false);
        setIsProcessing(false);
        setIsDataChanged(false);
        setTimeout(() => {
          setToastError(false);
        }, 5000);
      });

  };


  // Reset Form To Initial State
  const resetForm = () => {
    setIsDataChanged(false);
    setIsProceed(false);
    setActiveAccordion("0");
    setIsNCEDataFilled(false);
    setIsLegacyDataFilled(false);
    setPostBody(postBodyInit);

    setIsReset(true);

    if (formRef.current) {
      formRef.current.reset();
    }
  }


  useEffect(() => {
    postErrorMsg &&
      setPostErrorMsgLegacy(postErrorMsg.Legacy_AdditionProperties);
    postErrorMsg &&
      setPostErrorMsgNce(postErrorMsg.Nce_AdditionProperties);
  }, [postErrorMsg]);


  //useEffect for calling get api on initial load
  useEffect(() => {
    GetSyncronizationData();
    setIsDataChanged(false);
    setIsProceed(false);
  }, []);

  // Post Body To send to Post API
  useEffect(() => {
    setPostBody({
      integrationId: integrationId,
      ...ncePostBody,
      ...legacyPostBody
    });

  }, [isNCEDataChanged, isLegacyDataChanged]);

  useEffect(() => {
    if (nceData.length > 0) {
      let subArray = [];
      nceData.map((item) => {
        // console.log("Outsidest",item)
        item.data.map((sub) => {
          // console.log("Outside",sub.selected);
          subArray.push(sub.selected);
        }
        )
        // console.log('Sub Array : ', subArray.includes(true));
        if (subArray.includes(true)) {
          setPopupOpen(false);
        }
        else {
          setPopupOpen(true);
        }
      })
    }
  }, [nceData])


  // To update Enable and Disable Clear and Save Button
  useEffect(() => {

    // console.log("Initial Data", postBodyInit);
    // console.log("Updated Data", postBody);

    if (postBodyInit == null || Object.keys(postBodyInit).length == 1 || postBodyDataReset == postBodyInit) {
      setPostBodyInit({
        integrationId: integrationId,
        ...ncePostBody,
        ...legacyPostBody
      });
      setNCEResetData({ ...ncePostBody });
      setLegacyResetData({ ...legacyPostBody });
    }

    if (JSON.stringify(postBodyInit) == JSON.stringify(postBody)) {
      setIsProceed(false);
      setIsDataChanged(false)
    } else {
      setIsDataChanged(true);
      setIsProceed(true);
    }
  }, [postBody]);

  useEffect(() => {
    if (postBody && (postBody.nce_Renewal != "0" && postBody.nce_PartialUpgradeSubscription != "0" && postBody.nce_ChangeQuantitySubscription != "0")) {
      setIsNCEDataFilled(true);
    } else {
      setIsNCEDataFilled(false);
    }

    if (postBody && (postBody.legacy_Renewal != "0" && postBody.legacy_SuspendSubscription != "0" && postBody.legacy_AdditionOfExistingSubscription != "0")) {
      setIsLegacyDataFilled(true);
    } else {
      setIsLegacyDataFilled(false);
    }

  }, [postBody]);


  return (
    <>
      <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />

      <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

      {isLoading == true &&
        <>
          <div className={styles["loading-customers"]}><img className="management-console-loading-icon" src={loadingGif} />
            <p className="text-medium text-strong management-console-loading-text">Loading...</p>
          </div>
        </>
      }

      {isLoading == false &&
        <>
          <div className="accordion-panel">
            <>
              <form ref={formRef}>
                <Accordion
                  activeKey={activeAccordion}
                  onSelect={(e) => { setActiveAccordion(e); }}
                >
                  {nceData.length > 0 &&
                    <NCESync nceData={nceData}
                      setNCEData={setNCEData}
                      setNCEPostBody={setNCEPostBody}
                      isNCEDataChanged={isNCEDataChanged}
                      setIsNCEDataChanged={setIsNCEDataChanged}
                      nceInitailPostValues={nceInitailPostValues}
                      isReset={isReset}
                      setIsReset={setIsReset}
                      syncData={syncData}
                      setIsNCEDataFilled={setIsNCEDataFilled}
                      errorStateNCE={postErrorMsgLegacy ? true : false}
                      isreadOnly={isreadOnly}
                    />
                  }

                  {
                    postErrorMsgNce && postErrorMsgNce.map(err => {
                      return (
                        <>
                          <p className='text-small red my-2'>{err}</p>
                        </>)
                    })
                  }

                  {legacyData.length > 0 &&
                    <LegacySync
                      legacyData={legacyData}
                      setLegacyData={setLegacyData}
                      setLegacyPostBody={setLegacyPostBody}
                      isLegacyDataChanged={isLegacyDataChanged}
                      setIsLegacyDataChanged={setIsLegacyDataChanged}
                      legacyInitailPostValues={legacyInitailPostValues}
                      isReset={isReset}
                      setIsReset={setIsReset}
                      syncData={syncData}
                      setIsLegacyDataFilled={setIsNCEDataFilled}
                      errorStateLegacy={postErrorMsgLegacy ? true : false}
                      isreadOnly={isreadOnly}
                    />
                  }

                  {
                    postErrorMsgLegacy && postErrorMsgLegacy.map(err => {
                      return (
                        <>
                          <p className='text-small red my-2'>{err}</p>
                        </>)
                    })
                  }


                </Accordion>
                <Row className={isreadOnly ? "d-none" : "my-2 align-items-center d-none-mobile"}>


                  <Col className="my-2 text-right btn-wrapper-outer">
                    {isDataChanged == true && isProcessing == false &&
                      <Button
                        className="btn-border-light w-md-auto col-xs-12 mb-3"
                        variant="light"
                        type='reset'
                        onClick={() => { resetForm() }}
                      >
                        Cancel
                      </Button>
                    }

                    {isDataChanged == false &&
                      <Button
                        className="btn-border-light w-md-auto col-xs-12 mb-3"
                        variant="light"
                        disabled
                      >
                        Cancel
                      </Button>
                    }

                    {isProcessing == true &&
                      <>
                        <Button
                          className="btn-border-light w-md-auto col-xs-12 mb-3"
                          variant="light"
                          disabled
                        >
                          Cancel
                        </Button>

                        <Button
                          disabled
                          className="btn-border-light w-md-auto col-xs-12 mb-3 ml-3"
                          variant="light"
                        >
                          Processing . . .
                        </Button>
                      </>
                    }

                    {(isProceed == true && isNCEDataFilled == true && isLegacyDataFilled == true) && isProcessing == false &&
                      <Button
                        className="ml-3 px-4 mb-3"
                        onClick={() => PostSynchronizationSettings()}
                      >
                        Save
                      </Button>
                    }

                    {(isProceed == false || isNCEDataFilled == false || isLegacyDataFilled == false) && isProcessing == false &&
                      <Button
                        className="btn-border-light w-md-auto col-xs-12 mb-3 ml-3 px-4 mb-3"
                        variant="light"
                        disabled
                      >
                        Save
                      </Button>
                    }

                  </Col>

                </Row>
              </form>
            </>
          </div>
        </>
      }

    </>
  )
}

export default SynchronizationSettingsManage