import React, { useState, useEffect } from 'react'
import { BarChart } from '@mui/x-charts/BarChart';
import { Bell } from "react-bootstrap-icons";
import { Col, Form, NavDropdown, Row } from 'react-bootstrap';
import styles from './subscriptionrenewal.module.scss'
import Summary from './LocalComponents/Summary';
import { CUSTOMER_DOWNLOAD_CSV_SUMMARY, DASHBOARD_SUBS_RENEW, DASHBOARD_SUBS_RENEW_DOWNLOAD, GET_SERVICES } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';
import Toast from '../../../../../GlobalComponents/Toast';

const SubscriptionsRenewal = ({ data, SaveDashboardWidget, id, widgetPositionData, Logout }) => {
    const date = new Date();                                                    // for js date time function

    const [barChartData, setBarChartData] = useState([
        { data: [0, 0, 0], stack: 'A', label: 'Scheduled subscriptions' },
        { data: [0, 0, 0], stack: 'A', label: 'Non-sceduled subscriptions' },
    ])
    const [barChartDataSeries, setBarChartDataSeries] = useState([])            // for x-axisdata in api

    const [selectedItem2, setSelectedItem2] = useState('Total'); // State for column 2 dropdown
    const [selectedItem3, setSelectedItem3] = useState('Microsoft CSP'); // State for column 3 dropdwon

    const [summaryPopup, setSummaryPopup] = useState(false);                            // My Profile Update failure flag
    const [tabName, setTabName] = useState("Summary");
    const [integrationCode, setIntegrationCode] = useState("microsoftsaas");
    const [serviceId, setServiceId] = useState("");

    // Use State for Toasts
    const [toastError, setToastError] = useState(false);                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download CSV API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download CSV File 
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url}?serviceId=${serviceId}&type=${selectedItem2}&integrationCode=${integrationCode}`, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to call subscription renewal widget
    async function GetAllSubscriptionRenewalCount() {
        await api.get(`${DASHBOARD_SUBS_RENEW}?type=${selectedItem2}&integrationCode=${integrationCode}&serviceId=${serviceId}`, config)
            .then(resp => {
                let localData = [];
                resp.data.data.map((data) => {
                    if (data.data.length === 0) {
                        localData.push({
                            "data": [0, 0, 0],
                            "stack": "A",
                            "label": "Expiring subscriptions"
                        })
                    }
                    else {
                        localData.push(data)
                    }
                })
                setBarChartData(localData);
                setBarChartDataSeries(resp.data.xAxisData);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    async function GetAllServices(selectedServiceName) {
        await api.get(`${GET_SERVICES}`, config)
            .then(resp => {
                resp.data.map((service) => {
                    if (service.name === selectedServiceName) {
                        setServiceId(service.id);
                        setIntegrationCode(service.integrationCode);
                        setSelectedItem3(selectedServiceName);
                    }
                })
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };


    // Function to delete widget in dashboard
    async function DeleteWidget() {
        let body = [];
        widgetPositionData.map((wd) => {
            if (wd.widgetName !== "add" && wd.id !== id) {
                body.push(wd);
            }
        })
        SaveDashboardWidget(body, true);
    };

    // Define a function to handle selecting a service from the dropdown
    const handleSelectService = (selectedServiceName) => {
        GetAllServices(selectedServiceName);
    };

    useEffect(() => {
        GetAllServices(selectedItem3);
        if (serviceId != "")
            GetAllSubscriptionRenewalCount();
    }, [selectedItem2, integrationCode, serviceId]);

    // function for dropdown data
    const createHandleSelect = (setSelectedItem) => (eventKey) => {
        setSelectedItem(eventKey);
    };

    return (
        <>
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

            <Row className={`${styles["widget-header"]} align-items-center pt-0`}>
                <Col md={6} xs={6}>
                    <h3 className='mb-0'>Subscriptions Renewal</h3>
                </Col>
                <Col md={6} xs={6} className="text-right">
                    <span onClick={() => { setSummaryPopup(true); setTabName("Summary"); }} className="dashboard-arrow mx-1 cpointer d-none-mobile"></span>
                    <span className="dashboard-circle-dot mx-1 cpointer d-none-mobile" onClick={() => { setSummaryPopup(true); setTabName("Details"); }}></span>
                    <span className="dashboard-download mx-1 cpointer d-none-mobile"
                        onClick={() => { DownloadAction(DASHBOARD_SUBS_RENEW_DOWNLOAD, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_subscriptions-renewal.csv`, "csv") }}>
                    </span>
                    <span className="dashboard-cross mx-1 cpointer" onClick={() => DeleteWidget()}></span>
                </Col>
                {
                    summaryPopup &&
                    <Summary setSummaryPopup={setSummaryPopup} summaryPopup={summaryPopup} tabName={tabName} setTabName={setTabName} selectedItem2={selectedItem2} setSelectedItem2={setSelectedItem2} selectedItem3={selectedItem3} setSelectedItem3={setSelectedItem3} integrationCode={integrationCode} setIntegrationCode={setIntegrationCode} serviceId={serviceId} setServiceId={setServiceId} Logout={Logout} />
                }
            </Row>
            <Row className={`pt-2 pb-0 ${styles["widget-header"]} `}>
                <Col lg={1} md={4} xs={1}>
                </Col>
                <Col lg={5} md={4} xs={5}>
                    <Form.Group>
                        <NavDropdown title={selectedItem2} id="cloud-service-dropdown-2" className={`servcies-dropdown ${styles['dropdown-disabled']}`} onSelect={createHandleSelect(setSelectedItem2)}>
                            <NavDropdown.Item eventKey="Total"
                                className={selectedItem2 === "Total" ? "selected" : ""}
                            >Total</NavDropdown.Item>
                            <NavDropdown.Item eventKey="Renewing"
                                className={selectedItem2 === "Renewing" ? "selected" : ""}
                            >Renewing</NavDropdown.Item>

                        </NavDropdown>
                    </Form.Group>
                </Col>
                <Col lg={6} md={4} xs={6}>
                    <Form.Group>
                        <NavDropdown title={selectedItem3} id="cloud-service-dropdown-3" className={`servcies-dropdown ${styles['dropdown-disabled']}`} onSelect={handleSelectService}>
                            <NavDropdown.Item eventKey="Microsoft CSP"
                                className={selectedItem3 === "Microsoft CSP" ? "selected" : ""}
                            >Microsoft CSP</NavDropdown.Item>
                            <NavDropdown.Item eventKey="Google Workspace"
                                className={selectedItem3 === "Google Workspace" ? "selected" : ""}
                            >Google Workspace</NavDropdown.Item> </NavDropdown>
                    </Form.Group>
                </Col>
            </Row>
            {barChartData && <BarChart
                series={barChartData}
                grid={{ horizontal: true, vertical: true }}
                slotProps={{
                    legend: {
                        direction: 'row',
                        position: { vertical: 'bottom', horizontal: 'middle' },
                        padding: 0,
                        labelStyle: {
                            fill: '#6a6a6a'
                        },
                    },
                }}
                colors={selectedItem2 === "Renewing" ? ['#77BA1D', '#5A63E7'] : ['#3599DA', '#F9A932']}
                xAxis={[{
                    data: barChartDataSeries,
                    scaleType: 'band',
                    tickPlacement: 'middle',
                    categoryGapRatio: 0.7,
                    tickLabelPlacement: 'middle',
                }]}
                yAxis={[{
                    tickNumber: 5,
                    tickMinStep: 1,
                    // valueFormatter: (value) => `$ ${(value / 1000).toLocaleString()}K`,
                }]}
                margin={{ top: 10, bottom: 70, left: 50, right: 50 }}
            />}
        </>
    )
}

export default SubscriptionsRenewal
