import React , {useState,useEffect} from 'react'
import { cmsApi } from '../../../Utils/API';
import { LANDING_PAGE_CMS_DATA } from '../../../Utils/GlobalConstants';

const PublicTermsAndCondition = () => {

    const [termsAndConditionsData, setTermsAndConditionsData] = useState([]);       // Storing terms and condition data

    // Function for calling Terms And Condition data from Orchid Core CMS
    async function GetTermsAndConditionData() {
        const data = `{
            termsCondition {
                content {
                html
                }
            }
        }`;
    
        // Defining Header Configuration
        const config = {
          headers: {
            "Content-Type": "application/graphql",
            Accept: "application/graphql",
          },
        };
    
        // Axios Post Request
        await cmsApi
          .post(LANDING_PAGE_CMS_DATA, data, config)
          .then((resp) => {
            // handle success
            // console.log(resp.data.data.termsCondition[0].content.html);
            setTermsAndConditionsData(
                resp.data.data.termsCondition[0].content.html
            );
          })
          .catch((error) => {
            // handle error
            // console.log(error);
          });
      }

    // UseEffect Defination to call the get TermsAndCondition items function from CMS
    useEffect(() => {
        GetTermsAndConditionData();
      }, []);

  return (
    <div className='px-4 mb-6' dangerouslySetInnerHTML={{__html:termsAndConditionsData}}>
    </div>
  )
}

export default PublicTermsAndCondition
