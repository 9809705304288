import React , {useState,useEffect, useContext} from 'react'
import LandingPageHeader from '../../Modules/Anonymous/LandingPage/Layout/Header'
import CmsDetailsContext from '../../Context/CmsDetails/CmsDetailsContext';
import { cmsApi } from '../../Utils/API';
import { LANDING_PAGE_CMS_DATA } from '../../Utils/GlobalConstants';
import styles from './unautorisedNavBar.module.scss';

const UnautorisedNavbar = ({cookieAccepted}) => {

    const globalData = useContext(CmsDetailsContext);

    const [landingPageData, setLandingPageData] = useState(null);
    const [backgroundImageData, setBackgroundImageData] = useState(null);
  
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
        
      // Scroll to Top on Initial loading
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    // Function for calling Landing Page item data from Orchid Core CMS
    async function GetLandingPageData() {
        const data = `
            {
            siteHomeContent {
                navLink {
                contentItems {
                    ... on ExternalItemLink {
                    linkUrl
                    displayText
                    }
                }
                }
                formEndpoint {
                contentItems {
                    ... on FormEndpoint {
                    formName
                    endpoint
                    }
                }
                }
            }
            }`;
    
        // Defining Header Configuration
        const config = {
            headers: {
            "Content-Type": "application/graphql",
            Accept: "application/graphql",
            },
        };
    
        // Axios Post Request
        await cmsApi
            .post(LANDING_PAGE_CMS_DATA, data, config)
            .then((resp) => {
            // handle success
            // console.log(resp.data.data.siteHomeContent[0]);
            setLandingPageData(
                resp.data.data.siteHomeContent[0]
            );
            })
            .catch((error) => {
            });
        }
    
    // Function for calling Landing Page item data from Orchid Core CMS
    async function GetBgImageData() {
    const data = `{
        siteImages {
            desktopImage {
            urls
            paths
            }
            mobileImage {
            urls
            paths
            }
            tabletImage {
            urls
            paths
            }
        }
        }`;

    // Defining Header Configuration
    const config = {
        headers: {
        "Content-Type": "application/graphql",
        Accept: "application/graphql",
        },
    };

    // Axios Post Request
    await cmsApi
        .post(LANDING_PAGE_CMS_DATA, data, config)
        .then((resp) => {
        // handle success
        // console.log("Background Image Data",resp.data.data.siteImages[0]);
        // setLandingPageData(
        //   resp.data.data.siteHomeContent[0]
        // );
        setBackgroundImageData(resp.data.data.siteImages[0])
        })
        .catch((error) => {
        });
    }

    // UseEffect Defination to call the get Landing Page items function from CMS
    useEffect(() => {
            GetLandingPageData();
            GetBgImageData();
    }, []);
     
    const [imagePath, setImgPath] = useState("");
    useEffect(() => {
        if(backgroundImageData && globalData && windowWidth){
        if(window.innerWidth > 1400) {
        setImgPath(globalData.baseUrl + backgroundImageData.desktopImage.urls)
            }
    
        if(window.innerWidth >= 768 && window.innerWidth <= 1400) {
        setImgPath(globalData.baseUrl + backgroundImageData.tabletImage.urls)
        }
    
        if(window.innerWidth < 768) {
        setImgPath(globalData.baseUrl + backgroundImageData.mobileImage.urls)
            }
        }
        
    
    },[backgroundImageData,globalData,windowWidth])
    
    useEffect(() => {
        function handleResize() {
        setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

  return (
    <>
        {backgroundImageData && globalData && <div className={`${styles['landing-page-upper']}`} style={{
            backgroundImage: `url(${imagePath})`,}}>
            <LandingPageHeader landingPageData={landingPageData} cookieAccepted={cookieAccepted}/>
        </div>}
    </>
  )
}

export default UnautorisedNavbar
