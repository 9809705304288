import React, { useState } from 'react'
import { Container, Button, Row, Col } from "react-bootstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import styles from './SummaryFilterBox.module.scss'

const SummaryFilterBox = (props) => {

    const [searchServiceValue, setSearchServiceValue] = useState(""); // Searched Value from Service
    const clearFilterSearchService = () => { // Clear Filter Box Search Input
        setSearchServiceValue("");
    }

    const clearFilterBox = () => {
        if (props.serviceId && props.serviceId !== null) {
            const element = document.getElementById(props.serviceId);
            if (element) {
                element.checked = false;
            }
        };
        props.setServiceId(null);
        props.setScrollToDivTop(true);
        props.setTableFilterApplied(false);
        props.setPageNo(1);
        props.GetTableData(1, props.pageSize, props.searchValueTable, null, props.invoiceDateFrom, props.invoiceDateTo, props.sortCol, props.sortDir, null, null);
        props.setFilterFlag(false);
    }

    return (
        <div className="filter-box filter-box-sm">
            <Container>
                <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
                    <h2>Filters</h2>
                    <XLg size={16} onClick={() => props.setShowFilterBox(false)} />
                </Col>
                <Row className={`mb-3 mobile-filter-row ${styles['ipad-pro-filterbox']}`}>
                    <Col className={`filter-column ${styles['filter-column-height']}`}>
                        <h3 className="mb-3">Service</h3>
                        <div>
                            <InputGroup className="search-input-group mb-3">
                                <Form.Control
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="search"
                                    value={searchServiceValue}
                                    onChange={(e) => setSearchServiceValue(e.target.value)}
                                />
                                <InputGroup.Text class="clear-search" onClick={clearFilterSearchService}><span>&nbsp;</span></InputGroup.Text>
                            </InputGroup>
                            <div className="filter-options">

                                {
                                    props.serviceData &&
                                    props.serviceData.filter(option => (option.name).toLowerCase().includes(searchServiceValue.toLowerCase())).map((filteredOption) => {
                                        if (filteredOption.serviceType == "ONLINE") {
                                            return (
                                                <>
                                                    <Form.Check // prettier-ignore
                                                        key={filteredOption.id}
                                                        type={'radio'}
                                                        id={filteredOption.id}
                                                        label={filteredOption.name}
                                                        name="servicesGroup"
                                                        value={filteredOption.id}
                                                        aria-label={filteredOption.name}
                                                        defaultChecked={filteredOption.id == props.serviceId ? true : false}
                                                        onChange={(e) => {
                                                            (e.target.value === "default") ? props.setServiceId(null) : props.setServiceId(e.target.id);
                                                        }
                                                        }
                                                    />
                                                </>
                                            )
                                        }
                                    })
                                }

                                {
                                    !props.serviceData && <p>Loading...</p>
                                }
                            </div>
                        </div>
                    </Col>

                </Row>
                <Row className="actions-row">
                    <Col className="d-flex justify-content-end">
                        {(!props.serviceId) &&
                            <Button
                                className="btn btn-lg mx-3"
                                variant="light"
                                disabled
                            >
                                Clear
                            </Button>
                        }
                        {(props.serviceId) &&
                            <Button
                                className="btn btn-lg mx-3 btn-border-light"
                                variant="light"
                                onClick={clearFilterBox}>
                                Clear
                            </Button>
                        }
                        {(props.serviceId) &&
                            <Button
                                className="btn btn-lg"
                                variant="primary"
                                onClick={() => {
                                    props.setPageNo(1);
                                    props.setScrollToDivTop(true);
                                    props.setTableFilterApplied(true);
                                    props.GetTableData(1, props.pageSize, props.searchValueTable, props.serviceId, props.invoiceDateFrom, props.invoiceDateTo, props.sortCol, props.sortDir, props.customerId, props.status, true, false);
                                    props.setShowFilterBox(false);
                                    props.setFilterFlag(true);
                                }
                                }
                            >
                                Apply
                            </Button>
                        }
                        {(!props.serviceId) &&
                            <Button
                                className="btn btn-lg"
                                variant="light"
                                disabled
                            >
                                Apply
                            </Button>
                        }
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

export default SummaryFilterBox