import React, { useEffect, useRef, useState } from 'react'
import styles from './createGdap.module.scss'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { GDAP_CREATE_GET, GDAP_CREATE_POST } from '../../../../../../../Utils/GlobalConstants';
import { api } from "../../../../../../../Utils/API";
import loadingGif from "../../../../../../../Assets/Images/Animations/loading-management-console.gif"

const CreateGDAP = ({ vendorAccountId, setToastSuccess, setToastError, setToastMessage, setTabName, Logout }) => {

  const [gdapData, setGdapData] = useState(null);                                          // storing searching value in the search input box
  const [value, setValue] = useState(null);                                                // storing searching value in the search input box
  const [defaultChecked, setDefaultChecked] = useState(null);                              // storing searching value in the search input box
  const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);                     // storing searching value in the search input box
  const [checkedItems, setCheckedItems] = useState({});                                    // checked items 
  const [checkedIds, setCheckedIds] = useState([]);                                        // checked ID's 
  const [defaultCheckedIds, setDefaultCheckedIds] = useState([]);                          // storing searching value in the search input box

  const [defaultGdapData, setDefaultGdapData] = useState(gdapData);                       // default storage of value for searchbox
  const [searchValueTable, setSearchValueTable] = useState(null);                         // storing searching value in the search input box
  const [searchIconFlag, setSearchIconFlag] = useState(false);                            // to open and close search box
  const searchInputRef = useRef();                                                        // Search Input Box

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  //get api call for creating gdap
  async function GetCreateGdapData() {
    await api.get(`${GDAP_CREATE_GET}?vendorAccountId=${vendorAccountId}`, config)
      .then(resp => {
        setGdapData(resp.data);
        setValue(resp.data.duration);
        setDefaultGdapData(resp.data);

        const defaultCheckedItems = {};
        resp.data.entraRoles.map((checkbox) => {
          if (checkbox.isDefault) {
            defaultCheckedItems[checkbox.roleName] = true;
            defaultCheckedIds.push(checkbox.roleDefinitionId);
          }
        });

        setCheckedItems(defaultCheckedItems);
        setDefaultChecked(defaultCheckedItems);
        setCheckedIds(defaultCheckedIds);

      })
      .catch(error => {
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

  //post api for creating create gdap
  async function PostCreateGdap() {
    setConfirmLoadingFlag(true);
    const requestBody = {
      "vendorAccountId": vendorAccountId,
      "domainName": gdapData?.domainName,
      "duration": value.toString(),
      "entraRoles": checkedIds
    };
    await api.post(GDAP_CREATE_POST, requestBody, config)
      .then(resp => {
        if (resp.status == 200) {
          setConfirmLoadingFlag(false);
          setToastMessage("New GDAP request has been sent successfully.")
          setToastSuccess(true);
          setCheckedItems(defaultChecked);
          setValue(gdapData?.duration);
          setCheckedIds(defaultCheckedIds);
          setTimeout(() => {
            setToastSuccess(false);
          }, 5000);
          setTabName("GDAP Relationships");
        }
      })
      .catch(error => {
        setConfirmLoadingFlag(false);

        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        else {
          setToastMessage(error.message);
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 5000);
        }
      });
  }

  // On search field value we trigger this function having react debugging after every 800 ms
  useEffect(() => {
    if (searchValueTable !== undefined && searchValueTable !== null) {
      if (searchValueTable === "") {
        setGdapData(defaultGdapData);
      }
      else {
        const getData = setTimeout(() => {
          let localData = [];
          gdapData && gdapData.entraRoles.map(item => {
            if (item.roleName.toLowerCase().includes(searchValueTable.toLowerCase())) {
              localData.push(item);
            }
          })
          // console.log("localData :", localData);
          setGdapData({
            "tenantId": defaultGdapData.tenantId,
            "domainName": defaultGdapData.domainName,
            "duration": defaultGdapData.duration,
            "entraRoles": localData
          });
        }, 800);
        return () => clearTimeout(getData);
      }
    }
  }, [searchValueTable])

  const handleCheckboxChange = (e, checkbox) => {
    const { name, checked } = e.target;
    setCheckedItems({ ...checkedItems, [name]: checked });

    // Update checkedIds state
    if (checked) {
      setCheckedIds([...checkedIds, checkbox.roleDefinitionId]);
    } else {
      setCheckedIds(checkedIds.filter(id => id !== checkbox.roleDefinitionId));
    }
    // console.log(`Checkbox ${name} is ${checked ? 'checked' : 'unchecked'}`);
    // console.log('Checked Items:', checkedItems);
    // console.log('Checked IDs:', checkedIds);
  };

  // for editing button control
  const arraysAreEqual = () => {

    const rolesArray = Object.values(checkedIds);
    const rolesArrayDefault = Object.values(defaultCheckedIds);

    const result = rolesArrayDefault.map(item => rolesArray?.includes(item));
    if (result.includes(false)) {
      return false;
    }
    return result;
  };

  useEffect(() => {
    GetCreateGdapData()
  }, []);

  return (
    <>
      <div className={`mt-4 mx-4 ${styles['gdap-height']}`}>
        {gdapData && <>
          <Row>
            <Col md={8}>
              <span className='text-medium'>Microsoft Entra Roles<span className="red">*</span></span>
              <Row>
                <Col md={4} className='text-strong text-medium notes-confirmation'>{checkedIds?.length} {checkedIds?.length > 1 ? "Roles" : "Role"} selected</Col>
                <Col md={8}>
                  <InputGroup className="search-input-box search-input-box-large">
                    <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                      <span className="searchgrey cpointer"
                      >
                        &nbsp;
                      </span>
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      className="search-input-box-input"
                      value={searchValueTable}
                      onChange={(e) => setSearchValueTable(e.target.value)}
                      ref={searchInputRef}
                    />
                    <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                      onClick={() => {
                        setSearchValueTable("");
                        setSearchIconFlag(!searchIconFlag);
                        setGdapData(defaultGdapData);
                      }}>
                      <span className="closebtn">
                        &nbsp;
                      </span>
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
              <Row className={`mt-3`}>
                <Col md={6}>
                  <div className='text-strong text-medium'>Default Roles:</div>
                  <div key="inline-checkbox" className={`mb-3 ${styles['create-gdap-height']}`}>
                    {gdapData && gdapData.entraRoles.map((checkbox, index) => {
                      if (checkbox.isDefault) {
                        return (
                          <Form.Check
                            key={checkbox.roleDefinitionId}
                            className='mt-2'
                            label={checkbox.roleName}
                            name={checkbox.roleName}
                            type='checkbox'
                            checked={checkedItems[checkbox.roleName] || false} // Set checked state based on the state variable
                            onChange={(e) => handleCheckboxChange(e, checkbox)}
                          />
                        )
                      }
                    })}
                  </div>
                </Col>
                <Col md={6}>
                  <div className='text-strong text-medium'>Other Roles:</div>
                  <div key="inline-checkbox" className={`mb-3 ${styles['create-gdap-height']}`}>
                    {gdapData && gdapData.entraRoles.map((checkbox, index) => {
                      if (!checkbox.isDefault) {
                        return (
                          <Form.Check
                            key={checkbox.roleDefinitionId} // Use a unique key for each checkbox in the array
                            className='mt-2'
                            label={checkbox.roleName}
                            name={checkbox.roleName}
                            type='checkbox'
                            checked={checkedItems[checkbox.roleName] || false}
                            onChange={(e) => handleCheckboxChange(e, checkbox)}
                          />
                        )
                      }
                    })}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <span className='text-medium text-strong'>Microsoft ID : <span >{vendorAccountId}</span></span>
              <div className='notes-confirmation'>
                <span className='text-medium text-strong'>Domain name : <span >{gdapData?.domainName}</span></span>
              </div>
              <Form className='mt-5'>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label className={`text-strong ${styles['form-label-color']}`} column md="5">
                    Duration (in days)<span className="red">*</span> :
                  </Form.Label>
                  <Col md="7">
                    <Form.Control
                      type="text"
                      value={value}
                      maxlength="10"
                      onChange={(e) => {
                       const inputValue = e.target.value;
                        if (inputValue === '' || /^[1-9][0-9]*$/.test(inputValue)) {
                          if (inputValue !== '') {
                            const newValue = Math.min(parseInt(inputValue, 10), 730);
                            setValue(newValue);
                          } else {
                            setValue('');
                          }
                        } else {
                          setValue('');
                        }
                      }}
                    />
                  </Col>
                </Form.Group>
              </Form>

            </Col>
          </Row>
          <Row className='mt-4'>
            <Col lg={8} md={6}>
              {!arraysAreEqual() && <p className='red text-small notes-confirmation'> Please note that you have opted for custom
                granular delegated admin permissions. If you haven’t selected all the default roles
                recommended by Microsoft (for Indirect providers), Synnex Australia will have limited
                ability to assist your customers with their support requests.</p>}
            </Col>
            <Col lg={4} md={6}>
              <div className="btn-wrapper mt-5 text-right">
                {/* when length of selected item is graeter than 0 */}
                {
                  <>
                    {!confirmLoadingFlag &&
                      <>
                        {((JSON.stringify(defaultChecked) !== JSON.stringify(checkedItems)) || (value != gdapData?.duration)) &&
                          <Button
                            className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                            variant="light"
                            onClick={() => {
                              setCheckedItems(defaultChecked);
                              setValue(gdapData?.duration);
                              setCheckedIds(defaultCheckedIds);
                            }}
                          >
                            Clear
                          </Button>}
                        {!((JSON.stringify(defaultChecked) !== JSON.stringify(checkedItems)) || (value != gdapData?.duration)) &&
                          <Button
                            className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                            variant="light"
                            disabled
                          >
                            Clear
                          </Button>}
                        {value?.length != 0 && checkedIds?.length != 0 &&
                          <Button
                            className="px-4 mx-2"
                            onClick={() => {
                              PostCreateGdap();
                            }}
                          >Create GDAP</Button>}
                        {(value?.length == 0 || checkedIds?.length == 0) &&
                          <Button
                            className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                            variant="light"
                            disabled
                          >Create GDAP</Button>
                        }
                      </>}
                  </>}
                {/* buttons used when post api is called */}
                {confirmLoadingFlag && <>
                  <Button
                    className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                    variant="light"
                    disabled
                  >
                    Clear
                  </Button>
                  <Button
                    disabled
                    className="ml-2 px-4 btn-border-light w-md-auto col-xs-12"
                    variant="light"
                  >
                    Processing . . .
                  </Button>
                </>}
              </div>
            </Col>
          </Row>
        </>}
        {
          !gdapData &&
          <div className={styles["loading-subscriptions"]}>
            <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading ...</p>
          </div>
        }
      </div>
    </>
  )
}

export default CreateGDAP
