// Initial Status of Form
const initStatus = {
    domainName: "",
    domainValidationFE: {
        isValid: "",
        message: "Customer domain is not valid. Please enter a valid domain."
    },
    domainCheckStatusBE: {
        isExistInSynnex: "",
        isSuccess: "",
        message: "",
        cloudIdentityId: ""
    },
    btnChecks: {
        btnCheckAvailability: false,
        btnClear: true,
        btnBuySubscription: false
    },
    stepsCompletedCheck: {
        isDomainCheckCompleted: false, // Domain check completed to Buy or Transition
        isDomainTransitionCompleted: false, // Domain Transition status
        isDomainFlowCompleted: false, // Domain (Buy or Transition) Flow Completed
    },
}

export { initStatus };