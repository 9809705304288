import React, { useState, useEffect } from 'react'
import { BarChart } from '@mui/x-charts/BarChart';
import { Col, Form, NavDropdown, Row } from 'react-bootstrap';
import styles from './subscriptionrenewal.module.scss'
import Summary from './LocalComponents/Summary';
import { CUSTOMERS_SUBSCRIPTION_RENEWAL_WIDGET, CUSTOMERS_SUBSCRIPTION_RENEWAL_DOWNLOAD_WIDGET_REPORT } from '../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../Utils/API';
import Toast from '../../../../../../../GlobalComponents/Toast';
import { useLocation } from 'react-router-dom';

const SubscriptionsRenewal = ({ serviceData, Logout }) => {
    const date = new Date();                                                    // for js date time function
    const location = useLocation();                                                  // use to access data from other page data

    const [customerCode, setCustomerCode] = useState(location.state !== null ? location.state.customerCode : "");             // storing searching value in the search input box
    const [barChartData, setBarChartData] = useState([
        { data: [0, 0, 0], stack: 'A', label: 'Scheduled subscriptions' },
        { data: [0, 0, 0], stack: 'A', label: 'Non-sceduled subscriptions' },
    ])
    const [barChartDataSeries, setBarChartDataSeries] = useState([])            // for x-axisdata in api

    const [selectedItem2, setSelectedItem2] = useState('Total'); // State for column 2 dropdown (type)
    const [selectedItem3, setSelectedItem3] = useState('Microsoft CSP'); // State for column 3 dropdwon (service)

    const [summaryPopup, setSummaryPopup] = useState(false);                            // for summary popup open or close
    const [tabName, setTabName] = useState("Summary");                               // tab name in popup
    const [integrationCode, setIntegrationCode] = useState("microsoftsaas");         // for services chosen from dropdown
    const [serviceId, setServiceId] = useState("");                                  // for services chosen from dropdown

    // Use State for Toasts
    const [toastError, setToastError] = useState(false);                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

    const [customerName, setCustomerName] = useState(location.state?.customerName ? location.state?.customerName : "");

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download CSV API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download CSV File 
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url}?serviceId=${serviceId}&type=${selectedItem2}&integrationCode=${integrationCode}&customerCode=${customerCode}`, configuration)
            .then(resp => {
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to call all Customer data
    async function GetAllSubscriptionRenewalCount() {
        await api.get(`${CUSTOMERS_SUBSCRIPTION_RENEWAL_WIDGET}?type=${selectedItem2}&integrationCode=${integrationCode}&serviceId=${serviceId}&customerCode=${customerCode}`, config)
            .then(resp => {
                let localData = [];
                resp.data.data.map((data) => {
                    if (data.data.length === 0) {
                        localData.push({
                            "data": [0, 0, 0],
                            "stack": "A",
                            "label": "Expiring subscriptions"
                        })
                    }
                    else {
                        localData.push(data)
                    }
                })
                setBarChartData(localData);
                setBarChartDataSeries(resp.data.xAxisData);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // function to call whenver service is changed from the dropdown
    function GetAllServices(selectedServiceName) {
        serviceData.map((service) => {
            if (service?.name === selectedServiceName) {
                setServiceId(service.id);
                setIntegrationCode(service.integrationCode);
                setSelectedItem3(selectedServiceName);
            }
        })
    }

    // Define a function to handle selecting a service from the dropdown
    const handleSelectService = (selectedServiceName) => {
        GetAllServices(selectedServiceName);
    };

    useEffect(() => {
        GetAllServices(selectedItem3);
        if (serviceId != "")
            GetAllSubscriptionRenewalCount();
    }, [selectedItem2, integrationCode, serviceId]);

    // function for dropdown data
    const createHandleSelect = (setSelectedItem) => (eventKey) => {
        setSelectedItem(eventKey);
    };

    return (
        <>
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

            <Row className={`${styles["widget-header"]} align-items-center pt-0`}>
                <Col md={6}>
                    <h3 className='mb-0'>Subscriptions Renewal</h3>
                </Col>
                <Col md={6} className="text-right">
                    <span onClick={() => { setSummaryPopup(true); setTabName("Summary"); }} className="dashboard-arrow mx-1 cpointer"></span>
                    <span className="dashboard-circle-dot mx-1 cpointer" onClick={() => { setSummaryPopup(true); setTabName("Details"); }}></span>
                    <span className="dashboard-download mx-1 cpointer"
                        onClick={() => { DownloadAction(CUSTOMERS_SUBSCRIPTION_RENEWAL_DOWNLOAD_WIDGET_REPORT, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${customerName}_subscriptions-renewal.csv`, "csv") }}>
                    </span>
                </Col>
                {
                    summaryPopup &&
                    <Summary setSummaryPopup={setSummaryPopup} summaryPopup={summaryPopup} tabName={tabName} setTabName={setTabName} selectedItem2={selectedItem2} setSelectedItem2={setSelectedItem2} selectedItem3={selectedItem3} setSelectedItem3={setSelectedItem3} integrationCode={integrationCode} setIntegrationCode={setIntegrationCode} serviceId={serviceId} setServiceId={setServiceId} customerCode={customerCode}
                        serviceData={serviceData} />
                }
            </Row>
            <Row className={`pt-2 pb-0 ${styles["widget-header"]} `}>
                <Col lg={1} md={4}>
                </Col>
                <Col lg={5} md={4}>
                    <Form.Group>
                        <NavDropdown title={selectedItem2} id="cloud-service-dropdown-2" className={`servcies-dropdown ${styles['dropdown-disabled']}`} onSelect={createHandleSelect(setSelectedItem2)}>
                            <NavDropdown.Item eventKey="Total"
                                className={selectedItem2 === "Total" ? "selected" : ""}
                            >Total</NavDropdown.Item>
                            <NavDropdown.Item eventKey="Renewing"
                                className={selectedItem2 === "Renewing" ? "selected" : ""}
                            >Renewing</NavDropdown.Item>

                        </NavDropdown>
                    </Form.Group>
                </Col>
                <Col lg={6} md={4}>
                    <Form.Group>
                        <NavDropdown title={selectedItem3} id="cloud-service-dropdown-3" className={`servcies-dropdown ${styles['dropdown-disabled']}`} onSelect={handleSelectService}>
                            <NavDropdown.Item eventKey="Microsoft CSP"
                                className={selectedItem3 === "Microsoft CSP" ? "selected" : ""}
                            >Microsoft CSP</NavDropdown.Item>
                            <NavDropdown.Item eventKey="Google Workspace"
                                className={selectedItem3 === "Google Workspace" ? "selected" : ""}
                            >Google Workspace</NavDropdown.Item>
                        </NavDropdown>
                    </Form.Group>
                </Col>
            </Row>
            {barChartData && <BarChart
                series={barChartData}
                grid={{ horizontal: true, vertical: true }}
                slotProps={{
                    legend: {
                        direction: 'row',
                        position: { vertical: 'bottom', horizontal: 'middle' },
                        padding: 0,
                        labelStyle: {
                            fill: '#6a6a6a'
                        },
                    },
                }}
                colors={selectedItem2 === "Renewing" ? ['#77BA1D', '#5A63E7'] : ['#3599DA', '#F9A932']}
                xAxis={[{
                    data: barChartDataSeries,
                    scaleType: 'band',
                    tickPlacement: 'middle',
                    categoryGapRatio: 0.7,
                    tickLabelPlacement: 'middle',
                }]}
                yAxis={[{
                    tickNumber: 5,
                    tickMinStep: 1,
                    // valueFormatter: (value) => `$ ${(value / 1000).toLocaleString()}K`,
                }]}
                margin={{ top: 20, bottom: 80, left: 50, right: 50 }}
            />}
        </>
    )
}

export default SubscriptionsRenewal
