import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import styles from '../../GWSCustomerPrerequisite.module.scss';

const GWSCustomerDomainProvisioned = (props) => {
  const navigate = useNavigate();                                                           // to use navigate function
  const [isDisablePrerequisite, setIsDisablePrerequisite] = useState(localStorage.getItem("disabled_array").includes("Prerequisite") ? true : false);
  return (
    <>
      {/* Domain Check Accorion */}

      <div class="details-box p-3 pb-5 product-box-outer">
        <h3 className={`mt-3 ${styles['accordion-header-wrapper']}`}>
          <label className="pl-2">
            <span className={`${styles['accordion-name']}`}>Domain</span>
          </label>
        </h3>



        <div className={`pl-0 pt-3 ${styles['accordion-content']}`}>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="formOrganaisation">
                <Form.Label>Customer Domain Name:</Form.Label>

                <Row>
                  <Col lg={3} md={6}>
                    <Row className="align-items-center">
                      <Col md={12}>
                        <Form.Control
                          type="text"
                          value={props.domainName}
                          placeholder="Enter Customer Domain Name"
                          onChange={(e) => props.checkDomainAvailability(e)}
                          disabled
                        />
                      </Col>
                    </Row>
                  </Col>

                </Row>

                <Row className="align-items-center pt-5 mt-5">
                  <Col className="text-left">
                    {props.gwIsTransferOrderInProgress == true &&
                      
                        <span className='d-flex items-align-center mt-1'>
                          <span className="text-small">
                            Your transfer request is being reviewed.
                          </span>
                        </span>
                       

                    }

                    {(props.gwIsTransferOrderInProgress == false && props.isViewDetails == true) &&
                     
                        <span className='d-flex items-align-center mt-1'>
                          <span className="status-success  mr-1">&nbsp;</span>
                          <span className="green text-small">
                            Your transfer is successful.
                          </span>
                        </span>
                     
                    }
                  </Col>
                  <Col className="text-right">
                    <div className="button-wrapper">
                      {props.gwIsTransferOrderInProgress == true &&
                        <Button
                          className="btn btn-lg ml-2 px-4"
                          onClick={() => {
                            props.setActiveLocalKey("cloudProvision");
                            navigate("/cloud-provision", {
                              state: {
                                customerId: props.customerCode,
                                serviceId: props.serviceId,
                                isManagementConsoleNavigation: true,
                                isGWSTransferPending: true

                              }
                            })
                          }
                          }
                        >
                          View Plans
                        </Button>
                      }

                      {props.gwIsTransferOrderInProgress == false &&
                        <Button
                          className={!isDisablePrerequisite ? "btn btn-lg ml-2 px-4" : "d-none"}
                          onClick={() => {
                            props.setActiveLocalKey("cloudProvision");
                            navigate("/cloud-provision", {
                            state: {
                              customerId: props.customerCode,
                              serviceId: props.serviceId,
                              isManagementConsoleNavigation: true,
                            }
                          })}}
                        >
                          Buy Subscription
                        </Button>
                      }
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>

        </div>

      </div>
    </>
  );
}

export default GWSCustomerDomainProvisioned;