import React , {useState,useEffect} from 'react'
import { LANDING_PAGE_CMS_DATA } from '../../../Utils/GlobalConstants';
import { cmsApi } from '../../../Utils/API';

const CookiePolicy = () => {

    const [cookiePolicyData, setCookiePolicyData] = useState([]);           // Storing cookie policy html coming from CMS

    // Function for calling Cookie Policy item data from Orchid Core CMS
    async function GetCookiePolicyata() {
        const data = `{
            cookiePolicy {
                content {
                html
                }
            }
            }`;
    
        // Defining Header Configuration
        const config = {
          headers: {
            "Content-Type": "application/graphql",
            Accept: "application/graphql",
          },
        };
    
        // Axios Post Request
        await cmsApi
          .post(LANDING_PAGE_CMS_DATA, data, config)
          .then((resp) => {
            // handle success
            // console.log(resp.data.data.cookiePolicy[0].content.html);
            setCookiePolicyData(
                resp.data.data.cookiePolicy[0].content.html
            );
          })
          .catch((error) => {
            // handle error
            // console.log(error);
          });
      }

    // UseEffect Defination to call the get Cookie Policy items function from CMS
    useEffect(() => {
        GetCookiePolicyata();
      }, []);

  return (
    <div className='px-4 mb-6' dangerouslySetInnerHTML={{__html:cookiePolicyData}}>
    </div>
  )
}

export default CookiePolicy