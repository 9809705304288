import React from 'react';
import styled from 'styled-components';

const UnsecuredPageWarning = styled.h1`
  color: red;
`;

const Link = styled.a`
  text-decoration: none;
  color: red;
`;

const UnsecuredPage = () => (
  <div>
    <UnsecuredPageWarning>
      If you see this page, the Web App link you clicked on is under Clickjacking security attack.
    </UnsecuredPageWarning>
    <h2>Please inform the team with the reference of the application from where you clicked this link.</h2>
    <h2>
      Click{' '}
      <Link href={window.self.location.href} title="Web Application" target="_blank">
        here
      </Link>{' '}
      to access WebApp safely.
    </h2>
  </div>
);

export default UnsecuredPage;
