import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Accordion, Form, Alert, NavDropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from '../../../../../../../GlobalComponents/Toast';
import styles from './MicrosoftResellerPrerequisite.module.scss';
import { CheckCircleFill, CheckLg, XCircleFill, XLg } from 'react-bootstrap-icons';
import { CSP_VERIFY_MPNID, CSP_SAVE_MPNID, GET_CSP_MPNID_DATA } from '../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../Utils/API';
import PrerequisiteErrorState from '../../../../../../../Assets/Images/Illustrations/PrerequisiteErrorState.svg';
import loadingGif from "../../../../../../../Assets/Images/Animations/loading-management-console.gif"
import EmptyDataTableFilterSearch from '../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'

const MicrosoftResellerPrerequisite = ({ Logout }) => {
  const location = useLocation();                                                                                     // get Location
  const [productServiceId, setProductServiceId] = useState(location.state !== null ? location.state.serviceId : "");                       // Get Service ID From Listing Table
  const navigate = useNavigate();                                               // to use navigate function
  const [mpnId, setMpnId] = useState("");                                       // to set the mpn id entered by the user
  const [verifyMessage, setVerifyMessage] = useState("");                       // to display the verified message on verifictaion
  const [isMpnEntered, setIsMpnEntered] = useState(null);                       // To check mpn id is verified or not
  const [isMpnVerified, setIsMpnVerified] = useState(null);                     // To check mpn id is verified or not
  const [domainCheckBlockErrors, setDomainCheckBlockErrors] = useState(false);  // to check error while saving and buying subscription
  const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);          // Processing after calling api
  const [isMpnSaved, setIsMpnSaved] = useState(false);                          // when mpn id is saved
  const [isVerificationHold, setIsVerificationHold] = useState(false);          // for processing intermediate button                                  
  const [isAlreadyConfigured, setIsAlreadyConfigured] = useState(null);         // To check whether mpn id is already configured or not
  const [serverError, setServerError] = useState(false);                        // server error check state in table during api call to load data

  // Use State for Toasts
  const [toastSuccess, setToastSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // Header Authorization for API
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Function to get details whether mpn id is already configured or not
  async function GetMPNData() {
    await api.get(GET_CSP_MPNID_DATA, config)
      .then(resp => {
        setIsAlreadyConfigured(resp.data.isAlreadyConfigured);
        setServerError(false);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        else {
          setServerError(true);
        }
      });
  };

  //post api for mpn id verification
  async function MPNidVerification(mpnId) {
    setConfirmLoadingFlag(true);
    const configFile = {
      headers: {
        "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    await api.post(`${CSP_VERIFY_MPNID}?mpnId=${mpnId}`, null, configFile)
      .then(resp => {
        if (resp.status = 200) {
          { resp.data.isVerified ? setIsMpnVerified(true) : setIsMpnVerified(false) }
          setVerifyMessage(resp.data.message);
          setConfirmLoadingFlag(false);
          setIsVerificationHold(false);
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        else {
          setConfirmLoadingFlag(false);
        }
      })
  };

  //post api for saving mpn id
  async function MPNidSave(mpnId) {
    setConfirmLoadingFlag(true);
    const configFile = {
      headers: {
        "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    await api.post(`${CSP_SAVE_MPNID}?mpnId=${mpnId}`, null, configFile)
      .then(resp => {
        if (resp.status = 200) {
          setIsMpnSaved(true);
          setToastMessage("MPN ID Saved Successfully.");
          setToastSuccess(true);
          setTimeout(() => {
            setToastSuccess(false);
          }, 5000);
          setConfirmLoadingFlag(false);
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        setConfirmLoadingFlag(false);
        setIsMpnSaved(false);
        setDomainCheckBlockErrors(true);
      })
  };

  // To check whether it is already configured or not and whether user is going through the provisioning screen
  useEffect(() => {
    // console.log("service id:",productServiceId )
    if (productServiceId !== "")
      GetMPNData();
  }, []);

  return (
    <>
      <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
      {
        productServiceId === "" &&
        <div className={styles["no-data-cloud-invoice"]}>
          <img src={PrerequisiteErrorState} className="empty-cloud-invoice-table-svg" />
          <h2 className='mb-2 mt-4'>Vendor Requirement for the service is possible through provisioning.</h2>
          <p className='mb-2'> Please proceed to Cloud Provision for managing.</p>
          <Button onClick={() => navigate('/cloud-provision')}>Proceed</Button>
        </div>
      }
      {
        productServiceId !== "" &&
        <>
          {serverError &&
            <div className={styles["no-data-cloud-invoice"]}>
              <img src={EmptyDataTableFilterSearch} className="empty-cloud-creditNote-table-svg" />
              <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
            </div>
          }
          {/* when mpn id already exists */}
          {
            isAlreadyConfigured && !serverError &&
            <div className={styles["no-data-cloud-invoice"]}>
              <img src={PrerequisiteErrorState} className="empty-cloud-invoice-table-svg" />
              <h2 className='mb-2 mt-4'>Vendor Requirement for the selected service is already provisioned</h2>
              <p className='mb-2'> Please proceed to My Profile for managing.</p>
              <Button onClick={() => navigate('/profile')}>Proceed</Button>
            </div>
          }

          {
            isAlreadyConfigured === null && !serverError &&
            <div className={styles["no-data-cloud-invoice"]}>
              <div className="management-console-loading-component text-center"><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading...</p></div>
            </div>
          }

          {/* when mpn id doesn't exist */}
          {!isAlreadyConfigured && isAlreadyConfigured != null && !serverError &&
            <div className="main-content">
              <Container fluid>
                <Row>
                  <Col className="justify-content-between d-flex align-items-center">
                    <div className="cpointer text-large">
                      <span className="back" onClick={() => navigate('/cloud-provision', { state: { serviceId: productServiceId}})}>&nbsp;</span>&nbsp;<strong>Vendor requirements</strong>
                    </div>
                  </Col>
                </Row>
                <div className='service-management-csp-header mt-3 ml-5'>
                  <Row>
                    <Col md={3}>
                      <Form.Group >
                        <Form.Label>Vendor</Form.Label>
                        <NavDropdown title="Microsoft" id="cloud-service-dropdown"
                          className={`servcies-dropdown ${styles['dropdown-disabled']}`} disabled>
                        </NavDropdown>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <div className="accordion-panel pb-4">
                  <Accordion
                    defaultActiveKey="0"
                    className="pre-requisite-panels"
                    alwaysOpen>
                    <Accordion.Item className={`mt-5 ${domainCheckBlockErrors ? "accordionErrors" : ""}`} eventKey="0">
                      <Accordion.Header>
                        <h3 className={`${styles['accordion-header-wrapper']}`}>
                          <label>
                            <span className={`${styles['accordion-number']}`}>1</span>
                          </label>
                          <label className="pl-2">
                            <span className={`${styles['accordion-name']}`}>MPN ID</span>
                          </label>
                        </h3>
                      </Accordion.Header>
                      <Accordion.Body className='pt-0'>
                        <div className={`${styles['accordion-content']}`}>
                          <Row>
                            <Col lg={8} md={12}>
                              <Form.Group className="mb-3" >
                                <Form.Label>Enter MPN ID</Form.Label>
                                <Row>
                                  <Col md={6} >
                                    <Form.Control
                                      type="text"
                                      value={mpnId}
                                      placeholder="Enter MPN ID"
                                      disabled={isMpnSaved}
                                      onChange={(e) => {
                                        setIsMpnVerified(null);
                                        setDomainCheckBlockErrors(false);
                                        if ( e.target.value === '' || /^[0-9]+$/.test(e.target.value) && e.target.value.length <= 7) {
                                          setMpnId(e.target.value);
                                          setIsMpnEntered(e.target.value.length === 7);
                                        }
                                      }}
                                    />
                                    {isMpnEntered &&
                                      <>
                                        {/* if MPN ID verification is valid */}
                                        {isMpnVerified === true && <span className='d-flex items-align-center mt-2'>
                                          <span className="status-success mr-1">&nbsp;</span>
                                          <span className="green text-small">
                                            {verifyMessage}
                                          </span> </span>}

                                        {/* if MPN ID verification is invalid  */}
                                        {isMpnVerified === false &&
                                          <span className='d-flex items-align-center mt-2'>
                                            <span className="status-error mr-1">&nbsp;</span>
                                            <span className="red text-small">
                                              {verifyMessage}
                                            </span>
                                          </span>}
                                      </>
                                    }
                                    {isMpnVerified === null &&
                                      <p className='empty-paragraph'></p>
                                    }

                                  </Col>
                                  <Col md={6}>
                                    {isMpnEntered ?
                                      (!confirmLoadingFlag && !isMpnSaved && <Button
                                        className="btn btn-lg btn-border-light px-4 pl-11 pr-11"
                                        variant="light"
                                        onClick={() => { MPNidVerification(mpnId); setIsVerificationHold(true); }}
                                      >
                                        Verify MPN ID
                                      </Button>) : (!confirmLoadingFlag && <Button
                                        className="btn btn-lg btn-border-light px-4 pl-11 pr-11"
                                        variant='light'
                                        disabled
                                      >
                                        Verify MPN ID
                                      </Button>)}
                                      {
                                        isMpnEntered && isMpnSaved && 
                                        <Button
                                        className="btn btn-lg btn-border-light px-4 pl-11 pr-11"
                                        variant='light'
                                        disabled
                                      >
                                        Verify MPN ID
                                      </Button>
                                      }
                                    {confirmLoadingFlag && <Button
                                      disabled
                                      className="btn btn-lg btn-border-light px-4 pl-11 pr-11"
                                      variant="light"
                                    >
                                      Processing . . .
                                    </Button>}
                                  </Col>
                                </Row>
                                
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="text-right">
                              <div className="button-wrapper">
                                {!confirmLoadingFlag && <>
                                  {mpnId.length === 0 && <Button
                                    className="btn btn-lg mx-2"
                                    variant="light"
                                    disabled
                                  >
                                    Clear
                                  </Button>}
                                  {mpnId.length > 0 && !isMpnSaved &&
                                    <Button
                                      className="btn btn-lg btn-border-light mx-2"
                                      variant="light"
                                      onClick={() => { setMpnId(""); setIsMpnEntered(null); setVerifyMessage(""); setIsMpnVerified(null); setDomainCheckBlockErrors(false); }}
                                    >
                                      Clear
                                    </Button>}
                                  {
                                    !isMpnVerified && !isMpnSaved &&
                                    <>
                                      <Button
                                        className="btn btn-lg ml-2 px-4"
                                        variant="light"
                                        disabled
                                      >
                                        Save
                                      </Button>
                                    </>
                                  }

                                  {
                                    isMpnVerified && !isMpnSaved &&
                                    <Button
                                      className="btn btn-lg ml-2 px-4"
                                      onClick={() => {
                                        MPNidSave(mpnId);
                                      }}
                                    >
                                      Save
                                    </Button>
                                  }
                                  {/* when mpn id is saved then navigate to product listing screen*/}
                                  {
                                    isMpnVerified && isMpnSaved &&
                                    <Button
                                      className="btn btn-lg ml-2 px-4"
                                      onClick={() => {
                                        navigate('/cloud-provision');
                                      }}
                                    >
                                      Buy Subscription
                                    </Button>
                                  }
                                </>}
                                {confirmLoadingFlag && !isMpnSaved && !isVerificationHold &&
                                  <><Button
                                    className="btn btn-lg mx-2"
                                    variant="light"
                                    disabled
                                  >
                                    Clear
                                  </Button>
                                    <Button
                                      disabled
                                      className="ml-2 px-4 btn-border-light w-md-auto col-xs-12"
                                      variant="light"
                                    >
                                      Processing . . .
                                    </Button></>
                                }
                                {confirmLoadingFlag && !isMpnSaved && isVerificationHold &&
                                  <><Button
                                    className="btn btn-lg mx-2"
                                    variant="light"
                                    disabled
                                  >
                                    Clear
                                  </Button>
                                    <Button
                                      disabled
                                      className="ml-2 px-4 btn-border-light w-md-auto col-xs-12"
                                      variant="light"
                                    >
                                      Save
                                    </Button></>
                                }
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>{domainCheckBlockErrors &&
                    <span className='d-flex items-align-center mt-2'>
                      <span className="mr-1">&nbsp;</span>
                      <span className="red text-small">
                        We encountered an issue while saving the MPN ID. Please try again!</span>
                    </span>}
                </div>
              </Container >
            </div >}
        </>
      }
    </>
  )
}

export default MicrosoftResellerPrerequisite
