import React, { useState, useEffect } from 'react'
import { Container, Button, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import styles from "./userManagementFilterBox.module.scss";

const UserManagementFilterBox = (props) => {
    const [selectedStatus, setSelectedStatus] = useState([]);                // all the selected value in status
    const [selectedRoles, setSelectedRoles] = useState([]);                  // all the selected value in roles

    // Function to handle "All Status" checkbox
    const handleAllStatusChange = (isChecked) => {
        setSelectedStatus(isChecked ? props.statusData.map((status) => status.id) : []);
    };

    // Function to update selected status
    const handleStatusChange = (id) => {
        setSelectedStatus((prevSelectedStatus) => {
            if (prevSelectedStatus.includes(id)) {
                return prevSelectedStatus.filter((typeId) => typeId !== id);
            } else {
                return [...prevSelectedStatus, id];
            }
        });
    };

    // Function to handle "All Roles" checkbox
    const handleAllRolesChange = (isChecked) => {
        setSelectedRoles(isChecked ? props.rolesData.map((roles) => roles.roleName) : []);
    };

    // Function to update selected role
    const handleRolesChange = (name) => {
        setSelectedRoles((prevSelectedRoles) => {
            if (prevSelectedRoles.includes(name)) {
                return prevSelectedRoles.filter((roleName) => roleName !== name);
            } else {
                return [...prevSelectedRoles, name];
            }
        });
    };

    // when clear button is clicked
    const clearFilterBox = () => {
        if (props.status && props.status !== null) {
            const element = document.getElementById(props.status);
            if (element) {
                element.checked = false;
            }
        };
        if (props.roles && props.roles !== null) {
            const element = document.getElementById(props.roles);
            if (element) {
                element.checked = false;
            }
        };

        props.setScrollToDivTop(true);
        setSelectedRoles([]);
        setSelectedStatus([]);
        props.setStatus(null);
        props.setRoles(null);
        props.setPageNo(1);
        props.GetTableData(1, props.pageSize, props.searchValueTable,[],[]);
        props.setFilterFlag(false);
    }

    useEffect(() => {
        (props.roles != null || props.roles != undefined) ? setSelectedRoles(props.roles) : setSelectedRoles([]);
        (props.status != null || props.status != undefined) ? setSelectedStatus(props.status) : setSelectedStatus([]);
    }, []);

    return (
        <div className="filter-box filter-box-sm">
            <Container>
                <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
                    <h2>Filters</h2>
                    <XLg size={16} onClick={() => props.setShowFilterBox(false)} />
                </Col>
                <Row className="mb-3 mobile-filter-row">
                    <Col className="filter-column">
                        <h3 className="mb-3">Status</h3>
                        <div>
                            <div className="filter-options">
                                <>
                                    {props.statusData && props.statusData.length > 0 && <Form.Check
                                        className="mb-2"
                                        type="checkbox"
                                        id={`statuses-all`}
                                        label="All Statuses"
                                        checked={(selectedStatus.length === props.statusData.length)}
                                        onChange={(e) => handleAllStatusChange(e.target.checked)}
                                    />}
                                    {props.statusData && props.statusData.length > 0 &&
                                        props.statusData.map((statusItem) => {
                                            return (
                                                <Form.Check
                                                    className="mb-2"
                                                    key={statusItem.id}
                                                    type="checkbox"
                                                    id={`status-${statusItem.id}`}
                                                    label={statusItem.name}
                                                    checked={selectedStatus.includes(statusItem.id)}
                                                    onChange={() => handleStatusChange(statusItem.id)}
                                                />
                                            );
                                        })}
                                </>
                            </div>
                        </div>

                    </Col>

                    <Col className="filter-column">
                        <h3 className="mb-3">Role</h3>
                        <div>
                            <div className="filter-options">
                                <>
                                    {props.rolesData && props.rolesData.length > 0 && <Form.Check
                                        className="mb-2"
                                        type="checkbox"
                                        id={`roles-all`}
                                        label="All Roles"
                                        checked={(selectedRoles.length === props.rolesData.length)}
                                        onChange={(e) => handleAllRolesChange(e.target.checked)}
                                    />}
                                    {props.rolesData && props.rolesData.length > 0 &&
                                        props.rolesData.map((rolesItem) => {
                                            return (
                                                <Form.Check
                                                    className="mb-2"
                                                    key={rolesItem.roleName}
                                                    type="checkbox"
                                                    id={`roles-${rolesItem.roleName}`}
                                                    label={rolesItem.roleName}
                                                    checked={selectedRoles.includes(rolesItem.roleName)}
                                                    onChange={() => handleRolesChange(rolesItem.roleName)}
                                                />
                                            );
                                        })}
                                </>
                            </div>
                        </div>

                    </Col>
                </Row>
                <Row className="actions-row">
                    <Col className="d-flex justify-content-end">
                        {(selectedStatus.length == 0 && selectedRoles.length == 0) &&
                            <Button
                                className="btn btn-lg mx-3"
                                variant="light"
                                disabled
                            >
                                Clear
                            </Button>
                        }
                        {(selectedStatus.length > 0 || selectedRoles.length > 0 ) &&
                            <Button
                                className="btn btn-lg mx-3 btn-border-light"
                                variant="light"
                                onClick={clearFilterBox}>
                                Clear
                            </Button>
                        }
                        {(selectedStatus.length > 0 || selectedRoles.length > 0) &&
                            <Button
                                className="btn btn-lg"
                                variant="primary"
                                onClick={() => {
                                    props.setScrollToDivTop(true);
                                    props.setRoles(selectedRoles);
                                    props.setStatus(selectedStatus);
                                    props.setPageNo(1);
                                    props.GetTableData(1, props.pageSize, props.searchValueTable, selectedStatus, selectedRoles, false, true);
                                    props.setShowFilterBox(false);
                                    props.setFilterFlag(true);
                                }
                                }
                            >
                                Apply
                            </Button>
                        }
                        {(selectedStatus.length == 0 && selectedRoles.length == 0) &&
                            <Button
                                className="btn btn-lg"
                                variant="light"
                                disabled
                            >
                                Apply
                            </Button>
                        }
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

export default UserManagementFilterBox;