import React , {useContext, useState,useEffect} from 'react';
import styles from './landingPage.module.scss';
import LandingPageHeader from './Layout/Header';
import Info from './LocalComponents/Info';
import LandingCard from './LocalComponents/Card';
import Help from './LocalComponents/Help';
import VendorPortfolio from './LocalComponents/Vendor';
import Carousal from './LocalComponents/Carousal';
import { LANDING_PAGE_CMS_DATA } from '../../../Utils/GlobalConstants';
import { cmsApi } from '../../../Utils/API';
import CmsDetailsContext from '../../../Context/CmsDetails/CmsDetailsContext';
import logoTop from '../../../Assets/Images/Illustrations/Landing Page/Background-Image/svg/logo-top.svg';
import { useNavigate } from "react-router-dom";
import InstallPWA from '../../../GlobalComponents/InstallPWA';

const LandingPage = (props) => {

    // calling useContext site settings details for image base url
    const globalData = useContext(CmsDetailsContext);

    // Defining UseNavigate for react router DOM v6
    const navigate = useNavigate();
    
    const [landingPageData, setLandingPageData] = useState(null);           // Storing Landing Page data coming from CMS
    const [backgroundImageData, setBackgroundImageData] = useState(null);   // Storing background image data coming from CMS

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);      // UseState to capture change of window width and changing bg image

        
    // Scroll to Top on Initial loading
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    // Function for calling Landing Page item data from Orchid Core CMS
    async function GetLandingPageData() {
      // console.log("Landing Page Data Fetching Initiated");
      const data = `
        {
          siteHomeContent {
            siteUsp {
              contentItems {
                ... on SiteUsp {
                  description
                  title
                  image {
                    urls
                    paths
                  }
                }
              }
            }
            ourVendors {
              contentItems {
                ... on OurVendors {
                  description
                  title
                  tabletImage {
                    urls
                    paths
                  }
                  mobileImage {
                    urls
                    paths
                  }
                  desktopImage {
                    paths
                    urls
                  }
                }
              }
            }
            newsLatter {
              contentItems {
                ... on LatestNews {
                  title
                  description
                  latestNewsCards {
                    contentItems {
                      ... on NewsLatterCards {
                        description
                        heading
                        image {
                          urls
                          paths
                        }
                        title
                        url
                      }
                    }
                  }
                }
              }
            }
            navLink {
              contentItems {
                ... on ExternalItemLink {
                  linkUrl
                  displayText
                }
              }
            }
            heroContent {
              contentItems {
                ... on SiteHeroContent {
                  description
                  title
                  boldTitle
                }
              }
            }
            help {
              contentItems {
                ... on SiteHelpContent {
                  description
                  title
                  tabs {
                    contentItems {
                      ... on TabContent {
                        title
                        mobileImage {
                          urls
                          paths
                        }
                        desktopImage {
                          urls
                          paths
                        }
                        description
                        boldDescription
                      }
                    }
                  }
                  boldTitle
                }
              }
            }
            formEndpoint {
              contentItems {
                ... on FormEndpoint {
                  formName
                  endpoint
                }
              }
            }
          }
        }`;

      // Defining Header Configuration
      const config = {
        headers: {
          "Content-Type": "application/graphql",
          Accept: "application/graphql",
        },
      };

      // Axios Post Request
      await cmsApi
        .post(LANDING_PAGE_CMS_DATA, data, config)
        .then((resp) => {
          // handle success
          // console.log(resp.data.data.siteHomeContent[0]);
          setLandingPageData(
            resp.data.data.siteHomeContent[0]
          );
          props.setFormEndPoint(resp.data.data.siteHomeContent[0].formEndpoint.contentItems);
          resp.data.data.siteHomeContent[0].formEndpoint.contentItems.map((ep)=>{
            if(ep.formName=="NewsLetter"){
              localStorage.setItem("footer_newsletter_link",ep.endpoint);
            }
          })
        })
        .catch((error) => {
          // handle error
          // console.log(error);
        });
    }

    // Function for calling main bg image for desktop,tablet and mobile from Orchid Core CMS
    async function GetBackgroundImageData() {
      const data = `{
          siteImages {
            desktopImage {
              urls
              paths
            }
            mobileImage {
              urls
              paths
            }
            tabletImage {
              urls
              paths
            }
          }
        }`;

      // Defining Header Configuration
      const config = {
        headers: {
          "Content-Type": "application/graphql",
          Accept: "application/graphql",
        },
      };

      // Axios Post Request
      await cmsApi
        .post(LANDING_PAGE_CMS_DATA, data, config)
        .then((resp) => {
          // handle success
          // console.log("Background Image Data",resp.data.data.siteImages[0]);
          // setLandingPageData(
          //   resp.data.data.siteHomeContent[0]
          // );
          setBackgroundImageData(resp.data.data.siteImages[0])
        })
        .catch((error) => {
          // handle error
          // console.log(error);
        });
    }

    // Function for calling B2B URL data from Orchid Core CMS
    async function GetB2BUrl() {
      const data = `{
          b2BNavigationLink {
              url
              displayText
          }
          }`;
  
      // Defining Header Configuration
      const config = {
        headers: {
          "Content-Type": "application/graphql",
          Accept: "application/graphql",
        },
      };
  
      // Axios Post Request
      await cmsApi
        .post(LANDING_PAGE_CMS_DATA, data, config)
        .then((resp) => {
          // handle success
          // console.log(resp.data.data.b2BNavigationLink[0].url);
          localStorage.setItem("b2b_navigation", resp.data.data.b2BNavigationLink[0].url);
        })
        .catch((error) => {
          // handle error
          // console.log(error);
        });
    }

    // To navigate user To management Console when a user gets logged in
    if (props.customerIsAuthenticated) {
      navigate(localStorage.getItem("navigation_link") ? localStorage.getItem("navigation_link") : "/synnex-cloud/dashboard");
    }
  
    // UseEffect Defination to call the get Landing Page items function from CMS
    useEffect(() => {
      setTimeout(() => { 
        GetLandingPageData();
        GetBackgroundImageData();
        GetB2BUrl();
      }, 1000);  
    }, []);


  // Useefffects to set the image path and window resize handling
  const [imagePath, setImgPath] = useState("");

  useEffect(() => {
    if(backgroundImageData && globalData && windowWidth){
      if(window.innerWidth > 1400) {
      setImgPath(globalData.baseUrl + backgroundImageData.desktopImage.urls)
       }
 
   if(window.innerWidth >= 768 && window.innerWidth <= 1400) {
     setImgPath(globalData.baseUrl + backgroundImageData.tabletImage.urls)
   }
 
   if(window.innerWidth < 768) {
     setImgPath(globalData.baseUrl + backgroundImageData.mobileImage.urls)
       }
    }
   
 
  },[backgroundImageData,globalData,windowWidth])

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  

  return (
    <div className={styles['main-container']}>
      {
        !landingPageData && <div className={`${styles['landing-page-upper-loading']}`}>
            <img src={logoTop} alt="Logo Top" />
          </div>
      }
      {backgroundImageData && globalData && <div className={`${styles['landing-page-upper']}`} style={{
      backgroundImage: `url(${imagePath})`,}}>
        <LandingPageHeader landingPageData={landingPageData} globalData={globalData} cookieAccepted={props.cookieAccepted} />
        <Info landingPageData={landingPageData} globalData={globalData}  />
      </div>}
      {landingPageData && <div className={styles['cards-container']}>
        {
          landingPageData && globalData && landingPageData.siteUsp.contentItems.map((usp)=>{
            return(
              <LandingCard src={globalData.baseUrl + usp.image.urls} title={usp.title} description={usp.description} />
            )
          })
        }
      </div>}
        {landingPageData && <Help landingPageData={landingPageData} globalData={globalData} />}
        {landingPageData && <VendorPortfolio landingPageData={landingPageData} globalData={globalData} />}
        {landingPageData && <Carousal landingPageData={landingPageData} globalData={globalData} />}
        {/* <LandingPageFooter/> */}
        <InstallPWA />
   
    </div>
  );
}

export default LandingPage;
