import React from 'react';
import styles from './help.module.scss'; // Assuming you have a SCSS module set up
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap';
import { useState , useEffect } from 'react';

function Help({landingPageData,globalData}) {

  // storing default tab
  const [tabName, setTabName] = useState("Streamline Operations");

  //Form End Point
  const [nfrFormEndPoint, setNfrFormEndPoint] = useState(null);

  useEffect(() => {
    if(landingPageData){
      setTabName(landingPageData.help.contentItems[0].tabs.contentItems[0].title);
      // console.log("NFR FORM LINK",landingPageData.formEndpoint.contentItems);
      landingPageData.formEndpoint.contentItems.map((data)=>{
        if(data.formName=="NFR Form"){
          setNfrFormEndPoint(data.endpoint);
        }
      })
    }
  }, [landingPageData])

  return (
    <Container fluid className={`${styles['div']} mb-3`}>
      <h2 className={`${styles['help-h3']}`}>{landingPageData && landingPageData.help.contentItems[0].title}</h2>
      <h1 className={`${styles.h1} py-5 pt-2`}>{landingPageData && landingPageData.help.contentItems[0].description} <span className={styles.span}>{landingPageData && landingPageData.help.contentItems[0].boldTitle}</span></h1>
      <Tab.Container id="main-tab-bar">
        <Tab.Container activeKey={tabName}>
          <Row>
            <Col sm={12}>
              <Nav className={` ${styles['nav-container']} inline-nav `} variant="pills" defaultActiveKey={null}>
                {
                  landingPageData && landingPageData.help.contentItems[0].tabs.contentItems.map((data,id)=>{
                    // console.log("id :",id)
                    let cssStyle="";
                    if(id==0)cssStyle="underline-blue"
                    if(id==1)cssStyle="underline-grey"
                    if(id==2)cssStyle="underline-yellow"
                    if(id==3)cssStyle="underline-green"
                    return(
                      <p className={tabName===(data.title) ? `${styles['nav-link', cssStyle]} mr-0 text-strong` :  `${styles['nav-link', cssStyle]} mr-0` } onClick={() => { setTabName((data.title)) }}>{(data.title)}</p>
                    )
                  })
                }
              </Nav>
            </Col>
            <Col sm={12}>
              {landingPageData && globalData && <Tab.Content>
                <Tab.Pane eventKey={landingPageData.help.contentItems[0].tabs.contentItems[0].title} className={`${styles['tab-content-main-div']}`} >
                  <Row>
                    <Col sm={12} md={6} className={`${styles['tab-content-left']}`} >
                      <h2 className={`${styles.pContainer} mb-8`}>{landingPageData.help.contentItems[0].tabs.contentItems[0].title}</h2>
                      <p className={styles.pContainer} >
                        {landingPageData.help.contentItems[0].tabs.contentItems[0].description} <span className={styles['span-container']}>{landingPageData.help.contentItems[0].tabs.contentItems[0].boldDescription}</span></p>
                    </Col>
                    <Col sm={12} md={6} >
                    <picture>
                        <source media="(min-width: 992px)" srcSet={globalData.baseUrl + landingPageData.help.contentItems[0].tabs.contentItems[0].desktopImage.urls} />
                        <source media="(min-width: 768px)" srcSet={globalData.baseUrl + landingPageData.help.contentItems[0].tabs.contentItems[0].desktopImage.urls} />
                        <source media="(max-width: 767px)" srcSet={globalData.baseUrl + landingPageData.help.contentItems[0].tabs.contentItems[0].mobileImage.urls} />
                        <img className={`${styles['img']}`} src={globalData.baseUrl + landingPageData.help.contentItems[0].tabs.contentItems[0].desktopImage.urls} alt="tab1" />
                    </picture>
                      
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey={landingPageData.help.contentItems[0].tabs.contentItems[1].title} className={`${styles['tab-content-main-div']}`} >
                  <Row>
                    <Col sm={12} md={6} className={`${styles['tab-content-left']}`} >
                      <h2 className={`${styles.pContainer} mb-8`}>{landingPageData.help.contentItems[0].tabs.contentItems[1].title}</h2>
                      <p className={styles.pContainer} >
                        <span className={styles['span-container']}>{landingPageData.help.contentItems[0].tabs.contentItems[1].boldDescription}</span> {landingPageData.help.contentItems[0].tabs.contentItems[1].description}</p>
                      <div className={`pl-4 ${styles['btn-div']}`}>
                      {
                          landingPageData && landingPageData.formEndpoint.contentItems.map((data)=>{
                            if(data.formName==landingPageData.help.contentItems[0].tabs.contentItems[1].title){
                              return(
                                <a href={data.endpoint} target='_blank'><button className={styles.tabBtn}>Learn more</button></a>
                              )
                            }
                          })
                        }
                      </div>
                    </Col>
                    <Col sm={12} md={6}>
                      <img className={`${styles['tab2']}`} src={globalData.baseUrl + landingPageData.help.contentItems[0].tabs.contentItems[1].desktopImage.urls} alt="tab2" />
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey={landingPageData.help.contentItems[0].tabs.contentItems[2].title} className={`${styles['tab-content-main-div']}`} >
                  <Row>
                    <Col sm={12} md={6} className={`${styles['tab-content-left']}`} >
                    <h2 className={`${styles.pContainer} mb-8`}>{landingPageData.help.contentItems[0].tabs.contentItems[2].title}</h2>
                      <p className={styles.pContainer}>
                        <span className={styles['span-container']}>{landingPageData.help.contentItems[0].tabs.contentItems[2].boldDescription}</span>{landingPageData.help.contentItems[0].tabs.contentItems[2].description}</p>
                      <div className={`pl-4 ${styles['btn-div']}`}>
                        {
                          landingPageData && landingPageData.formEndpoint.contentItems.map((data)=>{
                            if(data.formName==landingPageData.help.contentItems[0].tabs.contentItems[2].title){
                              return(
                                <a href={data.endpoint} target='_blank'><button className={styles.tabBtn}>Get Started</button></a>
                              )
                            }
                          })
                        }
                      </div>
                    </Col>
                    <Col sm={12} md={6} >
                      <img className={`${styles['img']}`} src={globalData.baseUrl + landingPageData.help.contentItems[0].tabs.contentItems[2].desktopImage.urls} alt="tab3" />
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey={landingPageData.help.contentItems[0].tabs.contentItems[3].title} className={`${styles['tab-content-main-div']}`} >
                  <Row>
                    <Col sm={12} md={6} className={`${styles['tab-content-left']}`} >
                      <h2 className={`${styles.pContainer} mb-8`}>{landingPageData.help.contentItems[0].tabs.contentItems[3].title}</h2>
                      <p className={styles.pContainer}>{landingPageData.help.contentItems[0].tabs.contentItems[3].description.split(landingPageData.help.contentItems[0].tabs.contentItems[3].boldDescription)[0]}
                        <span className={styles['span-container']}>{landingPageData.help.contentItems[0].tabs.contentItems[3].boldDescription}</span>
                        {landingPageData.help.contentItems[0].tabs.contentItems[3].description.split(landingPageData.help.contentItems[0].tabs.contentItems[3].boldDescription)[1]}</p>
                    </Col>
                    <Col sm={12} md={6} >
                    <div className={styles['form-container']}>
                       <div className='_form_299'>
                       <iframe
                          src={nfrFormEndPoint && nfrFormEndPoint}
                          frameBorder="0"
                          width="100%"
                          height="100%"
                          allowTransparency="true"
                          scrolling="yes"
                          // onLoad={() => setLoading(false)}
                      />
                        </div>
                      </div>

                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>}
            </Col>
          </Row>
        </Tab.Container>
      </Tab.Container>
    </Container>
  );
}

export default Help;
