import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Accordion, AccordionContext, Button, Card, Col, Container, Form, OverlayTrigger, Row, Table, Tooltip, useAccordionButton } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import styles from './CSPUpgrade.module.scss';
import DataTable from 'react-data-table-component';
import { api } from '../../../../../../../../Utils/API';
import { CSP_UPGRADE_PLANS_STEP1, CSP_UPGRADE_PLANS_STEP2, CSP_TRIAL_TO_PAID_POST_PLANS } from '../../../../../../../../Utils/GlobalConstants';
import CustomNumberSimpleInput from '../../../../../../../../GlobalComponents/CustomNumberSimpleInput';
import EmptyDataTableFilterSearch from '../../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import loadingGif from "../../../../../../../../Assets/Images/Animations/loading-management-console.gif"


const CSPUpgrade = ({ subscriptionTableAPIData, subscriptionId, upgradeActionPopupModalOpen, setUpgradeActionPopupModalOpen,
    columnsSubscriptionTable, tableSubscriptionTable, setToastSuccess, setToastError, setToastMessage, setActionCompletedRefreshSubscriptionTable, Logout }) => {

    const alphanumericRegex = /^[a-zA-Z0-9]{0,25}$/; // RegEx for PO Number

    const [loading, setLoading] = useState(false);                                                                                //loading state when api is called
    const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") == "false" ? false : true);                                                                   //Order confirmation checkbox
    const [defaultUpgrade, setDefaultUpgrade] = useState();                                                                       //stepper step1 which contain selected data
    const [defaultUpgradeStep2, setDefaultUpgradeStep2] = useState();                                                             //stepper step2 which contain selected data
    const [confirmUpgradeLoadingFlag, setConfirmUpgradeLoadingFlag] = useState(false);                                            //onclose popup functionality and processing button after confirmation


    //for upgrade core
    let UpgradeCoreColumnName = ["", "Plan", "Description"];                                                                      //first upgrade table columns
    let UpgradeCoreColumnNameStep2 = ["", "Plan", "Billing Term", "RRP (Inc GST)", "Reseller Price (Ex GST)"];                    //second step columns in upgrade
    const [coreUpgradeDetails, setCoreUpgradeDetails] = useState();                                                               //data containing all details of step 1 in stepper 
    const [coreUpgradeDetailsStep2, setCoreUpgradeDetailsStep2] = useState();                                                     //data containing all details of step 2 in stepper 
    const [upgradeCoreTableStep3, setUpgradeCoreTableStep3] = useState([]);                                                       //step 3 edit quantity table 
    const [upgradeCoreColumnsStep3, setUpgradeCoreColumnsStep3] = useState([]);                                                   //step 3 edit quantity columns
    let UpgradeCoreColumnNameStep3 = ["Plan", "Billing Term", "RRP (Inc GST)", "Reseller Price (Ex GST)",
        "Quantity", "Sub-Total Price"];                                                                                           //step3 columns -edit quantity
    const [upgradeCoreTableStep4, setUpgradeCoreTableStep4] = useState(null);                                                     //order confirmation table
    const [upgradeCoreColumnsStep4, setUpgradeCoreColumnsStep4] = useState([]);                                                   //order confirmation column
    let UpgradeCoreColumnNameStep4 = ["Plan", "Billing Term", "Unit Price", "Quantity", "Sub-Total Price"];
    const [coreUpgradeNewQuantity, setCoreUpgradeNewQuantity] = useState(null);                                                   //edit quantity 
    const [poNumber, setPoNumber] = useState("");                                                                               //post api order confirmation po number 
    const [allSelectedDetailsStep2, setAllSelectedDetailsStep2] = useState([]);                                                   //data stored in step 2
    const [emptyDataError, setEmptyDataError] = useState(false);                                                                  // to check empty data error-204
    const [serverError, setServerError] = useState(false);                                                                        // to check server error-400

    //Stepper
    const steps = ['Select Plan', 'Select SKU', 'Add Quantity'];
    const [activeStep, setActiveStep] = useState(0);
    const [planName, setPlanName] = useState();

    //buttons for upgrade stepper
    const [buttonCheckstep1, setButtonCheckstep1] = useState(false);
    const [buttonCheckstep2, setButtonCheckstep2] = useState(false);
    const [buttonCheckstep3, setButtonCheckstep3] = useState(false);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };
    //UPGRADE Core Action Tab
    //upgrade core step 1
    async function GetUpgradeDetails(subscriptionId) {
        (subscriptionId === undefined || subscriptionId === null) ? subscriptionId = "" : subscriptionId = subscriptionId;
        await api.get(`${CSP_UPGRADE_PLANS_STEP1}${subscriptionId}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setEmptyDataError(true);
                }
                // handle success
                else {
                    setEmptyDataError(false);
                    setServerError(false);
                    setCoreUpgradeDetails(resp.data);
                }
            })
            .catch(error => {
                if (error.response.status == 400) {
                    setServerError(true);
                }
                else if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };
    //upgrade core step 2
    async function GetUpgradePurchasableOffersCore(subscriptionId, planName) {
        (subscriptionId === undefined || subscriptionId === null) ? subscriptionId = "" : subscriptionId = subscriptionId;
        (planName === undefined || planName === null) ? planName = "" : planName = planName;

        await api.get(`${CSP_UPGRADE_PLANS_STEP2}?subscriptionId=${subscriptionId}&planName=${planName}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setEmptyDataError(true);
                }
                // handle success
                else {
                    setCoreUpgradeDetailsStep2(resp.data.plans);
                }
            })
            .catch(error => {
                if (error.response.status == 400) {
                    setServerError(true);
                }
                else if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };
    //upgrade core step 3
    function CoreUpgradeStep3() {
        // setCoreUpgradeNewQuantity(subscriptionTableAPIData.quantity);

        if (subscriptionTableAPIData && planName && allSelectedDetailsStep2) {
            // Column Edited Quantity
            let f = [];
            // console.log("core: ....", allSelectedDetailsStep2);
            f.push
                (
                    {
                        "RRP (Inc GST)": `$${allSelectedDetailsStep2.rrpInclusiveTax}`,
                        "Reseller Price (Ex GST)": `$${allSelectedDetailsStep2.price}`,
                        "Billing Term": allSelectedDetailsStep2.periodType,
                        "Commitment Term": allSelectedDetailsStep2.paymentPlan,
                        "Plan": allSelectedDetailsStep2.planName,
                        "Billing Term": allSelectedDetailsStep2.billingTerm,
                        "Current Quantity": subscriptionTableAPIData.quantity,
                        "Quantity":
                            <div className='d-flex flex-wrap my-2'>
                                <CustomNumberSimpleInput
                                    defaultValue={coreUpgradeNewQuantity ? coreUpgradeNewQuantity : subscriptionTableAPIData.quantity}
                                    min={allSelectedDetailsStep2.flavor.minQuantity}
                                    max={allSelectedDetailsStep2.flavor.maxQuantity}
                                    setUpdatedQuanity={setCoreUpgradeNewQuantity}
                                />
                            </div>
                        ,
                        "Sub-Total Price": `$${coreUpgradeNewQuantity ? ((allSelectedDetailsStep2.price) * coreUpgradeNewQuantity).toFixed(2)
                            : ((allSelectedDetailsStep2.price)) * subscriptionTableAPIData.quantity}`
                    }
                );
            setUpgradeCoreTableStep3(f);         // Setting Table Data      
            let d = []
            UpgradeCoreColumnNameStep3.map((td) => {
                if (td === "Sub-Total Price") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        right: true,
                        allowOverflow: true,
                    })
                }
                else if (td === "Billing Term") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                            >
                                <span className="infoIcon-support-table ml-1"></span>
                            </OverlayTrigger></div>,
                        selector: row => <div className="text-wrap">
                            {row[`${td}`]}</div>,
                        left: true,
                        grow: 2,
                        allowOverflow: true,
                    })
                }
                else if (td === "Reseller Price (Ex GST)" || td === "RRP (Inc GST)") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        right: true,
                        grow: 1.7,
                        allowOverflow: true,
                    })
                }
                else if (td === "Quantity") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        left: true,
                        grow: 1.7,
                        allowOverflow: true,
                    })
                }
                else {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        left: true,
                        allowOverflow: true,
                    })
                }
            })
            setUpgradeCoreColumnsStep3(d);
        }
    }
    //order confirmation table last step for upgrade core 
    function CoreUpgradeOrderConfirmation() {
        if (subscriptionTableAPIData) {
            // Column upgrade tab level step 3
            let f = [];
            f.push
                (
                    {
                        "Unit Price": `$${allSelectedDetailsStep2.price}`,
                        "Plan": allSelectedDetailsStep2.planName,
                        "Quantity": coreUpgradeNewQuantity,
                        "Billing Term": allSelectedDetailsStep2.billingTerm,
                        "Sub-Total Price": `$${coreUpgradeNewQuantity ? ((allSelectedDetailsStep2.price) * coreUpgradeNewQuantity).toFixed(2)
                            : ((allSelectedDetailsStep2.price)) * subscriptionTableAPIData.quantity}`

                    }
                );
            setTimeout(() => {
                setUpgradeCoreTableStep4(f);         // Setting Table Data
            }, 50);
            let d = []
            UpgradeCoreColumnNameStep4.map((td) => {
                if (td === "Unit Price" || td === "Sub-Total Price" || td === "Quantity") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        right: true,
                        // grow: 3,
                        allowOverflow: true,
                    })
                }
                else if (td === "Billing Term") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                            >
                                <span className="infoIcon-support-table ml-1"></span>
                            </OverlayTrigger></div>,
                        selector: row => <div className="text-wrap">
                            {row[`${td}`]}</div>,
                        left: true,
                        grow: 2,
                        allowOverflow: true,
                    })
                }

                else {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        left: true,
                        grow: 3.5,
                        allowOverflow: true,
                    })
                }
            })
            setUpgradeCoreColumnsStep4(d);     // Setting Column Data  
        }
    }
    //post api for upgrade confirmation
    async function CoreOrderUpgradeConfirmation() {
        setConfirmUpgradeLoadingFlag(true);

        const data = {
            "planId": allSelectedDetailsStep2.planId,
            "subscriptionId": parseInt(subscriptionId),
            "quantity": parseInt(coreUpgradeNewQuantity),
            "poNumber": poNumber,
        };
        const configFile = {
            headers: {
                "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
                "Content-Type": "application/json",
            },
        };
        await api.post(CSP_TRIAL_TO_PAID_POST_PLANS, data, configFile)
            .then(resp => {
                if (resp.status = 200) {
                    setConfirmUpgradeLoadingFlag(false);
                    setServerError(false);
                    setEmptyDataError(false);
                    setUpgradeActionPopupModalOpen(false);
                    setToastMessage("Upgrade successful.");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setActionCompletedRefreshSubscriptionTable(true);
                }
            })
            .catch(error => {
                setConfirmUpgradeLoadingFlag(false);
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                setToastMessage("Upgrade failed.");
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 5000);

            })
    };

    // Stepper
    const handleBackOrderConfirmation = () => {
        setButtonCheckstep3(false);
        setPoNumber("");

        setActiveStep((prevActiveStep) => prevActiveStep - 1);

    };
    const handleBack = () => {
        setDefaultUpgradeStep2();
        setCoreUpgradeDetailsStep2();
        setButtonCheckstep2(false);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleBackQuantity = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setCoreUpgradeNewQuantity(subscriptionTableAPIData.quantity);
    };
    const handleNextStep1 = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (planName) {
            GetUpgradePurchasableOffersCore(subscriptionId, planName)
        }
    };
    const handleNextStep2 = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        CoreUpgradeStep3();
    };
    const handleNextStep3 = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setButtonCheckstep3(true);
        CoreUpgradeOrderConfirmation();
    };

    // clear the selected data
    const handleClearRows = () => {
        setDefaultUpgrade();
        setButtonCheckstep1(false);
        setButtonCheckstep2(false);
    }

    // accordion
    function CustomToggle({ children, eventKey, callback }) {
        const { activeEventKey } = useContext(AccordionContext);
        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );
        const isCurrentEventKey = activeEventKey === eventKey;
        return (
            <span
                className='pt-1 align-content-center'
                onClick={decoratedOnClick}
            >{children}
                {isCurrentEventKey ? (
                    <span className='up-accordion' />
                ) : (
                    <span className='down-accordion' />
                )}
            </span>
        );
    }
    // React Data Table Custom Styles
    const customStyles = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for data cells
                paddingRight: '1rem',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
                borderBottom: '0.5px solid #DDDDDD',
                wordBreak: 'normal',
            },
        },
        headCells: {
            style: {
                paddingLeft: '1rem',                                   // override the cell padding for head cells
                paddingRight: '1rem',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                wordBreak: 'normal',
            },
        },
        expanderButton: {
            style: {
                '&:hover:enabled': {
                    cursor: 'pointer',
                },
                '&:disabled svg path': {
                    visibility: 'hidden'
                },
                svg: {
                    margin: 'auto',
                },
            },
        },
    };

    // React Data Table Custom Styles for upgrade
    const customStylesUpgrade = {
        rows: {
            style: {
                minHeight: '60px',                                      // override the row height
                borderBottom: '0.5px solid #DDDDDD',
            },
        },
        cells: {
            style: {
                paddingLeft: '1rem',                                   // override the cell padding for data cells
                paddingRight: '1rem',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
                wordBreak: 'normal',
            },
        },
        //for adding background in row-first child
        headRow: {
            style: {
                backgroundColor: '#fafafa'
            },
        },
        headCells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for head cells
                paddingRight: '1rem',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                wordBreak: 'normal',
            },
        },
        expanderButton: {
            style: {
                '&:hover:enabled': {
                    cursor: 'pointer',
                },
                '&:disabled svg path': {
                    visibility: 'hidden'
                },
                svg: {
                    margin: 'auto',
                },
            },
        },
    };

    useEffect(() => {
        GetUpgradeDetails(subscriptionId);
    }, [subscriptionId])

    useEffect(() => {
        CoreUpgradeStep3();
    }, [coreUpgradeNewQuantity])

    return (
        <div>
            <Popup
                className="custom-modal custom-modal-xl"
                open={upgradeActionPopupModalOpen}
                onClose={() => { setUpgradeActionPopupModalOpen(false); setServerError(false); setEmptyDataError(false); }}
                closeOnDocumentClick={!(confirmUpgradeLoadingFlag || buttonCheckstep2 || buttonCheckstep1 || buttonCheckstep3)}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">

                        <div className="header">
                            {upgradeCoreTableStep4 ? <h2>Order Confirmation - Upgrade</h2> : <h2>Upgrade</h2>}
                            <XLg
                                size={18}
                                className="cpointer text-strong"
                                onClick={() => { if (!confirmUpgradeLoadingFlag) close() }}
                                color="#6A6A6A"
                            />
                        </div>
                        <Container className={`upgrade-modal-height ${styles['modal-width']}`}>
                            <Accordion defaultActiveKey="1">
                                <Card className={`${styles['accordion-upgradeGWS']}`}>
                                    <Row className={`ml-1 mr-1 ${styles['tab-header']}`}>
                                        <Col md={5} className="mt-2 mb-2">
                                            <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Plan :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.planName : "Loading"}</span>
                                        </Col>
                                        <Col md={6} className="mt-2 mb-2">
                                            <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Subscription ID :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.vendorSubscriptionId : "Loading"}</span>
                                        </Col>
                                        <Col md={1} className='text-right'><CustomToggle className='text-left text-center' eventKey="0"></CustomToggle></Col>
                                    </Row>

                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <div className={`table-responsive  ${styles['gws-subsription-details-table']}`}>
                                                {tableSubscriptionTable &&
                                                    <DataTable
                                                        columns={columnsSubscriptionTable}
                                                        data={tableSubscriptionTable}
                                                        theme="solarized"
                                                        customStyles={customStyles}
                                                        // striped={true}
                                                        persistTableHead={false}
                                                        noDataComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Subscription Details . . .</p></div>}
                                                        width="100%"
                                                        fixedHeaderScrollHeight="60vh"
                                                        progressPending={loading}
                                                        progressComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Subscription Details . . .</p></div>}
                                                        fixedHeader
                                                    />
                                                }
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>

                            <Box sx={{ width: '100%' }} className='pt-6'>
                                <Row >
                                    <Col md={2}></Col>
                                    <Col md={8}
                                        className={`${styles['button-color-manage-gws']}`}>
                                        {activeStep !== steps.length && (
                                            <Stepper activeStep={activeStep}>
                                                {steps.map((label, index) => {
                                                    const stepProps = {};
                                                    const labelProps = {};
                                                    return (
                                                        <Step key={label} {...stepProps}>
                                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                                        </Step>
                                                    );
                                                })}
                                            </Stepper>
                                        )}
                                    </Col>
                                    <Col md={2}></Col>
                                </Row>
                                {buttonCheckstep3 && <div className={`${styles['gws-upgrade-core-step4']}`}>
                                    {upgradeCoreTableStep4 &&
                                        <DataTable
                                            columns={upgradeCoreColumnsStep4}
                                            data={upgradeCoreTableStep4}
                                            theme="solarized"
                                            customStyles={customStyles}
                                            persistTableHead={false}
                                            noDataComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans. . .</p></div>}
                                            width="100%"
                                            fixedHeaderScrollHeight="31vh"
                                            progressPending={loading}
                                            progressComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans. . .</p></div>}
                                            fixedHeader
                                        />
                                    }
                                    <p className='mt-4'><span className='text-strong'>Note :</span> Plan cost is provided under 'Sub - Total Price', final cost may vary based on vendor’s billing logic.</p>

                                    <Row>
                                        <Col>
                                            <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="w-auto d-inline ml-3"
                                                id="inputPONumber"
                                                placeholder='Add PO Number'
                                                aria-describedby="inputPONumber"
                                                value={poNumber}
                                                maxlength="25"
                                                onChange={(e) => {
                                                    alphanumericRegex.test(e.target.value)
                                                        ? setPoNumber(e.target.value)
                                                        : console.log("")
                                                }}
                                            />
                                            <Form.Text id="poNumber" />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={6}>
                                            <Form.Check                                                  // prettier-ignore
                                                type="checkbox"
                                                id="termsCheckBox"
                                                label=""
                                                disabled
                                                checked={isTermsChecked}
                                                className="d-inline-block w-auto"
                                            />
                                            <p className="d-inline-block  w-auto mb-0">
                                                I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => setIsTermsChecked(true)}>terms & conditions</a> of Synnex cloud platform
                                            </p>

                                        </Col>
                                        <Col md={6}>
                                            <div className="btn-wrapper text-right d-md-block d-none">
                                                {isTermsChecked && <>
                                                    {!confirmUpgradeLoadingFlag && <Button
                                                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled={activeStep === 0}
                                                        onClick={handleBackOrderConfirmation}
                                                        sx={{ mr: 1 }}
                                                    >
                                                        Back
                                                    </Button>}
                                                    {confirmUpgradeLoadingFlag && <Button
                                                        className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled
                                                    >Back</Button>}

                                                    {!confirmUpgradeLoadingFlag && <Button
                                                        className="px-4 mx-2"
                                                        onClick={() => {
                                                            CoreOrderUpgradeConfirmation();
                                                        }}
                                                    >
                                                        Confirm
                                                    </Button>}
                                                    {confirmUpgradeLoadingFlag &&
                                                        <Button
                                                            disabled
                                                            className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                        >
                                                            Processing . . .
                                                        </Button>}
                                                </>
                                                }
                                                {!isTermsChecked &&
                                                    <>
                                                        <Button
                                                            className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                            disabled={activeStep === 0}
                                                            onClick={handleBackOrderConfirmation}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            Back
                                                        </Button>

                                                        <Button
                                                            variant="light"
                                                            className="px-4 mx-2"
                                                            disabled
                                                        >
                                                            Confirm
                                                        </Button>
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>}
                                <React.Fragment>
                                    {serverError &&
                                        <div className={styles["no-data-cloud-invoice"]}>
                                            <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                                            <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                        </div>
                                    }
                                    {emptyDataError &&
                                        <div className={styles["no-data-cloud-invoice"]}>
                                            <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                                            <h2 className="mt-4 mb-3">{activeStep === 0 ? "This Subscription can't be upgraded" : "No Plans Found"}</h2>
                                        </div>
                                    }
                                    {activeStep === 0 && <Typography sx={{ mt: 2, mb: 1 }}>
                                        {!serverError && !emptyDataError &&
                                            <div className={`table-responsive pt-3 ${styles['gws-upgrade-core']}`}>
                                                {!coreUpgradeDetails &&
                                                    <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans. . .</p></div>}
                                                {coreUpgradeDetails &&
                                                    <Table responsive="md">
                                                        <thead className="sticky-top">
                                                            <tr>
                                                                {UpgradeCoreColumnName.map(th => {
                                                                    return (<th className={th === "Plan" ? `${styles['gws-upgrade-plan-min-width']}` : ""}>{th}</th>)
                                                                })}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                coreUpgradeDetails.map((td) => {
                                                                    return (<tr>
                                                                        <td>
                                                                            <input type='radio'
                                                                                id={td.planName}
                                                                                style={{ verticalAlign: 'bottom' }}
                                                                                name="upgradeOptions"
                                                                                checked={defaultUpgrade === td.planName}
                                                                                onChange={() => {
                                                                                    setDefaultUpgrade(td.planName);
                                                                                    setPlanName(td.planName);
                                                                                    setButtonCheckstep1(true);
                                                                                }} />
                                                                        </td>
                                                                        <td>{td.planName}</td>
                                                                        <td>{td.description}</td>
                                                                    </tr>)
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                }
                                            </div>}
                                    </Typography>}
                                    {activeStep === 1 && <Typography sx={{ mt: 2, mb: 1 }}>
                                        {!serverError && !emptyDataError &&
                                            <div className={`table-responsive pt-3 ${styles['gws-upgrade-core-step2']}`}>
                                                {!coreUpgradeDetailsStep2 &&
                                                    <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans. . .</p></div>}
                                                {coreUpgradeDetailsStep2 &&
                                                    <Table responsive="md">
                                                        <thead className="sticky-top">
                                                            <tr>
                                                                {UpgradeCoreColumnNameStep2.map(th => {
                                                                    return (<th className={(th === "RRP (Inc GST)" || th === "Reseller Price (Ex GST)") ? "text-right" : ""}>{
                                                                        th === "Billing Term" ? <>{th}
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}

                                                                            >
                                                                                <span className="infoIcon-support-table infoIcon-support-table-big ml-1"></span>
                                                                            </OverlayTrigger></> : th}</th>)
                                                                })}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                coreUpgradeDetailsStep2.map((td) => {
                                                                    return (<tr>
                                                                        <td><input type='radio'
                                                                            style={{ verticalAlign: 'bottom' }}
                                                                            id={td.planId}
                                                                            name="upgradeOptionsStep2"
                                                                            checked={defaultUpgradeStep2 === (td.planId)}
                                                                            onChange={() => {
                                                                                setDefaultUpgradeStep2(td.planId);
                                                                                setButtonCheckstep2(true);
                                                                                setAllSelectedDetailsStep2(td);
                                                                            }}
                                                                        />
                                                                        </td>
                                                                        <td>{td.planName}</td>
                                                                        <td>{td.billingTerm}</td>
                                                                        <td className='text-right'>${td.rrpInclusiveTax}</td>
                                                                        <td className='text-right'>${td.price}</td>
                                                                    </tr>)
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                }
                                            </div>}
                                    </Typography>}
                                    {activeStep === 2 && <Typography sx={{ mt: 2, mb: 1 }}>
                                        <div className={`table-responsive pt-3 ${styles['gws-upgrade-core-step3']}`}>
                                            {upgradeCoreTableStep3 &&
                                                <DataTable
                                                    columns={upgradeCoreColumnsStep3}
                                                    data={upgradeCoreTableStep3}
                                                    theme="solarized"
                                                    customStyles={customStylesUpgrade}
                                                    persistTableHead={false}
                                                    noDataComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plan Details. . .</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="31vh" progressPending={loading}
                                                    progressComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plan Details. . .</p></div>}
                                                    fixedHeader
                                                />
                                            }
                                        </div>
                                    </Typography>}
                                    {/* buttons for upgrade */}
                                    {!serverError && !emptyDataError &&
                                        <div className='text-right pt-4'>
                                            {activeStep === 0 &&
                                                <React.Fragment>
                                                    {buttonCheckstep1 && <Button onClick={handleClearRows} className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light" sx={{ mr: 1 }}>
                                                        Clear
                                                    </Button>}
                                                    {!buttonCheckstep1 && <Button className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled>
                                                        Clear
                                                    </Button>}
                                                    {!buttonCheckstep1 && <Button
                                                        className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled
                                                    >
                                                        Next
                                                    </Button>}
                                                    {buttonCheckstep1 && <Button onClick={handleNextStep1}
                                                    >
                                                        Next
                                                    </Button>}
                                                </React.Fragment>
                                            }
                                            {activeStep === 1 &&
                                                <React.Fragment>
                                                    <Button
                                                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled={activeStep === 0}
                                                        onClick={handleBack}
                                                        sx={{ mr: 1 }}
                                                    > Back  </Button>
                                                    {buttonCheckstep2 && <Button onClick={handleNextStep2}
                                                    > Next </Button>}
                                                    {!buttonCheckstep2 && <Button
                                                        className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled
                                                    >Next </Button>}
                                                </React.Fragment>
                                            }
                                            {activeStep === 2 &&
                                                <React.Fragment>
                                                    <Button
                                                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        onClick={handleBackQuantity}
                                                        sx={{ mr: 1 }}
                                                    >
                                                        Back
                                                    </Button>
                                                    {<Button onClick={handleNextStep3}
                                                    >
                                                        Proceed
                                                    </Button>}
                                                </React.Fragment>
                                            }
                                        </div>}
                                </React.Fragment>
                            </Box>
                        </Container>
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default CSPUpgrade
