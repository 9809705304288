import React, { useState, useEffect } from 'react'
import { Accordion, Button, Col, Container, Form, Row } from 'react-bootstrap';
import styles from './others.module.scss';
import EmptyDataTable from '../../../../../Assets/Images/Illustrations/no-credit-card-data.svg'
import { GET_OTHERS_DETAILS } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';

const Others = (props) => {

  const [othersDetails, setOthersDetails] = useState(null);   // Details stored in Others payment method

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Get Others Data
  async function GetOthersDetails() {
    await api.get(GET_OTHERS_DETAILS, config)
      .then(resp => {
        if (resp.status === 200) {
          setOthersDetails(resp.data);
        }
        else {
          // console.log(resp.status);
        }
      })
      .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response.status == 401) {
          // console.log("Error 401");
          props.Logout();
        }
        else {
          // console.log(error);
        }

      });
  };

  useEffect(() => {
    if (props.activeAccordion === 3) {
      GetOthersDetails();
    }
  }, [props])
  return (
    <div className='mx-4' dangerouslySetInnerHTML={{ __html: othersDetails }}>
    </div>
  )
}

export default Others
