import React , {useState,useEffect} from 'react'
import { LANDING_PAGE_CMS_DATA } from '../../../Utils/GlobalConstants';
import { cmsApi } from '../../../Utils/API';
import "./styles.scss"
import UnautorisedNavbar from '../../../GlobalComponents/UnautorisedNavBar';

const OurVendors = ({customerIsAuthenticated,cookieAccepted}) => {

  const [ourVendorsData, setOurVendorsData] = useState(null);             // Our vendor page data

  // Function for calling vendor page item data from Orchid Core CMS    
	async function GetOurVendorsData() {
        const data = ` {
			ourVendorsPage {
				textContent
			}
		}`;

        // Defining Header Configuration
        const config = {
          headers: {
            "Content-Type": "application/graphql",
            Accept: "application/graphql",
          },
        };
    
        // Axios Post Request
        await cmsApi
          .post(LANDING_PAGE_CMS_DATA, data, config)
          .then((resp) => {
            // handle success
            // console.log(resp.data.data.ourVendorsPage[0].textContent);
            setOurVendorsData(
                resp.data.data.ourVendorsPage[0].textContent
            );
          })
          .catch((error) => {
            // handle error
            // console.log(error);
          });
      }

  // UseEffect Defination to call the get Cookie Policy items function from CMS
  useEffect(() => {
      GetOurVendorsData();
    }, []);

    useEffect(() => {
      if(ourVendorsData){
          const script = document.createElement('script');
          script.innerHTML = ourVendorsData.match(/<script>.*?<\/script>/s)[0].replace(/<script>/, '').replace(/<\/script>/, '');
          document.body.appendChild(script);
      }
      
    }, [ourVendorsData]);

  return (
    <>
    {!customerIsAuthenticated && <UnautorisedNavbar cookieAccepted={cookieAccepted}/>}
    <div className="our-vendors-main-component container-fluid">
		{/* <h2 className='mt-6'>Our Vendors</h2> */}
		{ourVendorsData && <div className='px-2 my-6 our-vendors-public-page' dangerouslySetInnerHTML={{__html:ourVendorsData.replace(/<script>.*?<\/script>/s, ''),}}>
    	</div>}
	</div> </>
  )
}

export default OurVendors