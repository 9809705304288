import { Step, StepLabel, Stepper } from '@mui/material';
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { useState } from "react";
import styles from './configureIntegration.module.scss';
import IntegrationSettingsConfigure from './LocalComponents/IntegrationSettings';
import SynchronizationSettingsConfigure from './LocalComponents/SynchronizationSettings';
import AgreementMappingConfigure from './LocalComponents/AgreementMapping';

const ConfigureIntegration = ({toggleDrawer, setSelectService, setSelectCategory, Logout}) => {
    
    //Stepper
    const steps = ['Integration settings', 'Synchronisation settings', 'Agreement mapping'];
    const [activeStep, setActiveStep] = useState(0);

    return (
        <div className='main-content'>
            <div className="accordion-panel mt-0">
                <Container fluid>
                    <Row >
                        <Col lg={6} md={9} 
                            className={`${styles['button-color-manage-gws']}`}>
                            {activeStep !== steps.length && (
                                <Stepper activeStep={activeStep}>
                                    {steps.map((label, index) => {
                                        const stepProps = {};
                                        const labelProps = {};
                                        return (
                                            <Step key={label} {...stepProps}>
                                                <StepLabel {...labelProps}>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            )}
                        </Col>
                        <Col lg={6}></Col>
                    </Row>
                </Container>
                {activeStep === 0 &&
                    <IntegrationSettingsConfigure setActiveStep={setActiveStep} toggleDrawer={toggleDrawer} setSelectService={setSelectService} setSelectCategory={setSelectCategory} Logout={Logout} />
                }
                {activeStep === 1 &&
                    <SynchronizationSettingsConfigure setActiveStep={setActiveStep} Logout={Logout} />
                }
                {activeStep === 2 &&
                    <AgreementMappingConfigure setActiveStep={setActiveStep} Logout={Logout} />
                }
            </div>
        </div>
    )
}

export default ConfigureIntegration
