import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import styles from './monthlySummary.module.scss'
import { CUSTOMERS_MONTHLY_SUMMARY_WIDGET } from '../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../Utils/API';
import { useLocation } from "react-router-dom";

const MonthlySummary = ({ Logout }) => {

  const location = useLocation();
  const [customerCode, setCustomerCode] = useState(location.state !== null ? location.state.customerCode : "");   // used for api
  const [monthlySummaryWidget, setMonthlySummaryWidget] = useState(null);                                         // storing monthly summary data from api

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Function to call monthly summary
  async function GetMonthlySummary() {
    await api.get(`${CUSTOMERS_MONTHLY_SUMMARY_WIDGET}/${customerCode}`, config)
      .then(resp => {
        setMonthlySummaryWidget(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

  useEffect(() => {
    GetMonthlySummary()
  }, [])

  return (
    <div className={styles["monthly-summary"]}>
      <h3>Monthly Summary</h3>
      <Row>
        {
          monthlySummaryWidget && monthlySummaryWidget.map((widget) => {
            return (
              <Col lg={5} md={11} className={styles["monthly-summary-box"]}>
                <span className="text-strong">{widget.widgetTitle}</span>
                {(widget.percentage == "-" || widget.percentage == 0) ? <h1 className='text-disabled'>&nbsp;- 0%</h1> :
                  <h1 className={widget.isIncrease ? "text-success" : "text-danger"}><span className={widget.isIncrease ? "dashboard-green-arrow mb-1" : "dashboard-red-arrow mb-1"}></span> {Math.abs(widget.percentage)}%</h1>
                }
                <span>{widget.total} {(widget.widgetTitle)}</span>
              </Col>
            )
          })
        }
      </Row>
    </div>
  )
}

export default MonthlySummary
