import React, { useState } from 'react'
import { Button, Col, Container, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import styles from './Map.module.scss';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import CompanySelection from './CompanySelection';
import AgreementSelection from './AgreementSelection';
import AdditionSelection from './AdditionSelection';
import SuccessImagePreRequisite from '../../../../../../../../../Assets/Images/Illustrations/SuccessImagePreRequisite.svg';
import failure from '../../../../../../../../../Assets/Images/Illustrations/failure.png';
import { api } from '../../../../../../../../../Utils/API';
import { POST_AGREEMENT_MAPPING } from '../../../../../../../../../Utils/GlobalConstants';


const Map = ({ setMapActionPopup, mapActionPopup, completeRowData, setCompleteRowData, syncronizationData, setActionCompletedRefreshTable, Logout }) => {

    let ColumnName = ["Customer", "Service", "Plan Name", "Subscription ID", "Billing Term"];  // Columns of table 
    const [loadingState, setLoadingState] = useState(false);                                   // loading state

    //  Company Selection - Stepper 1
    const [companyData, setCompanyData] = useState(null);
    const [defaultCompanySelected, setDefaultCompanySelected] = useState(null);                                                                       //stepper step1 which contain selected data
    const [companyId, setCompanyId] = useState(null);
    const [companyName, setCompanyName] = useState(null);

    // Agreement Selection - Stepper 2
    const [agreementData, setAgreementData] = useState(null);
    const [defaultAgreementSelected, setDefaultAgreementSelected] = useState(null);                                                                       //stepper step1 which contain selected data
    const [agreementId, setAgreementId] = useState(null);
    const [agreementName, setAgreementName] = useState(null);

    // Addition selection - Stepper 3
    const [additionData, setAdditionData] = useState(null);
    const [defaultAdditionSelected, setDefaultAdditionSelected] = useState(null);
    const [additionName, setAdditionName] = useState(null);
    const [additionDescription, setAdditionDescription] = useState(null);
    const [additionId, setAdditionId] = useState(null);

    //confirmation
    let ConfirmationColumnName = ["Company", "Agreement Name", "Addition Name", "Addition Description"];   // Columns of confirmation table 
    const [confirmationCheck, setConfirmationCheck] = useState(false);                                     // to display confirmation table
    const [errorSuccessPopup, setErrorSuccessPopup] = useState(false);                                     // to open and close confirmation screen ie. success or error popup
    const [isSuccess, setIsSuccess] = useState(false);                                                     // to check whether it is success or error in post api
    const [isErrorMessage, setIsErrorMessage] = useState("");                                              // displaying error message

    //Stepper
    const steps = ['Select Company', 'Select Agreement', 'Select Addition'];
    const [activeStep, setActiveStep] = useState(0);
    const [buttonCheckstep1, setButtonCheckstep1] = useState(false);
    const [buttonCheckstep2, setButtonCheckstep2] = useState(false);
    const [buttonCheckstep3, setButtonCheckstep3] = useState(false);

    // selected options for nce plans -tooltip
    const { selectedOptionsWithHeading: nceSelectedOptionsWithHeading, selectedCheckboxes: nceSelectedCheckboxes } = GetSyncronizationToolTipData(syncronizationData.nceSyncronizationSettings);
    //  selected options for legacy plans-tooltip
    const { selectedOptionsWithHeading: legacySelectedOptionsWithHeading, selectedCheckboxes: legacySelectedCheckboxes } = GetSyncronizationToolTipData(syncronizationData.legacySyncronizationSettings);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    //for syncronization settings
    function GetSyncronizationToolTipData(dataArray) {
        const selectedOptionsWithHeading = [];
        const selectedCheckboxes = [];
        if (Array.isArray(dataArray)) {
            for (const item of dataArray) {
                if (item.elementType === "CheckBox") {
                    const selectedOptions = item.data.filter(option => option.selected === true).map(option => option.name);
                    if (selectedOptions.length > 0) {
                        selectedCheckboxes.push({
                            heading: item.name,
                            selectedOptions: selectedOptions.join(', ')
                        });
                    }
                } else {
                    const selectedOption = item.data.find(option => option.selected === true);
                    if (selectedOption) {
                        selectedOptionsWithHeading.push({
                            heading: item.name,
                            selectedOption: selectedOption.name
                        });
                    }
                }
            }
        } else {
            // console.error("Input data is not an array.");
        }
        return { selectedOptionsWithHeading, selectedCheckboxes };
    }
    // syncronization settings tooltip
    const renderTooltip = (props) => (
        <Tooltip className='tooltip-integrations' {...props}>
            {/* nce plans */}
            {completeRowData.ncePlan && (
                <div className='mt-2 mb-2'>
                    <span className='text-strong text-medium'>Synchronisation settings currently active for NCE plans</span>

                    <div className='row mt-2 text-medium'>
                        {/* selected options (radio buttons) */}
                        {nceSelectedOptionsWithHeading.map((option, index) => (
                            <div key={index} className='col-6 mb-2'>
                                <div>
                                    <span>{option.heading}</span><br />
                                    <span className='text-strong'>{option.selectedOption}</span>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* selected checkboxes*/}
                    <div className='row'>
                        {nceSelectedCheckboxes.map((option, index) => (
                            <div key={index} className='col-6 mb-2'>
                                <div className='text-medium'>
                                    <span>{option.heading}</span><br />
                                    <span className='text-strong'>{option.selectedOptions}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* legacy plans */}
            {!completeRowData.ncePlan && (
                <div className='mb-2 mt-2'>
                    <span className='text-strong text-medium'>Synchronisation settings currently active for Legacy plans</span>

                    <div className='row mt-2 text-medium'>
                        {/* selected options (radio) */}
                        {legacySelectedOptionsWithHeading.map((option, index) => (
                            <div key={index} className='col-6 mb-2'>
                                <div>
                                    <span>{option.heading}</span><br />
                                    <span className='text-strong'>{option.selectedOption}</span>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* selected checkboxes */}
                    <div className='row'>
                        {legacySelectedCheckboxes.map((option, index) => (
                            <div key={index} className='col-6 mb-2'>
                                <div className='text-medium'>
                                    <span>{option.heading}</span><br />
                                    <span className='text-strong'>{option.selectedOptions}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </Tooltip>
    );

    // Post Agreement Mapping
    async function PostAgreementMapping() {
        setLoadingState(true);
        let body = {
            "cloudVendorSubscriptionId": completeRowData.vendorSubscriptionId,
            "agreementMappingId": completeRowData.agreementMappingId,
            "connectwiseCustomerId": companyId,
            "connectwiseCustomerName": companyName,
            "connectwiseCustomerAgreementId": agreementId,
            "connectwiseCustomerAgreementName": agreementName,
            "connectwiseCustomerAgreementAdditionId": additionId,
            "connectwiseCustomerAgreementAdditionName": additionName,
            "connectwiseAdditionQuantity": 1,
            "connectwiseAdditionCancelledDate": "",
            "integrationId": 1,
            "connectwiseAdditionDescription": additionDescription
        }
        await api.post(POST_AGREEMENT_MAPPING, body, config)
            .then(resp => {
                setLoadingState(false);
                if (resp.request.status == 200) {
                    setMapActionPopup(false);
                    // handle success
                    setIsSuccess(true);
                    setErrorSuccessPopup(true);
                }
            })
            .catch(error => {
                setLoadingState(false);
                setErrorSuccessPopup(true);
                setMapActionPopup(false);
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                    setIsErrorMessage(error.response.data.errors);
                    setIsSuccess(false);
                }
            });
    };

    return (
        <div>

            {/* Success error popup */}
            <Popup
                className="custom-modal custom-modal-sm"
                open={errorSuccessPopup}
                onClose={() => { setErrorSuccessPopup(false); setAdditionName(""); setAgreementName(""); setCompanyName(""); setActiveStep(0); setConfirmationCheck(false); setCompanyData(null); setAgreementData(null); setAdditionData(null); setDefaultAdditionSelected(null); setDefaultAgreementSelected(null); setDefaultCompanySelected(null); setCompleteRowData(""); }}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">Map Addition - Confirmation</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color="#6A6A6A"
                            />
                        </div>
                        <Container>
                            <div className={styles["no-data-cloud-invoice"]}>
                                {isSuccess && <> <img src={SuccessImagePreRequisite} className="empty-cloud-invoice-table-svg" />
                                    <span className='mt-5 mb-5'>Your subscription is mapped successfully.</span></>}
                                {!isSuccess && <> <img src={failure} className="empty-cloud-invoice-table-svg" />
                                    <span className='red text-strong'>Subscription mapping failed.</span>
                                    <span className="mt-4 mb-5 text-center">{isErrorMessage}</span></>}
                                <Button
                                    className="px-4 mx-2"
                                    onClick={() => {
                                        setActionCompletedRefreshTable(true);
                                        close();
                                    }}
                                >Ok</Button>
                            </div>
                        </Container>
                    </div>)}
            </Popup>


            <Popup
                className="custom-modal custom-modal-xl"
                open={mapActionPopup}
                onClose={() => { setMapActionPopup(false); setAdditionName(""); setAgreementName(""); setCompanyName(""); setActiveStep(0); setConfirmationCheck(false); setCompanyData(null); setAgreementData(null); setAdditionData(null); setDefaultAdditionSelected(null); setDefaultAgreementSelected(null); setDefaultCompanySelected(null); setCompleteRowData(""); setButtonCheckstep1(false); setButtonCheckstep2(false); setButtonCheckstep3(false);}}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">{confirmationCheck? "Map Addition - Confirmation":"Map Addition"}</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color="#6A6A6A"
                            />
                        </div>
                        <Container className='pb-0'>
                            <h3 className="mb-5 px-3">Synnex data</h3>
                            <Table responsive="md">
                                <thead className="sticky-top">
                                    <tr>
                                        {ColumnName.map(th => {
                                            return (<th>{th}</th>)
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {completeRowData &&
                                        <tr>
                                            <td>{completeRowData.customerName}</td>
                                            <td>{completeRowData.serviceName}</td>
                                            <td>{completeRowData.planName}</td>
                                            <td>{completeRowData.vendorSubscriptionId}</td>
                                            <td>{completeRowData.billingTerm}</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            <Row>
                                <Col md={8}>
                                    <h3 className="mb-0 px-3">Connectwise data</h3>
                                </Col>
                                <Col md={4}>
                                    <h3 className="mb-0 px-3 justify-content-end d-flex">Synchronisation settings
                                        <OverlayTrigger
                                            placement="left"
                                            overlay={renderTooltip()}
                                        >
                                            <span className="infoIcon-support-table ml-1"></span>
                                        </OverlayTrigger></h3>
                                </Col>
                            </Row>

                            {/* confirmation table with all required details */}
                            {confirmationCheck &&
                                <div className='mt-3'>
                                    <Table responsive="md">
                                        <thead className="sticky-top">
                                            <tr>
                                                {ConfirmationColumnName.map(th => {
                                                    return (<th>{th}</th>)
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                <tr>
                                                    <td>{companyName}</td>
                                                    <td>{agreementName}</td>
                                                    <td>{additionName}</td>
                                                    <td>{additionDescription}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                    <div className="btn-wrapper text-right mb-6">
                                        {!loadingState && <>  <Button
                                            className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                            variant="light"
                                            onClick={() => {
                                                setConfirmationCheck(false); setActiveStep(2);
                                            }}
                                        >
                                            Back
                                        </Button>
                                            <Button
                                                className="px-4 mx-2"
                                                onClick={() => {
                                                    PostAgreementMapping();
                                                }}
                                            >Confirm</Button></>}
                                        {loadingState && <>
                                            <Button
                                                className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                variant="light"
                                                disabled
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                disabled
                                                className="ml-2 px-4 btn-border-light w-md-auto col-xs-12"
                                                variant="light"
                                            >
                                                Processing . . .
                                            </Button>
                                        </>
                                        }
                                    </div>
                                </div>
                            }
                            {!confirmationCheck &&
                                <>
                                    <Box sx={{ width: '100%' }} className='pt-4 mb-2'>
                                        <Row>
                                            <Col md={2}></Col>
                                            <Col md={8}>
                                                {activeStep !== steps.length && (
                                                    <Stepper activeStep={activeStep}>
                                                        {steps.map((label, index) => {
                                                            const stepProps = {};
                                                            const labelProps = {};
                                                            return (
                                                                <Step key={label} {...stepProps}>
                                                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                                                </Step>
                                                            );
                                                        })}
                                                    </Stepper>)}
                                            </Col>
                                            <Col md={2}></Col>
                                        </Row>
                                        <Row className='px-3'>
                                            <Col md={4}>
                                                {buttonCheckstep1 && (activeStep === 1 || activeStep === 2) && <div className='text-small mb-3 mt-3'>
                                                    <span className='text-strong'>Company name : </span>
                                                    {companyName}
                                                </div>}
                                            </Col>
                                            <Col md={8}>
                                                {activeStep === 2 && <div className='text-small mb-3 mt-3'>
                                                    <span className='text-strong'>Agreement name : </span>
                                                    {agreementName}
                                                </div>}

                                            </Col>
                                        </Row>
                                    </Box>
                                    {activeStep === 0 &&
                                        <CompanySelection setMapActionPopup={setMapActionPopup} setActiveStep={setActiveStep} activeStep={activeStep} companyName={companyName} setCompanyName={setCompanyName} buttonCheckstep1={buttonCheckstep1} setButtonCheckstep1={setButtonCheckstep1} setCompanyId={setCompanyId} setCompanyData={setCompanyData} companyData={companyData} setDefaultCompanySelected={setDefaultCompanySelected} defaultCompanySelected={defaultCompanySelected} Logout={Logout} />
                                    }
                                    {
                                        activeStep === 1 &&
                                        <AgreementSelection setActiveStep={setActiveStep} activeStep={activeStep} agreementName={agreementName} setAgreementName={setAgreementName} companyId={companyId} buttonCheckstep2={buttonCheckstep2} setButtonCheckstep2={setButtonCheckstep2} setAgreementId={setAgreementId} agreementData={agreementData} setAgreementData={setAgreementData} defaultAgreementSelected={defaultAgreementSelected} setDefaultAgreementSelected={setDefaultAgreementSelected} Logout={Logout} />
                                    }
                                    {
                                        activeStep === 2 &&
                                        <AdditionSelection setActiveStep={setActiveStep} activeStep={activeStep} setConfirmationCheck={setConfirmationCheck} agreementId={agreementId} buttonCheckstep3={buttonCheckstep3} setButtonCheckstep3={setButtonCheckstep3} additionName={additionName} setAdditionName={setAdditionName} additionData={additionData} setAdditionData={setAdditionData} defaultAdditionSelected={defaultAdditionSelected} setDefaultAdditionSelected={setDefaultAdditionSelected} setAdditionDescription={setAdditionDescription} setAdditionId={setAdditionId} Logout={Logout} />
                                    }
                                </>}
                        </Container>
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default Map
