import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"
import styles from '../../myCustomers.module.scss'

import { createTheme } from 'react-data-table-component';
import { api } from "../../../../../Utils/API";

import Form from 'react-bootstrap/Form';
import 'reactjs-popup/dist/index.css';
import InputGroup from 'react-bootstrap/InputGroup';
import EmptyDataTableFilterSearch from '../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import RaiseTicketIllustration from '../../../../../Assets/Images/Illustrations/raiseticketillustration.svg'
import { AUDIT_REPORT_CUSTOMER_BULK_UPLOAD, AUDIT_REPORT_CUSTOMER_BULK_UPLOAD_DOWNLOAD, AUDIT_REPORT_MCA_BULK_UPLOAD, AUDIT_REPORT_MCA_BULK_UPLOAD_DOWNLOAD } from '../../../../../Utils/GlobalConstants';
import "reactjs-popup/dist/index.css";
import { useMsal } from '@azure/msal-react';
import BulkUploadFilterBoxCustomers from './LocalComponents/FilterBoxCustomer';
import BulkUploadFilterBoxMCA from './LocalComponents/FilterBoxMCA';
import LazyTable from './LocalComponents/LazyTable';
import Toast from '../../../../../GlobalComponents/Toast';

const AuditReport = (props) => {
    const navigate = useNavigate();                                             // to use navigate function from react router dom    
    const date = new Date();                                                    // for js date time function

    const { instance } = useMsal();

    const [tabName, setTabName] = useState("Customer Bulk Upload");             // Default Tab

    // Customer Tab Search and Filter Parameters

    const [searchIconFlag, setSearchIconFlag] = useState(false);                // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(null);             // storing searching value in the search input box
    const [filterFlag, setFilterFlag] = useState(false);                        // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                  // showing filter box
    const searchInputRef = useRef();                                            // Search Input Box


    // MCA Tab Search and Filter Parameters

    const [searchIconFlagMCA, setSearchIconFlagMCA] = useState(false);                // to open and close search box
    const [searchValueTableMCA, setSearchValueTableMCA] = useState(null);             // storing searching value in the search input box
    const [filterFlagMCA, setFilterFlagMCA] = useState(false);                        // to show active and deactive filter states 
    const [showFilterBoxMCA, setShowFilterBoxMCA] = useState(false);                  // showing filter box
    const searchInputRefMCA = useRef();                                            // Search Input Box


    // Table Parameters
    const [pageNo, setPageNo] = useState(1);                                    // table active page number
    const [pageSize, setPageSize] = useState(15);                                // table active page size
    const [pageNoMCA, setPageNoMCA] = useState(1);                                    // table active page number MCA
    const [pageSizeMCA, setPageSizeMCA] = useState(15);                                // table active page size MCA
    const [totalRows, setTotalRows] = useState(0);                              // table total count of data from api 
    const [totalRowsMCA, setTotalRowsMCA] = useState(0);                              // table total count of data from api 
    const [columns, setColumns] = useState([]);                                 // columns used to display column headers in the react data table component
    const [columnsMCA, setColumnsMCA] = useState([]);                           // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                     // data state used to display data in react data table component
    const [tableMCA, setTableMCA] = useState([]);                                     // data state used to display data in react data table component

    let customerColumns = ["Audit ID", "Date", "Organization", "Full Name", "Email", "ABN Number", "Status", "Message"];    // Customer Bulk Upload Columns

    let mcaColumns = ["Audit ID", "Date", "Company  Name", "Contact Name", "MCA Domain", "MCA Agreement", "Status", "Message"];    // MCA Bulk Upload Columns
    const [initialLoading, setInitialLoading] = useState(true);                 // loader for table
    const [initialLoadingMCA, setInitialLoadingMCA] = useState(true);                 // loader for table
    const [status, setStatus] = useState("");                                 // getTableData() status
    const [statusMCA, setStatusMCA] = useState("");                                 // getTableData() status
    const [customerCode, setCustomerCode] = useState("");                       // getTableData() customer code 

    const [serverErrorCustomer, setServerErrorCustomer] = useState(false);      // server error check state in table during api call to load data
    const [serverErrorCustomerMCA, setServerErrorCustomerMCA] = useState(false);      // server error check state in table during api call to load data
    const [emptyCustomer, setEmptyCustomer] = useState(false);                  // empty table check state in table during api call to load data
    const [emptyCustomerMCA, setEmptyCustomerMCA] = useState(false);                  // empty table check state in table during api call to load data
    const [emptyCustomerFilters, setEmptyCustomerFilters] = useState(false);    // empty filter response check state in table during api call to load data
    const [emptyCustomerFiltersMCA, setEmptyCustomerFiltersMCA] = useState(false);    // empty filter response check state in table during api call to load data
    const [emptyCustomerSearch, setEmptyCustomerSearch] = useState(false);      // empty search response check state in table during api call to load data
    const [emptyCustomerSearchMCA, setEmptyCustomerSearchMCA] = useState(false);      // empty search response check state in table during api call to load data


    // Get Table Data Function Parameters
    const [isDownloadReport, setIsDownloadReport] = useState(false);             // getTableData() Download Allowed  

    const [dateFrom, setDateFrom] = useState(null);                             // getTableData() from date
    const [dateTo, setDateTo] = useState(null);                                 // getTableData()  to date
    const [sortCol, setSortCol] = useState("");                     // getTableData() sorting column name
    const [sortDir, setSortDir] = useState("");                              // getTableData() sorting direction ie. "DESC" for descending and "ASC" for ascending

    //filter
    const [dateLastUsedDate, setDateLastUsedDate] = useState("default");        // date dropdown saved data after applying filters
    const [statusLastUsed, setStatusLastUsed] = useState("default");            // status dropdown saved data after applying filters  
    const [statusData, setStatusData] = useState([
        {
            "id": "Success",
            "name": "Success"
        },
        {
            "id": "Failed",
            "name": "Failed"
        }
    ]);                            // storing data of status filter api

    // if custom was selected in date in filter box
    const [dateCustomFromLast, setDateCustomFromLast] =
        useState(date.toISOString().slice(0, 10));            // from date saved data after applying filter having custom selection
    const [dateCustomToLast, setDateCustomToLast] =
        useState(date.toISOString().slice(0, 10));            // to date saved data after applying filter having custom selection



    // MCA Tabs
    const [dateFromMCA, setDateFromMCA] = useState(null);                             // getTableData() from date
    const [dateToMCA, setDateToMCA] = useState(null);                                 // getTableData()  to date
    const [sortColMCA, setSortColMCA] = useState("");                     // getTableData() sorting column name
    const [sortDirMCA, setSortDirMCA] = useState("");                              // getTableData() sorting direction ie. "DESC" for descending and "ASC" for ascending

    //filter
    const [dateLastUsedDateMCA, setDateLastUsedDateMCA] = useState("default");        // date dropdown saved data after applying filters
    const [statusLastUsedMCA, setStatusLastUsedMCA] = useState("default");            // status dropdown saved data after applying filters  
    const [statusDataMCA, setStatusDataMCA] = useState([
        {
            "id": "Success",
            "name": "Success"
        },
        {
            "id": "Failed",
            "name": "Failed"
        }
    ]);                            // storing data of status filter api

    // if custom was selected in date in filter box
    const [dateCustomFromLastMCA, setDateCustomFromLastMCA] =
        useState(date.toISOString().slice(0, 10));            // from date saved data after applying filter having custom selection
    const [dateCustomToLastMCA, setDateCustomToLastMCA] =
        useState(date.toISOString().slice(0, 10));            // to date saved data after applying filter having custom selection

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                    // toast message displaying success message
    const [toastError, setToastError] = useState(false);                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                       // toast message usestate


    const [bulkUpload, setBulkUpload] = useState("Bulk Upload");                //  Set Upload Type on change
    const uploadType = ["Customer bulk upload", "MCA Bulk upload"];             // Upload Types

    //lazy loading
    const [scrollToDivTop, setScrollToDivTop] = useState(false);
    const [scrollToDivTopMCA, setScrollToDivTopMCA] = useState(false);
    const [infinityLoading, setInfinityLoading] = useState(false);                     // loading state while table loads for lazy loading
    const [infinityLoadingMCA, setInfinityLoadingMCA] = useState(false);                     // loading state while table loads for lazy loading

    // Logout Function on error 410
    function Logout() {
        props.setCustomerIsAuthenticated(false);
        if (props.customerIsAuthenticated) { navigate("/") };
        localStorage.clear();
        instance.logoutRedirect();
    }

    // UseEffect to run on initial load to navigate unauthorized user to Login Page
    // useEffect(() => {
    //     if (props.customerIsAuthenticated === false) {
    //         navigate("/");
    //     }
    // }, [])

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download CSV API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download CSV File 
    async function DownloadAction(id, url, name, fileType, searchValueTableAudit, statusAudit, dateFromAudit, dateToAudit) {

        let searchValueTableCustomer = searchValueTableAudit || "";
        let statusCustomer = statusAudit || "";
        let dateStartFromCustomer = dateFromAudit || "";
        let dateEndToCustomer = dateToAudit || "";

        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url}?searchText=${searchValueTableCustomer}&status=${statusCustomer}&fromDate=${dateStartFromCustomer}&toDate=${dateEndToCustomer}` + id, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {

                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });

                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                }
            });
    };

    // Three Dot Actions Functionality
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            className={styles['threeDotAnchorTag']}
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <span className={styles["threedots"]} />
        </a>
    ));

    // Get Table Data with Filters, Pagination, Search and Sort Functionality for Customer Bulk Upload
    async function GetTableDataCustomer(searchValueTable, status, dateFrom, dateTo, IsDownloadReport, pageNo, pageSize, appendData, isFilter) {
        setInitialLoading(false);
        (searchValueTable === undefined || searchValueTable === null) ? searchValueTable = "" : searchValueTable = searchValueTable;
        (status === undefined || status === null) ? status = "" : status = status;
        (dateFrom === undefined || dateFrom === null) ? dateFrom = "" : dateFrom = dateFrom;
        (dateTo === undefined || dateTo === null) ? dateTo = "" : dateTo = dateTo;
        (IsDownloadReport === undefined || IsDownloadReport === null) ? IsDownloadReport = "" : IsDownloadReport = IsDownloadReport;
        isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;

        await api.get(`${AUDIT_REPORT_CUSTOMER_BULK_UPLOAD}?searchText=${searchValueTable}&status=${status}&fromDate=${dateFrom}&toDate=${dateTo}&IsDownloadReport=${IsDownloadReport}&pageNo=${pageNo}&pageSize=${pageSize}&sortCol=${sortCol}&sortDir=${sortDir}`, config)
            .then(resp => {

                setInitialLoading(true);
                setInfinityLoading(false);
                if (resp.status == 200) {
                    setServerErrorCustomer(false);
                    setEmptyCustomer(false);
                    setEmptyCustomerFilters(false);
                    setEmptyCustomerSearch(false);
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                // Customer Bulk Upload Columns
                                {
                                    "Audit ID": td.auditId,
                                    "Date": td.createdDate,
                                    "Organization": td.organisation,
                                    "Full Name": td.customerName,
                                    "Email": td.email,
                                    "ABN Number": td.abnNumber,
                                    "Status": td.status == "Failed" ?
                                        <>
                                            <span className='d-block text-center mt-1'>
                                                <span className="status-error">&nbsp;</span><br />
                                                <span className="red text-small">Failed</span>
                                            </span>
                                        </>
                                        :
                                        <>
                                            <span className='d-block text-center mt-1'>
                                                <span className="status-success">&nbsp;</span><br />
                                                <span className="green text-small">Success</span>
                                            </span>
                                        </>,
                                    "Message": td.message
                                }
                            );
                    })

                    setTimeout(() => {
                        if (appendData) {
                            setTable(table => [...table, ...f])            // Setting Table Data
                        } else {
                            setTable(f)
                        }
                    }, 50);
                    let d = [];

                    customerColumns.map((td) => {
                        if (td == "Status") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: false,
                                center: true,
                                allowOverflow: true,
                            })
                        } else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                allowOverflow: true,
                            })
                        }


                    })
                    setColumns(d);
                }

                if (resp.status == 204) {
                    setTotalRows(0);

                    if (searchValueTable === "" && dateFrom === "" && dateTo === "" && status === "") {
                        setEmptyCustomer(true);
                    } else if ((dateFrom != "" || dateTo != "" || status != "") && isFilter) {
                        setEmptyCustomerFilters(true);
                    } else if (searchValueTable != "" && !isFilter) {
                        setEmptyCustomerSearch(true);
                    }
                }


            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                }
            });
    };


    // Get Table Data with Filters, Pagination, Search and Sort Functionality for Customer Bulk Upload
    async function GetTableDataMCA(searchValueTableMCA, statusMCA, dateFromMCA, dateToMCA, IsDownloadReport, pageNoMCA, pageSizeMCA, appendData, isFilterMCA) {
        setInitialLoadingMCA(false);
        (searchValueTableMCA === undefined || searchValueTableMCA === null) ? searchValueTableMCA = "" : searchValueTableMCA = searchValueTableMCA;
        (statusMCA === undefined || statusMCA === null) ? statusMCA = "" : statusMCA = statusMCA;
        (dateFromMCA === undefined || dateFromMCA === null) ? dateFromMCA = "" : dateFromMCA = dateFromMCA;
        (dateToMCA === undefined || dateToMCA === null) ? dateToMCA = "" : dateToMCA = dateToMCA;
        (IsDownloadReport === undefined || IsDownloadReport === null) ? IsDownloadReport = "" : IsDownloadReport = IsDownloadReport;
        isFilterMCA = (isFilterMCA === undefined || isFilterMCA === null) ? false : isFilterMCA;

        await api.get(`${AUDIT_REPORT_MCA_BULK_UPLOAD}?searchText=${searchValueTableMCA}&status=${statusMCA}&fromDate=${dateFromMCA}&toDate=${dateToMCA}&IsDownloadReport=${IsDownloadReport}&pageNo=${pageNoMCA}&pageSize=${pageSizeMCA}`, config)
            .then(resp => {
                setInitialLoadingMCA(true);
                setInfinityLoadingMCA(false);

                if (resp.status == 200) {
                    setServerErrorCustomerMCA(false);
                    setEmptyCustomerMCA(false);
                    setEmptyCustomerFiltersMCA(false);
                    setEmptyCustomerSearchMCA(false);
                    let f = [];
                    setTotalRowsMCA(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                // Customer Bulk Upload Columns
                                {
                                    "Audit ID": td.auditId,
                                    "Date": td.createdDate,
                                    "Company  Name": td.organisation,
                                    "Contact Name": td.contactName,
                                    "MCA Domain": td.mcaDomain,
                                    "MCA Agreement": td.mcaAgreementDate,
                                    "Status": td.status == "Failed" ?

                                        <span className='d-block text-center mt-1'>
                                            <span className="status-error">&nbsp;</span><br />
                                            <span className="red text-small">Failed</span>
                                        </span>

                                        :

                                        <span className='d-block text-center mt-1'>
                                            <span className="status-success">&nbsp;</span><br />
                                            <span className="green text-small">Success</span>
                                        </span>
                                    ,
                                    "Message": td.message
                                }
                            );
                    })

                    setTimeout(() => {
                        if (appendData) {
                            setTableMCA(table => [...table, ...f])            // Setting Table Data
                        } else {
                            setTableMCA(f)
                        }
                    }, 50);
                    let d = [];

                    mcaColumns.map((td) => {
                        if (td == "Status") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: false,
                                center: true,
                                allowOverflow: true,
                            })
                        } else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                allowOverflow: true,
                            })
                        }

                    })
                    setColumnsMCA(d);
                }
                if (resp.status == 204) {
                    setTotalRowsMCA(0);

                    if (searchValueTableMCA === "" && dateFromMCA === "" && dateToMCA === "" && statusMCA === "") {
                        setEmptyCustomerMCA(true);
                    } else if ((dateFromMCA != "" || dateToMCA != "" || statusMCA != "") && isFilterMCA) {
                        setEmptyCustomerFiltersMCA(true);
                    } else if (searchValueTableMCA != "" && !isFilterMCA) {
                        setEmptyCustomerSearchMCA(true);
                    }


                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                }
            });
    };

    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
        GetTableDataCustomer(searchValueTable, status, dateFrom, dateTo, isDownloadReport, pageNo, pageSize);
        GetTableDataMCA(searchValueTable, status, dateFrom, dateTo, isDownloadReport, pageNoMCA, pageSizeMCA);
    }, []);

    // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCalls = async () => {
        setInfinityLoading(true);
        setPageNo((prev) => {
            const newPageNumber = prev + 1;
            (async () => await GetTableDataCustomer(searchValueTable, status, dateFrom, dateTo, isDownloadReport, newPageNumber, pageSize, true))();

            return newPageNumber;
        });
    }

    // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCallsMCA = async () => {
        setInfinityLoadingMCA(true);
        setPageNoMCA((prev) => {
            const newPageNumber = prev + 1;
            (async () => await GetTableDataMCA(searchValueTableMCA, statusMCA, dateFromMCA, dateToMCA, isDownloadReport, pageNoMCA, pageSizeMCA, true))();

            return newPageNumber;
        });
    }
    // Customer Search On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
        if (searchValueTable !== undefined && searchValueTable !== null) {
            setInitialLoading(true);
            const getData = setTimeout(() => {
                if (searchValueTable.length > 0) {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    GetTableDataCustomer(searchValueTable, status, dateFrom, dateTo, isDownloadReport, 1, pageSize);
                } else {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    GetTableDataCustomer(searchValueTable, status, dateFrom, dateTo, isDownloadReport, 1, pageSize);
                }
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable]);


    // Customer Search On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
        if (searchValueTableMCA !== undefined && searchValueTableMCA !== null) {
            const getData = setTimeout(() => {
                if (searchValueTableMCA.length > 0) {
                    setScrollToDivTopMCA(true);
                    setPageNoMCA(1);
                    GetTableDataMCA(searchValueTableMCA, statusMCA, dateFromMCA, dateToMCA, isDownloadReport, 1, pageSizeMCA);
                } else {
                    setScrollToDivTopMCA(true);
                    setPageNoMCA(1);
                    GetTableDataMCA(searchValueTableMCA, statusMCA, dateFromMCA, dateToMCA, isDownloadReport, 1, pageSizeMCA);
                }
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTableMCA]);

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {

        if (tabName == "Customer Bulk Upload") {
            setPageNo(pageNo);
            GetTableDataCustomer(searchValueTable, status, dateFrom, dateTo, isDownloadReport, pageNo, pageSize);
        }
        if (tabName == "MCA Bulk Upload") {
            setPageNoMCA(pageNo);
            GetTableDataMCA(searchValueTable, status, dateFrom, dateTo, isDownloadReport, pageNo, pageSizeMCA);
        }
    };

    // Function Triggers when Rows Per Page is Changed by Customer 
    const handlePerRowsChange = async (newPerPage, page) => {
        setPageNo(page);
        setPageSize(newPerPage);
        GetTableDataCustomer(searchValueTable, status, dateFrom, dateTo, isDownloadReport, pageNo, newPerPage);
    };

    // Function Triggers when Rows Per Page is Changed by Customer 
    const handlePerRowsChangeMCA = async (newPerPage, page) => {
        setPageNoMCA(page);
        setPageSizeMCA(newPerPage);
        GetTableDataMCA(searchValueTable, status, dateFrom, dateTo, isDownloadReport, pageNoMCA, newPerPage);

    };

    // React Data Table Custom Theme
    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    // React Data Table Custom Styles
    const customStyles = {
        rows: {
            style: {
                minHeight: '60px',  // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                // textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                // textTransform: 'capitalize',
                // textAlign: 'center',
            },
        },
    };

    return (
        <div className='cloud-invoice-table-inner table-details details-box'>
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
            <div className="my-0 table-heading align-items-center row py-0">
                <div className="mb-3 d-flex px-0">

                    <Tab.Container activeKey={tabName}>
                        <Col className={`py-0 ${styles['customers-table']}`}>
                            <div className="my-0 table-heading align-items-center row">
                                <Col lg={7} md={7} className={`${searchIconFlag ? "hide-in-mobile" : ""}`}>
                                    <Nav variant="pills">
                                        <Nav.Item className={`${styles['customers-audit-tab-pills']}`}>
                                            <Nav.Link eventKey="Customer Bulk Upload" className="innerTabs addons-core-gws-manage  pl-6 pr-6 pt-3 pb-3"
                                                onClick={() => { setTabName("Customer Bulk Upload") }}
                                            >
                                                Customer Bulk Upload ({totalRows})</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className={`${styles['customers-audit-tab-pills']}`}>
                                            <Nav.Link eventKey="MCA Bulk Upload" className="innerTabs addons-core-gws-manage  pl-6 pr-6 pt-3 pb-3"
                                                onClick={() => { setTabName("MCA Bulk Upload") }}
                                            >
                                                MCA Bulk Upload ({totalRowsMCA})</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                {tabName == "Customer Bulk Upload" &&
                                    <Col md={12} className="my-1 d-md-none d-block">
                                        <Col md={6} className="d-flex justify-content-end col-12">
                                            <span className={`mx-3 ${styles['download-report']}`} onClick={(e) => { DownloadAction(customerCode, AUDIT_REPORT_CUSTOMER_BULK_UPLOAD_DOWNLOAD, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${customerCode}_customer-bulk-upload.csv`, "csv", searchValueTable, status, dateFrom, dateTo) }}><span className="download">&nbsp;</span>Download Report</span>
                                        </Col>
                                    </Col>
                                }

                                {tabName == "MCA Bulk Upload" &&
                                    <Col md={12} className="my-1 d-md-none d-block">
                                        <Col md={6} className="d-flex justify-content-end col-12">
                                            <span className={`mx-3 ${styles['download-report']}`} onClick={(e) => { DownloadAction(customerCode, AUDIT_REPORT_MCA_BULK_UPLOAD_DOWNLOAD, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${customerCode}_mca-bulk-upload.csv`, "csv", searchValueTableMCA, statusMCA, dateFromMCA, dateToMCA) }}><span className="download">&nbsp;</span>Download Report</span>
                                        </Col>
                                    </Col>
                                }


                                {/* Customer Bulk Upload Tab */}
                                {tabName == "Customer Bulk Upload" &&
                                    <>
                                        {/* desktop and tab search and filter */}
                                        <Col className="px-1 d-none d-md-block" lg={5} md={5}>
                                            <Row className="justify-content-lg-end justify-content-md-start align-items-center mobile-padding-search-open-sub">
                                                {!serverErrorCustomer && !emptyCustomer && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                    {!searchIconFlag &&
                                                        <span className="mx-3">&nbsp;</span>
                                                    }
                                                    {!searchIconFlag && !emptyCustomerFilters &&
                                                        <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                    }
                                                    {searchIconFlag && !emptyCustomerFilters &&
                                                        <InputGroup className="search-input-box search-input-box-xl">
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                <span className="searchgrey cpointer"
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                placeholder="Search Organization / email id / abn"
                                                                aria-label="Search"
                                                                aria-describedby="basic-addon2"
                                                                className="search-input-box-input"
                                                                value={searchValueTable}
                                                                onChange={(e) => setSearchValueTable(e.target.value)}
                                                                ref={searchInputRef}
                                                            />
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                onClick={() => {
                                                                    setSearchValueTable("");
                                                                    setSearchIconFlag(!searchIconFlag);
                                                                }}>
                                                                <span className="closebtn">
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                    }

                                                    {!filterFlag && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                        setShowFilterBox(!showFilterBox);
                                                    }}></span>}

                                                    {filterFlag && !emptyCustomerSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}

                                                    {
                                                        !emptyCustomerSearch && showFilterBox &&

                                                        <BulkUploadFilterBoxCustomers
                                                            GetTableData={GetTableDataCustomer}
                                                            pageNo={pageNo}
                                                            setPageNo={setPageNo}
                                                            pageSize={pageSize}
                                                            searchValueTable={searchValueTable}
                                                            dateFrom={dateFrom}
                                                            dateTo={dateTo}
                                                            setDateFrom={setDateFrom}
                                                            setDateTo={setDateTo}
                                                            sortCol={sortCol} sortDir={sortDir}
                                                            setShowFilterBox={setShowFilterBox}
                                                            setFilterFlag={setFilterFlag}
                                                            dateLastUsedDate={dateLastUsedDate}
                                                            status={status}
                                                            setStatus={setStatus}
                                                            setDateLastUsedDate={setDateLastUsedDate}
                                                            dateCustomFromLast={dateCustomFromLast}
                                                            setDateCustomFromLast={setDateCustomFromLast}
                                                            dateCustomToLast={dateCustomToLast}
                                                            setDateCustomToLast={setDateCustomToLast}
                                                            statusLastUsed={statusLastUsed}
                                                            setStatusLastUsed={setStatusLastUsed}
                                                            statusData={statusData}
                                                            setStatusData={setStatusData}
                                                            setScrollToDivTop={setScrollToDivTop}
                                                        />
                                                    }


                                                    {/* Download csv file button */}
                                                    <span className={`mx-3 ${styles['download-report']}`} onClick={(e) => { DownloadAction(customerCode, AUDIT_REPORT_CUSTOMER_BULK_UPLOAD_DOWNLOAD, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${customerCode}_customer_bulk_upload.csv`, "csv", searchValueTable, status, dateFrom, dateTo) }}><span className="download">&nbsp;</span>Download Report</span>
                                                </Col>}
                                            </Row>
                                        </Col>

                                        {/* mobile search and filter */}
                                        <Col className="d-md-none d-block mt-2 px-3" md={12}>
                                            <Row className="justify-content-start align-items-center mobile-padding-search-open-sub">
                                                {!serverErrorCustomer && !emptyCustomer && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                    <InputGroup className="search-input-box search-input-box-xl">
                                                        <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                            <span className="searchgrey cpointer"
                                                            >
                                                                &nbsp;
                                                            </span>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            placeholder="Search Organization, Full name, Email"
                                                            aria-label="Search"
                                                            aria-describedby="basic-addon2"
                                                            className="search-input-box-input"
                                                            value={searchValueTable}
                                                            onChange={(e) => setSearchValueTable(e.target.value)}
                                                        />
                                                        <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                            onClick={() => {
                                                                setSearchValueTable("");
                                                                setSearchIconFlag(!searchIconFlag);
                                                            }}>
                                                            <span className="closebtn">
                                                                &nbsp;
                                                            </span>
                                                        </InputGroup.Text>
                                                    </InputGroup>


                                                    {!filterFlag && !emptyCustomerSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                        setShowFilterBox(!showFilterBox);
                                                    }}></span>}
                                                    {initialLoading && filterFlag && !emptyCustomerSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                </Col>}
                                            </Row>
                                            {
                                                !emptyCustomerSearch && showFilterBox &&

                                                <BulkUploadFilterBoxCustomers
                                                    GetTableData={GetTableDataCustomer}
                                                    pageNo={pageNo}
                                                    setPageNo={setPageNo}
                                                    pageSize={pageSize}
                                                    searchValueTable={searchValueTable}
                                                    dateFrom={dateFrom}
                                                    dateTo={dateTo}
                                                    setDateFrom={setDateFrom}
                                                    setDateTo={setDateTo}
                                                    sortCol={sortCol} sortDir={sortDir}
                                                    setShowFilterBox={setShowFilterBox}
                                                    setFilterFlag={setFilterFlag}
                                                    dateLastUsedDate={dateLastUsedDate}
                                                    status={status}
                                                    setStatus={setStatus}
                                                    setDateLastUsedDate={setDateLastUsedDate}
                                                    dateCustomFromLast={dateCustomFromLast}
                                                    setDateCustomFromLast={setDateCustomFromLast}
                                                    dateCustomToLast={dateCustomToLast}
                                                    setDateCustomToLast={setDateCustomToLast}
                                                    statusLastUsed={statusLastUsed}
                                                    setStatusLastUsed={setStatusLastUsed}
                                                    statusData={statusData}
                                                    setStatusData={setStatusData}
                                                    setScrollToDivTop={setScrollToDivTop}
                                                />
                                            }
                                        </Col>

                                    </>
                                }

                                {/* MCA Tab */}
                                {tabName == "MCA Bulk Upload" &&
                                    <>
                                        {/* desktop and tab search and filter */}
                                        <Col className="px-1 d-none d-md-block" lg={5} md={5}>
                                            <Row className="justify-content-lg-end justify-content-md-start align-items-center mobile-padding-search-open-sub">
                                                {!serverErrorCustomerMCA && !emptyCustomerMCA && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                    {!searchIconFlag &&
                                                        <span className="mx-3">&nbsp;</span>
                                                    }
                                                    {!searchIconFlagMCA && !emptyCustomerFiltersMCA &&
                                                        <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlagMCA(!searchIconFlagMCA); }}>&nbsp;</span>
                                                    }
                                                    {searchIconFlagMCA && !emptyCustomerFiltersMCA &&
                                                        <InputGroup className="search-input-box search-input-box-xl">
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                <span className="searchgrey cpointer"
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                placeholder="Search Company Name / Contact / MCA Domain"
                                                                aria-label="Search"
                                                                aria-describedby="basic-addon2"
                                                                className="search-input-box-input"
                                                                value={searchValueTableMCA}
                                                                onChange={(e) => setSearchValueTableMCA(e.target.value)}
                                                                ref={searchInputRefMCA}
                                                            />
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                onClick={() => {
                                                                    setSearchValueTableMCA("");
                                                                    setSearchIconFlagMCA(!searchIconFlagMCA);
                                                                }}>
                                                                <span className="closebtn">
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                    }

                                                    {!filterFlagMCA && !emptyCustomerSearchMCA && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                        setShowFilterBoxMCA(!showFilterBoxMCA);
                                                    }}></span>}
                                                    {filterFlagMCA && !emptyCustomerSearchMCA && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBoxMCA(!showFilterBoxMCA) }}></span>}

                                                    {
                                                        !emptyCustomerSearchMCA && showFilterBoxMCA &&
                                                        <BulkUploadFilterBoxMCA
                                                            GetTableData={GetTableDataMCA}
                                                            pageNo={pageNoMCA}
                                                            setPageNoMCA={setPageNoMCA}
                                                            pageSize={pageSizeMCA}
                                                            searchValueTable={searchValueTableMCA}
                                                            dateFrom={dateFromMCA}
                                                            dateTo={dateToMCA}
                                                            setDateFrom={setDateFromMCA}
                                                            setDateTo={setDateToMCA}
                                                            sortCol={sortColMCA} sortDir={sortDirMCA}
                                                            setShowFilterBox={setShowFilterBoxMCA}
                                                            setFilterFlag={setFilterFlagMCA}
                                                            dateLastUsedDate={dateLastUsedDateMCA}
                                                            status={statusMCA}
                                                            setStatus={setStatusMCA}
                                                            setDateLastUsedDate={setDateLastUsedDateMCA}
                                                            dateCustomFromLast={dateCustomFromLastMCA}
                                                            setDateCustomFromLast={setDateCustomFromLastMCA}
                                                            dateCustomToLast={dateCustomToLastMCA}
                                                            setDateCustomToLast={setDateCustomToLastMCA}
                                                            statusLastUsed={statusLastUsedMCA}
                                                            setStatusLastUsed={setStatusLastUsedMCA}
                                                            statusData={statusDataMCA}
                                                            setStatusData={setStatusDataMCA}
                                                            setScrollToDivTopMCA={setScrollToDivTopMCA}
                                                        />
                                                    }


                                                    {/* Download csv file button */}
                                                    <span className={`mx-3 ${styles['download-report']}`} onClick={(e) => { DownloadAction(customerCode, AUDIT_REPORT_MCA_BULK_UPLOAD_DOWNLOAD, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${customerCode}_mca.csv`, "csv", searchValueTableMCA, statusMCA, dateFromMCA, dateToMCA) }}><span className="download">&nbsp;</span>Download Report</span>
                                                </Col>}
                                            </Row>
                                        </Col>

                                        {/* mobile search and filter */}
                                        <Col className="d-md-none d-block mt-2 px-3" md={12}>
                                            <Row className="justify-content-start align-items-center mobile-padding-search-open-sub">
                                                {!serverErrorCustomerMCA && !emptyCustomerMCA && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                    <InputGroup className="search-input-box search-input-box-xl">
                                                        <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                            <span className="searchgrey cpointer"
                                                            >
                                                                &nbsp;
                                                            </span>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            placeholder="Search Company Name / Contact / MCA Domain"
                                                            aria-label="Search"
                                                            aria-describedby="basic-addon2"
                                                            className="search-input-box-input"
                                                            value={searchValueTableMCA}
                                                            onChange={(e) => setSearchValueTableMCA(e.target.value)}
                                                        />
                                                        <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                            onClick={() => {
                                                                setSearchValueTableMCA("");
                                                                setSearchIconFlagMCA(!searchIconFlagMCA);
                                                            }}>
                                                            <span className="closebtn">
                                                                &nbsp;
                                                            </span>
                                                        </InputGroup.Text>
                                                    </InputGroup>


                                                    {!filterFlagMCA && !emptyCustomerSearchMCA && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                        setShowFilterBox(!showFilterBox);
                                                    }}></span>}
                                                    {initialLoadingMCA && filterFlagMCA && !emptyCustomerSearchMCA && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBoxMCA(!showFilterBoxMCA) }}></span>}
                                                </Col>}
                                            </Row>
                                        </Col>

                                    </>
                                }
                            </div>

                            <Col lg={12}>
                                <Tab.Content>
                                    {/* Customer Tab */}
                                    <Tab.Pane eventKey="Customer Bulk Upload">
                                        <div className="my-0 table-heading align-items-center row py-0">
                                            <div className="mb-3 d-flex px-0">
                                                <Col className={`py-0 ${styles['customers-table']}`}>
                                                    <Container fluid className="mb-5 p-0">
                                                        <Row>
                                                            <Col>
                                                                <div className={`table-responsive bulk-upload-table ${styles['customers-history-table']}`}>
                                                                    <div className="table-details">
                                                                        {initialLoading && serverErrorCustomer &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCustomerSearch &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">No Matching Customer Found.</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCustomerFilters &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">No Data Found.</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCustomer &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={RaiseTicketIllustration} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">No Customer Found.</h2>
                                                                            </div>
                                                                        }
                                                                        {!serverErrorCustomer && !emptyCustomerFilters && !emptyCustomer && !emptyCustomerSearch &&
                                                                            <LazyTable
                                                                                d={columns}
                                                                                table={table}
                                                                                customStyles={customStyles}
                                                                                loading={infinityLoading}
                                                                                pageNo={pageNo}
                                                                                totalRows={totalRows}
                                                                                handlePerRowsChange={handlePerRowsChange}
                                                                                handlePageChange={handlePageChange}
                                                                                styles={styles}
                                                                                handleLazyCalls={handleLazyCalls}
                                                                                serverErrorUser={serverErrorCustomer}
                                                                                emptyUser={emptyCustomer}
                                                                                emptyUserFilters={emptyCustomerFilters}
                                                                                emptyUserSearch={emptyCustomerSearch}
                                                                                setScrollToDivTop={setScrollToDivTopMCA}
                                                                                scrollToDivTop={scrollToDivTopMCA}
                                                                            />}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Container>

                                                </Col>
                                            </div>
                                        </div>

                                    </Tab.Pane>

                                    {/* Audit Report Tab */}
                                    <Tab.Pane eventKey="MCA Bulk Upload">
                                        <Container fluid className="mb-5 p-0">
                                            <Row>
                                                <Col>
                                                    <div className={`table-responsive ${styles['customers-history-table']}`}>
                                                        {initialLoadingMCA && serverErrorCustomerMCA &&
                                                            <div className={styles["no-data-customers"]}>
                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                                            </div>
                                                        }
                                                        {initialLoadingMCA && emptyCustomerSearchMCA &&
                                                            <div className={styles["no-data-customers"]}>
                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                <h2 className="mt-4 mb-3">No Matching Customer Found.</h2>
                                                            </div>
                                                        }
                                                        {(initialLoadingMCA && emptyCustomerFiltersMCA) &&
                                                            <div className={styles["no-data-customers"]}>
                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                <h2 className="mt-4 mb-3">No Data Found.</h2>
                                                            </div>
                                                        }
                                                        {initialLoadingMCA && emptyCustomerMCA &&
                                                            <div className={styles["no-data-customers"]}>
                                                                <img src={RaiseTicketIllustration} className="empty-customers-table-svg" />
                                                                <h2 className="mt-4 mb-3">No Customer Found.</h2>
                                                            </div>
                                                        }
                                                        {!serverErrorCustomerMCA && !emptyCustomerFiltersMCA && !emptyCustomerMCA && !emptyCustomerSearchMCA &&
                                                            <LazyTable
                                                                d={columnsMCA}
                                                                table={tableMCA}
                                                                customStyles={customStyles}
                                                                loading={infinityLoadingMCA}
                                                                pageNo={pageSizeMCA}
                                                                totalRows={totalRowsMCA}
                                                                handlePerRowsChange={handlePerRowsChangeMCA}
                                                                handlePageChange={handlePageChange}
                                                                styles={styles}
                                                                handleLazyCalls={handleLazyCallsMCA}
                                                                serverErrorUser={serverErrorCustomerMCA}
                                                                emptyUser={emptyCustomerMCA}
                                                                emptyUserFilters={emptyCustomerFiltersMCA}
                                                                emptyUserSearch={emptyCustomerSearchMCA}
                                                                setScrollToDivTop={setScrollToDivTop}
                                                                scrollToDivTop={scrollToDivTop}
                                                            />
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>


                        </Col>
                    </Tab.Container>
                </div>
            </div>
        </div>

    );
}

export default AuditReport;