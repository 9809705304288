import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import './styles.scss';
// import CmsDetailsContext from '../../../Context/CmsDetails/CmsDetailsContext';
import CmsDetailsContext from '../../Context/CmsDetails/CmsDetailsContext';

const BrandDetails = (props) => {
    // console.log(props.bannerData.bannerImage.urls[0]);
    const globalData = useContext(CmsDetailsContext);                // Defining Global Data from CmsDetailsSate.js
    // console.log(globalData.headerLogo.urls[0]);
    return (
        <>
            {
                props.bannerData && globalData && 
                <div className={`logo mt-4 ${props.customClass}`}>
                    <img src={globalData.baseUrl+globalData.headerLogo.urls[0]} alt="Synnex" />
                    <p>{props.bannerData.bannerText}</p>

                    <Button onClick={props.handleClick} className="col-12 d-md-none">Proceed</Button>
                </div>
            }
        </>
    );
}

export default BrandDetails;