import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Form } from "react-bootstrap";
import Toast from '../../../../../../../GlobalComponents/Toast';
import styles from './ProfileMicrosoftResellerPrerequisite.module.scss';
import { CSP_VERIFY_MPNID, CSP_SAVE_MPNID, GET_CSP_MPNID_DATA, UPDATE_CSP_MPNID } from '../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../Utils/API';
import loadingGif from "../../../../../../../Assets/Images/Animations/loading-management-console.gif"
import EmptyDataTableFilterSearch from '../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'

const ProfileMicrosoftResellerPrerequisite = ({ Logout }) => {
  const [mpnId, setMpnId] = useState("");
  const [verifyMessage, setVerifyMessage] = useState("");                       // verified success or error message after verification of MPN ID
  const [isMpnEntered, setIsMpnEntered] = useState(null);                       // To check mpn id is verified or not
  const [isMpnVerified, setIsMpnVerified] = useState(null);                     // To check mpn id is verified or not
  const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);          // Processing after calling api
  const [isAlreadyConfigured, setIsAlreadyConfigured] = useState(false);        // To check whether mpn id is already configured or not
  const [alreadyConfiguredMpnId, setAlreadyConfiguredMpnId] = useState("");     // if mpn is already there
  const [isEditAllowed, setIsEditAllowed] = useState(false);                    // edit mpn id when clicked
  const [isMPNIdExpired, setIsMPNIdExpired] = useState(false);                  // To check whether mpn id is expired or not 
  const [previousMPNid, setPreviousMPNid] = useState("");                       // for saving mpn id which is already configured
  const [mpnData, setMpnData] = useState("");                                   // data storing from mpn id get api
  const [isVerificationHold, setIsVerificationHold] = useState(false);          // intermediate state for buttons control and check
  const [serverError, setServerError] = useState(false);                        // server error check state in table during api call to load data

  // Use State for Toasts
  const [toastSuccess, setToastSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastError, setToastError] = useState(false);                                // toast message displaying error message 

  // Header Authorization for API
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Function to get details whether mpn id is already configured or not
  async function GetMPNData() {
    await api.get(GET_CSP_MPNID_DATA, config)
      .then(resp => {
        setMpnData(resp.data);
        setIsAlreadyConfigured(resp.data.isAlreadyConfigured);
        setPreviousMPNid(resp.data.mpnId);
        setAlreadyConfiguredMpnId(resp.data.mpnId);
        setIsMPNIdExpired(resp.data.isMPNIdExpired);
        setServerError(false);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        else {
          setServerError(true);
        }
      });
  };

  //post api for mpn id verification
  async function MPNidVerification(mpnId) {
    setConfirmLoadingFlag(true);
    const configFile = {
      headers: {
        "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    await api.post(`${CSP_VERIFY_MPNID}?mpnId=${mpnId}`, null, configFile)
      .then(resp => {
        if (resp.status = 200) {
          { resp.data.isVerified ? setIsMpnVerified(true) : setIsMpnVerified(false) }
          setVerifyMessage(resp.data.message);
          setConfirmLoadingFlag(false);
          setIsVerificationHold(false);
        }
      })
      .catch(error => {
        setConfirmLoadingFlag(false);
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
      })
  };

  //post api for saving mpn id
  async function MPNidSave(mpnId) {
    setConfirmLoadingFlag(true);
    const configFile = {
      headers: {
        "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    await api.post(`${CSP_SAVE_MPNID}?mpnId=${mpnId}`, null, configFile)
      .then(resp => {
        if (resp.status = 200) {
          setConfirmLoadingFlag(false);
          setIsEditAllowed(false);
          setMpnData(null);
          setIsMpnEntered(null); setVerifyMessage(""); setIsMpnVerified(null);
          setPreviousMPNid("");
          GetMPNData();
          setToastMessage("MPN ID Saved Successfully.");
          setToastSuccess(true);
          setTimeout(() => {
            setToastSuccess(false);
          }, 5000);
        }
      })
      .catch(error => {
        setConfirmLoadingFlag(false);
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        else {
          setToastMessage("Error while saving MPN ID!");
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 5000);
        }
      })
  };

  //put api for Updating mpn id
  async function MPNidUpdate(mpnId) {
    setConfirmLoadingFlag(true);
    const configFile = {
      headers: {
        "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    await api.put(`${UPDATE_CSP_MPNID}?mpnId=${mpnId}`, null, configFile)
      .then(resp => {
        if (resp.status = 200) {
          GetMPNData();
          setConfirmLoadingFlag(false);
          setPreviousMPNid("");
          setIsEditAllowed(false);
          setIsMpnEntered(null); setVerifyMessage(""); setIsMpnVerified(null);
          setToastMessage("MPN ID Updated Successfully");
          setToastSuccess(true);
          setTimeout(() => {
            setToastSuccess(false);
          }, 5000);
        }
      })
      .catch(error => {
        setConfirmLoadingFlag(false);
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
      })
  };

  //Scroll to top on opening of page
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Defining UseEffect to call get mpn id 
  useEffect(() => {
    GetMPNData();
  }, []);

  return (
    <>
      <div className="main-content">
        <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
        <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />


        {serverError &&
          <div className={styles["no-data-cloud-summary"]}>
            <img src={EmptyDataTableFilterSearch} className="empty-cloud-creditNote-table-svg" />
            <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
          </div>
        }

        {/* if mpn id is not there */}
        {!isAlreadyConfigured && !serverError &&
          <>
            <div className={`details-box mt-5 card-remove-border-prerequisite`} eventKey="0">
              {!mpnData &&
                <div className={styles["no-data-cloud-summary"]}>
                  <div className="text-center"><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading...</p></div>
                </div>
              }
              {mpnData && <>
                <label className={`pt-5 pb-4 ${styles['accordion-content']}`}>
                  <span className={`text-large text-strong`}>MPN ID</span>
                </label>
                <div className='pt-0'>
                  <div className={`${styles['accordion-content']}`}>

                    <>
                      <Row>
                        <Col lg={8} md={12}>
                          <Form.Group className="mb-3" >
                            <Form.Label>Enter MPN ID</Form.Label>
                            <Row>
                              <Col md={6} >
                                <Form.Control
                                  type="text"
                                  value={mpnId}
                                  placeholder="Enter MPN ID"
                                  onChange={(e) => {
                                    setIsMpnVerified(null);
                                    if (e.target.value === '' || /^[0-9]+$/.test(e.target.value) && e.target.value.length <= 7) {
                                      setMpnId(e.target.value);
                                      setIsMpnEntered(e.target.value.length === 7);
                                    }
                                  }}
                                />
                                {isMpnEntered &&
                                  <>
                                    {/* if MPN ID verification is valid */}
                                    {isMpnVerified === true && <span className='d-flex items-align-center mt-2'>
                                      <span className="status-success mr-1">&nbsp;</span>
                                      <span className="green text-small">
                                        {verifyMessage}
                                      </span> </span>}

                                    {/* if MPN ID verification is invalid  */}
                                    {isMpnVerified === false &&
                                      <span className='d-flex items-align-center mt-2'>
                                        <span className="status-error mr-1">&nbsp;</span>
                                        <span className="red text-small">
                                          {verifyMessage}
                                        </span>
                                      </span>}
                                  </>
                                }
                                {isMpnVerified === null &&
                                  <p className='empty-paragraph'></p>
                                }
                              </Col>
                              <Col md={6}>
                                {isMpnEntered ?
                                  (!confirmLoadingFlag && <Button
                                    className="btn btn-lg btn-border-light px-4 pl-11 pr-11"
                                    variant="light"
                                    onClick={() => { MPNidVerification(mpnId); setIsVerificationHold(true); }}
                                  >
                                    Verify MPN ID
                                  </Button>) : (!confirmLoadingFlag && <Button
                                    className="btn btn-lg btn-border-light px-4 pl-11 pr-11"
                                    variant='light'
                                    disabled
                                  >
                                    Verify MPN ID
                                  </Button>)}
                                {confirmLoadingFlag && isMpnEntered &&
                                  <Button
                                    disabled
                                    className="btn btn-lg btn-border-light px-4 pl-11 pr-11"
                                    variant="light"
                                  >
                                    Processing . . .
                                  </Button>
                                }

                              </Col>
                            </Row>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-right">
                          <div className="button-wrapper mb-5 mr-2">
                            {!confirmLoadingFlag && <>
                              {mpnId.length > 0 &&
                                <Button
                                  className="btn btn-lg btn-border-light mx-2"
                                  variant="light"
                                  onClick={() => { setMpnId(""); setIsMpnEntered(null); setVerifyMessage(""); setIsMpnVerified(null); }}
                                >
                                  Clear
                                </Button>}
                              {mpnId.length === 0 && <Button
                                className="btn btn-lg mx-2"
                                variant="light"
                                disabled
                              >
                                Clear
                              </Button>}
                              {
                                !isMpnVerified &&
                                <>
                                  <Button
                                    className="btn btn-lg ml-2 px-4"
                                    variant="light"
                                    disabled
                                  >
                                    Save
                                  </Button>
                                </>
                              }

                              {
                                isMpnVerified &&
                                <Button
                                  className="btn btn-lg ml-2 px-4"
                                  onClick={() => {
                                    MPNidSave(mpnId);
                                  }}
                                >
                                  Save
                                </Button>
                              }
                            </>}

                            {confirmLoadingFlag && !isVerificationHold &&
                              <><Button
                                className="btn btn-lg mx-2"
                                variant="light"
                                disabled
                              >
                                Clear
                              </Button>

                                <Button
                                  disabled
                                  className="ml-2 px-4 btn-border-light w-md-auto col-xs-12"
                                  variant="light"
                                >
                                  Processing . . .
                                </Button>
                              </>
                            }
                            {confirmLoadingFlag && isVerificationHold &&
                              <><Button
                                className="btn btn-lg mx-2"
                                variant="light"
                                disabled
                              >
                                Clear
                              </Button>
                                <Button
                                  disabled
                                  className="ml-2 px-4 btn-border-light w-md-auto col-xs-12"
                                  variant="light"
                                >
                                  Save
                                </Button></>
                            }
                          </div>
                        </Col>
                      </Row>
                    </>
                  </div>
                </div>
              </>}
            </div>
          </>
        }
        {/* if mpn id is already there */}
        {isAlreadyConfigured && mpnData && !serverError &&
          <>
            <div className={`pb-6 details-box mt-5 card-remove-border-prerequisite`} eventKey="0">
              <label className={`pt-5 pb-4 ${styles['accordion-content']}`}>
                <span className={`text-large text-strong`}>MPN ID</span>
              </label>
              <div className='pt-0'>
                <div className={`${styles['accordion-content']}`}>
                  <Row>
                    <Col lg={8} md={12}>
                      <Form.Group className="mb-3" >
                        <Form.Label className={`${isMPNIdExpired ? "red pb-2" : "pb-1"}`}>MPN ID</Form.Label>
                        <Row>
                          <Col md={6}>
                            {!isEditAllowed &&
                              <div className={`${isMPNIdExpired ? "red" : ""}`}>
                                {previousMPNid === "" ? "Loading ..." : previousMPNid}
                              </div>}
                            {isEditAllowed &&
                              <Form.Control
                                type="number"
                                value={alreadyConfiguredMpnId}
                                placeholder="Enter MPN ID"
                                onChange={(e) => {
                                  setIsMpnVerified(null);
                                  if (e.target.value === '' || /^[0-9]+$/.test(e.target.value) && e.target.value.length <= 7) {
                                    setAlreadyConfiguredMpnId(e.target.value);
                                    setIsMpnEntered(e.target.value.length === 7);
                                  }
                                }}
                              />
                            }

                            {
                              isMPNIdExpired && !isEditAllowed &&
                              <span className='d-flex items-align-center mt-2'>
                                <span className="status-error mr-1">&nbsp;</span>
                                <span className="red text-small">
                                  MPN ID is expired , please update the MPN ID
                                </span>
                              </span>}

                            {isMpnEntered && isEditAllowed && !isMPNIdExpired && <>
                              {/* if MPN ID verification is valid */}
                              {isMpnVerified === true && <span className='d-flex items-align-center mt-2'>
                                <span className="status-success mr-1">&nbsp;</span>
                                <span className="green text-small">
                                  {verifyMessage}
                                </span> </span>}

                              {/* if MPN ID verification is invalid  */}
                              {isMpnVerified === false &&
                                <span className='d-flex items-align-center mt-2'>
                                  <span className="status-error mr-1">&nbsp;</span>
                                  <span className="red text-small">
                                    {verifyMessage}
                                  </span>
                                </span>}
                            </>
                            }
                            {isMpnVerified === null &&
                              <p className='empty-paragraph'></p>
                            }
                          </Col>
                          <Col md={6}>
                            {!isEditAllowed &&
                              <Button
                                className="btn btn-lg btn-border-light px-4 pl-11 pr-11"
                                variant="light"
                                onClick={() => {
                                  setIsEditAllowed(true);
                                }}
                              >
                                Edit MPN ID  </Button>}
                            {!confirmLoadingFlag && isEditAllowed && alreadyConfiguredMpnId != previousMPNid && alreadyConfiguredMpnId.length != 0 && alreadyConfiguredMpnId.length === 7 &&
                              <Button
                                className="btn btn-lg btn-border-light px-4 pl-11 pr-11"
                                variant="light"
                                onClick={() => { MPNidVerification(alreadyConfiguredMpnId); setIsVerificationHold(true) }}
                              >
                                Verify MPN ID  </Button>}
                            {confirmLoadingFlag && isEditAllowed &&
                              <Button
                                disabled
                                className="btn btn-lg btn-border-light px-4 pl-11 pr-11"
                                variant="light"
                              >
                                Processing . . .
                              </Button>
                            }
                            {isEditAllowed && !(alreadyConfiguredMpnId != previousMPNid && alreadyConfiguredMpnId.length != 0) &&
                              <Button
                                className="btn btn-lg btn-border-light px-4 pl-11 pr-11"
                                variant='light'
                                disabled
                              >
                                Verify MPN ID
                              </Button>}
                            {isEditAllowed && !(alreadyConfiguredMpnId.length === 7) && !(alreadyConfiguredMpnId.length === 0) && <Button
                              className="btn btn-lg btn-border-light px-4 pl-11 pr-11"
                              variant='light'
                              disabled
                            >
                              Verify MPN ID
                            </Button>}

                          </Col>
                        </Row>

                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      {isEditAllowed && !confirmLoadingFlag &&
                        <label className="cpointer" onClick={() => {
                          setIsEditAllowed(false); setAlreadyConfiguredMpnId(previousMPNid);
                          setIsMpnEntered(null); setVerifyMessage(""); setIsMpnVerified(null);
                        }}>
                          <span className="back-blue">&nbsp;</span>&nbsp;<strong className='back-blue-color'>Back</strong>
                        </label>
                      }
                      {!isEditAllowed && !confirmLoadingFlag &&
                        <p className='empty-paragraph pb-3 mb-8'></p>
                      }
                    </Col>
                    <Col md={9} className="text-right">
                      <div className="button-wrapper mr-2">
                        {isEditAllowed && (alreadyConfiguredMpnId.length === 0) && !isVerificationHold && <Button
                          className="btn btn-lg mx-2"
                          variant="light"
                          disabled
                        >
                          Clear
                        </Button>}
                        {isEditAllowed && isVerificationHold && <Button
                          className="btn btn-lg mx-2"
                          variant="light"
                          disabled
                        >
                          Clear
                        </Button>}

                        {isEditAllowed && alreadyConfiguredMpnId && alreadyConfiguredMpnId.length > 0 && !confirmLoadingFlag &&
                          <Button
                            className="btn btn-lg btn-border-light mx-2"
                            variant="light"
                            onClick={() => { setAlreadyConfiguredMpnId(""); setIsMpnEntered(null); setVerifyMessage(""); setIsMpnVerified(null); }}
                          >
                            Clear
                          </Button>}
                        {
                          isEditAllowed && !isMpnVerified &&
                          <>
                            <Button
                              className="btn btn-lg ml-2 px-4"
                              variant="light"
                              disabled
                            >
                              Save
                            </Button>
                          </>
                        }
                        {
                          isEditAllowed && isMpnVerified && !confirmLoadingFlag &&
                          <Button
                            className="btn btn-lg ml-2 px-4"
                            onClick={() => {
                              MPNidUpdate(alreadyConfiguredMpnId);
                            }}
                          >
                            Save
                          </Button>
                        }

                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </>}
      </div >
    </>
  )
}

export default ProfileMicrosoftResellerPrerequisite
