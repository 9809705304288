import React from 'react'
import styles from './info.module.scss'
import { Col, Container, Row } from 'react-bootstrap'
function Info({landingPageData,globalData}) {
  return (
    <Container fluid className='' style={{height:"60%"}}>
      {landingPageData && globalData && <Row className='px-2 h-100'>
        <Container fluid className={`${styles['info-main-div']} h-100 d-flex justify-content-center align-items-center`}>
          <Col md={6} sm={12} className='pl-0'>
              <div className={`${styles['content']}`}>
                <h1 className={`${styles['welcome-h1']} py-1`}>{landingPageData.heroContent.contentItems[0].title} <span className={`${styles['cloud-h1']} py-3`}>{landingPageData.heroContent.contentItems[0].boldTitle} </span></h1>

                <p className={styles.pContainer} >
                  {landingPageData.heroContent.contentItems[0].description}
                </p>
            </div>
          </Col>
          <Col md={6} sm={12}></Col>
        </Container>
      </Row>}
    </Container>
  )
}

export default Info