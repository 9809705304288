import { Form, Row, Col } from 'react-bootstrap';
import './styles.scss';
import { forwardRef } from 'react';

const PhoneNumber = forwardRef((props, phoneRef) => {

    return (
        <Form.Group className="mb-3" controlId="phoneNumber">
            <Form.Label className={props.isBold ? 'text-strong' : ''}>
                Phone Number <span className="red">*</span>
            </Form.Label>
            <Row>
                <Col>
                    <div className="phone-number-wrapper">
                        <label>+61</label>
                        <input type="text"
                            className="phone-number form-control col"
                            placeholder={props.placeholder}
                            value={props.phoneNumber}
                            disabled={props.disabled}
                            ref={phoneRef}
                            maxLength="15"
                            onChange={props.updatePhoneNumber} />
                        {props.errorMessage && <span className="red text-small">{props.errorMessage}</span>}
                        <span className="red text-small">{props.errorMessageFE}</span>
                    </div>
                </Col>
            </Row>
        </Form.Group>
    );
});

export default PhoneNumber;