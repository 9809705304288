import React , {useState,useEffect} from 'react'
import { LANDING_PAGE_CMS_DATA } from '../../../Utils/GlobalConstants';
import { cmsApi } from '../../../Utils/API';

const PrivacyPolicy = () => {
    const [privacyPolicyData, setPrivacyPolicyData] = useState([]);           // privacy policy data storage

    // Function for calling Privacy Policy item data from Orchid Core CMS
    async function GetPrivacyPolicyData() {
        const data = `{
            privacyPolicyPage  {
                content {
                html
                }
            }
            }`;
    
        // Defining Header Configuration
        const config = {
          headers: {
            "Content-Type": "application/graphql",
            Accept: "application/graphql",
          },
        };
    
        // Axios Post Request
        await cmsApi
          .post(LANDING_PAGE_CMS_DATA, data, config)
          .then((resp) => {
            // handle success
            // console.log(resp.data.data.privacyPolicyPage[0].content.html);
            setPrivacyPolicyData(resp.data.data.privacyPolicyPage[0].content.html);
          })
          .catch((error) => {
            // handle error
            // console.log(error);
          });
      }

    // UseEffect Defination to call the get Privacy Policy items function from CMS
    useEffect(() => {
        GetPrivacyPolicyData();
      }, []);
  return (
    <div className='px-4 mb-6' dangerouslySetInnerHTML={{__html:privacyPolicyData}}>
    </div>
  )
}

export default PrivacyPolicy
