import { Container, Row, Col, Button } from "react-bootstrap";
import styles from './styles.scss';
import { useEffect, useState } from "react";
import TableBlock from "../../../../../GlobalComponents/TableBlock";
import { api } from "../../../../../Utils/API";
import { CLOUD_CREDIT_NOTE_LISTING_TABLE, CLOUD_CREDIT_NOTE_DOWNLOAD_PDF_SUMMARY } from "../../../../../Utils/GlobalConstants";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from "../../../../../GlobalComponents/Toast";
import loadingGif from "../../../../../Assets/Images/Animations/loading-management-console.gif"

const CreditNoteDetails = (props) => {
    const location = useLocation();                                                                                             // get Location
    const date = new Date();                                                                                                    // for js date time function
    const navigate = useNavigate();                                                                                             // to use navigate function from react router dom
   const [loading, setLoading] = useState(false);                                                                              // loading state when api is called
    const [creditNoteId, setCreditNoteId] = useState(location.state !== null ? location.state.Id : "");                         // Get Credit Note ID From Table
    const [creditNoteNumber, setCreditNoteNumber] = useState(location.state !== null ? location.state.creditNoteNumber : "");   // Get Credit Note Number From Table

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                                                                    // toast message displaying success message
    const [toastError, setToastError] = useState(false);                                                                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                                                                       // toast message usestate

    // Scroll to Top on Initial loading
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [creditNoteDetailsDummy, setCreditNoteDetailsDummy] = useState(null);
    // Credit Note Details
    const [creditNoteDetails, setCreditNoteDetails] = useState(
        {

            "grossAmount": 0.00,
            "creditNoteNumber": "",
            "summaryNumber": "",
            "discountAmount": 0.00,
            "netAmount": 0.00,
            "taxAmount": 0.00,
            "creditTotalAmount": 0.00,
            "currency": "",
            "integrationCode": "",
            "paymentTermDescription": "",
            "resellerCode": "",
            "creditNoteDate": "",
            "invoiceDate": "",
            "invoiceNumber": "",
            "distributor": {
                "companyName": "",
                "addressLine1": "",
                "addressLine2": "",
                "city": "",
                "state": "",
                "country": "",
                "zipCode": "",
                "phone": "",
                "fax": "",
                "abn": "",
                "acn": ""
            },
            "soldTo": {
                "companyName": "",
                "firstName": "",
                "lastName": "",
                "email": "",
                "addressLine1": "",
                "addressLine2": "",
                "city": "",
                "state": "",
                "country": "",
                "zipCode": "",
                "terriCode": "",
                "abnNumber": "",
                "salesPerson": "",
                "externalCompanyCode": ""
            },
            "shipTo": {
                "customerCompanyName": "",
                "firstName": "",
                "lastName": "",
                "email": "",
                "addressLine1": "",
                "addressLine2": "",
                "city": "",
                "state": "",
                "country": "",
                "zipcode": ""
            },
            "creditNoteLineItems": [{
                "description": "",
                "productPlanName": "",
                "quantity": "",
                "serviceName": "",
                "total": "",
                "unitPrice": "",
                "planCode": ""
            }],
            "creditNoteDiscountLineItems": [{
                "brand": "",
                "description": "",
                "total": ""
            }],
            "annualRefunds": {
                "description": "",
                "amount": "",
                "lineItems": [{
                    "productPlanName": "",
                    "quantity": "",
                    "total": "",
                    "unitPrice": "",
                    "proratedUnitPrice": "",
                    "refundDays": "",
                    "startDate": "",
                    "endDate": ""
                }]
            },
            "monthlyRefunds": {
                "description": "",
                "amount": "",
                "lineItems": [{
                    "productPlanName": "",
                    "quantity": "",
                    "total": "",
                    "unitPrice": "",
                    "proratedUnitPrice": "",
                    "refundDays": "",
                    "startDate": "",
                    "endDate": ""
                }]
            }
        }
    );

    // Annual Refunds
    let refundsColumnName = ["No", "Plan Name", "Start Date", "End Date", "Active Qty", "Refund Days", "Unit Price (Ex GST)", "Prorate Price (Ex GST)", "Sub Total (Ex GST)"];          // Annual Refunds Table Columns
    const [anualRefunds, setAnualRefunds] = useState([]);                                                                                                                               // Annual Refunds Table
    const [anualRefundsData, setAnualRefundsData] = useState([]);                                                                                                                       // Annual Refunds Table Data

    // Monthly Refunds
    const [monthlyRefunds, setMonthlyRefunds] = useState([]);                                                                                                                           // Monthly Refunds Table
    const [monthlyRefundsData, setMonthlyRefundsData] = useState([]);                                                                                                                   // Monthly Refunds Table Data

    // Credit Note Discount Line Items
    let creditNoteDiscountLineItemsColumns = ["No", "Brand", "Description", "Amount"];                                                                                                  // Credit Note Discount Line Items Columns
    const [creditNoteDiscountLineItems, setCreditNoteDiscountLineItems] = useState([]);                                                                                                 // Credit Note Discount Line Items Table
    const [creditNoteDiscountLineItemsData, setCreditNoteDiscountLineItemsData] = useState([]);                                                                                         // Credit Note Discount Line Items Table Data

    // Credit Note Line Items
    let creditNoteLineItemsColumns = ["No", "Brand", "Description", "SKU", "QTY", "Price", "Amount"];                                                                                   // Credit Note Line Items Columns
    const [creditNoteLineItems, setCreditNoteLineItems] = useState([]);                                                                                                                 // Credit Note Line Items Table
    const [creditNoteLineItemsData, setCreditNoteLineItemsData] = useState([]);                                                                                                         // Credit Note Line Items Table Data


    // Header Authorization for API
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download PDF API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/pdf") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download PDF and CSV File from actions Dropdown
    async function DownloadAction(id, url, name, fileType) {
        let configuration;
        if (fileType === "pdf") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(url + id, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                } 
            });
    };

    // To Get Credit Notes Listing Table
    async function CreditNoteDetails() {
        setLoading(true);

        await api.get(CLOUD_CREDIT_NOTE_LISTING_TABLE + creditNoteId, config)
            .then(resp => {
                if (resp.status == 200) {
                    setCreditNoteDetails(resp.data);
                    setCreditNoteDetailsDummy(resp.data);

                    let annualRefundsTable = [];
                    let annualRefundsTableData = [];

                    if (resp.data.annualRefunds.lineItems.length > 0) {
                        let i = 0;
                        (resp.data.annualRefunds.lineItems).map((td) => {
                            i++
                            annualRefundsTableData.push
                                (
                                    {
                                        "No": i,
                                        "Plan Name": td.productPlanName,
                                        "Start Date": td.startDate,
                                        "End Date": td.endDate,
                                        "Active Qty": td.quantity,
                                        "Refund Days": td.refundDays,
                                        "Unit Price (Ex GST)": td.unitPrice >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.unitPrice).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.unitPrice)).toFixed(2))}`,
                                        "Prorate Price (Ex GST)": td.proratedUnitPrice >= 0 ? `$${td.proratedUnitPrice}` : `-$${td.proratedUnitPrice}`,
                                        "Sub Total (Ex GST)": td.total >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.total).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.total)).toFixed(2))}`,
                                    }
                                )
                        });

                        annualRefundsTableData.push
                            (
                                {
                                    "No": "",
                                    "Plan Name": "",
                                    "Start Date": "",
                                    "End Date": "",
                                    "Active Qty": "",
                                    "Refund Days": "",
                                    "Unit Price (Ex GST)": "",
                                    "Prorate Price (Ex GST)": "Total",
                                    "Sub Total (Ex GST)": resp.data.annualRefunds.amount,
                                }
                            )

                        setAnualRefundsData(annualRefundsTableData);
                       

                        refundsColumnName.map((td) => {
                            if (td == "Unit Price (Ex GST)" || td == "Prorate Price (Ex GST)" || td == "Sub Total (Ex GST)") {
                                annualRefundsTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false,
                                    right: true
                                })
                            } else {
                                annualRefundsTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false
                                })
                            }
                        });
                        setAnualRefunds(annualRefundsTable);
                       
                    }


                    let monthlyRefundsTable = [];
                    let monthlyRefundsTableData = [];

                    if (resp.data.monthlyRefunds.lineItems.length > 0) {
                        let i = 0;
                        (resp.data.monthlyRefunds.lineItems).map((td) => {
                            i++
                            monthlyRefundsTableData.push
                                (
                                    {
                                        "No": i,
                                        "Plan Name": td.productPlanName,
                                        "Start Date": td.startDate,
                                        "End Date": td.endDate,
                                        "Active Qty": td.quantity,
                                        "Refund Days": td.refundDays,
                                        "Unit Price (Ex GST)": td.unitPrice >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.unitPrice).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.unitPrice)).toFixed(2))}`,
                                        "Prorate Price (Ex GST)": td.proratedUnitPrice >= 0 ? `$${td.proratedUnitPrice}` : `-$${td.proratedUnitPrice}`,
                                        "Sub Total (Ex GST)": td.total >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.total).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.total)).toFixed(2))}`,
                                    }
                                )
                        });

                        monthlyRefundsTableData.push
                            (
                                {
                                    "No": "",
                                    "Plan Name": "",
                                    "Start Date": "",
                                    "End Date": "",
                                    "Active Qty": "",
                                    "Refund Days": "",
                                    "Unit Price (Ex GST)": "",
                                    "Prorate Price (Ex GST)": "Total",
                                    "Sub Total (Ex GST)": resp.data.monthlyRefunds.amount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((resp.data.monthlyRefunds.amount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(resp.data.monthlyRefunds.amount)).toFixed(2))}`
                                }
                            )

                        setMonthlyRefundsData(monthlyRefundsTableData);
                   

                        refundsColumnName.map((td) => {
                            if (td == "Unit Price (Ex GST)" || td == "Prorate Price (Ex GST)" || td == "Sub Total (Ex GST)") {
                                monthlyRefundsTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false,
                                    right: true
                                })
                            } else {
                                monthlyRefundsTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false
                                })
                            }
                        });
                        setMonthlyRefunds(monthlyRefundsTable);
                      

                    }

                    //Credit Note Discount Line Items
                    let creditNoteDiscountLineItemsTable = [];
                    let creditNoteDiscountLineItemsTableData = [];

                    if (resp.data.creditNoteDiscountLineItems.length > 0) {
                        let i = 0;
                        (resp.data.creditNoteDiscountLineItems.lineItems).map((td) => {
                            i++
                            creditNoteDiscountLineItemsTableData.push
                                (
                                    {
                                        "No": i,
                                        "Brand": td.productPlanName,
                                        "Description": td.startDate,
                                        "Amount": td.endDate >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.endDate).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.endDate)).toFixed(2))}`,
                                    }
                                )
                        });



                        setCreditNoteDiscountLineItemsData(creditNoteDiscountLineItemsTableData);
                       

                        creditNoteDiscountLineItemsColumns.map((td) => {
                            if (td == "Amount") {
                                creditNoteDiscountLineItemsTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false,
                                    right: true
                                })
                            } else {
                                creditNoteDiscountLineItemsTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false
                                })
                            }
                        });
                        setCreditNoteDiscountLineItems(creditNoteDiscountLineItemsTable);
                      
                    }

                    //Credit Note Line Items
                    let creditNoteLineItemsTable = [];
                    let creditNoteLineItemsTableData = [];

                    if (resp.data.creditNoteLineItems.length > 0) {
                        let i = 0;

                        (resp.data.creditNoteLineItems).map((td) => {
                            i++

                            creditNoteLineItemsTableData.push
                                (
                                    {
                                        "No": i,
                                        "Brand": td.serviceName,
                                        "Description": <>{td.productPlanName} <br /> {td.description}</>,
                                        "SKU": td.planCode,
                                        "QTY": td.quantity,
                                        "Price": td.unitPrice >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 6 }).format((td.unitPrice).toFixed(6))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 6 }).format((Math.abs(td.unitPrice)).toFixed(6))}`,
                                        "Amount": td.total >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.total).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.total)).toFixed(2))}`,
                                    }
                                )
                        });

                        creditNoteLineItemsTableData.push
                            (
                                {
                                    "No": "",
                                    "Brand": "",
                                    "Description": "",
                                    "SKU": "",
                                    "QTY": "",
                                    "Price": "Total",
                                    "Amount": resp.data.grossAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((resp.data.grossAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(resp.data.grossAmount)).toFixed(2))}`,
                                }
                            )

                        setCreditNoteLineItemsData(creditNoteLineItemsTableData);

                        creditNoteLineItemsColumns.map((td) => {
                            if (td == "Amount" || td == "Price") {
                                creditNoteLineItemsTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false,
                                    right: true
                                })
                            } else if (td == "Description") {
                                creditNoteLineItemsTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap py-1">{row[`${td}`]}</div>,
                                    center: false,
                                    left: false
                                })
                            } else {
                                creditNoteLineItemsTable.push({
                                    id: `${td}`,
                                    name: <div>{td}</div>,
                                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                    center: false
                                })
                            }
                        });
                        setCreditNoteLineItems(creditNoteLineItemsTable);
                    }



                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                }
                
            });
    }


    // Get Credit Note Details On Initial Loading
    useEffect(() => {
        CreditNoteDetails();
    }, [])

    // Credit Note Details Table
    const ColumnName = [
        {
            name: 'Credit Note Date',
            selector: row => row.creditNoteDate,
        },
        {
            name: 'Invoice Date',
            selector: row => row.invoiceDate,
        },
        {
            name: 'Invoice No',
            selector: row => row.invoiceNo,
        },
        {
            name: 'Customer Code',
            selector: row => row.customerCode,
        },
        {
            name: 'Terr. Code',
            selector: row => row.terrCode,
        },
        {
            name: 'ABN Number',
            selector: row => row.abnNumber,
        },
        {
            name: 'Sales Person',
            selector: row => row.salesPerson,
        },
        {
            name: 'Currency',
            selector: row => row.currency,
        },
    ];

    // Sample Data till API Ready
    const creditNoteDetilsData = [
        {
            creditNoteDate: creditNoteDetails.creditNoteDate,
            invoiceDate: creditNoteDetails.invoiceDate,
            invoiceNo: creditNoteDetails.invoiceNumber,
            customerCode: creditNoteDetails.soldTo.externalCompanyCode,
            terrCode: creditNoteDetails.soldTo.terriCode,
            abnNumber: creditNoteDetails.soldTo.abnNumber,
            salesPerson: creditNoteDetails.soldTo.salesPerson,
            currency: creditNoteDetails.currency
        }
    ]

    return (
        <div className="main-content credit-notes-details-table">
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
            {
                !creditNoteDetailsDummy &&
                <div className="no-data-cloud-credit-notes-details">
                    <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading credit notes details...</p>
                </div>
            }
            {creditNoteDetailsDummy && <Container fluid className="main-content px-0">
                <Container fluid className="my-3">
                    <Row className="align-items-center">
                        <Col className="gotoback col-md-6" onClick={() => navigate(-1)}>
                            <span className="back">&nbsp;</span>
                            <span>Credit Adjustment Note No. : </span>
                            <span className="text-normal">{creditNoteDetails.creditNoteNumber}</span>
                        </Col>

                        <div className="col-md-6 pt-6">
                            <div className="btn-wrapper">
                                <Button className="px-4 mr-3" onClick={(e) => { DownloadAction(creditNoteNumber, CLOUD_CREDIT_NOTE_DOWNLOAD_PDF_SUMMARY, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${creditNoteNumber}_creditNote.pdf`, "pdf") }}>Download Credit Note</Button>
                            </div>
                        </div>
                    </Row>

                    <Row className="details-box px-1 justify-content-between">

                        <Col lg={12} className="mt-3 p-3">
                            <h2 className="text-strong mb-0">Cloud Bill Summary No. :
                                <label><span className="text-normal subscription-heading-table-header">{creditNoteDetails.summaryNumber}</span></label></h2>
                        </Col>

                        <Col md={4} className="mb-3 p-3">
                            <div>
                                <h3 className="text-primary mb-5">DETAILS</h3>

                                <p>{creditNoteDetails.distributor.companyName},<br />
                                    {creditNoteDetails.distributor.addressLine1},<br />
                                    {creditNoteDetails.distributor.addressLine2 && <>{creditNoteDetails.distributor.addressLine2},<br /> </>}
                                    {creditNoteDetails.distributor.city},<br />
                                    {creditNoteDetails.distributor.state}, {creditNoteDetails.distributor.country} - {creditNoteDetails.distributor.zipCode}<br />
                                    ABN:{creditNoteDetails.distributor.abn}<br />
                                    ACN:{creditNoteDetails.distributor.acn}<br />
                                    TEL:{creditNoteDetails.distributor.phone}<br />
                                    FAX:{creditNoteDetails.distributor.fax}
                                </p>
                            </div>
                        </Col>

                        <Col md={4} className="mb-3 p-3 d-flex justify-content-md-center">
                            <div>
                                <h3 className="text-primary mb-5">SOLD TO</h3>
                                <p>{creditNoteDetails.soldTo.companyName},<br />
                                    {/* {creditNoteDetails.soldTo.salesPerson} <br /> */}
                                    {creditNoteDetails.soldTo.firstName}  {creditNoteDetails.soldTo.lastName},<br />
                                    {/* {creditNoteDetails.soldTo.externalCompanyCode} <br /> */}

                                    {creditNoteDetails.soldTo.addressLine1},<br />
                                    {creditNoteDetails.soldTo.addressLine2 && <>{creditNoteDetails.soldTo.addressLine2},<br /></>}
                                    {creditNoteDetails.soldTo.city},<br />
                                    {creditNoteDetails.soldTo.state}, {creditNoteDetails.soldTo.country} - {creditNoteDetails.soldTo.zipCode}<br />

                                    {/* ABN:{creditNoteDetails.soldTo.abnNumber}<br /> */}
                                    {creditNoteDetails.soldTo.email}<br />
                                </p>
                            </div>
                        </Col>

                        <Col md={4} className="mb-3 p-3 d-flex justify-content-md-center">
                            <div>
                                <h3 className="text-primary mb-5">SHIP TO</h3>
                                <p>
                                    {creditNoteDetails.shipTo.customerCompanyName}<br />
                                    {creditNoteDetails.shipTo.firstName} {creditNoteDetails.shipTo.lastName}<br />
                                    {creditNoteDetails.shipTo.addressLine1}<br />
                                    {creditNoteDetails.shipTo.addressLine2}<br />
                                    {creditNoteDetails.shipTo.city}<br />
                                    {creditNoteDetails.shipTo.state}, {creditNoteDetails.shipTo.country} - {creditNoteDetails.shipTo.zipcode}<br />
                                    {creditNoteDetails.shipTo.email}<br />
                                </p>
                            </div>
                        </Col>
                    </Row>

                    {/* Credit Notes Table */}
                    {creditNoteDetails.integrationCode === "microsoftazure" &&
                        <TableBlock
                            tableName=""
                            programName=""
                            domainName=""
                            loadingMessage="Credit notes details..."
                            columnNames={ColumnName}
                            columnData={creditNoteDetilsData}
                        />
                    }

                    {creditNoteDetails.integrationCode === "microsoftsaas" &&
                        <TableBlock
                            tableName=""
                            programName="Microsoft CSP"
                            domainName=""
                            loadingMessage="Credit notes details..."
                            columnNames={ColumnName}
                            columnData={creditNoteDetilsData}
                        />
                    }

                    {
                        creditNoteDetails.integrationCode == "microsoftsaas" &&
                        <>
                            {creditNoteDetails.annualRefunds.lineItems.length > 0 &&
                                <TableBlock
                                    tableName="Annual Refunds"
                                    domainName=""
                                    loadingMessage="Annual Refunds..."
                                    columnNames={anualRefunds}
                                    columnData={anualRefundsData}
                                />
                            }
                        </>
                    }


                    {
                        creditNoteDetails.integrationCode == "microsoftsaas" &&
                        <>
                            {creditNoteDetails.monthlyRefunds.lineItems.length > 0 &&
                                <div className="has-serial-number">
                                    <TableBlock
                                        tableName="Monthly Refunds"
                                        domainName=""
                                        loadingMessage="Monthly Refunds..."
                                        columnNames={monthlyRefunds}
                                        columnData={monthlyRefundsData}
                                    />
                                </div>
                            }
                        </>
                    }

                    {creditNoteDetails.integrationCode === "microsoftazure" &&

                        
                            <div className="has-serial-number">
                                <TableBlock
                                    tableName="Product Refunds"
                                    domainName=""
                                    loadingMessage="Product Refunds..."
                                    columnNames={creditNoteLineItems}
                                    columnData={creditNoteLineItemsData}
                                />
                            </div>
                       
                    }


                    {
                        creditNoteDetails.creditNoteDiscountLineItems.length > 0 &&
                        
                            <TableBlock
                                tableName="Credit Note Discount Line Items"
                                domainName=""
                                loadingMessage="Credit Note Discount Line Items..."
                                columnNames={creditNoteDiscountLineItems}
                                columnData={creditNoteDiscountLineItemsData}
                            />
                       
                    }

                    <Row className="details-box py-4 px-1">
                        <Col md={6}>
                            <p className="text-regular mb-1">Payment Terms<br /></p>
                            <h2 className="text-strong">{creditNoteDetails.paymentTermDescription}</h2>

                        </Col>
                        <Col md={6}>
                            <table className="text-right float-right mobile-table">
                                <tr>
                                    <td><label className="text-strong">Gross Amount (Ex GST):</label></td>
                                    <td><label className="text-strong ml-5 pl-5">
                                        {creditNoteDetails.grossAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((creditNoteDetails.grossAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(creditNoteDetails.grossAmount)).toFixed(2))}`}
                                    </label></td>
                                </tr>
                                <tr>
                                    <td><label className="text-strong">Discount Amount:</label></td>
                                    <td><label className="text-strong ml-5 pl-5">
                                        {creditNoteDetails.discountAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((creditNoteDetails.discountAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(creditNoteDetails.discountAmount)).toFixed(2))}`}
                                    </label></td>
                                </tr>
                                <tr>
                                    <td colSpan="2"><hr /></td>
                                </tr>
                                <tr>
                                    <td><label className="text-strong">Net Amount:</label></td>
                                    <td><label className="text-strong ml-5 pl-5">
                                        {creditNoteDetails.netAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((creditNoteDetails.netAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(creditNoteDetails.netAmount)).toFixed(2))}`}
                                    </label></td>
                                </tr>
                                <tr>
                                    <td><label className="text-strong">Plus GST:</label></td>
                                    <td><label className="text-strong ml-5 pl-5">
                                        {creditNoteDetails.taxAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((creditNoteDetails.taxAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(creditNoteDetails.taxAmount)).toFixed(2))}`}
                                    </label></td>
                                </tr>
                                <tr>
                                    <td colSpan="2"><hr /></td>
                                </tr>
                                <tr>
                                    <td><label><h2 className="text-strong">Credit total (Inc GST):</h2></label></td>
                                    <td><label><h2 className="text-primary ml-5 pl-5 text-strong">
                                        {creditNoteDetails.creditTotalAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((creditNoteDetails.creditTotalAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(creditNoteDetails.creditTotalAmount)).toFixed(2))}`}
                                    </h2></label></td>
                                </tr>
                            </table>
                        </Col>
                    </Row>
                </Container>
            </Container>}
        </div>
    );
}

export default CreditNoteDetails;