import { Button, Col, Container, Form, Nav, Row } from 'react-bootstrap'
import React, { useContext, useEffect, useState } from "react";
import styles from './landingpageFooter.module.scss'
import { LANDING_PAGE_CMS_DATA } from "../../Utils/GlobalConstants";
import { cmsApi } from "../../Utils/API";
import CmsDetailsContext from "../../Context/CmsDetails/CmsDetailsContext";

const Footer = (props) => {

    // UseStates Defination
  const [footerLinks, setFooterLinks] = useState();
  const [additionalFooterLinks, setAdditionalFooterLinks] = useState();
  const [socialMediaLinks, setSocialMediaLinks] = useState();
  const globalData = useContext(CmsDetailsContext)

  // Function for calling Footer item data from Orchid Core CMS
  async function GetLandingPageData() {
    // console.log("Footer Data Fetching Initiated");
    const data = `{
  footerContent {
    footerLinks {
      contentItems {
        ... on ExternalItemLink {
          displayText
          linkUrl
        }
      }
    }
    ourValuesFooterLinks {
      contentItems {
        ... on ExternalItemLink {
          displayText
          linkUrl
        }
      }
    }
    socialMedia {
      contentItems {
        ... on SocialMediaLink {
          displayText
          logo {
            urls
            paths
          }
          url
        }
      }
    }
  }
}`;

    // Defining Header Configuration
    const config = {
      headers: {
        "Content-Type": "application/graphql",
        Accept: "application/graphql",
      },
    };

    // Axios Post Request
    await cmsApi
      .post(LANDING_PAGE_CMS_DATA, data, config)
      .then((resp) => {
        // handle success
        // console.log(resp.data.data.footerContent[0]);
        resp.data.data.footerContent[0].ourValuesFooterLinks.contentItems.map((data)=>{
          if(data.displayText=="Our Vendors"){
            // console.log("Footer Navigationnnnnnnnnnnnnnn")
            localStorage.setItem("cloud_solutions_navigation", data.linkUrl);
          }
        })
        
        setFooterLinks(
          resp.data.data.footerContent[0].footerLinks.contentItems
        );
        setAdditionalFooterLinks(
          resp.data.data.footerContent[0].ourValuesFooterLinks.contentItems
        );
        setSocialMediaLinks(
          resp.data.data.footerContent[0].socialMedia.contentItems
        );
      })
      .catch((error) => {
        // handle error
        // console.log(error);
      });
  }

  // UseEffect Defination to call the get footer items function from CMS
  useEffect(() => {
    setTimeout(() => {
      GetLandingPageData();
    }, 1000);
    
  }, []);


  return (
    <div className={`${styles['footer-container']} mt-5`}>
      {footerLinks && <Container fluid>
        <div className={styles.divContainer}>
          <Row className={styles['footer-content']}>
            <Col md={5} lg={4} >
            <Row>
            <Col md={5} lg={4} xs={6} className='text-start'>
              <h1 className={`mb-2 pl-4 text-start ${styles['h1-footer']}`}>Our Value</h1>
              <div style={{ display: 'inline-flex'}}>
                <Nav className={`flex-column ${styles['navLink']} text-start`}>
                {additionalFooterLinks && additionalFooterLinks.map((td) => {
                    return (
                      <Nav.Link key={td.linkUrl} className={styles.link} href={td.linkUrl} target="_blank">
                          {td.displayText}
                      </Nav.Link>
                    );
                  })}
                </Nav>
              </div>
            </Col>
            <Col md={7} lg={8} xs={6} className='text-start'>
              <h1 className={`mb-2 pl-4 text-start ${styles['h1-footer']}`}>About Us</h1>
              <div style={{ display: 'inline-flex'}}>
                <Nav className={`flex-column ${styles['navLink']} text-start`}>
                {footerLinks && footerLinks.map((td) => {
                    return (
                      <Nav.Link key={td.linkUrl} className={styles.link} href={(props.customerIsAuthenticated && td.displayText==="Contact Us" ) ? "#" : td.linkUrl} onClick={()=>{if(props.customerIsAuthenticated && td.displayText==="Contact Us" ){props.setOpen(true)}}} target="_blank">
                          {td.displayText}
                      </Nav.Link>
                    );
                  })}
                </Nav>
              </div>
            </Col></Row>
            </Col>
            <Col md={3} lg={4} xs={6} className={`text-start`}>
              <h1 className={`mb-4 ${styles['h1-footer']}`} >Follow us</h1>
              <div className='text-start'>
                {socialMediaLinks && globalData && socialMediaLinks.map((link)=>{
                  return( 
                    <a href={link.url} target="_blank" >
                      <img className={styles['follow-btn']}
                        src={globalData.baseUrl + link.logo.urls}
                        alt="social1"
                      />
                    </a>
                  )
                })}
              </div>
            </Col>
            <Col md={4} lg={4} xs={6} className={`text-start`}>
              <h1 className={`mb-4 ${styles['h1-footer-stay']}`} >Stay updated and subscribe to our newsletter</h1>
              {/* <Form className={`${styles['form-footer']}`}>
                <div className={styles['input-group']}>
                  <Form.Control type="email" placeholder="Your email" className={styles['email-input']} />
                  <button className={styles['subscribe-btn']}><ChevronRight className={styles.chevronRight}/></button>
                </div>
              </Form> */}
              {<div  className={`${styles['footer-subscribe-embedded']}`}>
              <iframe
                src={localStorage.getItem("footer_newsletter_link") ? localStorage.getItem("footer_newsletter_link") : (props.formEndPoint) && (props.formEndPoint)[3].endpoint}
                frameBorder="0"
                width="100%"
                height="100%"
                allowTransparency="true"
                scrolling="yes"
                // onLoad={() => setLoading(false)}
            /></div>}
            </Col>
          </Row>
        </div>
        </Container>}
        {footerLinks && <div className={styles['footer-bottom']}>
          <Container fluid>
            <Row>
              <Col>
                <p >Copyright 2024 Synnex Australia Pty Ltd</p>
              </Col>
              <Col className={styles['footer-links']}>
                  <a href="/terms-and-conditions" className={styles['footer-link']} target='_blank' >Terms & Conditions</a>
                  <a href="/privacy-policy" className={styles['footer-link']} target='_blank' >Privacy Policy</a>
                  <a href="/cookie-policy" className={styles['footer-link']} target='_blank' >Cookie Policy</a>
              </Col>
            </Row>            
          </Container>  
        </div>}

    </div>

  )
}

export default Footer