import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import styles from './ProductListing.module.scss'
import { PROVISIONING_GET_SERVICES, GET_CUSTOMERS_LIST, PROVISIONING_GET_PRODUCT, PROVISIONING_GET_CURRENT_SUBSCRIPTION, PROVISIONING_GET_CUSTOMER_DOMAIN, GET_CSP_MPNID_DATA, GOOGLE_RESELLER_PREREQUISITE, CSP_MCA_AGREEMENT_DETAILS, GET_PAYMENT_GATEWAY_BILLING_ADDRESS, GET_CREDIT_CARD_DETAILS, GET_DIRECT_DEBITS } from '../../../Utils/GlobalConstants';
import { api } from '../../../Utils/API';
import { Container, Button, Row, Col, Accordion, AccordionContext, useAccordionButton, Card } from 'react-bootstrap';
import 'reactjs-popup/dist/index.css';
// Images
import gcpProductImage from '../../../Assets/Images/Illustrations/gcp.svg';
import gwsProductImage from '../../../Assets/Images/Illustrations/googleWorkspace.svg';
import ibmProductImage from '../../../Assets/Images/Illustrations/ibmCloud.svg';
import avastProductImage from '../../../Assets/Images/Illustrations/avast.svg';
import azureProductImage from '../../../Assets/Images/Illustrations/azure.svg';
import cspProductImage from '../../../Assets/Images/Illustrations/csp.svg';
import acronisProductImage from '../../../Assets/Images/Illustrations/acronis.svg';
import CSPCurrentSubscription from './LocalComponents/CurrentSubscription/CSP';
import GWSCurrentSubscription from './LocalComponents/CurrentSubscription/GWS';
import GoogleWorkspaceForEducationProductDetail from './LocalComponents/ProductDetail/GWS/GoogleWorkspaceForEducation';
import GoogleworkspaceProductDetail from './LocalComponents/ProductDetail/GWS/Googleworkspace';
import CSPProductDetail from './LocalComponents/ProductDetail/CSP';
import AzureProductDetail from './LocalComponents/ProductDetail/Azure';
import GCPProductDetail from './LocalComponents/ProductDetail/GCP';
import IBMProductDetail from './LocalComponents/ProductDetail/IBM';
import loadingGif from "../../../Assets/Images/Animations/loading-management-console.gif"
import DownloadCloudPricing from './LocalComponents/Download/Cloud/Provisioning';
import Toast from '../../../GlobalComponents/Toast';
import AcronisProductDetail from './LocalComponents/ProductDetail/Acronis';
import AvastProductDetail from './LocalComponents/ProductDetail/Avast';
import ProvisionSearchDropdown from './LocalComponents/ProvisionSearchDropdown';
import Popup from 'reactjs-popup';
import { XLg } from 'react-bootstrap-icons';
import AccessDenied from '../../../GlobalComponents/EmptyStates/AccessDenied';
import DeleteCardImage from '../../../Assets/Images/Illustrations/delete-card-img.svg';
import GCPCurrentSubscription from './LocalComponents/CurrentSubscription/GCP';
import AzureCurrentSubscription from './LocalComponents/CurrentSubscription/Azure';

const CloudProvision = (props) => {

  const location = useLocation();                                                 // use to access data from other page data
  const navigate = useNavigate();                                                 // to use navigate function from react router dom

  // Role Management useStates
  const [isreadOnly, setIsreadOnly] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Provision") ? true : false);
  const [isDisabled, setIsDisabled] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Provision") ? true : false);
  const [isDisabledPrerequisite, setIsDisabledPrerequisite] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Prerequisite") ? true : false);
  const [isReadOnlyPrerequisite, setIsReadOnlyPrerequisite] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Prerequisite") ? true : false);
  const [isdisableManage, setIsdisableManage] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Manage") ? true : false);

  const [serviceData, setServiceData] = useState(null);                           // data in service navigation bar
  const [customerData, setCustomerData] = useState([]);                           // data in status dropdown
  const [productPlan, setProductPlan] = useState();                               // data in product plan

  // Search Value for Customer Name
  const [customerNameDefaultValue, setCustomerNameDefaultValue] = useState("Select Customer");
  const [customerNameSearchValue, setCustomerNameSearchValue] = useState();
  const [customerName, setCustomerName] = useState([]);
  const [customerNameFiltered, setCustomerNameFiltered] = useState(customerName);
  const updatedCustomerName = [];
  const [customerId, setCustomerId] = useState(location.state?.customerId !== null ? location.state?.customerId : null);

  // usesates to store service id , customer id, products array and cuurent subscription array
  const [serviceId, setServiceId] = useState(location.state?.serviceId !== null ? location.state?.serviceId : null);
  const [productId, setProductId] = useState(null);
  const [productPlanDefault, setProductPlanDefault] = useState(null);
  const [productPlansArray, setProductPlansArray] = useState(null);
  const [currentSubscriptionArrayList, setCurrentSubscriptionArrayList] = useState([])

  // is coming from management console usestate
  const [isManagementConsoleNavigation, setIsManagementConsoleNavigation] = useState(location.state?.isManagementConsoleNavigation ? location.state?.isManagementConsoleNavigation : false)

  const [initialSubscriptionTableCall, setInitialSubscriptionTableCall] = useState(null);   // Initial provision customer search dropdown check
  // const [productDetailIntegrationCode, setProductDetailIntegrationCode] = useState(null)

  //popups for current subscription
  const [currentSubscriptionMicrosoftCspPopup, setCurrentSubscriptionMicrosoftCspPopup] = useState(false);
  const [currentSubscriptiongwsPopup, setCurrentSubscriptionGwsPopup] = useState(false);
  const [currentSubscriptionGcpPopup, setCurrentSubscriptionGcpPopup] = useState(false);
  const [currentSubscriptionAzurePopup, setCurrentSubscriptionAzurePopup] = useState(false);
  const [currentSubscriptionProductName, setCurrentSubscriptionProductName] = useState(null);
  let activeCustomerId;
  const [activeCustomerIdUseState, setActiveCustomerIdUseState] = useState(null)
  const [activeServiceIdUseState, setActiveServiceIdUseState] = useState(null)

  //Popup class for download cloud pricing
  const [downloadCloudPricingModalFlag, setDownloadCloudPricingModalFlag] = useState(false);

  // Online Service Id Arrays useStates
  const [serviceDataOnline, setServiceDataOnline] = useState(null);
  const [serviceDataOnlineIntegrationCode, setServiceDataOnlineIntegrationCode] = useState(null);

  const [checkDomainAvailability, setCheckDomainAvailability] = useState(location.state?.domainName ? location.state?.domainName : null);     // Check Domain Availablityc

  // Reseller prerequisite flag check and expiry flag
  const [checkResellerPrereqisiteFlag, setCheckResellerPrereqisiteFlag] = useState(null);
  const [checkResellerPrereqisiteExpiryFlag, setCheckResellerPrereqisiteExpiryFlag] = useState(null);

  const [microsoftServiceIdArray, setMicrosoftServiceIdArray] = useState([]);   // Array to store service id of csp and azure
  const [googleServiceIdArray, setGoogleServiceIdArray] = useState([]);         // Array to store service id of gws and csp

  const [googleServiceIdGWS, setGoogleServiceIdGWS] = useState([]);             // Used to store service id of gws to compare from the array and call reseller prerequsite api accordingly
  const [googleServiceIdGCP, setGoogleServiceIdGCP] = useState([]);             // Used to store service id of gcp to compare from the array and call reseller prerequsite api accordingly

  const [vendorRequirementPopupId, setVendorRequirementPopupId] = useState(null);         // to store service id in vendor requirement layover
  const [customerRequirementPopupId, setCustomerRequirementPopupId] = useState(null);     // to store service id in customer requirement layover

  const [activeIntegrationCode, setActiveIntegrationCode] = useState(null);

  // Use State for Toasts
  const [toastSuccess, setToastSuccess] = useState(false);                        // toast message displaying success message
  const [toastError, setToastError] = useState(false);                            // toast message displaying error message
  const [toastMessage, setToastMessage] = useState("");                           // toast message usestate

  const [selectCustomerErrorState, setSelectCustomerErrorState] = useState(false);

  const [planSelectedFlag, setPlanSelectedFlag] = useState(false);

  const [modalUnsavedChanges, setModalUnsavedChanges] = useState(false);

  // Customer prerequisite navigation check flags (isMcaCompleted, transfer popup,transfer message check state)
  const [isMCACompleted, setIsMCACompleted] = useState(true);
  const [isTransferGwsInProgress, setIsTransferGwsInProgress] = useState(false);
  const [isTransferGwsInProgressPopup, setIsTransferGwsInProgressPopup] = useState(location.state?.isGWSTransferPending ? location.state?.isGWSTransferPending : false);

  // Payment methods validations useStates
  const [billingPaymentGatewayErrorPopup, setBillingPaymentGatewayErrorPopup] = useState(false);
  const [billingPaymentGatewayErrorMessage, setBillingPaymentGatewayErrorMessage] = useState(null);
  const [billingPaymentGatewayData, setBillingPaymentGatewayData] = useState(null);
  const [billingPaymentCardExpiryFlag, setBillingPaymentCardExpiryFlag] = useState(false);
  const [billingPaymentDirecrDebitExpiryFlag, setBillingPaymentDirecrDebitExpiryFlag] = useState(false);
  const [proceedToProvisionFlag, setProceedToProvisionFlag] = useState(false);  // System hold proceed button check useState

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Function to call all dropdown values in Service Section 
  async function GetAllServices() {
    await api.get(PROVISIONING_GET_SERVICES, config)
      .then(resp => {
        // console.log("Data:", resp.data);
        let onlineServiceArray = [];
        let onlineServiceArrayIntegrationCode = [];
        let microsoftResellerServiceIdArray = [];
        let googleResellerServiceIdArray = [];
        resp.data.map((serviceItem) => {
          if (serviceItem.serviceType === "ONLINE") {
            onlineServiceArray.push(serviceItem);
            onlineServiceArrayIntegrationCode.push(serviceItem.id);
          }
          if (serviceItem.integrationCode === "microsoftsaas" || serviceItem.integrationCode === "microsoftazure") {
            microsoftResellerServiceIdArray.push(serviceItem.id);
          }
          if (serviceItem.integrationCode === "googleworkspace" || serviceItem.integrationCode === "GCP") {
            googleResellerServiceIdArray.push(serviceItem.id);
            if (serviceItem.integrationCode === "googleworkspace") setGoogleServiceIdGWS(serviceItem.id);
            if (serviceItem.integrationCode === "GCP") setGoogleServiceIdGCP(serviceItem.id);
          }
        })
        setServiceData(resp.data);
        setServiceDataOnline(onlineServiceArray);
        setServiceDataOnlineIntegrationCode(onlineServiceArrayIntegrationCode);
        setMicrosoftServiceIdArray(microsoftResellerServiceIdArray);
        setGoogleServiceIdArray(googleResellerServiceIdArray);

      })
      .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response.status == 401) {
          props.Logout();
        }
        else {
        }

      });
  };

  // Function to get Product Plans 
  async function GetAllProductPlans(serviceId, productId) {
    (serviceId === undefined || serviceId === null) ? serviceId = "" : serviceId = serviceId;
    (productId === undefined || productId === null) ? productId = "" : productId = productId;
    await api.get(`${PROVISIONING_GET_PRODUCT}?serviceId=${serviceId}&productId=${productId}`, config)
      .then(resp => {
        let productPlansArray = [];
        // console.log("Data:", resp.data);
        resp.data.map((products) => {
          productPlansArray.push(products.productId);
        })
        setProductPlansArray(productPlansArray)
        setProductPlan(resp.data);
        // console.log("New Service Name 123", serviceName);
      })
      .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response.status == 401) {
          props.Logout();
        }
        else {
        }

      });
  };

  // Function to call all dropdown values in Customer Section 
  async function GetAllCustomers() {
    await api.get(`${GET_CUSTOMERS_LIST}?isProvisionPage=${true}`, config)
      .then(resp => {
        // console.log("Data:", resp.data);
        setCustomerData(resp.data);
        setCustomerNameFiltered(resp.data);

        for (let i in resp.data) {
          if (resp.data[i].id === customerId) {
            setCustomerNameDefaultValue(resp.data[i].name) // serviceName.push(resp.data[i].name);
          }
        }

        for (let i in resp.data) {
          if (!customerName.includes(resp.data[i].name)) {
            customerName.push(resp.data[i].name);
          }
        }

        // console.log("New Customer Name 123", customerName);
      })
      .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response.status == 401) {
          props.Logout();
        }
        else {
          // console.log(error);
        }

      });
  };

  // Get all products
  async function GetAllProductDefault() {
    await api.get(PROVISIONING_GET_PRODUCT, config)
      .then(resp => {
        // console.log("Data:", resp.data);
        setProductPlanDefault(resp.data);
        // console.log("New Service Name 123", serviceName);
      })
      .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response.status == 401) {
          props.Logout();
        }
        else {
          // console.log(error);
        }

      });
  };

  // Get MCA Status
  async function GetMCADetails(customerId) {
    await api.get(`${CSP_MCA_AGREEMENT_DETAILS}?customerCode=${customerId}`, config)
      .then(resp => {

        // console.log("mca data", resp.data);
        // console.log("mca data", resp.data);

        if (resp.data.mcaStatus===true) {
          setIsMCACompleted(true);
        } else {
          setIsMCACompleted(false);
        }
      })
      .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response.status == 401) {
          props.Logout();  
        }
        else {
          // console.log(error);
        }
      });
  }

  // Function to call all dropdown values in Customer Section 
  async function GetCustomerDomain(customerId, serviceId) {
    // console.log("Inside Customer Domain Function", microsoftServiceIdArray, googleServiceIdArray, serviceId);
    // console.log("Inside Customer Domain Function", microsoftServiceIdArray, googleServiceIdArray, serviceId);
    if (microsoftServiceIdArray.includes(serviceId) || googleServiceIdArray.includes(serviceId)) {
      let integrationCode;
      serviceData.map((services) => {
        if (services.id === serviceId) {
          integrationCode = services.integrationCode;
        }
      })
      // console.log("Inside Customer Domain Function");
      // console.log("Inside Customer Domain Function");
      await api.get(`${PROVISIONING_GET_CUSTOMER_DOMAIN}?customerCode=${customerId}&serviceId=${serviceId}&integrationCode=${integrationCode}`, config)
        .then(resp => {
          // console.log("Resp ; ", resp);
          setCustomerRequirementPopupId(serviceId);
          if (resp.status === 200) {
            // console.log("Hi");
            // console.log("Data:", resp.data.domainName);
            setCheckDomainAvailability(resp.data.domainName);
            if (resp.data.gwIsTransferOrderInProgress) {
              setIsTransferGwsInProgress(true);
            }
            else {
              setIsTransferGwsInProgress(false);
            }
            if (microsoftServiceIdArray.includes(serviceId)) {
              GetMCADetails(customerId);
            }
          }
          else {
            setCheckDomainAvailability(location.state?.domainName ? location.state?.domainName : "");
            window.scrollTo(0, 0);
          }
        })
        .catch(error => {
          // console.log(error)
          if (error?.status == 401 || error?.response.status == 401) {
            props.Logout();
          }
          else {
            // console.log(error);
          }

        });
    }
    else {
      setCheckDomainAvailability(null);
    }
  };

  // Function to get vendor requiremnt status 
  async function GetVendorStatus(serviceId) {
    let url;
    setCheckResellerPrereqisiteFlag(null);
    // console.log("Inside :", serviceId, microsoftServiceIdArray, googleServiceIdArray);
    // console.log(microsoftServiceIdArray, googleServiceIdArray, serviceId, microsoftServiceIdArray.includes(serviceId), googleServiceIdArray.includes(serviceId));
    // console.log("Inside :", serviceId, microsoftServiceIdArray, googleServiceIdArray);
    // console.log(microsoftServiceIdArray, googleServiceIdArray, serviceId, microsoftServiceIdArray.includes(serviceId), googleServiceIdArray.includes(serviceId));
    let integrationCode;
    productPlanDefault.map((code) => {
      // console.log("Integration : ", code.serviceId == serviceId, code.serviceId, serviceId)
      // console.log("Integration : ", code.serviceId == serviceId, code.serviceId, serviceId)
      if (code.serviceId == serviceId) {
        integrationCode = code.integrationCode;
      }
    })
    // console.log("Integration : ", integrationCode)
    // console.log("Integration : ", integrationCode)
    setActiveIntegrationCode(integrationCode);
    if (microsoftServiceIdArray.includes(serviceId)) {
      // console.log("Inside microsoft");
      // console.log("Inside microsoft");
      url = GET_CSP_MPNID_DATA;
    }
    else if (googleServiceIdArray.includes(serviceId)) {
      // console.log("Inside gws");
      // console.log("Inside gws");
      url = GOOGLE_RESELLER_PREREQUISITE;
    }
    else {
      url = "";
      setCheckResellerPrereqisiteFlag(null);
    }
    if (url && url.length > 0) {
      await api.get(`${url}`, config)
        .then(resp => {
          // console.log("Resp ; ", resp);
          // console.log("Resp ; ", resp);
          if (resp.status === 200) {
            setVendorRequirementPopupId(serviceId);
            if (url == GOOGLE_RESELLER_PREREQUISITE) {
              setCheckResellerPrereqisiteExpiryFlag(null);
              if (serviceId === googleServiceIdGWS) {
                setCheckResellerPrereqisiteFlag(resp.data.isGWAlreadyConfigured);
                if (resp.data.isGWAlreadyConfigured == false) {
                  document.getElementById("provision-list-block-outer-div").scrollTo(0, 0);
                }
              }
              else if (serviceId === googleServiceIdGCP) {
                setCheckResellerPrereqisiteFlag(resp.data.isGCPEnabled);
                if (resp.data.isGCPEnabled == false) {
                  document.getElementById("provision-list-block-outer-div").scrollTo(0, 0);
                }
              }
            }
            if (url == GET_CSP_MPNID_DATA) {
              setCheckResellerPrereqisiteFlag(resp.data.isAlreadyConfigured);
              if (resp.data.isAlreadyConfigured == false) {
                document.getElementById("provision-list-block-outer-div").scrollTo(0, 0);
              }
              else {
                if (resp.data.isMPNIdExpired) {
                  setCheckResellerPrereqisiteExpiryFlag(resp.data.isMPNIdExpired);
                  document.getElementById("provision-list-block-outer-div").scrollTo(0, 0);
                }
                else {
                  setCheckResellerPrereqisiteExpiryFlag(resp.data.isMPNIdExpired);
                }
              }
            }
          }
        })
        .catch(error => {
          // console.log(error)
          if (error?.status == 401 || error?.response.status == 401) {
            props.Logout();
          }
          else {
            // console.log(error);
          }

        });
    }
    else {
      setCheckResellerPrereqisiteFlag(null);
    }

  };

  // Function to get current subscriptions list
  async function GetCurrentSubscription(customerId, productPlansArray) {
    (customerId === undefined || customerId === null) ? customerId = "" : customerId = customerId;
    (productPlansArray === undefined || productPlansArray === null) ? productPlansArray = "" : productPlansArray = productPlansArray;
    await api.get(`${PROVISIONING_GET_CURRENT_SUBSCRIPTION}?customerCode=${customerId}&productIds=${productPlansArray}`, config)
      .then(resp => {
        // setProductPlan(resp.data);
        let subArray = [];
        // console.log("Data", resp.data);
        // console.log("Data", resp.data);
        resp.data.productSubscriptions.map((item) => {
          if (item.isAlreadyProvisioned) {
            subArray.push(item.productId);
          }
        })
        setCurrentSubscriptionArrayList(subArray);
      })
      .catch(error => {
        // console.log(error)
        // console.log(error)
        if (error?.status == 401 || error?.response.status == 401) {
          props.Logout();
        }
        else {
        }

      });
  };


  //Addons accordion
  function CustomToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
      <span
        // type="button"
        className='pt-1 pl-5 label-sm button-transparent-bg'
        onClick={() => {
          if (!planSelectedFlag) {
            setActiveCustomerIdUseState(eventKey); 
            // console.log("ACTIVE", activeCustomerId); 
            decoratedOnClick()
          }
          else {
            // setProductIdConfigure(eventKey);
            setModalUnsavedChanges(true);
          }
        }}
      >
        {isCurrentEventKey ? (
          <span className='gdap-up-button ml-1 mr-2 text-center provision-arrow-icon-card' />
        ) : (
          <span className='gdap-down-button pt-3 ml-1  mr-2 text-center provision-arrow-icon-card' />
        )}
      </span>
    );
  }

  // Functions triggered on click of current subscriptions click
  function CurrentSubscriptionClick(integrationCode, name) {
    setCurrentSubscriptionProductName(name);
    // console.log("Data", currentSubscriptionProductName)
    // console.log("Data", currentSubscriptionProductName)
    if (integrationCode === "microsoftsaas")
      setCurrentSubscriptionMicrosoftCspPopup(true)
    else if (integrationCode === "googleworkspace")
      setCurrentSubscriptionGwsPopup(true)
    else if (integrationCode === "GCP")
      setCurrentSubscriptionGcpPopup(true)
    else if (integrationCode === "microsoftazure")
      setCurrentSubscriptionAzurePopup(true)
    else {

    }



    // subProduct.integrationCode == "googleworkspace" ? (
    //   gwsProductImage
    // ) : subProduct.integrationCode == "GCP" ? (
    //   gcpProductImage
    // ) : subProduct.integrationCode == "microsoftazure" ? (
    //   azureProductImage
    // ) : subProduct.integrationCode == "cloudcare" ? (
    //   avastProductImage
    // ) : subProduct.integrationCode == "microsoftsaas" ? (
    //   cspProductImage
    // ) : subProduct.integrationCode == "softlayer" ? (
    //   ibmProductImage
    // ) : acronisProductImage
  }

  // Function to call Direct Debit details 
  async function GetDirectDebitDetails() {
    await api.get(GET_DIRECT_DEBITS, config)
      .then(resp => {
        // console.log(resp.data)
        if (resp.status == 200) {
          let directDebitStatusArray = [];
          resp.data.map((data) => {
            directDebitStatusArray.push(data.status);
          })
          if (directDebitStatusArray.includes("Approved")) {
            setBillingPaymentDirecrDebitExpiryFlag(false);
          }
          else {
            setBillingPaymentDirecrDebitExpiryFlag(true);
          }
        }
        else {
          setBillingPaymentDirecrDebitExpiryFlag(false);
        }

      })
      .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response.status == 401) {
          props.Logout();
        }
        else {
          // console.log(error);
        }

      });
  };

  // Get Credit Cards Data
  async function GetCreditCardDetails() {
    await api.get(GET_CREDIT_CARD_DETAILS, config)
      .then(resp => {
        if (resp.status === 200) {
          resp.data.map((data) => {
            if (data.isPrimary && data.isExpired) {
              setBillingPaymentCardExpiryFlag(true);
            }
          })
        }
        else {
          setBillingPaymentCardExpiryFlag(false);
        }
      })
      .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response.status == 401) {
          props.Logout();
        }
        else {
          // console.log(error);
        }

      });
  };

  // Function to get payment gateway details
  async function GetPaymentGatewayBillingAddress() {
    await api.get(GET_PAYMENT_GATEWAY_BILLING_ADDRESS, config)
      .then(resp => {
        // console.log("Data : ", resp.data);
        setBillingPaymentGatewayData(resp.data);
        resp.data.paymentMethods.map((data) => {
          if (data.paymentMethod === "Credit Card" && data.isDefault) {
            GetCreditCardDetails();
          }
          if (data.paymentMethod === "Direct Debit" && data.isDefault) {
            GetDirectDebitDetails();
            // setBillingPaymentDirecrDebitExpiryFlag();
          }
        })
      })
      .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response.status == 401) {
          props.Logout();
        }
        else {
          // console.log(error);
        }

      });
  };

  useEffect(() => {
    localStorage.getItem("navigation_link") ?  localStorage.removeItem("navigation_link") : localStorage.setItem("navigation_link","/cloud-provision");
    GetAllServices();
    GetAllCustomers();
    GetPaymentGatewayBillingAddress();
    // GetAllProductPlans(serviceId,productId);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const productListingRightNavbar = document.querySelector('#Product-provision');
      const scrollPosition = window.innerHeight + window.scrollY;
      const documentHeight = document.documentElement.scrollHeight - 10;

      if (productListingRightNavbar) {
        if (scrollPosition >= documentHeight) {
          productListingRightNavbar.style.maxHeight = '85vh';
        } else {
          productListingRightNavbar.style.maxHeight = '100vh';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    GetAllProductPlans(serviceId, productId);
    setIsMCACompleted(true);
    setIsTransferGwsInProgress(false);
  }, [serviceId, productId]);

  useEffect(() => {
    GetAllProductDefault();
  }, [])

  useEffect(() => {
    // console.log(productPlansArray);
    // console.log(productPlansArray);
  }, [productPlansArray])

  useEffect(() => {
    if (customerId && productPlansArray && !isdisableManage)
      GetCurrentSubscription(customerId, productPlansArray)
  }, [customerId, productPlansArray])

  useEffect(() => {
    // console.log('DISPLAYED DATA', currentSubscriptionArrayList)
    // console.log('DISPLAYED DATA', currentSubscriptionArrayList)
  }, [currentSubscriptionArrayList])

  useEffect(() => {
    if (!isDisabledPrerequisite) {
      // console.log("Get Customer Domain", customerId, serviceId, productId, activeCustomerIdUseState, activeServiceIdUseState);
      // console.log("Get Customer Domain", customerId, serviceId, productId, activeCustomerIdUseState, activeServiceIdUseState);
      if (customerId && activeServiceIdUseState) {
        // console.log("Get Customer Domain Inside", activeCustomerIdUseState, activeServiceIdUseState, productId, customerId);
        // console.log("Get Customer Domain Inside", activeCustomerIdUseState, activeServiceIdUseState, productId, customerId);
        setIsMCACompleted(true);
        GetCustomerDomain(customerId, activeServiceIdUseState);
      }
    }
  }, [customerId, activeServiceIdUseState])

  useEffect(() => {
    // console.log("In")
    // console.log("In")
    if (isManagementConsoleNavigation === true && microsoftServiceIdArray.length > 0 && googleServiceIdArray.length > 0) {
      // console.log("In", customerId, serviceId)
      // console.log("In", customerId, serviceId)
      setIsMCACompleted(true);
      GetCustomerDomain(customerId, serviceId);
    }
  }, [isManagementConsoleNavigation, microsoftServiceIdArray, googleServiceIdArray, customerId])

  useEffect(() => {
    if (activeServiceIdUseState && !isDisabledPrerequisite) {
      GetVendorStatus(activeServiceIdUseState);
    }
  }, [activeServiceIdUseState])

  // useEffect(() => {
  //   if (serviceId) {
  //     GetVendorStatus(serviceId);
  //   }
  // }, [serviceId])

  useEffect(() => {
    if (activeCustomerIdUseState) {
      // console.log("activeCustomerIdUseState", activeCustomerIdUseState)
      // console.log("activeCustomerIdUseState", activeCustomerIdUseState)
      productPlanDefault.map((product) => {
        if (product.productId === activeCustomerIdUseState) {
          // console.log("Service :", product.serviceId)
          setActiveServiceIdUseState(product.serviceId);
        }
      })
    }
  }, [activeCustomerIdUseState])

  useEffect(() => {
    // console.log("checkResellerPrereqisiteExpiryFlag : ", checkResellerPrereqisiteExpiryFlag);
  }, [checkResellerPrereqisiteExpiryFlag])

  useEffect(() => {
    // console.log("planSelectedFlag : ", planSelectedFlag);
  }, [planSelectedFlag])

  // useEffect(() => {
  //   console.log('DISPLAYED product DATA', productDetailIntegrationCode)
  // }, [productDetailIntegrationCode])


  return (
    <>
      {!isDisabled && <Container fluid className="main-content pl-0">
        <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
        <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
        <Popup
          className="custom-modal custom-modal-sm"
          open={modalUnsavedChanges}
          onClose={() => setModalUnsavedChanges(false)}
          modal
          nested
        >
          {(close) => (
            <div className="modal">
              <div className="header p-4 py-3">
                <h2 className="mb-0 px-3">Unsaved Changes</h2>
                <XLg
                  size={18}
                  className="cpointer text-strong close-icon-popup-sm"
                  onClick={close}
                  color="#6A6A6A"
                />
              </div>
              <Container className="p-5">
                <p className='pt-0 px-3 pb-5 mb-0'>
                  <strong>Are you sure you want to proceed, any unsaved changes will be lost. </strong>
                </p>
                <div className="actions">
                  <div className="d-flex justify-content-end px-3 pb-3 pt-2">
                    <Button
                      className="btn btn-lg mx-3 btn-border-light"
                      variant="light"
                      onClick={() => {
                        // console.log("modal closed ");
                        close();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn btn-md"
                      onClick={() => {
                        setPlanSelectedFlag(false);
                        // setActiveCustomerIdUseState(productIdConfigure);
                        // setProductIdConfigure(null);
                        close();
                      }}
                    >
                      Proceed
                    </Button>
                  </div>
                </div>
              </Container>
            </div>
          )}
        </Popup>
        <Popup
          className="custom-modal custom-modal-sm"
          open={isTransferGwsInProgressPopup}
          onClose={() => {
            setIsTransferGwsInProgressPopup(false);
          }
          }
          modal
          nested
        >
          {(close) => (
            <div className="modal">
              <div className="header p-4 py-3">
                <h2 className="mb-0 px-3">Cloud Provision</h2>
                <XLg
                  size={18}
                  className="cpointer text-strong close-icon-popup-sm"
                  onClick={close}
                  color="#6A6A6A"
                />
              </div>
              <Container className="p-5">
                <p className='pt-0 px-3 pb-5 mb-0'>
                  <strong>Your transfer request is being reviewed. Kindly await completion before provisioning subscriptions.</strong>
                </p>
                <div className="actions">
                  <div className="d-flex justify-content-center px-3 pb-3 pt-2">
                    <Button
                      className="btn btn-md"
                      onClick={() => {
                        close();
                      }}
                    >
                      Ok
                    </Button>
                  </div>
                </div>
              </Container>
            </div>
          )}
        </Popup>
        {/* popup for Payment gateway Issue */}
        <Popup
          className="custom-modal custom-modal-sm"
          open={billingPaymentGatewayErrorPopup}
          onClose={() => {
            setBillingPaymentGatewayErrorPopup(false);
            setBillingPaymentGatewayErrorMessage(null);
          }}
          modal
          nested
        >
          {(close) => (
            <div className="modal">
              <div className="header p-4 py-3">
                <h2 className="mb-0 px-3">Payment Method</h2>
                <XLg
                  size={18}
                  className="cpointer text-strong close-icon-popup-sm"
                  onClick={close}
                  color="#6A6A6A"
                />
              </div>
              <Container>
                <div className={styles["no-data-cloud-delete"]}>
                  <img src={DeleteCardImage} className="empty-cloud-invoice-table-svg" />
                  <div className='my-3 text-center text-medium'>
                    {billingPaymentGatewayErrorMessage}
                  </div>

                  <div> <Button
                    className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12 cpointer"
                    variant="light"
                    onClick={()=>close()}>Back</Button>
                    {(billingPaymentGatewayErrorMessage == "There is an issue with your account. If you choose to proceed to provision the order, please contact Synnex account team to complete the provisioning of this order." || billingPaymentGatewayErrorMessage == "No payment method is chosen by default, click on proceed to add payment method.") ?
                      <Button className='cpointer' onClick={() => {
                        setProceedToProvisionFlag(true);
                        close();
                        if (!(billingPaymentGatewayData.isDefaultPaymentMethodValid) || billingPaymentCardExpiryFlag || billingPaymentDirecrDebitExpiryFlag)
                          navigate("/billing/payment-methods");
                      }}>Proceed</Button> : <span className='d-none'></span>}</div>

                </div>
              </Container>
            </div>
          )}
        </Popup>
        {productPlansArray && serviceData && <div>
          <Row className="mx-0">
            <DownloadCloudPricing downloadCloudPricingModalFlag={downloadCloudPricingModalFlag} setDownloadCloudPricingModalFlag={setDownloadCloudPricingModalFlag}
              serviceData={serviceDataOnline} integrationListArray={serviceDataOnlineIntegrationCode} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage} />
            <Col lg={2} md={3} id='Product-provision' className={`pt-3 pb-2 px-0 ${styles['product-listing-right-navbar']}`}>
              <Container fluid className="">
                <p className="mb-0 cpointer pb-2"><span className={(!serviceId && !productId) ? `${styles['active-main']}` : " "} onClick={() => { setCheckResellerPrereqisiteFlag(null); setServiceId(null); setActiveServiceIdUseState(null); setProductId(null); }}><span className='all-service mr-1'>&nbsp;</span>Cloud Services</span></p>
              </Container>
              <hr className='my-1 mb-2 provision-left-bar-hr' />
              <div className="online-services-outer">
                <div className="pr-1">
                  <div className="online-services">
                    {
                      serviceData && serviceData.map((item) => {
                        if (item.hasMultiProducts) {
                          if (item.serviceType === "ONLINE") {
                            return (
                              <Accordion activeKey={serviceId}>
                                <Accordion.Item eventKey={item.id} className=''>
                                  <Accordion.Header onClick={() => {
                                    if (!planSelectedFlag) {
                                      setProductId(null);
                                      if (!serviceId || item.id !== serviceId) {
                                        setServiceId(item.id); setActiveServiceIdUseState(item.id);
                                      }
                                      else {
                                        setServiceId(null); setActiveServiceIdUseState(null);
                                      }
                                    }
                                    else {
                                      setModalUnsavedChanges(true);
                                    }
                                  }} className={(serviceId === item.id) ? `cpointer ${styles['active-service']}` : `cpointer ${styles['inactive-service']}`} >
                                    <span><span className={`provision-${item.integrationCode} mr-2`}>&nbsp;</span>{item.name}</span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {
                                      productPlanDefault && productPlanDefault.map(product => {
                                        if (item.integrationCode === product.integrationCode) {
                                          return (
                                            <p className='px-2'>
                                              <span className={(product.productId === productId) ? `d-block cpointer ${styles['active-product']}` : `d-block cpointer ${styles['inactive-product']}`} onClick={() => { setProductId(product.productId); setServiceId(item.id); setActiveCustomerIdUseState(product.productId); setActiveServiceIdUseState(null); }}>{product.name}</span>
                                            </p>
                                          )
                                        }
                                      })
                                    }
                                  </Accordion.Body>
                                  <hr className='my-2 mb-2 provision-left-bar-hr' />
                                </Accordion.Item>
                              </Accordion>
                            )
                          }
                        } else {
                          if (item.serviceType === "ONLINE") {
                            return (
                              <><p className='mb-0'><span className={(serviceId === item.id) ? `d-block cpointer ${styles['active-service']}` : `d-block cpointer ${styles['inactive-service']}`}
                                onClick={() => {
                                  if (!planSelectedFlag) {
                                    setProductId(null); setServiceId(item.id); setActiveServiceIdUseState(item.id);
                                  }
                                  else {
                                    setModalUnsavedChanges(true);
                                  }
                                }}><span className={`provision-${item.integrationCode} mr-2`}>&nbsp;</span>{item.name}</span></p>
                                <hr className='my-2 mb-2 provision-left-bar-hr' /></>
                            )
                          }
                        }
                      })
                    }
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={10} md={9} className="pr-0">
              <Container fluid className={`pr-3 ${styles['floating-container']}`}>
                <Row className={`mb-5 d-flex align-items-center ${styles['product-plan-top-right-card-outer']}`}>
                  <Col lg={3} md={6} className={`col-6 ${styles['product-plan-top-right-card']}`}>
                    <ProvisionSearchDropdown
                      // dropdownLabel="Select Customer Name"
                      formGroupId="customerNameId"
                      placeholder="Enter Customer Name"
                      selectDefaultValue={customerNameDefaultValue}
                      setSelectDefaultValue={setCustomerNameDefaultValue}
                      setOptionId={setCustomerId}
                      selectOptions={customerData}
                      selectOptionsFiltered={customerNameFiltered}
                      setSelectOptionsFiltered={setCustomerNameFiltered}
                      selectSearchValue={customerNameSearchValue}
                      setSelectSearchValue={setCustomerNameSearchValue}
                      updatedOptions={updatedCustomerName}
                      setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                      hasStaticOptions={true}
                      selectCustomerErrorState={selectCustomerErrorState}
                    />
                    {selectCustomerErrorState && <p className='pt-1 mb-0 text-danger'>Note: Please select customer to proceed.</p>}
                  </Col>

                  {/* Button only for Mobile */}
                  <Col className='text-right d-block d-lg-none col-6'>
                    <Button
                      className="ml-3 mr-0 btn-border-light w-md-auto col-xs-12"
                      variant="light"
                      sx={{ mr: 1 }}
                      onClick={() => setDownloadCloudPricingModalFlag(true)}
                    >
                      Download Cloud Pricing
                    </Button>
                  </Col>


                  <Col lg={6} md={7}>
                    {
                      (((isManagementConsoleNavigation || (checkResellerPrereqisiteFlag !== null && checkResellerPrereqisiteFlag === true)) || (isManagementConsoleNavigation || (checkResellerPrereqisiteExpiryFlag === true && checkResellerPrereqisiteExpiryFlag !== null)))) && (checkDomainAvailability !== null) ? <p className='mb-0'>
                        {activeIntegrationCode !== "GCP" ? "Domain Name :" : "Domain Name :"}
                        {(checkDomainAvailability.length > 0 ) ?
                        // {(checkDomainAvailability.length > 0 && !isreadOnly) ?
                          checkDomainAvailability :
                          <>
                            <a className={`cpointer d-none-mobile ${styles['check-domain-link']}`} onClick={(e) => {
                              if (!isReadOnlyPrerequisite && customerNameDefaultValue!=="Select Customer") {
                                if (microsoftServiceIdArray.includes(customerRequirementPopupId)) {
                                  navigate("/cloud-provision/customer/prerequisite/microsoft", {
                                    state: { serviceId: customerRequirementPopupId, customerId: customerId, customerName: customerNameDefaultValue, integrationCode: activeIntegrationCode }
                                  }
                                  );
                                }
                                else if (googleServiceIdArray.includes(customerRequirementPopupId)) {
                                  navigate("/cloud-provision/customer/prerequisite/google", {
                                    state: { serviceId: customerRequirementPopupId, customerId: customerId, customerName: customerNameDefaultValue }
                                  }
                                  );
                                }
                              }
                            }}>
                              {activeIntegrationCode !== "GCP" && "Check Domain Availability"}
                            </a><br />
                            <p className={`mb-0 ${styles['check-domain-error']}`}>
                              {activeIntegrationCode === "GCP" ? "Please complete google workspace prerequisite to proceed" : "Note: Please check the domain availability to proceed."}
                            </p></>
                        }
                        {isTransferGwsInProgress ? <p className={`mb-0 ${styles['check-domain-error']}`}><span className='text-strong text-danger'>Note : </span>Your transfer request is being reviewed. Kindly await completion before provisioning subscriptions.</p> : <span></span>}
                      </p> : ""}
                  </Col>

                  {/* Button only for Desktop */}
                  <Col lg={3} md={12} className='text-right d-none d-lg-block'>
                    <Button
                      className="ml-3 mr-0 btn-border-light w-md-auto col-xs-12"
                      variant="light"
                      sx={{ mr: 1 }}
                      onClick={() => setDownloadCloudPricingModalFlag(true)}
                    >
                      Download Cloud Pricing
                    </Button>
                  </Col>
                </Row>
              </Container>
              {
                currentSubscriptionMicrosoftCspPopup &&
                <CSPCurrentSubscription currentSubscriptionMicrosoftCspPopup={currentSubscriptionMicrosoftCspPopup} setCurrentSubscriptionMicrosoftCspPopup={setCurrentSubscriptionMicrosoftCspPopup} currentSubscriptionProductName={"Microsoft CSP"} customerId={customerId} Logout={props.Logout} />
              }
              {
                currentSubscriptiongwsPopup &&
                <GWSCurrentSubscription currentSubscriptiongwsPopup={currentSubscriptiongwsPopup} setCurrentSubscriptionGwsPopup={setCurrentSubscriptionGwsPopup} currentSubscriptionProductName={"Google Workspace"} customerId={customerId} Logout={props.Logout} />
              }
              {
                currentSubscriptionGcpPopup &&
                <GCPCurrentSubscription currentSubscriptionGcpPopup={currentSubscriptionGcpPopup} setCurrentSubscriptionGcpPopup={setCurrentSubscriptionGcpPopup} currentSubscriptionProductName={"Google Cloud"} customerId={customerId} Logout={props.Logout} />
              }
              {
                currentSubscriptionAzurePopup &&
                <AzureCurrentSubscription currentSubscriptionAzurePopup={currentSubscriptionAzurePopup} setCurrentSubscriptionAzurePopup={setCurrentSubscriptionAzurePopup} currentSubscriptionProductName={"Microsoft CSP - Azure"} customerId={customerId} Logout={props.Logout} />
              }
              <Container fluid className="provision-list-block-outer" id="provision-list-block-outer-div">
                {
                  checkResellerPrereqisiteFlag !== null && checkResellerPrereqisiteFlag === false && !isreadOnly &&
                  <Row className={`mb-3 ${styles['vendor-requirement-plan-outer-card']} d-none-mobile`}>
                    <Col md={8} className={`${styles['vendor-requirement-plan-outer-card-heading']}`}><h3>Vendor requirements</h3><p className='mb-0'>Please add vendor requirements to provision subscriptions.</p></Col>
                    <Col md={4} className={`text-right ${styles['vendor-requirement-plan-outer-card-button']}`}>
                      <Button
                        type="button"
                        className='pt-1 px-3 label-sm'
                        onClick={() => {
                          if (!isReadOnlyPrerequisite) {
                            if (microsoftServiceIdArray.includes(vendorRequirementPopupId)) {
                              navigate("/cloud-provision/reseller/prerequisite/microsoft", {
                                state: { serviceId: vendorRequirementPopupId }
                              }
                              );
                            }
                            else if (googleServiceIdArray.includes(vendorRequirementPopupId)) {
                              navigate("/cloud-provision/reseller/prerequisite/google", {
                                state: { serviceId: vendorRequirementPopupId, integrationCode: activeIntegrationCode }
                              }
                              );
                            }
                          }
                        }}
                      >Proceed
                      </Button>
                    </Col>
                  </Row>
                }
                {
                  checkResellerPrereqisiteFlag !== null && checkResellerPrereqisiteFlag === true && checkResellerPrereqisiteExpiryFlag !== null && checkResellerPrereqisiteExpiryFlag === true && !isreadOnly &&
                  <Row className={`mb-3 ${styles['vendor-requirement-plan-outer-card-expiry']} d-none-mobile`}>
                    <Col md={8} className={`${styles['vendor-requirement-plan-outer-card-heading']}`}><h3>Vendor requirements expired</h3><p className='mb-0'>Please click on proceed to reset MPNID.</p></Col>
                    <Col md={4} className={`text-right ${styles['vendor-requirement-plan-outer-card-button']}`}>
                      <Button
                        type="button"
                        className='pt-1 px-3 label-sm'
                        onClick={() => {
                          if (microsoftServiceIdArray.includes(vendorRequirementPopupId)) {
                            navigate("/profile", {
                              state: { serviceId: vendorRequirementPopupId }
                            }
                            );
                          }
                          else if (googleServiceIdArray.includes(vendorRequirementPopupId)) {
                            navigate("/cloud-provision/reseller/prerequisite/google", {
                              state: { serviceId: vendorRequirementPopupId }
                            }
                            );
                          }

                        }}
                      >Proceed
                      </Button>
                    </Col>
                  </Row>
                }
                {
                  !isreadOnly && !isMCACompleted &&
                  <Row className={`mb-3 ${styles['vendor-requirement-plan-outer-card']} d-none-mobile`}>
                    <Col md={8} className={`${styles['vendor-requirement-plan-outer-card-heading']}`}><h3>MCA agreement</h3><p className='mb-0'>Microsoft Customer agreement (MCA) is not submitted, please click on proceed to submit</p></Col>
                    <Col md={4} className={`text-right ${styles['vendor-requirement-plan-outer-card-button']}`}>
                      <Button
                        type="button"
                        className='pt-1 px-3 label-sm'
                        onClick={() => {
                          if (!isReadOnlyPrerequisite && customerNameDefaultValue!=="Select Customer") {
                            if (microsoftServiceIdArray.includes(customerRequirementPopupId)) {
                              navigate("/cloud-provision/customer/prerequisite/microsoft", {
                                state: { serviceId: customerRequirementPopupId, customerId: customerId, customerName: customerNameDefaultValue, isMCACompleted: isMCACompleted, domainName: checkDomainAvailability, isDomainProvisioned: true, integrationCode: activeIntegrationCode }
                              })
                            }
                          }
                        }
                        }
                      >Proceed
                      </Button>
                    </Col>
                  </Row>
                }
                {
                  productPlan &&
                  <Accordion
                    activeKey={productPlan.length === 1 ? productPlan[0].productId : activeCustomerId}
                  >
                    {
                      productPlan.map((subProduct) => {
                        return (
                          <>
                            <Row className={`mb-3 ${styles['product-plan-outer-card']}`}>
                              <Col lg={2} md={4} xs={5}>
                                <img
                                  className={`${styles['img-bg']}`}
                                  src={
                                    subProduct.integrationCode == "googleworkspace" ? (
                                      gwsProductImage
                                    ) : subProduct.integrationCode == "GCP" ? (
                                      gcpProductImage
                                    ) : subProduct.integrationCode == "microsoftazure" ? (
                                      azureProductImage
                                    ) : subProduct.integrationCode == "cloudcare" ? (
                                      avastProductImage
                                    ) : subProduct.integrationCode == "microsoftsaas" ? (
                                      cspProductImage
                                    ) : subProduct.integrationCode == "softlayer" ? (
                                      ibmProductImage
                                    ) : acronisProductImage
                                  } />
                              </Col>

                              <Col lg={4} md={4} xs={5}>
                                <span className={`${styles['card-product-name']}`}>{subProduct.name}</span>
                              </Col>

                              <Col lg={3} md={2} className={`${styles['trail-featured-button']}`}>
                                {subProduct.isFeatured && <Button className={`${styles['tags-product-listing-featured']}`}>Featured</Button>}
                                {subProduct.hasFreeTrial && <Button className={`${styles['tags-product-listing-trial']}`}>Trial Available</Button>}
                              </Col>

                              {/* Confgure Button for Tab and Mobile Only */}
                              <Col md={2} xs={2} className={`text-right d-block d-lg-none ${styles['card-product-name']}`}>
                                <CustomToggle className='text-left text-center' eventKey={subProduct.productId}
                                  onClick={() => {
                                    // setProductDetailIntegrationCode(subProduct.integrationCode);
                                    // activeCustomerId = subProduct.productId;
                                  }}></CustomToggle>
                              </Col>


                              <Col lg={2} md={4} className={`${styles['trail-featured-button']}`}>
                                {currentSubscriptionArrayList.includes(subProduct.productId) && <a className={`cpointer ${styles['current-subscription-tag']}`} onClick={() => { CurrentSubscriptionClick(subProduct.integrationCode, subProduct.name); }}>Current Subscription</a>}
                              </Col>

                              {/* Confgure Button for Desktop Only */}
                              <Col lg={1} md={12} className='text-center d-none d-lg-block'>
                                <CustomToggle className='text-left text-center' eventKey={subProduct.productId}
                                  onClick={() => {
                                    // setProductDetailIntegrationCode(subProduct.integrationCode);
                                    // activeCustomerId = subProduct.productId;
                                  }}></CustomToggle>
                              </Col>
                              {/* data shown when configure button is clicked while expanding */}
                              <Accordion.Collapse eventKey={subProduct.productId} className='px-0'>
                                <Card.Body>
                                  <div>
                                    {activeCustomerIdUseState === subProduct.productId && (subProduct.integrationCode == "googleworkspace" && subProduct.name == "Google Workspace for Education") &&
                                      <GoogleWorkspaceForEducationProductDetail productId={subProduct.productId} Logout={props.Logout} />
                                    }
                                    {(activeCustomerIdUseState === subProduct.productId || serviceId === subProduct.serviceId) && (subProduct.integrationCode == "googleworkspace" && subProduct.name == "Google Workspace") &&
                                      <GoogleworkspaceProductDetail productId={subProduct.productId} serviceId={subProduct.serviceId} domainName={checkDomainAvailability} customerId={customerId} setSelectCustomerErrorState={setSelectCustomerErrorState} proceedToProvisionFlag={proceedToProvisionFlag}
                                        billingPaymentGatewayData={billingPaymentGatewayData} setBillingPaymentGatewayErrorMessage={setBillingPaymentGatewayErrorMessage} setBillingPaymentGatewayData={setBillingPaymentGatewayData} billingPaymentDirecrDebitExpiryFlag={billingPaymentDirecrDebitExpiryFlag} billingPaymentCardExpiryFlag={billingPaymentCardExpiryFlag} setBillingPaymentGatewayErrorPopup={setBillingPaymentGatewayErrorPopup}
                                        checkResellerPrereqisiteFlag={checkResellerPrereqisiteFlag} setPlanSelectedFlag={setPlanSelectedFlag} customerName={customerNameDefaultValue} isreadOnly={isreadOnly} setToastError={setToastError} setToastMessage={setToastMessage} isTransferGwsInProgress={isTransferGwsInProgress} Logout={props.Logout} />
                                    }
                                    {activeCustomerIdUseState === subProduct.productId && subProduct.integrationCode == "microsoftsaas" &&
                                      <CSPProductDetail productId={subProduct.productId} serviceId={subProduct.serviceId} isNce={subProduct.isNcePlan} categoryIdCsp={subProduct.preferredCategory} isPrerpectual={subProduct.isPerpetualSoftware} proceedToProvisionFlag={proceedToProvisionFlag}
                                        billingPaymentGatewayData={billingPaymentGatewayData} setBillingPaymentGatewayErrorMessage={setBillingPaymentGatewayErrorMessage} setBillingPaymentGatewayData={setBillingPaymentGatewayData} billingPaymentDirecrDebitExpiryFlag={billingPaymentDirecrDebitExpiryFlag} billingPaymentCardExpiryFlag={billingPaymentCardExpiryFlag} setBillingPaymentGatewayErrorPopup={setBillingPaymentGatewayErrorPopup}
                                        customerId={customerId} setSelectCustomerErrorState={setSelectCustomerErrorState} customerPrerequisite={checkDomainAvailability} checkResellerPrereqisiteFlag={checkResellerPrereqisiteFlag}
                                        checkResellerPrereqisiteExpiryFlag={checkResellerPrereqisiteExpiryFlag} setPlanSelectedFlag={setPlanSelectedFlag} customerName={customerNameDefaultValue} setToastMessage={setToastMessage} setToastError={setToastError} isreadOnly={isreadOnly} isMCACompleted={isMCACompleted} Logout={props.Logout} />
                                    }
                                    {(activeCustomerIdUseState === subProduct.productId || serviceId === subProduct.serviceId) && subProduct.integrationCode == "microsoftazure" &&
                                      <AzureProductDetail productId={subProduct.productId} serviceId={subProduct.serviceId} customerName={customerNameDefaultValue} customerId={customerId} setSelectCustomerErrorState={setSelectCustomerErrorState} proceedToProvisionFlag={proceedToProvisionFlag}
                                        billingPaymentGatewayData={billingPaymentGatewayData} setBillingPaymentGatewayErrorMessage={setBillingPaymentGatewayErrorMessage} setBillingPaymentGatewayData={setBillingPaymentGatewayData} billingPaymentDirecrDebitExpiryFlag={billingPaymentDirecrDebitExpiryFlag} billingPaymentCardExpiryFlag={billingPaymentCardExpiryFlag} setBillingPaymentGatewayErrorPopup={setBillingPaymentGatewayErrorPopup}
                                        setToastError={setToastError} setToastMessage={setToastMessage} domainName={checkDomainAvailability} checkResellerPrereqisiteFlag={checkResellerPrereqisiteFlag} checkResellerPrereqisiteExpiryFlag={checkResellerPrereqisiteExpiryFlag}
                                        setPlanSelectedFlag={setPlanSelectedFlag} isreadOnly={isreadOnly} Logout={props.Logout} />
                                    }
                                    {(activeCustomerIdUseState === subProduct.productId || serviceId === subProduct.serviceId) && (subProduct.integrationCode == "GCP" && subProduct.name === "Google Cloud Platform") &&
                                      <GCPProductDetail productId={subProduct.productId} serviceId={subProduct.serviceId} customerName={customerNameDefaultValue} customerId={customerId} setSelectCustomerErrorState={setSelectCustomerErrorState} proceedToProvisionFlag={proceedToProvisionFlag}
                                        billingPaymentGatewayData={billingPaymentGatewayData} setBillingPaymentGatewayErrorMessage={setBillingPaymentGatewayErrorMessage} setBillingPaymentGatewayData={setBillingPaymentGatewayData} billingPaymentDirecrDebitExpiryFlag={billingPaymentDirecrDebitExpiryFlag} billingPaymentCardExpiryFlag={billingPaymentCardExpiryFlag} setBillingPaymentGatewayErrorPopup={setBillingPaymentGatewayErrorPopup}
                                        setToastError={setToastError} setToastMessage={setToastMessage} domainName={checkDomainAvailability} checkResellerPrereqisiteFlag={checkResellerPrereqisiteFlag} setPlanSelectedFlag={setPlanSelectedFlag} isreadOnly={isreadOnly} Logout={props.Logout} />
                                    }
                                    {(activeCustomerIdUseState === subProduct.productId || serviceId === subProduct.serviceId) && subProduct.integrationCode == "softlayer" &&
                                      <IBMProductDetail productId={subProduct.productId} customerName={customerNameDefaultValue} customerId={customerId} setSelectCustomerErrorState={setSelectCustomerErrorState} proceedToProvisionFlag={proceedToProvisionFlag}
                                        billingPaymentGatewayData={billingPaymentGatewayData} setBillingPaymentGatewayErrorMessage={setBillingPaymentGatewayErrorMessage} setBillingPaymentGatewayData={setBillingPaymentGatewayData} billingPaymentDirecrDebitExpiryFlag={billingPaymentDirecrDebitExpiryFlag} billingPaymentCardExpiryFlag={billingPaymentCardExpiryFlag} setBillingPaymentGatewayErrorPopup={setBillingPaymentGatewayErrorPopup}
                                        setToastError={setToastError} setToastMessage={setToastMessage} setPlanSelectedFlag={setPlanSelectedFlag} isreadOnly={isreadOnly} Logout={props.Logout} />
                                    }
                                    {(activeCustomerIdUseState === subProduct.productId || serviceId === subProduct.serviceId) && subProduct.integrationCode == "acronis1" &&
                                      <AcronisProductDetail productId={subProduct.productId} serviceId={subProduct.serviceId} setToastError={setToastError} setToastSuccess={setToastSuccess} setToastMessage={setToastMessage} setPlanSelectedFlag={setPlanSelectedFlag} isreadOnly={isreadOnly}
                                        billingPaymentGatewayData={billingPaymentGatewayData} setBillingPaymentGatewayErrorMessage={setBillingPaymentGatewayErrorMessage} setBillingPaymentGatewayData={setBillingPaymentGatewayData} billingPaymentDirecrDebitExpiryFlag={billingPaymentDirecrDebitExpiryFlag} billingPaymentCardExpiryFlag={billingPaymentCardExpiryFlag} setBillingPaymentGatewayErrorPopup={setBillingPaymentGatewayErrorPopup} Logout={props.Logout} />
                                    }
                                    {activeCustomerIdUseState === subProduct.productId && (subProduct.integrationCode == "cloudcare" && (subProduct.name == "Commercial Offers" || subProduct.name == "Government Offers" || subProduct.name == "Academic Offers")) &&
                                      <AvastProductDetail productId={subProduct.productId} serviceId={subProduct.serviceId} subProduct={subProduct.name} setToastError={setToastError} setToastSuccess={setToastSuccess} setToastMessage={setToastMessage} setSelectCustomerErrorState={setSelectCustomerErrorState} customerId={customerId} setPlanSelectedFlag={setPlanSelectedFlag} isreadOnly={isreadOnly} Logout={props.Logout} />
                                    }
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Row>
                          </>
                        )
                      })
                    }
                  </Accordion>
                }
              </Container>
            </Col>
          </Row>
        </div>}
        {!(productPlansArray && serviceData) &&
          <div className={styles["main-loading-component"]}>
            <img className="management-console-loading-icon" src={loadingGif} />
            <p className="text-medium text-strong management-console-loading-text">Loading...</p>
          </div>
        }
      </Container>}
      {
        isDisabled && <AccessDenied />
      }
    </>
  )
}

export default CloudProvision