import { Col, Container, Form, NavDropdown, Row } from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import styles from './summary.module.scss'
import { DASHBOARD_CUSTOMERS, DASHBOARD_SEATS_SUMMARY } from "../../../../../../../Utils/GlobalConstants";
import { api } from "../../../../../../../Utils/API";
import Popup from "reactjs-popup";
import { XLg } from "react-bootstrap-icons";
import Toast from "../../../../../../../GlobalComponents/Toast";

const Summary = ({summaryPopup,setSummaryPopup,Logout}) => {
  const [data, setData] = useState(null)                   // for storing piechart data from api

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };


  // Function to call all Customer count data
  async function GetSubscriptions() {
    await api.get(`${DASHBOARD_SEATS_SUMMARY}`, config)
      .then(resp => {
        setData(resp.data);      
      })
      .catch(error => {
        // console.log("Error : ", error);
        if (error?.status == 401 || error?.response.status == 401) {
          // console.log("Error 401");
          Logout();
      }
        else {
        }
      });
  };

  //useEffect for calling get api on initial load
  useEffect(() => {
    GetSubscriptions();
  }, [])

  return (
    <div>
      <Popup
        className="custom-modal custom-modal-xxl"
        open={summaryPopup}
        onClose={() => { setSummaryPopup(false); }}
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header p-4 py-3">
              <h2 className="mb-0 px-3">Seat Summary</h2>
              <XLg
                size={18}
                className="cpointer text-strong close-icon-popup-sm"
                onClick={close}
                color="#6A6A6A"
              />
            </div>
            <>
              <div className='my-4 mx-8 height-dashboard-charts'>

                <Container fluid>
                  <Container fluid>
                  {data && <div className={`mt-2 ${styles["seats-summary-div"]}`}>
                    {
                      data.map((data) => {
                        return (
                          <Row className={` ${styles["seats-summary-card"]} py-2 my-4 `}>
                            <span className="text-strong my-2">{data.serviceName}</span>
                            <Col>
                              <h1 className={`${data.isIncrease ? "text-success" : "text-danger"} notes-confirmation`}><span className={data.isIncrease ? "dashboard-green-arrow mb-1" : "dashboard-red-arrow mb-1"}></span> {data.percentage}%</h1>
                            </Col>
                            <Col className="text-right my-1">
                              <span>{data.seats} Seats</span>
                            </Col>
                          </Row>
                        )

                      })
                    }
                  </div>}
                  </Container>
                </Container>
              </div>

            </>
          </div>)}
      </Popup>
    </div>
  )
}

export default Summary
