import { Accordion, Button, Col, Container, Form, Row } from 'react-bootstrap';
import styles from './ncesync.module.scss';
import { useEffect, useState } from 'react';

const NCESync = (
    { nceData,
        setNCEData,
        setNCEPostBody,
        setIsNCEDataChanged,
        isNCEDataChanged,
        nceInitailPostValues,
        errorStateNCE,
        setIsNCEDataFilled,
        isReset,
        setIsReset,
        syncData }) => {

    // Get Additional Properties On Initial Loading 
    const [additionalProperties, setAdditionalProperties] = useState(nceData.find(setting => setting.id === "Nce_AdditionProperties").data
        .filter(data => data.selected)
        .map(data => data.id).sort((a, b) => a - b).join(",") == "" ? '0' : nceData.find(setting => setting.id === "Nce_AdditionProperties").data
            .filter(data => data.selected)
            .map(data => data.id).sort((a, b) => a - b).join(","));

    // console.log("additionalProperties", additionalProperties);

    // Default NCE Post Values. These will be updated on change
    const [ncePostValues, setNCEPostValues] = useState({
        nce_Renewal: 0,
        nce_CancelSubscription: 0,
        nce_AdditionProperties: additionalProperties == "" ? '0' : additionalProperties,
        nce_SuspendSubscription: 0,
        nce_FullUpgradeSubscription: 0,
        nce_PartialUpgradeSubscription: 0,
        nce_ChangeQuantitySubscription: 0
    });

    nceInitailPostValues = ncePostValues;   // Set Initial Post Values for Comparison

    const [nceResetValues, setNCEResetValues] = useState({
        nce_Renewal: 0,
        nce_CancelSubscription: 0,
        nce_AdditionProperties: additionalProperties,
        nce_SuspendSubscription: 0,
        nce_FullUpgradeSubscription: 0,
        nce_PartialUpgradeSubscription: 0,
        nce_ChangeQuantitySubscription: 0
    });

    // Get Values from API and Loop through each bellow object in the form
    const onRenewal = nceData.length > 0 ? nceData.find(item => item.id === "Nce_Renewal") : null;
    const cancelSubscription = nceData.length > 0 ? nceData.find(item => item.id === "Nce_CancelSubscription") : null;
    const suspendSubscription = nceData.length > 0 ? nceData.find(item => item.id === "Nce_SuspendSubscription") : null;
    const subscriptionFullUpgrade = nceData.length > 0 ? nceData.find(item => item.id === "Nce_FullUpgradeSubscription") : null;
    const subscriptionPartialUpgrade = nceData.length > 0 ? nceData.find(item => item.id === "Nce_PartialUpgradeSubscription") : null;
    const subscriptionChangeQuantity = nceData.length > 0 ? nceData.find(item => item.id === "Nce_ChangeQuantitySubscription") : null;
    const additionPropertiesData = nceData.length > 0 ? nceData.find(item => item.id === "Nce_AdditionProperties") : null;

    // Function to Get Initial Values while loading
    const updateNceRenewalState = (dataId, postBodyId) => {
        const nceUpdatedData = nceData.find(item => item.id === dataId);
        if (nceUpdatedData) {
            const selectedOption = nceUpdatedData.data.find(option => option.selected);

            if (selectedOption) {
                setNCEPostValues(prevState => ({
                    ...prevState,
                    [postBodyId]: selectedOption.id
                }));

                setNCEResetValues(prevState => ({
                    ...prevState,
                    [postBodyId]: selectedOption.id
                }));

            }
        }

    };

    // Call this on mount component
    useEffect(() => {
        updateNceRenewalState("Nce_Renewal", "nce_Renewal");
        updateNceRenewalState("Nce_CancelSubscription", "nce_CancelSubscription");
        updateNceRenewalState("Nce_SuspendSubscription", "nce_SuspendSubscription");
        updateNceRenewalState("Nce_FullUpgradeSubscription", "nce_FullUpgradeSubscription");
        updateNceRenewalState("Nce_PartialUpgradeSubscription", "nce_PartialUpgradeSubscription");
        updateNceRenewalState("Nce_ChangeQuantitySubscription", "nce_ChangeQuantitySubscription");
    }, []);


useEffect(() => {
    nceInitailPostValues = ncePostValues;   // Set Initial Post Values After Post Success
}, [syncData])

    // Get the values of Radio elements
    const handleRadioClick = (e, nceOption) => {

        setNCEPostValues(prevState => ({
            ...prevState,
            [nceOption]: parseInt(e.target.value)
        }));
    };

    // Get the values of Check Box Elements Additional Properties
    const handleCheckBox = (e, nceOption) => {
        const { checked } = e.target;

        if (checked) {
            // If checkbox is checked, add the value to the array
            setAdditionalProperties(prevState => prevState + (prevState ? ',' : '') + e.target.value);
        } else {
            // If checkbox is unchecked, remove the value from the array
            setAdditionalProperties(prevState => prevState.split(',').filter(item => item != e.target.value).join(','));
        }

        setNCEPostValues(prevState => ({
            ...prevState,
            [nceOption]: additionalProperties
        }));
    };

    // Updates NCE Values whenver we change Addition Properties
    useEffect(() => {

        let additionalPropertiesArr = additionalProperties.split(',').map(Number).sort((a, b) => a - b).join(",").toString();

        setNCEPostValues(prevState => ({
            ...prevState,
            nce_AdditionProperties: additionalPropertiesArr
        }));
    }, [additionalProperties]);

    useEffect(() => {
        setNCEPostBody(ncePostValues);
        setIsNCEDataChanged(!isNCEDataChanged);
        if (additionalProperties == "0") {
            setAdditionalProperties("");
        }
    }, [ncePostValues, additionalProperties]);

    useEffect(() => {
        if (ncePostValues.nce_Renewal != "0" && ncePostValues.nce_PartialUpgradeSubscription != "0" && ncePostValues.nce_ChangeQuantitySubscription != "0") {
            setIsNCEDataFilled(true);
        } else {
            setIsNCEDataFilled(false);
        }

    }, [ncePostValues]);

    useEffect(() => {
        if (isReset == true) {
            setNCEPostValues(nceResetValues)  // Set Initial Post Values for Comparison
            nceInitailPostValues = ncePostValues;
            setIsReset(false);

        }
    }, [isReset])

    return (
        <>
            <Accordion.Item className={`mt-5 ${errorStateNCE ? "accordionErrors" : ""}`} eventKey="0">
                <Accordion.Header>
                    <h3 className={`${styles['accordion-header-wrapper']}`}>
                        Manage NCE Sync
                    </h3>
                </Accordion.Header>
                <Accordion.Body className='pt-0'>
                    <div className={`${styles['accordion-content']}`}>


                        <div>

                            <Row className="mb-3">
                                <Col md={3}>
                                    <span>On Renewal <span className='red'>*</span>:</span>
                                </Col>

                                <Col md={9}>
                                    <Row>
                                        {   // Renewal Settings
                                            onRenewal.data.map(item => (
                                                <Col md={4} key={item.id}>
                                                    <Form.Check
                                                        type="radio"
                                                        id={`${onRenewal.id}_${item.id}`}
                                                        label={item.name}
                                                        name="nceRenewalGroup"
                                                        value={item.id}
                                                        onClick={(e) => handleRadioClick(e, "nce_Renewal")}
                                                        defaultChecked={item.selected}
                                                    />
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <span>Cancel Subscription <span className='red'>*</span>:</span>
                                </Col>

                                <Col md={9}>
                                    <Row>
                                        {   // Cancel Subscription
                                            cancelSubscription.data.map(item => (
                                                <Col md={6} key={item.id}>
                                                    <span>{item.name}</span>
                                                </Col>
                                            ))
                                        }

                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <span>Suspend Subscription <span className='red'>*</span>:</span>
                                </Col>
                                <Col md={9}>
                                    <Row>
                                        {   // Suspend Subscription
                                            suspendSubscription.data.map(item => (
                                                <Col md={6} key={item.id}>
                                                    <span>{item.name}</span>
                                                </Col>
                                            ))
                                        }

                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <span>Subscription Full Upgrade <span className='red'>*</span>:</span>
                                </Col>
                                <Col md={9}>
                                    <Row>
                                        {   // Subscription Full Upgrade
                                            subscriptionFullUpgrade.data.map(item => (
                                                <Col md={6} key={item.id}>
                                                    <span>{item.name}</span>
                                                </Col>
                                            ))
                                        }

                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <span>Subscription Partial Upgrade <span className='red'>*</span>:</span>
                                </Col>
                                <Col md={9}>
                                    <Row>
                                        {   // Renewal Settings
                                            subscriptionPartialUpgrade.data.map(item => (
                                                <Col md={4} key={item.id}>
                                                    <Form.Check
                                                        type="radio"
                                                        id={`${subscriptionPartialUpgrade.id}_${item.id}`}
                                                        label={item.name}
                                                        name="nceSubscriptionPartialUpgrade"
                                                        value={item.id}
                                                        defaultChecked={item.selected}
                                                        onClick={(e) => handleRadioClick(e, "nce_PartialUpgradeSubscription")}
                                                    />
                                                </Col>
                                            ))
                                        }

                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <span>Subscription Quantity Change <span className='red'>*</span>:</span>
                                </Col>
                                <Col md={9}>
                                    <Row>
                                        {   // Renewal Settings
                                            subscriptionChangeQuantity.data.map(item => (
                                                <Col md={4} key={item.id}>
                                                    <Form.Check
                                                        type="radio"
                                                        id={`${subscriptionChangeQuantity.id}_${item.id}`}
                                                        label={item.name}
                                                        name="nceSubscriptionQuantityChange"
                                                        value={item.id}
                                                        defaultChecked={item.selected}
                                                        onClick={(e) => handleRadioClick(e, "nce_ChangeQuantitySubscription")}
                                                    />
                                                </Col>
                                            ))
                                        }

                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <span>Addition Properties:</span>
                                </Col>
                                <Col md={9}>
                                    <Row>
                                        {   // Renewal Settings
                                            additionPropertiesData.data.map(item => (
                                                <Col md={4} key={item.id}>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={`${additionPropertiesData.id}_${item.id}`}
                                                        label={item.name}
                                                        value={item.id}
                                                        defaultChecked={item.selected}
                                                        onClick={(e) => handleCheckBox(e, "nce_AdditionProperties")}
                                                    />
                                                </Col>
                                            ))
                                        }

                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </>
    );
}

export default NCESync;