import React from 'react'
import styles from './vendor.module.scss'
import vendorPortfolioDesktop from '../../../../../Assets/Images/Illustrations/Landing Page/Background-Image/png/vendor-logos-desktop.png';
import { Container } from 'react-bootstrap';
function VendorPortfolio({landingPageData,globalData}) {
  return (
    <Container fluid className={`${styles.vendorPortfolio}`}>
        <h2 className={styles.vendorHeading}>{landingPageData && landingPageData.ourVendors.contentItems[0].title}</h2>
        <h1 className={`${styles.vendorSubHeading} pb-5`}>{landingPageData && landingPageData.ourVendors.contentItems[0].description}</h1>
        {landingPageData && globalData && <picture>
            <source media="(min-width: 992px)" srcSet={globalData.baseUrl +  landingPageData.ourVendors.contentItems[0].desktopImage.urls} />
            <source media="(min-width: 768px)" srcSet={globalData.baseUrl +  landingPageData.ourVendors.contentItems[0].tabletImage.urls} />
            <source media="(max-width: 767px)" srcSet={globalData.baseUrl +  landingPageData.ourVendors.contentItems[0].mobileImage.urls} />
            <img src={vendorPortfolioDesktop} alt='vendor portfolio' className={styles.vendorImage} />
        </picture>}
    </Container>
  )
}

export default VendorPortfolio