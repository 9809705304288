import React, { useEffect, useState } from 'react'
import { Button, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { GCP_SUBSCRIPTION_TABLE, GET_CSP_SUBSCRIPTION_TABLE } from '../../../../../../Utils/GlobalConstants';
import styles from './GCPCurrentSubscription.module.scss'
import DataTable from 'react-data-table-component';
import { api } from '../../../../../../Utils/API';
import Popup from 'reactjs-popup';
import { XLg } from 'react-bootstrap-icons';
import EmptyDataTableFilterSearch from '../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg';
import EmptyServiceSubscription from '../../../../../../Assets/Images/Illustrations/EmptyServiceSubscription.svg'

const GCPCurrentSubscription = ({ currentSubscriptionGcpPopup, setCurrentSubscriptionGcpPopup, currentSubscriptionProductName, customerId, Logout }) => {
  const [pageNo, setPageNo] = useState(1);                                        // table active page number
  const [pageSize, setPageSize] = useState(5);                                    // table active page size
  const [totalRows, setTotalRows] = useState(0);                                  // table total count of data from api 
  const [table, setTable] = useState([]);                                         // data state used to display data in react data table component
  let ColumnName = ["Plan", "Sub-account Name", "Sub-account ID",
    "Linked Projects", "Billing Term", "Status"];                // table headers used to make object of react data table component 
  const [serverErrorUser, setServerErrorUser] = useState(false);                   // server error check state in table during api call to load data
  const [emptyUser, setEmptyUser] = useState(false);                               // empty table check state in table during api call to load data
  const [isDescStartDate, setIsDescStartDate] = useState(true);                    // sort state check for start date column
  const [isDescRenewalDate, setIsDescRenewalDate] = useState(null);                // sort state check for renewal date column
  const [sortCol, setSortCol] = useState("sortingStartDate");                             // getTableData() sorting column name
  const [sortDir, setSortDir] = useState("DESC");                                  // getTableData() sorting direction ie. "DESC" for descending and "ASC" for ascending
  const [loading, setLoading] = useState(false);                                   // loading state while table loads
  const [billingTerm, setBillingTerm] = useState(null);                           // getTableData() billing term
  const [planType, setPlanType] = useState(null);                                 // getTableData() plan type

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  let d = [];
  ColumnName.map((td) => {
    if (td === "Status" || td === "Linked Projects") {
      d.push({
        id: `${td}`,
        name: <div className='text-center'>{td}</div>,
        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
        center: true,
        allowOverflow: true,
      })
    }
    else if (td === "Plan") {
      d.push({
        id: `${td}`,
        name: <div>{td}</div>,
        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
        left: true,
        grow: 2,
        allowOverflow: true,

      })
    }
    else if (td === "Sub-account Name") {
      d.push({
        id: `${td}`,
        name: <div>{td}</div>,
        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
        left: true,
        grow: 3,
        allowOverflow: true,
      })
    }
    else if (td === "Billing Term") {
      d.push({
        id: `${td}`,
        name: <div>{td}</div>,
        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
        left: true,
        grow: 1,
        allowOverflow: true,
      })
    }
    else if (td === "Sub-account ID") {
      d.push({
        id: `${td}`,
        name: <div>{td}</div>,
        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
        left: true,
        grow: 1.5,
        allowOverflow: true,
      })
    }
    else {
      d.push({
        id: `${td}`,
        name: <div>{td}</div>,
        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
        left: true,
      })
    }

  })

  // Get Table Data with Pagination and Filter and Search Functionality
  async function GetTableData(page, newPerPage, sortCol, sortDir, billingTerm, planType) {
    setLoading(true);
    (billingTerm === undefined || billingTerm === null) ? billingTerm = "" : billingTerm = billingTerm;
    (planType === undefined || planType === null) ? planType = "" : planType = planType;
    await api.get(`${GCP_SUBSCRIPTION_TABLE}?pageNo=${page}&pageSize=${newPerPage}&customerId=${customerId}&sortCol=${sortCol}&sortDir=${sortDir}&billingTerm=${billingTerm}&planType=${planType}`, config)
      .then(resp => {
        setLoading(false);
        // console.log("resp", resp.data);
        // console.log(customerId);
        if (resp.status == 204) {
          setEmptyUser(true);
        }
        // handle success
        else {
          setServerErrorUser(false);
          setEmptyUser(false);
          let f = [];
          setTotalRows(resp.data.totalCount);
          (resp.data.content).map((td) => {
            f.push
              (
                {
                  "Plan": td.planName,
                  "Sub-account Name": td.subAccountName,
                  "Sub-account ID": ((td.vendorSubscriptionId).replace(/^billingAccounts\//i, '')),
                  "Linked Projects": <a target='_blank' href={`https://console.cloud.google.com/billing/${td.vendorSubscriptionId}/manage`}><span className={styles["gcp-color-underline"]}>{td.linkedProjects}</span></a>,
                  "Billing Term": "Pay As You Go",
                  "Status": td.status === 'ACTIVE' ? (
                    <div className={`${styles['status-gws']}`}>
                      <span className="status-active"></span>
                      <div className="py-1 text-muted text-small">Active</div>
                    </div>
                  ) : td.status === 'CANCELLED' ? (
                    <div className={`${styles['status-gws']}`}>
                      <span className="status-suspended"></span>
                      <div className="py-1 text-muted text-small">Cancelled</div>
                    </div>
                  ) : td.status === 'PENDING' ? (
                    <div className={`${styles['status-gws']}`}>
                      <span className="status-pending"></span>
                      <div className="py-1 text-muted text-small">Pending</div>
                    </div>
                  ) : td.status === 'ERROR' ? (
                    <div className={`${styles['status-gws']}`}>
                      <span className="status-failed"></span>
                      <div className="py-1 text-muted text-small">Error</div>
                    </div>
                  ) : td.status === 'DELETED' ? (
                    <div className={`${styles['status-gws']}`}>
                      <span className="status-suspended"></span>
                      <div className="py-1 text-muted text-small">Deleted</div>
                    </div>
                  ) : td.status === 'SUSPENDED' ? (
                    <div className={`${styles['status-gws']}`}>
                      <span className="status-suspended"></span>
                      <div className="py-1 text-muted text-small">Suspended</div>
                    </div>
                  ) : td.status === 'PENDING_USER_ACTION' ? (
                    <div className={`${styles['status-gws']}`}>
                      <span className="status-suspended"></span>
                      <div className="py-1 text-muted text-small">Pending User Action</div>
                    </div>
                  ) : (
                    td.status
                  ),
                }
              );
          })
          // console.log("Test F", f);
          setTimeout(() => {
            setTable(f);         // Setting Table Data
          }, 50);

          // setColumns(d);     // Setting Column Data
          // console.log("Reached");
          // console.log(d);
        }
      })
      .catch(error => {
        // console.log(error)
        setLoading(false);
        if (error?.status == 401 || error?.response.status == 401) {
          Logout();
        }
        else {
          setServerErrorUser(true);
          // console.log(error);
        }

      });
  };

  // Function Triggers when Page Number is Changed by Customer
  const handlePageChange = (pageNo) => {
    GetTableData(pageNo, pageSize, sortCol, sortDir, billingTerm, planType);
    setPageNo(pageNo);
  };
  // Function Triggers when Rows Per Page is Changed by Customer
  const handlePerRowsChange = async (newPerPage, page) => {
    setPageNo(page);
    setPageSize(newPerPage);
    GetTableData(page, newPerPage, sortCol, sortDir, billingTerm, planType);
  };

  // React Data Table Custom Styles
  const customStyles = {
    //for adding background in row-first child
    headRow: {
      style: {
        backgroundColor: '#fafafa'
      },
    },
    rows: {
      style: {
        minHeight: '60px', // override the row height
      },
    },
    cells: {
      style: {
        paddingLeft: '1rem', // override the cell padding for data cells
        paddingRight: '1rem',
        // textAlign: 'center',
        fontSize: '0.9rem',
        fontWeight: '400',
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '1rem', // override the cell padding for head cells
        paddingRight: '1rem',
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: '#fafafa',
        fontWeight: '500',
        fontSize: '0.875rem',
        color: '#6A6A6A !important',
        textTransform: 'capitalize',
        // textAlign: 'center',
      },
    },

  };

  // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
  useEffect(() => {
    GetTableData(pageNo, pageSize, sortCol, sortDir, billingTerm, planType);
  }, [isDescRenewalDate, isDescStartDate]);


  return (
    <Popup
      className="custom-modal custom-modal-xxl"
      open={currentSubscriptionGcpPopup}
      onClose={() => { setCurrentSubscriptionGcpPopup(false) }}
      modal
      nested
    >
      {(close) => (
        <div className="modal">
          <div className="header">
            <h2>Current Subscription - {currentSubscriptionProductName}</h2>
            <XLg
              size={18}
              className="cpointer text-strong"
              onClick={() => { close(); }}
              color="#6A6A6A"
            />
          </div>
          <Container fluid className='mt-5'>
            {serverErrorUser && !loading &&
              <div className={styles["no-data-user"]}>
                <img src={EmptyDataTableFilterSearch} className="empty-user-management-table-svg" />
                <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
              </div>
            }
            {emptyUser && !loading &&
              <div className={styles["no-data-user"]}>
                <img src={EmptyServiceSubscription} className="empty-customers-table-svg" />
                <h2 className="mt-4 mb-3">No Subscriptions in this Cloud Service.</h2>
                <p className="mb-3">Please click on Buy Subscriptions to Add Subscriptions</p>
                <Button className="btn btn-lg btn-primary px-3">Buy Subscription</Button>
              </div>
            }
            {!serverErrorUser && !emptyUser &&
              <div className={`table-responsive ${styles['csp-service-table']}`}>
                <DataTable
                  columns={d}
                  data={table}
                  theme="solarized"
                  customStyles={customStyles}
                  // striped={true}
                  noDataComponent={<div className={styles["loading-subscriptions"]}><p><b>Loading Subscription Details . . .</b></p></div>}
                  width="100%"
                  fixedHeaderScrollHeight="60vh"
                  progressPending={loading}
                  progressComponent={<div className={styles["loading-subscriptions"]}><p><b>Loading Subscription Details . . .</b></p></div>}
                  fixedHeader
                  pagination
                  persistTableHead={false}
                  paginationServer
                  paginationTotalRows={totalRows}
                  paginationPerPage={pageSize}
                  paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                  onChangePage={(pageNo) => handlePageChange(pageNo, totalRows)}
                  onChangeRowsPerPage={handlePerRowsChange}
                // paginationResetDefaultPage={resetPaginationToggle}
                />
              </div>}
          </Container>
        </div>
      )}
    </Popup>
  )
}

export default GCPCurrentSubscription

