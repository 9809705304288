import React, { useEffect, useState } from 'react'
import { api } from '../../../../../../Utils/API';
import { ACRONIS_PRODUCT_PLANS, ACRONIS_PLANS_TYPES, ACRONIS_PRICING_PLANS, ACRONIS_DOWNLOAD_PRICELIST, ACRONIS_EXISTING_ACCOUNT_CHECK, PROFILE_DETAILS, GET_RESELLER_DATA, POST_ACRONIS_PROVISIONING, ACCEPT_LOGIN_TERMS_AND_CONDITIONS } from '../../../../../../Utils/GlobalConstants';
import { Button, Col, Container, Form, InputGroup, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import styles from './AcronisProductDetail.module.scss';
import EmptyDataTableFilterSearch from '../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import 'reactjs-popup/dist/index.css';
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import SuccessImagePreRequisite from '../../../../../../Assets/Images/Illustrations/SuccessImagePreRequisite.svg';
import loadingGif from "../../../../../../Assets/Images/Animations/loading-management-console.gif"
import { useNavigate } from 'react-router-dom';
import { emailRegEx, phoneOnChangeRegex, phoneRegex, websiteURLRegEx } from "../../../../../../GlobalComponents/RegExPatterns";

const AcronisProductDetail = ({ productId, setToastError, setToastMessage, serviceId, setPlanSelectedFlag, isreadOnly,  billingPaymentGatewayData, setBillingPaymentGatewayErrorMessage, setBillingPaymentGatewayData, billingPaymentCardExpiryFlag, billingPaymentDirecrDebitExpiryFlag, setBillingPaymentGatewayErrorPopup, Logout, proceedToProvisionFlag }) => {

    const navigate = useNavigate();                                                 // for navigation to another screen
    const date = new Date();                                                        // for js date time function
    let AcronisProductDetailColumnName = ["Plan", "SKU", "Acronis pricing plans",
        "Plan type", "Category", "RRP (Inc GST)", "Price (Ex GST)"];                // Columns of table 
    const [productDetailsData, setProductDetailsData] = useState(null);             // storing data which can be displayed in table on selecting values in dropdown 
    const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") =="false" ? false : true);                         // when terms and conditions is checked  
    const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);            // loading state for processing button
    const [planTypesData, setPlanTypesData] = useState(null);                       // all the data which is to be displayed in plan types dropdown
    const [pricingPlansData, setPricingPlansData] = useState(null);                 // all the data which is to be displayed in pricing plan data dropdown
    const [planType, setPlanType] = useState(null);                                 // for storing selected plan type which is selected by user
    const [pricingPlan, setPricingPlan] = useState(null);                           // for storing selected pricing plan which is selected by user
    const [pricingPlanName, setPricingPlanName] = useState(null);                   // for storing name of pricing plan

    const [existingDataCheck, setExistingDataCheck] = useState(null);               // to check whether existing acronis plan is returning 204 or 200
    const [isConfirmationClicked, setIsConfirmationClicked] = useState(false);      // when post api returns success so that confirmation message can be displayed
    const [isTrial, setIsTrial] = useState(null);                                   // usestate to check whether trial or paid plan is selected by user
    const [pendingState, setPendingState] = useState(false);                        // pending state message 

    const [serverError, setServerError] = useState(false);                          // to check server error-400
    const [provisionOrderId, setProvisionOrderId] = useState("");                   // provision id to be displayed when order is confirmed.
    const [customerId, setCustomerId] = useState(null);                             // for navigating to service management screen

    //Stepper
    const steps = ['Pricing information', 'Service provider details'];              // all the steps of stepper                                           
    const [activeStep, setActiveStep] = useState(0);                                // active step in stepper

    // States 
    let ausStates = ["WA", "SA", "ACT", "QLD", "TAS", "NSW", "NT", "VIC"];

    // Usestates for storing data in second step of the form
    const [organization, setOrganization] = useState(null);
    const [adminUserName, setAdminUserName] = useState(null);

    const [contactDetailsFirstName, setContactDetailsFirstName] = useState("");
    const [contactDetailsLastName, setContactDetailsLastName] = useState("");
    const [contactDetailsRegisteredEmail, setContactDetailsRegisteredEmail] = useState("");
    const [contactDetailsConfirmationEmail, setContactDetailsConfirmationEmail] = useState("");
    const [contactDetailsWebsiteUrl, setContactDetailsWebsiteUrl] = useState("");
    const [contactDetailsIndustryType, setContactDetailsIndustryType] = useState("");
    const [contactDetailsOrganizationSize, setContactDetailsOrganizationSize] = useState("");
    const [contactDetailsPhoneNumber, setContactDetailsPhoneNumber] = useState("");

    const [addressDetailsAddressLine1, setAddressDetailsAddressLine1] = useState("");
    const [addressDetailsAddressLine2, setAddressDetailsAddressLine2] = useState("");
    const [stateSelected, setStateSelected] = useState(null);

    const [isdisableManage, setIsdisableManage] = useState(localStorage.getItem("disabled_array").includes("Manage") ? true : false);

    // ****************** Front End Validations Starts ****************** //

    // Contact Details Error FE
    const [contactDetailsRegisteredEmailErrorFe, setContactDetailsRegisteredEmailErrorFe] = useState("");
    const [contactDetailsAdminUsernameEmailErrorFe, setContactDetailsAdminUsernameEmailErrorFe] = useState("");
    const [contactDetailsConfirmationEmailErrorFe, setContactDetailsConfirmationEmailErrorFe] = useState("");
    const [contactDetailsWebsiteUrlErrorFe, setContactDetailsWebsiteUrlErrorFe] = useState("");
    const [contactDetailsPhoneNumberErrorFe, setContactDetailsPhoneNumberErrorFe] = useState("");

    // ****************** Front End Validations Ends ****************** //

     
    const [ipAddress, setIpAddress] = useState(null);
    const userAgent = navigator.userAgent;
    const browserName = userAgent.match(/(Chrome|Safari|Firefox|Edge|Opera|Internet Explorer)\//);
    const browserVersion = userAgent.match(/\((.*?)\)/);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download PDF API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/pdf") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download PDF pricing
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "pdf" || fileType === "zip") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(url, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Get api for plan types
    async function GetExistingVerificationCheck() {
        await api.get(`${ACRONIS_EXISTING_ACCOUNT_CHECK}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setExistingDataCheck(false);
                }
                // handle success
                else {
                    if (resp.data.isAcronisOrderInProgress) {
                        setPendingState(true);
                    }
                    else
                        setExistingDataCheck(true);
                    setCustomerId(resp.data.customerCode);
                    // console.log(resp.data);
                }
            })
            .catch(error => {

                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                    // console.log(error);
                }
            });
    };

    // Get Table Data with filter applied
    async function GetTableData(productId, pricingPlan, planType) {
        (productId === undefined || productId === null) ? productId = "" : productId = productId;
        await api.get(`${ACRONIS_PRODUCT_PLANS}?productId=${productId}&pricingPlan=${pricingPlan}&planType=${planType} `, config)
            .then(resp => {
                if (resp.status == 204) {
                    setServerError(false)
                }
                // handle success
                else {
                    setServerError(false)
                    // console.log("listing:", resp.data);
                    setProductDetailsData(resp.data);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else if (error.response.status == 500) {
                    setServerError(true);
                }
                else {
                }
            });
    };

    // Get api for plan types
    async function GetPlanTypes() {
        await api.get(`${ACRONIS_PLANS_TYPES}`, config)
            .then(resp => {
                if (resp.status == 204) {
                }
                // handle success
                else {
                    setPlanTypesData(resp.data);
                }
            })
            .catch(error => {

                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Get api for pricing plans
    async function GetPricingPlans() {
        await api.get(`${ACRONIS_PRICING_PLANS}`, config)
            .then(resp => {
                if (resp.status == 204) {
                }
                // handle success
                else {
                    setPricingPlansData(resp.data);
                }
            })
            .catch(error => {

                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to call account details (profile details)
    async function GetProfileDetails() {
        await api.get(GET_RESELLER_DATA, config)
            .then(resp => {
                setOrganization(resp.data.entityName);
                setAdminUserName(resp.data.registrationEmail);
                setContactDetailsFirstName(resp.data.firstName);
                setContactDetailsLastName(resp.data.lastName);
                setContactDetailsRegisteredEmail(resp.data.registrationEmail);
                setAddressDetailsAddressLine1(resp.data.addressLine1);
                setAddressDetailsAddressLine2(resp.data.addressLine2);
                setStateSelected(resp.data.state);

                // setUpdatedProfileDetails(resp.data);
            })
            .catch(error => {
                // console.log(error)
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    //post api for product listing confirmation
    async function PostAcronisProductOrdering() {
        setConfirmLoadingFlag(true);
        const data = {
            "serviceId": serviceId,
            "companyLegalName": organization,
            "commitmentAmount": pricingPlan,
            "registrationEmail": contactDetailsRegisteredEmail,
            "userName": adminUserName,
            "firstName": contactDetailsFirstName,
            "lastName": contactDetailsLastName,
            "addressLine1": addressDetailsAddressLine1,
            "addressLine2": addressDetailsAddressLine2,
            "emailConfirmation": contactDetailsConfirmationEmail,
            "websiteUrl": contactDetailsWebsiteUrl,
            "industry": contactDetailsIndustryType,
            "state": stateSelected,
            "country": "Australia",
            "phoneNumber": contactDetailsPhoneNumber,
            "organizationSize": contactDetailsOrganizationSize,
            "purchaseOrderNumber": "",
            "isTrial": isTrial,
        };
        const configFile = {
            headers: {
                "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
                "Content-Type": "application/json",
            },
        };
        await api.post(POST_ACRONIS_PROVISIONING, data, configFile)
            .then(resp => {
                if (resp.status = 200) {
                    setConfirmLoadingFlag(false);
                    setIsConfirmationClicked(true);
                    setProvisionOrderId(resp.data.orderCode);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                setConfirmLoadingFlag(false);
                if (error.response.status === 500) {
                    setToastMessage("There is an error in completing request. Please try creating it again.");
                }
                else {
                    setToastMessage(error.response.data);
                }
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 5000);

            })
    };

    // next function for going to 2nd step
    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        GetProfileDetails();
    };

    //back function for going to first step
    const handleBack = () => {
        setOrganization(""); setAdminUserName(""); setAddressDetailsAddressLine1(""); setAddressDetailsAddressLine2(""); setContactDetailsFirstName(""); setContactDetailsLastName(""); setContactDetailsRegisteredEmail(""); setContactDetailsConfirmationEmail(""); setContactDetailsWebsiteUrl(""); setContactDetailsIndustryType(""); setContactDetailsOrganizationSize(""); setContactDetailsPhoneNumber(""); setStateSelected(null); 
        setContactDetailsRegisteredEmailErrorFe(""); setContactDetailsConfirmationEmailErrorFe(""); setContactDetailsPhoneNumberErrorFe(""); setContactDetailsWebsiteUrlErrorFe(""); setContactDetailsAdminUsernameEmailErrorFe("");
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // function for form validation in step 2
    const validateForm = () => {
        let valid = true;

        // Validate phone number
        if (!phoneRegex.test(contactDetailsPhoneNumber)) {
            setContactDetailsPhoneNumberErrorFe('Invalid phone number');
            valid = false;
        }

        // Validate website URL
        if (!websiteURLRegEx.test(contactDetailsWebsiteUrl)) {
            setContactDetailsWebsiteUrlErrorFe('Invalid website URL');
            valid = false;
        }

        // Validate registered email
        if (!emailRegEx.test(contactDetailsRegisteredEmail)) {
            setContactDetailsRegisteredEmailErrorFe('Invalid email');
            valid = false;
        }

        // Validate confirmation email
        if (!emailRegEx.test(adminUserName)) {
            setContactDetailsAdminUsernameEmailErrorFe('Invalid username.Please enter valid email');
            valid = false;
        }

        // Validate confirmation email
        if (!emailRegEx.test(contactDetailsConfirmationEmail)) {
            setContactDetailsConfirmationEmailErrorFe('Invalid email');
            valid = false;
        }

        if (contactDetailsRegisteredEmail !== contactDetailsConfirmationEmail) {
            setContactDetailsConfirmationEmailErrorFe('Confirmation email must match with registered email');
            valid = false;
        }

        if (valid) {
            // Proceed with form submission
            PostAcronisProductOrdering();
        }
    };
    
    function generateRandomIp() {
        // Generate random IP address components
        const octets = [];
        for (let i = 0; i < 4; i++) {
            octets.push(Math.floor(Math.random() * 256).toString());
        }
        
        // Join the octets with dots to form the IP address
        const ipAddress = octets.join(".");
        
        return ipAddress;
    }

    // Terms and Condition accept Popup Data
    async function TermsAndConditionAcceptFunction(browser,ipAddress) {
        (ipAddress === undefined || ipAddress === null || ipAddress === "null") ? ipAddress = generateRandomIp() : ipAddress = ipAddress;
        let body = {
            "browser": browser,
            "ipAddress": ipAddress,
            "module":"Cloud",
        }
        await api.post(ACCEPT_LOGIN_TERMS_AND_CONDITIONS,body, config).then((resp) => {
            // handle success
            // console.log(resp.data);
            localStorage.setItem("cloud_terms_and_conditions","true");
            setIsTermsChecked(true);
            })
            .catch((error) => {
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
            });
    }

    useEffect(() => {
        if(!isTermsChecked){
            fetch("https://api.ipify.org?format=json")
            .then(response => response.json())
            .then(data => setIpAddress(data.ip));
        }
    }, [isTermsChecked])


    // Data returned when product id is coming from parent and called when user doesn't have existing purchased plan.
    useEffect(() => {
        if (!existingDataCheck && existingDataCheck != null) {
            if (productId)
                GetTableData(productId, pricingPlan, planType);
            GetPlanTypes();
            GetPricingPlans();
        }
    }, [productId, existingDataCheck]);

    // when plantype and pricingplan is selected from dropdowns
    useEffect(() => {
        if (pricingPlan && planType)
            GetTableData(productId, pricingPlan, planType)
    }, [pricingPlan, planType]);

    // useeffect to check whether user has already purchased Acronis or not
    useEffect(() => {
        GetExistingVerificationCheck();
    }, []);

    // useeffect to check whether paid or trial plan is selected by user 
    useEffect(() => {
        if (planType === "Trial")
            setIsTrial(true);
        else if (planType === "Paid")
            setIsTrial(false);
        else
            setIsTrial(null);
    }, [planType, isTrial]);


    useEffect(() => {
        if (activeStep > 0) {
            setPlanSelectedFlag(true);
        }
        else {
            setPlanSelectedFlag(false);
        }
    }, [activeStep])

    return (
        
            <div className={`mt-4 ${styles['productlisting-main-table']}`}>
                {
                    existingDataCheck && existingDataCheck !== null &&
                    <Row className='ml-4 mt-4'>
                        <Col lg={10} md={8}>
                            <span className='notes-confirmation'>You already have an acronis cyber cloud account, please click on Manage service to go to service management page.</span>
                        </Col>
                        {!isdisableManage && <Col lg={2} md={4}>
                            <div className='btn-wrapper justify-content-end d-flex mr-2'>
                                <Button
                                    className="px-4 pt-0 pb-0 pl-3 pr-3 w-md-auto col-xs-12"
                                    onClick={() => navigate("/management-console/acronis", { state: { customerId: customerId } })}
                                >   Manage service
                                </Button></div>
                        </Col>}
                    </Row>
                }
                 {
                    !existingDataCheck && pendingState &&
                    <Row className='ml-4 mt-4'>
                        <Col lg={10} md={8}>
                            <span className='notes-confirmation'>Order is already in progress. Please wait for order to be completed.</span>
                        </Col>               
                    </Row>
                }
                {
                    existingDataCheck === null && !pendingState &&
                    <div className={styles["no-data-cloud-invoice"]}>
                        <div className="management-console-loading-component text-center"><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading...</p></div>
                    </div>

                }
                {serverError &&
                    <div className={styles["no-data-cloud-invoice"]}>
                        <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                        <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                    </div>
                }
                {/* Stepper */}
                {!existingDataCheck && existingDataCheck !== null && !isConfirmationClicked && !serverError &&
                    <Box sx={{ width: '100%' }} className='pt-4'>
                        <Row className='pb-4'>
                            <Col md={3}></Col>
                            <Col md={6}
                                className={`${styles['button-color-manage-gws']}`}>
                                {activeStep !== steps.length && (
                                    <Stepper activeStep={activeStep}>
                                        {steps.map((label, index) => {
                                            const stepProps = {};
                                            const labelProps = {};
                                            return (
                                                <Step key={label} {...stepProps}>
                                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                )}
                            </Col>
                            <Col md={3}></Col>
                        </Row>
                        <React.Fragment>

                            {activeStep === 0 &&
                                <Typography >
                                    {!serverError &&
                                        <div className={`${styles['gws-changePaymentPlanGWS-core']}`}>
                                            <div className={`${styles['productlisting-main-table']}`} >
                                                <div className='mt-3 text-medium  ml-4'>Select Plan Type  &  Acronis Pricing Plans:</div>
                                            </div>
                                            <Row className='ml-1 mt-3'>
                                                <Col lg={3} md={6}>
                                                    <p className="mb-1">Plan type</p>
                                                    <div>
                                                        {
                                                            <Form.Select aria-label="Default select"
                                                                value={planType}
                                                                onChange={(e) => {
                                                                    setPlanType(e.target.value)
                                                                }}
                                                            >
                                                                {planType === null && <option value="select">Select</option>}
                                                                {planTypesData &&
                                                                    planTypesData.map((dropdownItem) => {
                                                                        return (
                                                                            <option value={dropdownItem.id}>{dropdownItem.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </Form.Select>
                                                        }
                                                    </div>
                                                </Col>

                                                <Col lg={3} md={6}>
                                                    <p className="mb-1">Acronis pricing plans</p>
                                                    <div>
                                                        {
                                                            <Form.Select aria-label="Default select"
                                                                value={pricingPlan + "/" + pricingPlanName}
                                                                onChange={(e) => {
                                                                    // console.log(e);
                                                                    setPricingPlan(e.target.value.split("/")[0]);
                                                                    // console.log("id: ", e.target.value.split("/")[0]);
                                                                    // console.log("value: ", e.target.value.split("/")[1]);
                                                                    setPricingPlanName(e.target.value.split("/")[1]);

                                                                }}
                                                            >
                                                                {pricingPlan === null && <option value="select">Select</option>}
                                                                {pricingPlansData &&
                                                                    pricingPlansData.map((dropdownItem) => {
                                                                        return (
                                                                            <option value={`${dropdownItem.id}/${dropdownItem.name}`}>{dropdownItem.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </Form.Select>
                                                        }
                                                    </div>
                                                </Col>

                                                <Col md={6}>
                                                    <div className='text-right mt-6'>
                                                        <Button variant="light"
                                                            className="px-4 pt-0 pb-0 btn-border-light w-md-auto col-xs-12" onClick={(e) => { DownloadAction(ACRONIS_DOWNLOAD_PRICELIST, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_acronis_price_list.pdf`, "pdf") }}> Download price list</Button>
                                                    </div>
                                                </Col>

                                            </Row>
                                            <h2 className='ml-4 mb-2 mt-4'>Plans</h2>
                                            <div className={`${styles['table-scroll']}`}>
                                                <Table className={`table-responsive ${styles['csp-change-status-core-table']}`} responsive="md">
                                                    <thead className="sticky-top">
                                                        <tr>
                                                            {AcronisProductDetailColumnName.map(th => {
                                                                return (<th className={(th === "RRP (Inc GST)" || th === "Price (Ex GST)") ? "text-right" : ""}>{th}</th>)
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* when nothing is selected in filter */}
                                                        {!productDetailsData && <tr>
                                                            <td colSpan={7}>
                                                                <div className={`${styles['loading-plans']}`}><p>Please select plan type and acronis pricing plans from the top.</p></div>
                                                            </td>
                                                        </tr>}

                                                        {productDetailsData &&
                                                            productDetailsData.map((td) => {
                                                                return (<tr>
                                                                    <td>{td.planName}</td>
                                                                    <td>{td.skuId}</td>
                                                                    <td>{td.pricingPlans}</td>
                                                                    <td>{td.planType}</td>
                                                                    <td>{td.category}</td>
                                                                    <td className='text-right'>${td.strRRP}</td>
                                                                    <td className='text-right'>${td.strPrice}</td>
                                                                </tr>)
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>

                                            <div className={isreadOnly ? "d-none d-none-mobile" : ` ${styles['csp-purchase-addon-count-row']} mb-0 d-none-mobile`}>
                                                <div className={`${styles['csp-purchase-addon-count-row-inner']} mb-0`}>
                                                    <Row>
                                                        <Col md={4} className="d-flex pt-0 pb-0 align-items-center">
                                                            <p className="m-0 p-0 text-small">Selected plan type: {planType}</p>
                                                        </Col>
                                                        <Col md={7} className="d-flex pt-0 pb-0 align-items-center">
                                                            <p className="m-0 p-0 text-small">Selected acronis pricing plan: {pricingPlanName}</p>
                                                        </Col>
                                                        <Col md={1} className='pt-0 pb-0'>
                                                            <div className="btn-wrapper justify-content-end d-flex">
                                                                {(planType && pricingPlan) ? <Button
                                                                    className="px-4 pt-0 pb-0  w-md-auto col-xs-12"
                                                                    onClick={()=>{
                                                                        if(billingPaymentGatewayData.isDefaultPaymentMethodValid){
                                                                            if(billingPaymentGatewayData.accountHoldStatus==="No Hold" || proceedToProvisionFlag){
                                                                                if(billingPaymentCardExpiryFlag || billingPaymentDirecrDebitExpiryFlag){
                                                                                    if(billingPaymentCardExpiryFlag){
                                                                                        setBillingPaymentGatewayErrorMessage("Your default payment method of credit card has an expired primary card, please proceed to add a new card")
                                                                                        setBillingPaymentGatewayErrorPopup(true)
                                                                                    }
                                                                                    else{
                                                                                        setBillingPaymentGatewayErrorMessage("Your default payment method of Direct Debit has all expired statuses, please proceed to add a new application")
                                                                                        setBillingPaymentGatewayErrorPopup(true)
                                                                                    }
                                                                                }
                                                                                else{
                                                                                    handleNextStep()
                                                                                }
                                                                            }
                                                                            if(billingPaymentGatewayData.accountHoldStatus==="System Hold" &&  !proceedToProvisionFlag){
                                                                                setBillingPaymentGatewayErrorMessage("There is an issue with your account. If you choose to proceed to provision the order, please contact Synnex account team to complete the provisioning of this order.")
                                                                                setBillingPaymentGatewayErrorPopup(true)
                                                                            }
                                                                            if(billingPaymentGatewayData.accountHoldStatus==="Manual Hold"){
                                                                                setBillingPaymentGatewayErrorMessage("We are unable to process your request at this time. Please contact Synnex Accounts team at 'accounts@au.synnex-grp.com' or '0385408888' for further instructions")
                                                                                setBillingPaymentGatewayErrorPopup(true)
                                                                            }
                                                                            
                                                                        }
                                                                        else{
                                                                            if(!(billingPaymentGatewayData.isDefaultPaymentMethodValid)){
                                                                                setBillingPaymentGatewayErrorMessage("No payment method is chosen by default, click on proceed to add payment method.")
                                                                                setBillingPaymentGatewayErrorPopup(true)
                                                                            }
                                                                        }
                                                                    
                                                                    }}
                                                                >
                                                                    Next
                                                                </Button>
                                                                    :
                                                                    <Button
                                                                        className="w-md-auto col-xs-12"
                                                                        variant="disabled"
                                                                        disabled
                                                                    >
                                                                        Next
                                                                    </Button>}

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                        </div>}
                                </Typography>}

                            {activeStep === 1 &&
                                <Typography>
                                    <div className={`${styles['productlisting-main-table']}`}>
                                        <div className='ml-4 mt-4'>
                                            <div className='mb-3'>
                                                <h2>Pricing information</h2>
                                                <Row className='text-small pt-1'>
                                                    <Col md={3}>Selected plan type: {planType}</Col>
                                                    <Col md={9}>Selected acronis pricing plan: {pricingPlanName}</Col>
                                                </Row>
                                            </div>
                                            <div>
                                                <div className='mb-3 pt-2'><h2 className='mb-4'>Organisation details</h2>
                                                    <Row>
                                                        <Col lg={5} md={7}>
                                                            <Form.Group className="mb-3" controlId="organizationName">
                                                                <Form.Label>Legal entity name <span className="red">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={organization}
                                                                    placeholder="Enter entity name"
                                                                    name="legalEntity"
                                                                    onChange={(e) => { setOrganization(e.target.value); }}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={5} md={7}>
                                                            <Form.Group className="mb-3" controlId="adminUserName">
                                                                <Form.Label>Admin user Name <span className="red">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={adminUserName}
                                                                    placeholder="Enter admin user name"
                                                                    name="adminUsername"
                                                                    onChange={(e) => {
                                                                        setAdminUserName(e.target.value);
                                                                        setContactDetailsAdminUsernameEmailErrorFe("")
                                                                    }} />
                                                                <span className="red text-small">{contactDetailsAdminUsernameEmailErrorFe}</span>

                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className='mb-3'><h2 className='mb-4'>Contact details</h2>
                                                    <Row className='pb-2'>
                                                        <Col lg={5} md={7}>
                                                            <Form.Group className="mb-3" controlId="firstName">
                                                                <Form.Label>First name <span className="red">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={contactDetailsFirstName}
                                                                    placeholder=" Enter first name"
                                                                    maxlength="30"
                                                                    name="firstName"
                                                                    onChange={(e) => { setContactDetailsFirstName(e.target.value); }}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={5} md={7}>
                                                            <Form.Group className="mb-3" controlId="lastName">
                                                                <Form.Label>Last name <span className="red">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="lastName"
                                                                    value={contactDetailsLastName}
                                                                    placeholder="Enter last name"
                                                                    maxlength="30"
                                                                    onChange={(e) => {
                                                                        setContactDetailsLastName(e.target.value);
                                                                    }} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className='pb-2'>
                                                        <Col lg={5} md={7}>
                                                            <Form.Group className="mb-3" controlId="registeredEmail">
                                                                <Form.Label>Registered email <span className="red">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="email"
                                                                    name="registeredEmail"
                                                                    value={contactDetailsRegisteredEmail}
                                                                    placeholder="Enter registered email id"
                                                                    // maxlength="50"
                                                                    onChange={(e) => {
                                                                        setContactDetailsRegisteredEmail(e.target.value);
                                                                        setContactDetailsRegisteredEmailErrorFe("");
                                                                    }} />
                                                                <span className="red text-small">{contactDetailsRegisteredEmailErrorFe}</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={5} md={7}>
                                                            <Form.Group className="mb-3" controlId="emailConfirmation">
                                                                <Form.Label>Email confirmation <span className="red">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="email"
                                                                    name="emailConfirmation"
                                                                    value={contactDetailsConfirmationEmail}
                                                                    placeholder="Enter registered email id"
                                                                    maxlength="50"
                                                                    onChange={(e) => {
                                                                        setContactDetailsConfirmationEmail(e.target.value)
                                                                        setContactDetailsConfirmationEmailErrorFe("");
                                                                    }}
                                                                />
                                                                <span className="red text-small">{contactDetailsConfirmationEmailErrorFe}</span>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className='pb-2'>
                                                        <Col lg={5} md={7}>
                                                            <Form.Group className="mb-3" controlId="websiteUrl">
                                                                <Form.Label>Website url<span className="red">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="websiteUrl"
                                                                    value={contactDetailsWebsiteUrl}
                                                                    placeholder="Enter website url"
                                                                    maxlength="50"
                                                                    onChange={(e) => {
                                                                        setContactDetailsWebsiteUrl(e.target.value);
                                                                        setContactDetailsWebsiteUrlErrorFe("");
                                                                    }} />
                                                                <span className="red text-small">{contactDetailsWebsiteUrlErrorFe}</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={5} md={7}>
                                                            <Form.Group className="mb-3" controlId="industryType">
                                                                <Form.Label>Industry type <span className="red">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="industryType"
                                                                    value={contactDetailsIndustryType}
                                                                    placeholder="Enter industry type"
                                                                    onChange={(e) => {
                                                                        if (e.target.value === '' || /^[a-zA-Z0-9]+$/.test(e.target.value)) {
                                                                            setContactDetailsIndustryType(e.target.value);
                                                                        }
                                                                    }}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className='pb-2'>
                                                        <Col lg={5} md={7}>
                                                            <Form.Group className="mb-3" controlId="organizationSize">
                                                                <Form.Label>Organisation size (no. of employees)<span className="red">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="organizationSize"
                                                                    value={contactDetailsOrganizationSize}
                                                                    placeholder="Enter organisation size"
                                                                    onChange={(e) => {
                                                                        if (e.target.value === '' || /^(?=.*[1-9])\d{1,25}$/.test(e.target.value)) {
                                                                            setContactDetailsOrganizationSize(e.target.value);
                                                                        }
                                                                    }} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={5} md={7}>
                                                            <Form.Group className="mb-3" controlId="phoneNumber">
                                                                <Form.Label>
                                                                    Phone number <span className="red">*</span>
                                                                </Form.Label>
                                                                <Row>
                                                                    <Col>
                                                                        <div className="phone-number-wrapper">
                                                                            <label>+61</label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                className="phone-number form-control col"
                                                                                placeholder="xxxxxxxxxx"
                                                                                name="phoneNumber"
                                                                                value={contactDetailsPhoneNumber}
                                                                                maxLength="15"
                                                                                onChange={(e) => {
                                                                                    if (e.target.value === '' || phoneOnChangeRegex.test(e.target.value)) {
                                                                                        setContactDetailsPhoneNumber(e.target.value);
                                                                                        setContactDetailsPhoneNumberErrorFe("")
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            <span className="red text-small">{contactDetailsPhoneNumberErrorFe}</span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className='mb-3'><h2 className='mb-4'>Address details</h2>
                                                    <Row className='pb-2'>
                                                        <Col lg={5} md={7}>
                                                            <Form.Group className="mb-3" controlId="address1">
                                                                <Form.Label>Address 1 <span className="red">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={addressDetailsAddressLine1}
                                                                    placeholder="Enter address 1"
                                                                    name="address1"
                                                                    onChange={(e) => {
                                                                        if (e.target.value === '' || /^[a-zA-Z0-9\- ,]{1,100}$/.test(e.target.value)) {
                                                                            setAddressDetailsAddressLine1(e.target.value);
                                                                        }
                                                                    }} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={5} md={7}>
                                                            <Form.Group className="mb-3" controlId="address2">
                                                                <Form.Label>Address 2 <span className="red">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="address2"
                                                                    value={addressDetailsAddressLine2}
                                                                    placeholder="Enter address 2"
                                                                    onChange={(e) => {
                                                                        if (e.target.value === '' || /^[a-zA-Z0-9\- ,]{1,100}$/.test(e.target.value)) {
                                                                            setAddressDetailsAddressLine2(e.target.value);
                                                                        }
                                                                    }}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={5} md={7}>
                                                            <Form.Group className="mb-3" controlId="country">
                                                                <Form.Label>Country <span className="red">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value="Australia"
                                                                    placeholder="Enter country"
                                                                    readOnly
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={5} md={7}>
                                                            <Form.Group className="mb-3" controlId="state">
                                                                <Form.Label>State <span className="red">*</span></Form.Label>
                                                                <Form.Select
                                                                    value={stateSelected}
                                                                    onChange={(e) => {
                                                                        if (e.target.value === "selectstate")
                                                                            setStateSelected(null)
                                                                        else
                                                                            setStateSelected(e.target.value)
                                                                    }}
                                                                >
                                                                    <option value="selectstate">Select state</option>
                                                                    {
                                                                        ausStates.map(state => {
                                                                            return (
                                                                                <option
                                                                                    value={state}
                                                                                >
                                                                                    {state}
                                                                                </option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </div>

                                            </div>

                                            <Row className='pt-8'>
                                                <Col lg={8} className="mb-4">
                                                    <Form.Check // prettier-ignore
                                                        type="checkbox"
                                                        id="termsCheckBox"
                                                        label=""
                                                        disabled
                                                        checked={isTermsChecked}
                                                        className="d-inline-block w-auto"
                                                    />
                                                    <div className="d-inline-block text-small w-auto mb-0">
                                                        I agree to the <a href="/terms-and-conditions" target='_blank'  onClick={() => {if(!isTermsChecked){let browser = browserName[1] + " " + browserVersion[1];TermsAndConditionAcceptFunction(browser,ipAddress);}}}>terms & conditions</a> of Synnex and Acronis cyber protect cloud
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="btn-wrapper text-right">
                                                        {!confirmLoadingFlag && isTermsChecked &&
                                                            <>
                                                                <Button
                                                                    className="ml-2 btn-border-light w-md-auto col-xs-12"
                                                                    variant="light"
                                                                    onClick={handleBack}
                                                                >Back</Button>
                                                                {(stateSelected != null && organization != "" && adminUserName != "" && contactDetailsFirstName != "" && contactDetailsLastName != "" && contactDetailsRegisteredEmail != "" && contactDetailsConfirmationEmail != "" && contactDetailsWebsiteUrl != "" && contactDetailsIndustryType != "" && contactDetailsOrganizationSize != "" && addressDetailsAddressLine1 != "" && addressDetailsAddressLine2 != ""
                                                                    && contactDetailsPhoneNumber != "") &&
                                                                    <Button
                                                                        className="px-4 mx-1"
                                                                        onClick={() => {
                                                                            validateForm();
                                                                        }}>
                                                                        Confirm
                                                                    </Button>}
                                                                {(stateSelected === null || organization === "" || adminUserName === "" || contactDetailsFirstName === "" || contactDetailsLastName === "" || contactDetailsRegisteredEmail === "" || contactDetailsConfirmationEmail === "" || contactDetailsWebsiteUrl === "" || contactDetailsIndustryType === "" || contactDetailsOrganizationSize === "" || addressDetailsAddressLine1 === "" || addressDetailsAddressLine2 === ""
                                                                    || contactDetailsPhoneNumber === "") &&
                                                                    <Button
                                                                        variant="light"
                                                                        className="px-4 mx-1"
                                                                        disabled
                                                                    >
                                                                        Confirm
                                                                    </Button>
                                                                }
                                                            </>
                                                        }
                                                        {!confirmLoadingFlag && !isTermsChecked &&
                                                            <>
                                                                <Button
                                                                    className="ml-2 btn-border-light w-md-auto col-xs-12"
                                                                    variant="light"
                                                                    onClick={handleBack}>Back
                                                                </Button>
                                                                <Button
                                                                    variant="light"
                                                                    className="px-4 mx-2"
                                                                    disabled
                                                                > Confirm
                                                                </Button>
                                                            </>
                                                        }
                                                        {confirmLoadingFlag &&
                                                            <>
                                                                <Button
                                                                    variant="light"
                                                                    disabled
                                                                    className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                                >Back
                                                                </Button>
                                                                <Button
                                                                    disabled
                                                                    className="ml-2 btn-border-light w-md-auto col-xs-12"
                                                                    variant="light"
                                                                >
                                                                    Processing . . .
                                                                </Button>
                                                            </>
                                                        }
                                                    </div></Col>

                                            </Row>
                                        </div>
                                    </div>
                                </Typography>}

                        </React.Fragment>

                    </Box>}

                {isConfirmationClicked &&
                    <div className={styles["no-data-cloud-invoice"]}>
                        <img src={SuccessImagePreRequisite} className="empty-cloud-invoice-table-svg" />
                        <span className='mt-5 mb-5'>Your order is placed successfully. Please wait till it is processed completely.</span>
                        <span><b>Provision order ID:</b> {provisionOrderId}</span>
                    </div>
                }
            </div>
        
    )
}

export default AcronisProductDetail
