import { Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/Styles/App.scss";


// Anonymous User Screen Import
import Footer from './Layout/Footer';
import ResetPassword from "./Modules/Anonymous/ResetPassword";
import LoginCustomer from "../src/Modules/Anonymous/Login";
import CustomerNavbar from "./Layout/Navbar";

// Authenticated User Screen Import
import CmsDetailsState from "./Context/CmsDetails/CmsDetailsSate";
import PageNotFound from "./GlobalComponents/EmptyStates/PageNotFound";
import ForgotPassword from "./Modules/Anonymous/ForgotPassword";
import MfaVerification from "./Modules/Anonymous/MfaVerification";
import DashBoard from "./Modules/Customer/DashBoard";
import CloudProvision from "./Modules/Customer/CloudProvision";
import ManagementConsole from "./Modules/Customer/ManagementConsole";
import MyCustomers from "./Modules/Customer/MyCustomers";
import Integrations from "./Modules/Customer/ Integrations";
import AddCustomer from "./Modules/Customer/MyCustomers/AddCustomer";
import SupportTicket from "./Modules/Customer/SupportTicket";
import TermsAndConditions from "./Modules/Customer/TermsAndConditions";
import CloudSummaryListing from "./Modules/Customer/Billing/CloudSummaries";
import CreditNotesListing from "./Modules/Customer/Billing/CreditNotes";
import InvoiceDetailsListing from "./Modules/Customer/Billing/Invoices";
import CloudSummaryDetails from "./Modules/Customer/Billing/CloudSummaries/Details";
import InvoiceDetails from "./Modules/Customer/Billing/Invoices/Details";
import CreditNotesDetails from "./Modules/Customer/Billing/CreditNotes/Details";
import OrderHistory from "./Modules/Customer/OrderHistory";
import EditCustomer from "./Modules/Customer/MyCustomers/EditCustomer";
import BulkUpload from "./Modules/Customer/MyCustomers/BulkUpload";
import UnbilledUsageReport from "./Modules/Customer/Billing/IaasUnbilledUsage";
import AcronisServiceManagement from "./Modules/Customer/ManagementConsole/ServiceManagement/Acronis";
import UserManagement from "./Modules/Customer/UserManagement";
import Profile from "./Modules/Customer/Profile";
import EditUser from "./Modules/Customer/UserManagement/EditUser";
import AddSubAccountUser from "./Modules/Customer/UserManagement/AddUser";
import AzureServiceManagement from "./Modules/Customer/ManagementConsole/ServiceManagement/Azure";
import CspServiceManagement from "./Modules/Customer/ManagementConsole/ServiceManagement/CSP";
import GWServiceManagement from "./Modules/Customer/ManagementConsole/ServiceManagement/GWS";
import AvastServiceManagement from "./Modules/Customer/ManagementConsole/ServiceManagement/Avast";
import GCPServiceManagement from "./Modules/Customer/ManagementConsole/ServiceManagement/GCP";
import { useNavigate } from "react-router-dom";

// Microsoft Auth Login
import { MsalProvider, useMsal, MsalAuthenticationTemplate, AuthenticatedTemplate} from '@azure/msal-react';
import { useIsAuthenticated } from '@azure/msal-react';

import IBMServiceManagement from "./Modules/Customer/ManagementConsole/ServiceManagement/IBM";
import GWSCustomerPrerequisite from "./Modules/Customer/CloudProvision/LocalComponents/Customer/Prerequisite/GWS";
import MicrosoftResellerPrerequisite from "./Modules/Customer/CloudProvision/LocalComponents/Reseller/Prerequisite/Microsoft";
import ProfileGoogleWorkspaceResellerPrerequisite from "./Modules/Customer/Profile/LocalComponents/Reseller/Prerequisite/Googleworkspace";
import ProfileMicrosoftResellerPrerequisite from "./Modules/Customer/Profile/LocalComponents/Reseller/Prerequisite/Microsoft";
import CSPCustomerPrerequisite from "./Modules/Customer/CloudProvision/LocalComponents/Customer/Prerequisite/CSP";
import GoogleworkspaceResellerPrerequisite from "./Modules/Customer/CloudProvision/LocalComponents/Reseller/Prerequisite/Googleworkspace";
import ConfigureIntegration from "./Modules/Customer/ Integrations/LocalComponents/Configure";
import ManageIntegration from "./Modules/Customer/ Integrations/LocalComponents/Manage";
import CloneUser from "./Modules/Customer/UserManagement/CloneUser";
import Subscriptions from "./Modules/Customer/Subscription";
import CustomerBulkUpload from "./Modules/Customer/MyCustomers/LocalComponents/CustomerBulkUpload";
import MCABulkUpload from "./Modules/Customer/MyCustomers/LocalComponents/MCABulkUpload";
import ContactUsDrawer from "./GlobalComponents/ContactUsDrawer";
import Notifications from "./Modules/Customer/Notifications";
import PaymentMethods from "./Modules/Customer/Billing/PaymentMethods";
import LandingPage from "./Modules/Anonymous/LandingPage";
import FeedbackDrawer from "./GlobalComponents/FeedbackDrawer";
import PublicTermsAndCondition from "./Modules/Anonymous/Terms&Conditions";
import OfflineServices from "./Modules/Anonymous/OfflineServices";
import PrivacyPolicy from "./Modules/Anonymous/PrivacyPolicy";
import CookiePolicy from "./Modules/Anonymous/CookiePolicy";
import OurVendors from "./Modules/Anonymous/OurVendors";
import VendorDetails from "./Modules/Anonymous/OurVendors/VendorDetails";
import PublicContactUs from "./Modules/Anonymous/PublicContactUs";
import { loginRequest } from './authConfig';
import CookiePopup from "./Modules/Anonymous/CookiePopup";
import ReactGA from "react-ga4";
import MaintainancePage from "./Modules/Anonymous/MaintainancePage";
import AcronisTnC from "./Modules/Anonymous/Terms&Conditions/LocalComponents/AcronisTnC";
import CSPTnC from "./Modules/Anonymous/Terms&Conditions/LocalComponents/CSPTnC";
import GoogleCloudToP from "./Modules/Anonymous/Terms&Conditions/LocalComponents/GoogleCloudToP";
import GoogleCloudProductSchedule from "./Modules/Anonymous/Terms&Conditions/LocalComponents/GoogleCloudProductSchedule";
import LookerProductAddendum from "./Modules/Anonymous/Terms&Conditions/LocalComponents/LookerProductAddendum";
import SecOpsProductsAddendum from "./Modules/Anonymous/Terms&Conditions/LocalComponents/SecOpsProductsAddendum";
import B2b2cTnC from "./Modules/Anonymous/Terms&Conditions/LocalComponents/B2b2cTnC";
import ConnectwiseTnC from "./Modules/Anonymous/Terms&Conditions/LocalComponents/ConnectwiseTnC";
import AcronisTnCVersion0 from "./Modules/Anonymous/Terms&Conditions/LocalComponents/AcronisTnC/LocalComponents/V1.0";
import AcronisTnCVersion1 from "./Modules/Anonymous/Terms&Conditions/LocalComponents/AcronisTnC/LocalComponents/V1.1";
import AcronisTnCVersion2 from "./Modules/Anonymous/Terms&Conditions/LocalComponents/AcronisTnC/LocalComponents/V1.2";

const App = ({ instance, isCusLoggedIn }) => {

  const navigate = useNavigate();   // React Router v6

  const isAuthenticated = useIsAuthenticated();

  // UseStates Defination
  const [customerIsAuthenticated, setCustomerIsAuthenticated] = useState(false);               // Customer is authenticated Flag
  const [profileInitialCredentialChange, setProfileInitialCredentialChange] = useState(false); // Keeping Count of Profile Image initials change
  const [activeLocalKey, setActiveLocalKey] = useState("default");                             // navigation bar default value
  const [pageNotFound, setPageNotFound] = useState(false);                                     // Page not found Flag
  const [open, setOpen] = useState(false);                                               // Contact Us Toggle Drawer

  const [hookRecentNotifications, setHookRecentNotifications] = useState(false);                                               // Contact Us Toggle Drawer
  const [hookNotifications, setHookNotifications] = useState(false); 

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  }; 
  
  const [openFeedback, setOpenFeedback] = useState(false);                                               // Feedback Toggle Drawer

  const toggleDrawerFeedback = (newOpen) => () => {
    setOpenFeedback(newOpen);
  };

  const [formEndPoint, setFormEndPoint] = useState(null);

  const [termsAndConditionPopupFlag, setTermsAndConditionPopupFlag] = useState(false);

  const [selectService, setSelectService] = useState("Select Service");   // Select Service
  const [selectCategory, setSelectCategory] = useState("Select Category"); // Select Category
  const [integrationContactUsFormFlag, setIntegrationContactUsFormFlag] = useState(false); 
  const [comingFromIntegration, setComingFromIntegration] = useState(false);

  const [cookieAccepted, setCookieAccepted] = useState(false);
  const [maintainancePageActive, setMaintainancePageActive] = useState(false);

  useEffect(() => {
    let value= false;

    if(isAuthenticated===true) {
      // navigate("/synnex-cloud/dashboard")
      // console.log("Logedddd innnnnnnnnnnnn");
      value=true;
    }

    // localStorage.clear();
    // let value = localStorage.getItem("customerIsAuthenticated");
    if (value) {
      // navigate('/dashboard');
      setCustomerIsAuthenticated(value); // Defining default value that customer is signed in or not
    }
  }, [isAuthenticated]);

  const setAuthentication = (value) => {
    // localStorage.setItem("customerIsAuthenticated", value);
    setCustomerIsAuthenticated(value); // Defining value that customer is signed in or not after change in useState
  };

    // Logout Function on error 410
    function Logout() {
      setCustomerIsAuthenticated(false);
      if(localStorage.getItem("access_token") && localStorage.getItem("access_token").length>0){
        if (customerIsAuthenticated) { navigate("/") };
        localStorage.clear();
        instance.logoutRedirect();
      }
      else{
          // console.log("Instance :",instance)
          instance.loginRedirect(loginRequest).catch((error) => console.log(error));
      } 
  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `${window.location.pathname + window.location.search}`, title: "Cloud Marketplace" });
  }, [window.location.pathname])

  return (
      <>
        <CmsDetailsState>
          {!maintainancePageActive && <div className={!maintainancePageActive ? "main-page" : "d-none"}>
            <AuthenticatedTemplate>
              <>
                <CustomerNavbar setCustomerIsAuthenticated={setAuthentication} activeLocalKey={activeLocalKey} setActiveLocalKey={setActiveLocalKey} hookRecentNotifications={hookRecentNotifications} setHookRecentNotifications={setHookRecentNotifications}
                  profileInitialCredentialChange={profileInitialCredentialChange} setProfileInitialCredentialChange={setProfileInitialCredentialChange} toggleDrawer={toggleDrawer} toggleDrawerFeedback={toggleDrawerFeedback} setHookNotifications={setHookNotifications} setComingFromIntegration={setComingFromIntegration} Logout={Logout} />
                {/* Navbar visible to logged in Customer */}
              </>
            </AuthenticatedTemplate>
            <ContactUsDrawer toggleDrawer={toggleDrawer} open={open} setOpen={setOpen} setSelectService={setSelectService} selectService={selectService} selectCategory={selectCategory} setSelectCategory={setSelectCategory} setIntegrationContactUsFormFlag={setIntegrationContactUsFormFlag}  comingFromIntegration={comingFromIntegration} Logout={Logout} />
            <FeedbackDrawer toggleDrawer={toggleDrawerFeedback} open={openFeedback} setOpen={setOpenFeedback} Logout={Logout}/>
            <Routes>
              {/* Defining Routes for unauthorized Customers */}

              <Route
                path="/landing-page/"
                element={
                  <LoginCustomer
                    customerIsAuthenticated={customerIsAuthenticated}
                    setCustomerIsAuthenticated={setAuthentication}
                    instance={instance}
                  />
                }
              />
              <Route
                path="/"
                element={<LandingPage 
                  customerIsAuthenticated={customerIsAuthenticated}
                  setCustomerIsAuthenticated={setAuthentication}
                  instance={instance}
                  setFormEndPoint={setFormEndPoint}
                  cookieAccepted={cookieAccepted}
                />}
              />
              <Route
                path="/reset-password/"
                element={<ResetPassword />}
              />
              <Route
                path="/mfa-verification"
                element={<MfaVerification />}
              />
              <Route
                path="/offline-services"
                element={<OfflineServices />}
              />
              <Route
                path="/forgot-password"
                element={<ForgotPassword />}
              />
              <Route
                path="/terms-and-condition"
                element={<PublicTermsAndCondition />}
              />
              <Route
                path="/privacy-policy"
                element={<PrivacyPolicy />}
              />
              <Route
                path="/our-vendors"
                element={<OurVendors customerIsAuthenticated={customerIsAuthenticated} cookieAccepted={cookieAccepted} />}
              />
              <Route
                path="/our-vendors-details"
                element={<VendorDetails />}
              />
              <Route
                path="/cookie-policy"
                element={<CookiePolicy />}
              />
              <Route
                path="/contact-us"
                element={<PublicContactUs customerIsAuthenticated={customerIsAuthenticated} cookieAccepted={cookieAccepted} />}
              />
              <Route path="*" element={<PageNotFound setPageNotFound={setPageNotFound} />} />
              <Route path="/synnex-cloud/dashboard" element={<DashBoard open={open} customerIsAuthenticated={customerIsAuthenticated} termsAndConditionPopupFlag={termsAndConditionPopupFlag} setTermsAndConditionPopupFlag={setTermsAndConditionPopupFlag} Logout={Logout} />} />
              <Route path="/synnex-cloud/order-history" element={<OrderHistory customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout}  />} />
              <Route path="/cloud-provision" element={<CloudProvision Logout={Logout}/>} />
              <Route path="/cloud-provision/customer/prerequisite/google" element={<GWSCustomerPrerequisite setActiveLocalKey={setActiveLocalKey} Logout={Logout}/>} />
              <Route path="/cloud-provision/customer/prerequisite/microsoft" element={<CSPCustomerPrerequisite setActiveLocalKey={setActiveLocalKey} toggleDrawer={toggleDrawer} setSelectService={setSelectService} setSelectCategory={setSelectCategory} Logout={Logout}/>} />
              <Route path="/cloud-provision/reseller/prerequisite/microsoft" element={<MicrosoftResellerPrerequisite customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout}/>} />
              <Route path="/cloud-provision/reseller/prerequisite/google" element={<GoogleworkspaceResellerPrerequisite customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout}/>} />
              <Route path="/management-console" element={<ManagementConsole Logout={Logout} />} />
              <Route path="/my-customers" element={<MyCustomers customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout}/>} />

              <Route path="/synnex-cloud/integrations" element={<Integrations toggleDrawer={toggleDrawer} setSelectService={setSelectService} setSelectCategory={setSelectCategory} setIntegrationContactUsFormFlag={setIntegrationContactUsFormFlag} integrationContactUsFormFlag={integrationContactUsFormFlag} setComingFromIntegration={setComingFromIntegration} Logout={Logout}/>} />

              <Route path="/integrations/configure" element={<ConfigureIntegration toggleDrawer={toggleDrawer} setSelectService={setSelectService} setSelectCategory={setSelectCategory} Logout={Logout}/>} />
              <Route path="/integrations/manage" element={<ManageIntegration toggleDrawer={toggleDrawer} setSelectService={setSelectService} setSelectCategory={setSelectCategory} Logout={Logout}/>} />
              <Route path="/my-customers/add-customer" element={<AddCustomer customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout}/>} />
              <Route path="/my-customers/edit-customer" element={<EditCustomer customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} setOpen={setOpen} setActiveLocalKey={setActiveLocalKey} toggleDrawer={toggleDrawer} setSelectService={setSelectService} setSelectCategory={setSelectCategory} Logout={Logout}/>} />
              <Route path="/my-customer/customer-bulk-upload" element={<CustomerBulkUpload customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout} />} />
              <Route path="/my-customer/mca-bulk-upload" element={<MCABulkUpload Logout={Logout}/>} />
              <Route path="/support-ticket" element={<SupportTicket Logout={Logout} />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions Logout={Logout}/>} />
              <Route path="/terms-and-conditions/acronis-terms-and-conditions-v1.3/" element={<AcronisTnC Logout={Logout}/>} />
              <Route path="/terms-and-conditions/acronis-terms-and-conditions-v1.0/" element={<AcronisTnCVersion0 Logout={Logout}/>} />
              <Route path="/terms-and-conditions/acronis-terms-and-conditions-v1.1/" element={<AcronisTnCVersion1 Logout={Logout}/>} />
              <Route path="/terms-and-conditions/acronis-terms-and-conditions-v1.2/" element={<AcronisTnCVersion2 Logout={Logout}/>} />
              <Route path="/terms-and-conditions/csp-terms-and-conditions/" element={<CSPTnC Logout={Logout}/>} />
              <Route path="/terms-and-conditions/google-cloud-terms-of-program/" element={<GoogleCloudToP Logout={Logout}/>} />
              <Route path="/terms-and-conditions/google-cloud-platform-product-schedule/" element={<GoogleCloudProductSchedule Logout={Logout}/>} />
              <Route path="/terms-and-conditions/looker-product-addendum/" element={<LookerProductAddendum Logout={Logout}/>} />
              <Route path="/terms-and-conditions/secops-products-addendum/" element={<SecOpsProductsAddendum Logout={Logout}/>} />
              <Route path="/terms-and-conditions/synnex-b2b2c-terms-condition/" element={<B2b2cTnC Logout={Logout}/>} />
              <Route path="/terms-and-conditions/connectwise-terms-condition/" element={<ConnectwiseTnC Logout={Logout}/>} />
              <Route path="/billing/cloud-summary" element={<CloudSummaryListing customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout} />} />
              <Route path="/billing/credit-notes" element={<CreditNotesListing customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout}/>} />
              <Route path="/billing/invoice" element={<InvoiceDetailsListing customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout}/>} />
              <Route path="/billing/cloud-summary/summary-detail" element={<CloudSummaryDetails customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout}/>} /> 
              <Route path="/billing/credit-notes" element={<CreditNotesListing customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication}  Logout={Logout}/>} />
              <Route path="/billing/cloud-invoice/invoice-detail" element={<InvoiceDetails customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication}  Logout={Logout}/>} />
              <Route path="/billing/cloud-creditNote/creditNote-detail" element={<CreditNotesDetails customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication}  Logout={Logout}/>} />
              <Route path="/billing/cloud-summary/summary-detail" element={<CloudSummaryDetails customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication}  Logout={Logout}/>} />
              <Route path="/my-customers/bulk-upload" element={<BulkUpload customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication}  Logout={Logout}/>} />
              <Route path="/billing/unbilled-iaas-usage-report" element={<UnbilledUsageReport customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication}  Logout={Logout}/>} />
              <Route path="/billing/payment-methods" element={<PaymentMethods customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication}  Logout={Logout}/>} />
              <Route path="/management-console/acronis" element={<AcronisServiceManagement toggleDrawer={toggleDrawer} setSelectService={setSelectService} setSelectCategory={setSelectCategory} Logout={Logout}/>} />
              <Route path="/management-console/azure" element={<AzureServiceManagement Logout={Logout}/>} />
              <Route path="/management-console/csp" element={<CspServiceManagement toggleDrawer={toggleDrawer} setSelectService={setSelectService} setSelectCategory={setSelectCategory} Logout={Logout}/>} />
              <Route path="/management-console/avast" element={<AvastServiceManagement Logout={Logout}/>} />
              <Route path="/management-console/gcp" element={<GCPServiceManagement setOpen={setOpen} setSelectService={setSelectService} setSelectCategory={setSelectCategory} Logout={Logout}/>} />
              <Route path="/management-console/ibm" element={<IBMServiceManagement Logout={Logout}/>} />
              <Route path="/management-console/gws" element={<GWServiceManagement setOpen={setOpen} setSelectService={setSelectService} setSelectCategory={setSelectCategory} Logout={Logout}/>} />
              <Route path="/user-management" element={<UserManagement customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout} />} />
              <Route path="/user-management/add-user" element={<AddSubAccountUser customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout} />} />
              <Route path="/user-management/clone-user" element={<CloneUser customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout} />} />
              <Route path="/user-management/edit-user" element={<EditUser customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout} />} />
              <Route path="/profile" element={<Profile customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout}/>} />
              <Route path="/profile/csp" element={<ProfileMicrosoftResellerPrerequisite customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout}/>} />
              <Route path="/profile/gws" element={<ProfileGoogleWorkspaceResellerPrerequisite customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout}/>} />
              <Route path="/subscriptions" element={<Subscriptions customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} Logout={Logout} />} />
              <Route path="/notifications" element={<Notifications customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setAuthentication} setHookRecentNotifications={setHookRecentNotifications} hookNotifications={hookNotifications} setHookNotifications={setHookNotifications} Logout={Logout} />} />

            </Routes>
            {!pageNotFound && <Footer formEndPoint={formEndPoint} setOpen={setOpen} customerIsAuthenticated={customerIsAuthenticated} />} {/* Footer for all screen */}
            <CookiePopup setCookieAccepted={setCookieAccepted} customerIsAuthenticated={customerIsAuthenticated} Logout={Logout} /> 
          </div>}
          <div className={maintainancePageActive ? "" : "d-none"}>
            <MaintainancePage setMaintainancePageActive={setMaintainancePageActive}/>
          </div>
        </CmsDetailsState>
      </>
  );
}

export default App;