import React, { useState } from 'react'
import styles from './quickAction.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';

const QuickActions = (props) => {
  const navigate = useNavigate();                         // for navigation from one page to another
  const location = useLocation();
  const [customerId, setCustomerId] = useState(location.state?.customerCode ? location.state?.customerCode : "");
  const [customerName, setCustomerName] = useState(location.state?.customerName ? location.state?.customerName : "");

  return (
    <div className={`${styles["quick-action"]} mt-4`} >
      <h3>Quick Actions</h3>
      <p className={`${styles["quick-actions-item"]} text-strong mb-0 py-1 cpointer`} onClick={() => props.setOpen(true)}>Support Ticket</p>
      {/* <p className={`${styles["quick-actions-item"]} text-strong mb-0 py-1 cpointer`} onClick={() => navigate("/billing/cloud-summary")}>View Invoices</p> */}
      <p className={`${styles["quick-actions-item"]} text-strong mb-0 py-1 cpointer`} onClick={() => navigate("/billing/invoice", { state: { searchData: customerName, customerId: customerId } })}>View Invoices</p>
      <p className={`${styles["quick-actions-item"]} text-strong mb-0 py-1 cpointer`} onClick={() => navigate("/cloud-provision", { state: { customerId: customerId } })}>Buy Subscription</p>
      <p className={`${styles["quick-actions-item"]} text-strong mb-0 py-1 cpointer`} onClick={() => { navigate('/management-console', { state: { searchData: customerName } }) }}>Manage Subscription</p>
    </div>
  )
}

export default QuickActions
