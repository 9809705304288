import React, { useEffect , useContext } from "react";
import "./styles.scss";
import PageNotFoundImage from "../../../Assets/Images/Illustrations/pageNotFound.svg";
import {Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const PageNotFound = (props) => {

    const navigate = useNavigate(); // React Router DOM v6

    // Footer Hidden in the page
    useEffect(() => {
    props.setPageNotFound(true);
    }, [])

  return (
    <div className='main-page-not-found'>
      <img src={PageNotFoundImage} className="image-page-not-found" alt="" />
      <h2 className="mt-4 mb-3">No Page Found</h2>
      <p className="mb-3">The requested URL was not found on this server.</p>
        <Button
          className="btn btn-lg btn-primary px-3"
          onClick={() => {
            navigate("/");
            props.setPageNotFound(false);
          }}
        >
          Go to Homepage
        </Button>
    </div>
  )
}

export default PageNotFound
