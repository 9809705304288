import React from 'react'
import { Row, Col, Button } from "react-bootstrap";
import styles from '../../CSPCustomerPrerequisite.module.scss';
import { useNavigate } from "react-router-dom";
import noCustomerImg from '../../../../../../../../../Assets/Images/Illustrations/noCustomer.svg';

const BuyDomainFailed = () => {
    const navigate = useNavigate();                                             // to use navigate function
    return (
        <div className={`${styles['success-panel']} text-center`}>
            <Row>
                <Col md={12}>
                    <div className={styles["no-data-cloud-invoice"]}>
                        <img alt="No Customer" src={noCustomerImg} className="no-customer" />
                        <h2 className='mb-2 mt-4'>No customer is selected</h2>
                        <p className='mb-2'> Please proceed to provision screen again.</p>
                        <Button onClick={() => navigate('/profile')}>Proceed</Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default BuyDomainFailed;


