import React, { useState, useEffect, useRef } from "react";
import { Check2 } from "react-bootstrap-icons";
import "./styles.scss";

const CustomNumberSimpleInput = ({ defaultValue, min, max, setUpdatedQuanity }) => {
  const [quantityEditedCheck, setQuantityEditedCheck] = useState(false);
  const qunatityRefBox = useRef();
  const [value, setValue] = useState(defaultValue);
  // Functions to increase and decrease the value
  const increaseValue = () => {
    setValue(parseInt(value) + 1);
  };

  const decreaseValue = () => {
    setValue(parseInt(value) - 1);
  };

  useEffect(() => {
    setUpdatedQuanity(value);
  }, [value]);

  return (
    <>
      <div className="d-flex">
        <div class="number-input" ref={qunatityRefBox}>
          <input
            type="number"
            min={min}
            id="number-input"
            max={max}
            defaultValue={value}
            value={value}
            onChange={(e) => {
              if (e.target.value >= min && e.target.value <= max) {
                setValue(e.target.value);
              } else if (e.target.value < min) {
                setValue(min);
              } else if (e.target.value === "") {
                setValue(min);
              } else if (e.target.value > max) {
                setValue(max);
              }
            }}
            onKeyDown={(e) => {
              if (e.code === "Minus") e.preventDefault();
            }}
          />
          <div className="btn-wrapper">
            {value < max ? (
              <button className="plus" onClick={() => increaseValue()}>
                +
              </button>
            ) : (
              <button className="plus disabled" disabled>
                +
              </button>
            )}

            {value > min ? (
              <button className="minus" onClick={() => decreaseValue()}>
                <span>&nbsp;</span>
              </button>
            ) : (
              <button className="minus disabled" disabled>
                <span>&nbsp;</span>
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="text-disabled col-12">
        <span className="text-small">Min {min}, </span>
        <span className="text-small">Max {max}</span>
      </div>
    </>
  );
};

export default CustomNumberSimpleInput;