import React, { useState, useEffect } from 'react';
import "./styles.scss";

const ScrollToTop = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisibility = () => {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 300) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="scroll-to-top" onClick={handleScrollToTop}>
            {visible && (
                <span className="scroll-to-top-arrow">
                    &nbsp;
                </span>
            )}
        </div>
    );
};

export default ScrollToTop;
