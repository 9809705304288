import React from 'react'
import { Row, Col, Button, Accordion } from "react-bootstrap";
import styles from '../../CSPCustomerPrerequisite.module.scss';



const GDAPAssignment = (props) => {

    // Define a handler for the right-click event
    const handleContextMenuGDAP = (event) => {
    // event.preventDefault(); // Prevent the default context menu from appearing

        // Update state on right-click
        props.setIsGDAPTermsChecked(true);
    };


    return (
        <>
            {/* GDAP Assignment */}
            <Accordion.Item className={`mt-5 done ${(props.domainCheckBlockErrors || props.delegateAdminRelationshipApproved === false) ? "accordionErrors" : ""} ${props.stepsCompletedCheck.isMCACompleted ? "" : ""}`} eventKey="gdap">
                <Accordion.Header>
                    <h3 className={`${styles['accordion-header-wrapper']}`}>
                        <label>
                            {props.stepsCompletedCheck.isGDAPCompleted ?
                                <span className="step-completed">&nbsp;</span>
                                :
                                <span className={`${styles['accordion-number']}`}>3</span>
                            }
                        </label>
                        <label className="pl-2">
                            <span className={`${styles['accordion-name']}`}>GDAP Assignment</span>
                        </label>
                    </h3>
                </Accordion.Header>
                {
                    <Accordion.Body>
                        <div className={`${styles['accordion-content']}`}>
                            <Row>
                                <Col md={12}>

                                    <Row>
                                        <Col md={12}>
                                            <p>To provision subscriptions for an existing Microsoft account, you need to setup GDAP Admin Relationship:</p>
                                        </Col>

                                        <Col md={12} className={`${styles['transition-domain-checks']}`}>
                                            <div className="d-flex">
                                                <span className={`${styles['round-number']}`}>1</span>
                                                <div className="mb-3">
                                                    <p>As part of this transition process, a new admin relationship request has been created. <a className="link-text" href={props.gdapURI} target="_blank"
                                                        onContextMenu={handleContextMenuGDAP} onClick={handleContextMenuGDAP}>Click here</a> and sign in using Admin Credentials, make sure to approve the admin relationship request.</p>

                                                    <h3>Microsoft assigned roles</h3>

                                                    <span>
                                                        {props.assignedRoles.length < 1 && <p className='text-center text-strong'> Loading assigned roles...</p>}
                                                        {props.assignedRoles.map((role) => {
                                                            return (
                                                                <>
                                                                    <span className='notes-confirmation gdap-bullet'></span><span className='mr-2'>{role}</span>
                                                                </>
                                                            )
                                                        })}
                                                    </span>

                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <span className={`${styles['round-number']}`}>2</span>
                                                <p>Once admin relationship request is approved from Microsoft admin portal, click on ‘Complete Transition’ .</p>
                                            </div>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                            <Row>

                                {props.isCompletinTtansaction &&
                                    <Col className="text-right">
                                        <div className="button-wrapper">
                                            <Button
                                                className="btn btn-lg btn-border-light px-4"
                                                variant="light"
                                                disabled
                                            >
                                                Back
                                            </Button>



                                            <Button
                                                className="btn btn-lg ml-2 px-4"
                                                variant="light"
                                                disabled
                                            >
                                                Completing Transition...
                                            </Button>

                                        </div>
                                    </Col>
                                }
                                {!props.isCompletinTtansaction &&
                                    <Col className="text-right">
                                        <div className="button-wrapper">
                                            <Button
                                                className="btn btn-lg btn-border-light px-4"
                                                variant="light"
                                                onClick={() => props.setActiveAccordion(["transitionDomain"])}
                                            >
                                                Back
                                            </Button>

                                            {
                                                props.isGDAPTermsChecked == true ?
                                                    <Button
                                                        className="btn btn-lg ml-2 px-4"
                                                        onClick={() => {
                                                            props.GDAPTransitionStatusPOST();
                                                            props.GetCustomerDomain();
                                                        }}>
                                                        Complete Transition
                                                    </Button>
                                                    :
                                                    <Button
                                                        className="btn btn-lg ml-2 px-4"
                                                        variant="light"
                                                        disabled
                                                    >
                                                        Complete Transition
                                                    </Button>
                                            }
                                        </div>
                                    </Col>
                                }
                            </Row>
                        </div>
                    </Accordion.Body>
                }
            </Accordion.Item>
            {/* {props.delegateAdminRelationshipApproved === false &&
                <p className='red text-small mt-3'>Note: Please redo Step 1.</p>
            } */}

        </>
    );
}

export default GDAPAssignment;